/*
 * --------------------------------------------------------------------------------
 * <copyright file="devices.ts" company="Bystronic Laser AG">
 *  Copyright (C) Bystronic Laser AG 2021-2024
 * </copyright>
 * --------------------------------------------------------------------------------
 */

import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';
import { currentUser } from '@/utils/permissionUtils';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';

export async function getAllDevices(): Promise<Device[]> {
  if (canCurrentUserHaveDevices()) {
    return await devicesService.getAll();
  } else {
    await devicesService.store.updateAll([]);
    return new Promise<Device[]>((resolve) => resolve([]));
  }
}

export async function getAllDevicesByCustomerId(customerId: number): Promise<Device[]> {
  if (canCurrentUserHaveDevices()) {
    return await devicesService.getAllByCustomerId(customerId);
  } else {
    await devicesService.store.updateAll([]);
    return new Promise<Device[]>((resolve) => resolve([]));
  }
}

function canCurrentUserHaveDevices() {
  return currentUser().consoles.includes(ConsoleEnum.WCC) ||
    currentUser().isGlobalAdmin ||
    currentUser().isServiceUserType ||
    currentUser().isTechnologyUser;
}
