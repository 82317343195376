// --------------------------------------------------------------------------------
// <copyright file="healthIndicatorsRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { DeviceElementHealthData } from '../chartsData';
import { metricsService } from '@/services/metrics.service';

export class HealthIndicatorsRetriever extends DataRetriever<DeviceElementHealthData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<DeviceElementHealthData[]> {
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<DeviceElementHealthData[]>(
      this.procedure,
      this.tenantIdDh,
      selectedDevices[0],
      {
        dateFrom,
        dateTo,
      },
      this.controller,
    );
  }
}
