// --------------------------------------------------------------------------------
// <copyright file="temperaturesChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { TemperaturesData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, SeriesOption } from 'echarts';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { isCategoryXAxis } from '@/utils/charts';
import { uniq } from '@/utils/array';
import { XAXisOption } from 'echarts/types/dist/shared';
import { metricsService } from '@/services/metrics.service';
import { tooltipFormatter } from './temperaturesChartTooltipFormatter';
import {
  CUTTING_HEAD_VERSION_CHANGE_SYMBOL,
  FIELD_MARKING_HEAD_VERSION_CHANGE,
  getHeadVersionChangeSeriesData,
} from './cuttingHeadCommons';

const DIFFERENCE_SERIES_SUFFIX = 'Difference';

export const ABSOLUTE_TEMPERATURE_X_AXIS_INDEX = 0;
export const TEMPERATURE_DIFFERENCE_X_AXIS_INDEX = 1;

export const HEAD_VERSION_CHANGES_Y_AXIS_INDEX = 1;
export const ABSOLUTE_TEMPERATURE_Y_AXIS_INDEX = 0;
export const TEMPERATURE_DIFFERENCE_Y_AXIS_INDEX = 2;

export class TemperaturesChartGenerator extends ChartGenerator<TemperaturesData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
    params: { [key: string]: any },
  ) {
    const deviceId = selectedDevices[0];
    return metricsService.getSSCMetrics<TemperaturesData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: timeSpan[0],
        dateTo: timeSpan[1],
        dateGrouping: timeAxisSpan,
        aggregate: params.aggregate,
        versionInformation: true,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: TemperaturesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);
    const uniqueDates = uniq(data.map((dataItem) => dataItem.bucket));

    return {
      title: {
        show: false,
      },
      legend: {
        icon: 'roundRect',
        type: 'scroll',
        bottom: 0,
        formatter: (name) => i18n.t(`report.${name}`).toString(),
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'weakFilter',
          xAxisIndex: [ABSOLUTE_TEMPERATURE_X_AXIS_INDEX, TEMPERATURE_DIFFERENCE_X_AXIS_INDEX],
          bottom: 40,
          labelFormatter: '',
        },
        {
          type: 'inside',
          filterMode: 'weakFilter',
          xAxisIndex: [ABSOLUTE_TEMPERATURE_X_AXIS_INDEX, TEMPERATURE_DIFFERENCE_X_AXIS_INDEX],
        },
      ],
      grid: [
        {
          top: 10,
          left: 40,
          right: 10,
          height: '38%',
        },
        {
          left: 40,
          right: 10,
          bottom: 100,
          height: '38%',
        },
      ],
      xAxis: [
        {
          gridIndex: 0,
          type: isCategoryAxis ? 'category' : 'time',
          data: isCategoryAxis ? uniqueDates : undefined,
          axisLabel: {
            show: true,
            hideOverlap: true,
          },
          axisTick: { show: false },
        },
        {
          gridIndex: 1,
          type: isCategoryAxis ? 'category' : 'time',
          data: isCategoryAxis ? uniqueDates : undefined,
        },
      ] as XAXisOption[],
      yAxis: [
        // Temperatures on absolute temperatures sub-chart
        {
          gridIndex: 0,
          type: 'value',
          scale: true,
          splitLine: {
            show: true,
          },
          axisLabel: {
            formatter: (value: number) => i18n.n(value),
          },
        },
        // Cutting head version changes on absolute temperatures sub-chart
        {
          gridIndex: 0,
          type: 'value',
          show: false,
        },
        // Temperatures difference sub-chart
        {
          gridIndex: 1,
          type: 'value',
          max: (value: any) => value.max + 0.1,
          min: (value: any) => value.min - 0.1,
          splitLine: {
            show: true,
          },
          axisLabel: {
            formatter: (value: number) => i18n.n(value),
          },
        },
      ],
      series: this.generateSeries(data, isCategoryAxis),
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) =>
          tooltipFormatter(params, data, parameters.timeAxisSpan!, isCategoryAxis),
      },
    };
  }

  private generateSeries(data: TemperaturesData[], isCategoryAxis: boolean): SeriesOption[] {
    // Adds directly the series name because those names dont always come in the first bucket, like did previously.
    return [
      'capacitiveTemperature',
      'capacitiveTemperatureDifference',
      'controllerConnectionTemperature',
      'controllerConnectionTemperatureDifference',
      'mirrorConnectionRHTTemperature',
      'mirrorConnectionRHTTemperatureDifference',
      'opticsModuleTemperature',
      'opticsModuleTemperatureDifference',
      'cuttingHeadSoftwareVersion',
    ].map((name) =>
      this.isHeadVersionChangeField(name)
        ? {
            data: getHeadVersionChangeSeriesData(data, isCategoryAxis),
            type: 'scatter',
            // FIXME: We should be able to load the image like below, but for some
            //   reason it looks blurry.
            // symbol: 'image:///images/cutting-head-version-change-mark.svg',
            symbol: CUTTING_HEAD_VERSION_CHANGE_SYMBOL,
            symbolSize: 15,
            symbolOffset: [0, '-50%'],
            color: '#000000',
            xAxisIndex: ABSOLUTE_TEMPERATURE_X_AXIS_INDEX,
            yAxisIndex: HEAD_VERSION_CHANGES_Y_AXIS_INDEX,
            z: 3, // Make the symbol appear above the rest of the series
          }
        : {
            data: this.getSeriesData(name as keyof TemperaturesData, data, isCategoryAxis),
            type: this.getChartType(name),
            name: this.getSeriesName(name),
            emphasis: { focus: 'series' },
            xAxisIndex: this.getXAxisIndex(name),
            yAxisIndex: this.getYAxisIndex(name),
          },
    );
  }

  private getChartType(fieldName: string): 'line' | 'bar' {
    return this.isAbsoluteTemperatureField(fieldName) ? 'line' : 'bar';
  }

  private getSeriesName(fieldName: string): string {
    const normalizedFieldName = fieldName.replace(DIFFERENCE_SERIES_SUFFIX, '');
    return normalizedFieldName.toString();
  }

  private getXAxisIndex(fieldName: string): number {
    return this.isAbsoluteTemperatureField(fieldName)
      ? ABSOLUTE_TEMPERATURE_X_AXIS_INDEX
      : TEMPERATURE_DIFFERENCE_X_AXIS_INDEX;
  }

  private getYAxisIndex(fieldName: string): number {
    return this.isAbsoluteTemperatureField(fieldName)
      ? ABSOLUTE_TEMPERATURE_Y_AXIS_INDEX
      : TEMPERATURE_DIFFERENCE_Y_AXIS_INDEX;
  }

  private isAbsoluteTemperatureField(fieldName: string): boolean {
    return fieldName.endsWith('Temperature');
  }

  private isHeadVersionChangeField(fieldName: string): boolean {
    return fieldName === FIELD_MARKING_HEAD_VERSION_CHANGE;
  }

  private getSeriesData(
    fieldName: keyof TemperaturesData,
    data: TemperaturesData[],
    isCategoryAxis: boolean,
  ): any {
    return isCategoryAxis
      ? data.map((dataItem) => dataItem[fieldName])
      : data.map((dataItem) => [dataItem.bucket, dataItem[fieldName]]);
  }
}
