
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SearchBox extends Vue {
  @Prop({ default: '', type: String })
  private value!: string;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  private searchText = '';
  private isSearchBoxShown = false;

  @Ref('text-input')
  private textInput: any;


  @Watch('value', { immediate: true })
  private onValueChanged() {
    this.searchText = this.value;
  }

  private async activateSearch() {
    this.isSearchBoxShown = true;

    this.$nextTick(() => {
      this.textInput.focus();
    });
    this.$emit('active-change', true);
  }

  private async closeSearch() {
    this.isSearchBoxShown = false;
    this.searchText = '';
    this.$emit('active-change', false);
  }

  @Watch('searchText')
  private onSearchTextChanged() {
    this.$emit('input', this.searchText);
  }
}
