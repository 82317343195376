// --------------------------------------------------------------------------------
// <copyright file="BetaFeature.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

/**
 * Defines available beta features that can be assigned to a user.
 */
export enum BetaFeature {
  WorkCenterConsoleCutIntradayStates = 'work-center-console-cut-intraday-states',
  WorkCenterConsoleCutCurrentWorkload = 'work-center-console-cut-current-workload',
  WorkCenterConsoleBendSummary = 'work-center-console-bend-summary',
  WorkCenterConsoleBendIntradayStates = 'work-center-console-bend-intraday-states',
}
