// --------------------------------------------------------------------------------
// <copyright file="version.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

function parseVersion(version: string): number[] {
  return version
    .split('.')
    .slice(0, 3)
    .map((v) => parseInt(v, 10));
}

export function versionIsGreaterThanOrEqualTo(
  versionTarget: string,
  versionCurrent: string,
): boolean {
  const currentVersionParsed = parseVersion(versionCurrent);
  const targetVersionParsed = parseVersion(versionTarget);

  for (let i = 0; i < Math.max(currentVersionParsed.length, targetVersionParsed.length); i++) {
    const currentSegment = currentVersionParsed[i] || 0;
    const targetSegment = targetVersionParsed[i] || 0;

    if (currentSegment > targetSegment) {
      return true;
    } else if (currentSegment < targetSegment) {
      return false;
    }
    // If they are equal, we continue with the next segment
  }
  // If all segments are equal, the version is equal
  return true;
}
