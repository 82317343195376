
import { Component, Vue } from 'vue-property-decorator';
import { TechnologyTenantDevicesTableItem } from '@/models/technologyTenantDevicesTableItem';
import { Routes } from '@/router/routes';
import { Logger } from '@/utils/logger';
import i18n from '@/i18n';
import OldTimeZoneIndicator from '@/components/OldTimeZoneIndicator.vue';
import { tenantsService } from '@/services/tenants.service';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { metricsService } from '@/services/metrics.service';

@Component({
  components: {
    TimeZoneIndicator: OldTimeZoneIndicator,
    TableWithActions,
  },
  computed: {
    Routes: () => Routes,
  },
})
export default class TechnologyConsoleTenantDevices extends Vue {
  private devices: TechnologyTenantDevicesTableItem[] = [];
  private isLoading = true;

  private readonly tableFields: Array<keyof TechnologyTenantDevicesTableItem> = [
    'deviceName',
    'equipmentNumber',
    'firstDataDate',
    'hoursPowerOn',
    'cuttingHours',
    'bmcVersion',
    'bvcVersion',
    'cuttingHeadType',
    'cuttingHeadSoftwareVersion',
    'cuttingHeadSerialNumber',
    'cuttingHeadBaseProductNumber',
    'cuttingHeadBeamShaperSerialNumber',
  ];
  private tenantTimeZone: string | null = null;
  private controller = new AbortController();

  private async mounted() {
    let tenant;

    try {
      tenant = await tenantsService.getByIdDh(+this.$route.params.tenantIdDh);
    } catch (e) {
      Logger.error(
        `Error getting tenant with idDh '${this.$route.params.tenantIdDh}'. Redirecting to home...`,
      );
      await this.$router.push({ name: Routes.Index });
      return;
    }

    this.tenantTimeZone = tenant.timeZone;

    metricsService
      .getDevicesInfo<TechnologyTenantDevicesTableItem[]>(
        +this.$route.params.tenantIdDh!,
        this.controller,
      )
      .then((devices) => (this.devices = devices))
      .finally(() => (this.isLoading = false));
  }

  private goToDevice(device: TechnologyTenantDevicesTableItem) {
    this.$router.push({
      name: Routes.TechnologyConsoleDevice,
      params: { deviceId: device.deviceId },
    });
  }

  private beforeDestroy() {
    this.controller.abort();
  }

  private addTitleToCuttingHeadColumns(column: any): any {
    const field = column.$options.propsData.field;

    if (this.isCuttingHeadField(field)) {
      return {
        title: i18n.t(`report.tenant_technology_devices_table.${field}_long`),
      };
    }
  }

  private isCuttingHeadField(field: string) {
    return field.startsWith('cuttingHead');
  }
}
