
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MaterialGroup } from '@/models/materialGroup';
import { materialsService } from '@/services/materials.service';
import { Material } from '@/models/material';

@Component
export default class MaterialGroupAssignmentModal extends Vue {
  @Prop({ required: true })
  public materialGroups!: MaterialGroup[];

  @Prop({ required: true })
  public material!: Material;

  public materialGroupId = 0;

  private isSubmitting = false;

  public async onSubmit() {
    this.material.materialGroupId = this.materialGroupId;

    this.isSubmitting = true;
    materialsService
      .update(this.material)
      .then(() => {
        this.$emit('save');
        this.$emit('close');
      })
      .finally(() => (this.isSubmitting = false));
  }
}
