
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isEmpty, isNil, uniqueId } from '@/utils/misc';
import { values } from '@/utils/enum';
import { AlertWorkflowStatus } from '@/models/alertWorkflow';
import i18n from '@/i18n';

@Component({
  computed: {
    AlertWorkflowStatus: () => AlertWorkflowStatus,
  },
  methods: { isNil, values },
})
export default class AlertWorkflowStatusSelect extends Vue {
  @Prop()
  private value?: AlertWorkflowStatus | AlertWorkflowStatus[];

  @Prop({ default: false, type: Boolean })
  private multiple!: boolean;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({ default: () => [] })
  private disabledStatuses!: AlertWorkflowStatus[];

  @Prop({ default: false, type: Boolean })
  private hiddenLabel!: boolean;

  @Prop({ default: false, type: Boolean })
  private hiddenDropdownIcon!: boolean;

  @Prop({ default: false, type: Boolean })
  private isFontSizeSmall!: boolean;

  private componentId = uniqueId();

  private get multipleSelectionText() {
    return isEmpty(this.value)
      ? ''
      : i18n.t('e_service_center.alert_workflow_status_select.multiple_selected', {
          count: (this.value as AlertWorkflowStatus[]).length,
        });
  }

  private isStatusDisabled(status: AlertWorkflowStatus): boolean {
    return this.disabledStatuses.some((s) => s === status);
  }

  private isStatusActive(status: AlertWorkflowStatus): boolean {
    if (isNil(this.value)) {
      return false;
    }

    if (Array.isArray(this.value)) {
      return this.value.includes(status);
    }

    return this.value === status;
  }
}
