// --------------------------------------------------------------------------------
// <copyright file="dashboardDefinition.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import WidgetDefinition, { deserializeWidgetDefinition } from '@/models/Charts/widgetDefinition';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { WidgetEnum } from '../enums/WidgetEnum';
import { LayoutSlot, TOTAL_COLUMNS, TOTAL_ROWS } from '@/views/dashboard/layout-grid';

// Rows by columns
export enum DashboardLayout {
  OneByOne = 1,
  OneByTwo = 2,
  TwoByTwo = 3,
  TwoByThree = 4,
  BCOverviewLike = 5, // One 2x2 widget on the left, two 1x1 widgets on the right
  InvertedBCOverviewLike = 6, // One 2x2 widget on the right, two 1x1 widgets on the left
  TwoByOne = 7, // Two rows, one column
  TwoByThreeMergeTopRight = 8,
  TwoByThreeMergeTopLeft = 9,
  TwoByThreeMergeBottomRight = 10,
  TwoByThreeMergeRight = 11,
  TwoByThreeMergeLeft = 12,
  TwoByThreeAlternatingDoubleSingle = 13,
}

export const availableDashboardLayouts: Record<DashboardLayout, LayoutSlot[]> = {
  // Not all possible constants have been used because it ends up looking
  // cumbersome. However, if you think some way or another is better, feel free
  // to add more or remove the ones used.
  [DashboardLayout.OneByOne]: [{ column: 0, row: 0, width: TOTAL_COLUMNS, height: TOTAL_ROWS }],
  [DashboardLayout.OneByTwo]: [
    { column: 0, row: 0, width: 6, height: TOTAL_ROWS },
    { column: 6, row: 0, width: 6, height: TOTAL_ROWS },
  ],
  [DashboardLayout.TwoByTwo]: [
    { column: 0, row: 0, width: 6, height: TOTAL_ROWS / 2 },
    { column: 6, row: 0, width: 6, height: TOTAL_ROWS / 2 },
    { column: 0, row: 4, width: 6, height: TOTAL_ROWS / 2 },
    { column: 6, row: 4, width: 6, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.TwoByThree]: [
    { column: 0, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 0, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 4, width: 4, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.BCOverviewLike]: [
    { column: 0, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 0, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 0, width: 8, height: TOTAL_ROWS },
  ],
  [DashboardLayout.InvertedBCOverviewLike]: [
    { column: 0, row: 0, width: 8, height: TOTAL_ROWS },
    { column: 8, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 4, width: 4, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.TwoByOne]: [
    { column: 0, row: 0, width: TOTAL_COLUMNS, height: TOTAL_ROWS / 2 },
    { column: 0, row: 4, width: TOTAL_COLUMNS, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.TwoByThreeMergeTopRight]: [
    { column: 0, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 0, width: 8, height: TOTAL_ROWS / 2 },
    { column: 0, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 4, width: 4, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.TwoByThreeMergeTopLeft]: [
    { column: 0, row: 0, width: 8, height: TOTAL_ROWS / 2 },
    { column: 8, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 0, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 4, width: 4, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.TwoByThreeMergeBottomRight]: [
    { column: 0, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 0, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 4, width: 8, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.TwoByThreeMergeRight]: [
    { column: 0, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 0, width: 4, height: TOTAL_ROWS },
    { column: 0, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 4, width: 4, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.TwoByThreeMergeLeft]: [
    { column: 0, row: 0, width: 4, height: TOTAL_ROWS },
    { column: 4, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 8, row: 4, width: 4, height: TOTAL_ROWS / 2 },
  ],
  [DashboardLayout.TwoByThreeAlternatingDoubleSingle]: [
    { column: 0, row: 0, width: 8, height: TOTAL_ROWS / 2 },
    { column: 8, row: 0, width: 4, height: TOTAL_ROWS / 2 },
    { column: 0, row: 4, width: 4, height: TOTAL_ROWS / 2 },
    { column: 4, row: 4, width: 8, height: TOTAL_ROWS / 2 },
  ],
};

export default class DashboardDefinition {
  static defaultDashboard(): DashboardDefinition {
    const wd = new WidgetDefinition(
      WidgetEnum.FactoryOverview,
      '',
      FilterTimeSpanEnum.None,
      [],
      undefined,
    );
    wd.index = 0;
    return new DashboardDefinition('Factory', [wd], DashboardLayout.OneByOne);
  }

  constructor(
    public name: string,
    public widgetDefinitions: WidgetDefinition[],
    public layout: DashboardLayout,
  ) {}

  static GetCopy(definition: DashboardDefinition): DashboardDefinition {
    return new DashboardDefinition(
      definition.name,
      [
        ...definition.widgetDefinitions.map((wd, index) =>
          !!wd.getCopy ? wd.getCopy({}) : deserializeWidgetDefinition(wd, index),
        ),
      ],
      definition.layout,
    );
  }

  deleteWidget(index: number): void {
    this.widgetDefinitions = [...this.widgetDefinitions.filter((wd) => wd.index !== index)];
  }

  addWidget(wd: WidgetDefinition): void {
    this.widgetDefinitions = [...this.widgetDefinitions, wd];
  }

  clearWidgets(): void {
    this.widgetDefinitions = [];
  }

  clone(): DashboardDefinition {
    return new DashboardDefinition(this.name, this.widgetDefinitions, this.layout);
  }
}
