// --------------------------------------------------------------------------------
// <copyright file="alert.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { AlertAction, AlertMetric, AlertOperator } from './enums/AlertsEnums';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';

export class Alert {
  static GetEmpty(): Alert {
    return new Alert(null, 0, null, AlertMetric.None, AlertOperator.None, 0, AlertAction.None, []);
  }

  static GetCopy(original: Alert): Alert {
    return new Alert(
      original.id,
      original.tenantId,
      original.deviceId,
      original.metric,
      original.operator,
      original.threshold,
      original.action,
      [...(original.recipients ?? [])],
      original.dateGrouping,
      original.deviceName,
      original.variable,
    );
  }

  constructor(
    public id: number | null,
    public tenantId: number | null,
    public deviceId: number | null,
    public metric: AlertMetric,
    public operator: AlertOperator,
    public threshold: number,
    public action: AlertAction | null,
    public recipients: number[],
    // Only needed for Care alerts (required)
    public dateGrouping?: FilterTimeAxisSpanEnum,
    // Received when requesting an alerts list
    public deviceName?: string,
    public variable?: string,
    // Used when metric is ErrorCountBySource or WarningCountBySource
    public eventSources?: string[],
    // Used when metric is ErrorCount or WarningCount
    public eventCodes?: string[],
    // Received when requesting an alerts list
    public tenantName?: string,
  ) {}
}
