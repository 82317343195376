// --------------------------------------------------------------------------------
// <copyright file="laserAccumulatedConsumptionTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { LaserConsumptionTimelineData } from './chartsData';
import { SeriesOption } from 'echarts';
import { LaserConsumptionTimelineGenerator } from '@/models/Charts/laserConsumptionTimelineGenerator';
import { LaserAccumulatedConsumptionKxSeriesGeneratorHelper } from '@/models/Charts/laserAccumulatedConsumptionKxSeriesGeneratorHelper';

export class LaserAccumulatedConsumptionTimelineGenerator extends LaserConsumptionTimelineGenerator {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    super(procedure, tenantIdDh);
  }

  protected generateKxSeries(
    data: LaserConsumptionTimelineData[],
    isCategoryAxis: boolean,
  ): SeriesOption[] {
    return new LaserAccumulatedConsumptionKxSeriesGeneratorHelper(
      data,
      1,
      isCategoryAxis,
    ).generate();
  }
}
