
// tslint:disable:member-access
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class TagInput extends Vue {
  // sets the selected tags
  // We need it to be nullable because of EventsTableExpandedView.
  // See comment on select*.
  @Prop({ default: () => [] })
  private value!: string[] | null;

  @Prop({ default: () => [] })
  private tags!: string[];

  @Prop({ default: false })
  private loading!: boolean;

  @Prop()
  private id?: string;

  @Prop()
  private placeholder?: string;

  @Prop({ default: false })
  private appendToBody!: boolean;

  @Prop({ default: false })
  private allowNew!: boolean;

  private valueInternal: string[] = []; // selected tags
  private remainingTags: string[] = [];
  private filteredTags: string[] = [];

  private mounted() {
    this.valueInternal = this.value ?? [];
    this.filteredTags = this.remainingTags = this.excludeSelectedTags(this.tags);
  }

  private excludeSelectedTags(tags: string[]) {
    return tags.filter((tag) => !this.valueInternal.includes(tag));
  }

  @Watch('value')
  private onValueChanged() {
    this.valueInternal = this.value ?? [];
  }

  @Watch('tags')
  private onTagsChanged() {
    this.filteredTags = this.remainingTags = this.tags;
  }

  private onSelectedTagsChanged(selectedTags: string[]) {
    this.remainingTags = this.filteredTags = this.excludeSelectedTags(this.tags);
    this.$emit('input', selectedTags);
  }

  private updateFilteredTags(filter: string): void {
    this.filteredTags = this.remainingTags.filter((tag) =>
      tag.toLowerCase().includes(filter.trim().toLowerCase()),
    );
  }
}
