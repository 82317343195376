// --------------------------------------------------------------------------------
// <copyright file="abstractGanttChartTableGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { last } from '@/utils/array';
import { ChartGenerator, ProcedureName } from '../abstract/chartGenerator';
import { GanttTableData } from '../chartsData';
import { isEmpty } from '@/utils/misc';
import { GeneratorParams } from '../generatorParams';
import {
  CustomSeriesRenderItemAPI,
  CustomSeriesRenderItemReturn,
  EChartsOption,
  graphic,
} from 'echarts';
import moment from 'moment';
import { formatDuration } from '@/utils/dates';

export abstract class AbstractGanttChartTableGenerator<TState> extends ChartGenerator<
  GanttTableData<TState>[]
> {
  constructor(procedure: ProcedureName) {
    super(procedure);
  }

  override updateOptions(
    data: GanttTableData<TState>[],
    parameters: GeneratorParams,
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const mergedData = this.getMergedData(data);
    return {
      ...this.getCommonEChartsOption(parameters),
      series: this.getStates(data)
        .map((state) => ({
          type: 'custom' as const,
          renderItem: this.renderItem,
          itemStyle: {
            opacity: 0.8,
          },
          encode: {
            x: [0, 1],
          },
          // This is necessary so as not to cause the mysterious error that occurred when applying multiple filters, which caused the Gantt to resize but not adapt and leave blank spaces.
          animation: false,
          name: state as string,
          data: mergedData.filter((x) => x.state === state),
        }))
        .filter((series) => !isEmpty(series.data)),
    };
  }

  private thereIsGapBetweenIntervals(
    former: GanttTableData<TState>,
    latter: GanttTableData<TState>,
  ): boolean {
    return latter.startTimestamp - former.endTimestamp > 1;
  }

  private areIntervalsNeighborsWithSameState(
    former: GanttTableData<TState>,
    latter: GanttTableData<TState>,
  ): boolean {
    return former.state === latter.state && !this.thereIsGapBetweenIntervals(former, latter);
  }

  // Two intervals of the same state might be split if a shift change occurred during it or if the data
  // is too recent. Nevermind the reason, these intervals ought to be merged, for visual purposes.
  // This method merges the data.
  private mergeIntervals(data: GanttTableData<TState>[]): GanttTableData<TState>[] {
    const sortedData = data.sort((a, b) => {
      if (a.startTimestamp === b.startTimestamp) {
        return a.endTimestamp - b.endTimestamp;
      } else {
        return a.startTimestamp - b.startTimestamp;
      }
    });
    const mergedEntries: GanttTableData<TState>[] = [];
    for (const entry of sortedData) {
      if (
        isEmpty(mergedEntries) ||
        !this.areIntervalsNeighborsWithSameState(last(mergedEntries)!, entry)
      ) {
        mergedEntries.push(entry);
      } else {
        const lastEntry = mergedEntries.pop()!;
        const newEntry: GanttTableData<TState> = {
          ...lastEntry,
          endTimestamp: entry.endTimestamp,
        };
        mergedEntries.push(newEntry);
      }
    }
    return mergedEntries;
  }

  protected getCommonEChartsOption(parameters: GeneratorParams): EChartsOption {
    return {
      tooltip: {
        confine: false,
        appendToBody: true,
        formatter: (params: any) => this.getTooltipGantt(params),
      },
      grid: {
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
      xAxis: {
        type: 'time',
        min: moment(parameters.ganttChartBounds![0]).valueOf(),
        max: moment(parameters.ganttChartBounds![1]).valueOf(),
      },
      yAxis: {
        data: [0], // Anything goes here, as long as it has length 1
        show: false,
      },
      legend: {
        show: false,
      },
    };
  }

  private getTooltipGantt(params: any) {
    const startTime = moment(params.value[0]);
    const endTime = moment(params.value[1]);
    const dateStr = startTime.format('YYYY-MM-DD');
    const duration = formatDuration(endTime.unix() - startTime.unix());
    return (
      `<p style="color:rgba(74,74,74, 0.5)"> ${dateStr}</p>` +
      `<p>${params.marker} <span style="font-weight: bold">${this.getLocalizedStateName(
        params.data.state,
      )}</p>` +
      `<p>${startTime.format('HH:mm:ss')} - ${endTime.format('HH:mm:ss')}</p>` +
      `${duration}`
    );
  }

  protected getMergedData(data: GanttTableData<TState>[]) {
    return this.mergeIntervals(data).map((x, index) => ({
      value: [x.startTimestamp * 1000, x.endTimestamp * 1000],
      itemStyle: {
        ...this.getCustomItemStyle(x.state),
        color: this.getStateColor(x.state, index),
      },
      state: x.state,
    }));
  }

  protected renderItem(params: any, api: CustomSeriesRenderItemAPI): CustomSeriesRenderItemReturn {
    // The Gantt data is mapped into an array of length 2 in getMergedData:
    // value: [start_timestamp in milliseconds, end_timestamp in milliseconds]
    // Calling api.value(0) will retrieve the start_timestamp, while api.value(1) will retrieve the end_timestamp
    // api.coord([x, y]) will map these values to coordinates in the canvas

    // api.coord returns [x, y] coordinates, but having only one device's data per graph,
    // we don't need to know about the Y coordinates.
    const [startX] = api.coord([api.value(0), 0]);
    const [endX] = api.coord([api.value(1), 0]);
    const height = params.coordSys.height;

    return {
      type: 'rect',
      transition: ['shape'],
      // graphic.clipRectByRect is used to calculate what part of a status block would clip out of the chart.
      // All the clipped pixels are deleted (or not rendered at all).
      shape: graphic.clipRectByRect(
        // This object represents the status block
        {
          x: startX,
          y: 0,
          width: endX - startX,
          height,
        },
        // params.coordSys stores the coordinates and dimensions of the canvas,
        // so this rect represents the whole canvas
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height,
        },
      ),
      style: api.style(),
    };
  }

  protected abstract getStateColor(state: TState, index: number): string;
  protected getCustomItemStyle(state: TState): any {
    return {};
  }
  protected abstract getStates(data: GanttTableData<TState>[]): TState[];
  protected abstract getLocalizedStateName(state: TState): string;
}
