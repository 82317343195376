// --------------------------------------------------------------------------------
// <copyright file="eventTypeByTimeTooltipFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import {
  ByModuleEventsData,
  ImportantMessageLevel,
  EventTypeByTimeData,
} from '@/models/Charts/chartsData';
import { dateWithGroupingTitleGenerator } from '@/models/Charts/tooltipFormatter';
import i18n from '@/i18n';
import { formatPercentage } from '@/utils/number';
import { isNil } from '@/utils/misc';

export const MAX_MODULES_COUNT = 5;

export function tooltipFormatter(
  params: any,
  data: EventTypeByTimeData[],
  dateGrouping: FilterTimeAxisSpanEnum,
): string {
  return getTitleHtml(params, dateGrouping) + getBodyHtml(params, data);
}

function getTitleHtml(params: any, dateGrouping: FilterTimeAxisSpanEnum) {
  return `
  <div class="tooltip-title" data-testid="title">
    ${dateWithGroupingTitleGenerator(params, dateGrouping)}
  </div>
  `;
}

function getBodyHtml(params: any, data: EventTypeByTimeData[]) {
  // Depending on the date grouping
  const date = params[0].value?.[0] ?? params[0].axisValueLabel;

  const tooltipEntries: string[] = [];

  if (isSeriesEnabledForType(ImportantMessageLevel.Error, params)) {
    const errorsItem = getDataItemByDateAndType(date, data, ImportantMessageLevel.Error);
    const errorMarker = getMarker(params, ImportantMessageLevel.Error);
    const errorTooltipEntries = (errorsItem?.byModule ?? []).map((moduleItem) =>
      getNumericValueHtml(errorMarker, moduleItem, errorsItem?.count),
    );
    tooltipEntries.push(...errorTooltipEntries);
  }

  if (isSeriesEnabledForType(ImportantMessageLevel.Warning, params)) {
    const warningsItem = getDataItemByDateAndType(date, data, ImportantMessageLevel.Warning);
    const warningMarker = getMarker(params, ImportantMessageLevel.Warning);
    const warningTooltipEntries = (warningsItem?.byModule ?? []).map((moduleItem) =>
      getNumericValueHtml(warningMarker, moduleItem, warningsItem?.count),
    );
    tooltipEntries.push(...warningTooltipEntries);
  }

  return tooltipEntries.slice(0, MAX_MODULES_COUNT).join('\n');
}

function getDataItemByDateAndType(
  date: string,
  data: EventTypeByTimeData[],
  type: ImportantMessageLevel,
): EventTypeByTimeData | undefined {
  return data.find((dataItem) => dataItem.date === date && dataItem.type === type.toString());
}

function getMarker(params: any, type: ImportantMessageLevel): string {
  return getTypeParam(params, type)?.marker ?? '';
}

function isSeriesEnabledForType(type: ImportantMessageLevel, params: any): any {
  return !isNil(getTypeParam(params, type));
}

function getTypeParam(params: any, type: ImportantMessageLevel): any | undefined {
  return params.find((seriesParam: any) => seriesParam.seriesName === type.toString());
}

function getNumericValueHtml(
  marker: string,
  moduleItem: ByModuleEventsData,
  totalEvents = 0,
): string {
  const percentage = totalEvents !== 0 ? (moduleItem.count / totalEvents) * 100 : 0;

  const valueString = `
    <span data-testid="count">
      ${i18n.n(moduleItem.count, { maximumFractionDigits: 2 })}
    </span>
    <span data-testid="percentage">
      (${formatPercentage(percentage)})
    </span>
  `;
  return getValueHtml(marker, moduleItem.name, valueString);
}

function getValueHtml(marker: string, name: string, value: string): string {
  return `
    <div class="tooltip-series-value-row">
      <div class="series-marker-name">
        ${marker}
        
        <span data-testid="series-name">
          ${name}
        </span>
      </div>
      <div class="series-value">
        ${value}
      </div>
    </div>
  `;
}
