
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableWithActions, {
  truncatedValueColumnEllipsisTitle,
} from '@/components/tableWithActions/TableWithActions.vue';
import { AlertOccurrence } from '@/models/alertOccurrence';
import {
  matchesDateGrouping,
  matchesEventCode,
  matchesEventSource,
  matchesMetric,
  matchesOperator,
  matchesThreshold,
  matchesVariable,
  formatWithUnit,
  translateKpi,
  translateOperator,
} from '@/utils/alerts';
import { translateDateGrouping } from '@/utils/dates';
import { decimalFormatter } from '@/utils/number';
import { isEmpty } from '@/utils/misc';
import i18n from '@/i18n';
import { matchIgnoringCase } from '@/utils/table';

@Component({
  methods: {
    matchesDateGrouping,
    matchesMetric,
    matchesOperator,
    matchesThreshold,
    decimalFormatter,
    formatWithUnit,
    isEmpty,
    translateDateGrouping,
    translateKpi,
    translateOperator,
    truncatedValueColumnEllipsisTitle,
  },
  components: {
    TableWithActions,
  },
})
export default class AlertOccurrencesTable extends Vue {
  @Prop({ default: 20 })
  private rowsPerPage!: number;

  @Prop({ required: true })
  private alertOccurrences!: AlertOccurrence[] | null;

  @Prop({ default: false })
  private loading!: boolean;

  private alertHasSources(alertOccurrence: AlertOccurrence): boolean {
    return !isEmpty(alertOccurrence.eventSources);
  }

  private alertHasCodes(alertOccurrence: AlertOccurrence): boolean {
    return !isEmpty(alertOccurrence.eventCodes);
  }

  private additionalInformationSearch(alertOccurrence: AlertOccurrence, input: string) {
    return (
      matchesVariable(alertOccurrence, input) ||
      matchesEventSource(alertOccurrence, input) ||
      matchesEventCode(alertOccurrence, input) ||
      (alertOccurrence.variable &&
        matchIgnoringCase(i18n.t('report.alert_occurrences_table.variable').toString(), input)) ||
      (alertOccurrence.eventSources &&
        matchIgnoringCase(
          i18n.t('report.alert_occurrences_table.event_sources').toString(),
          input,
        )) ||
      (alertOccurrence.eventCodes &&
        matchIgnoringCase(i18n.t('report.alert_occurrences_table.event_codes').toString(), input))
    );
  }
}
