
import { Component, Vue, Watch } from 'vue-property-decorator';
import { InboxFilterValues } from '@/views/careConsole/commons/model';
import { areArraysEqual } from '@/utils/array';
import CustomTableFilters from '@/components/tableWithActions/CustomTableFilters.vue';
import { isEmpty, isNil } from '@/utils/misc';
import AlertWorkflowStatusSelect from '@/components/inputs/AlertWorkflowStatusSelect.vue';
import { AlertWorkflowStatus } from '@/models/alertWorkflow';
import { dateToShortISOString, isDate } from '@/utils/dates';
import { getEnumValuesFromQueryParameterOrDefault } from '@/utils/url';

/**
 * Filters form for the alert workflows table in EServiceCenterInbox.
 */
@Component({
  components: {
    AlertWorkflowStatusSelect,
    CustomTableFilters,
  },
})
export default class InboxFilters extends Vue {
  private static readonly defaultFilterValues: InboxFilterValues = Object.freeze({
    subsidiaryName: [],
    customerName: [],
    equipmentNumber: [],
    equipmentName: [],
    equipmentModel: [],
    status: [],
    assigneeName: [],
    alertMetric: [],
    crmCaseNumber: [],
    createdDate: null,
    processingDate: null,
    postponedDate: null,
    resolutionDate: null,
  });

  private filters = structuredClone(InboxFilters.defaultFilterValues);
  private isUpdatingUrlQueryParametersFromFilters = false;

  private mounted() {
    this.filters = structuredClone(InboxFilters.defaultFilterValues);
    this.updateFiltersFromUrlQueryParameters();
  }

  clearAllFilters() {
    this.filters = structuredClone(InboxFilters.defaultFilterValues);
    this.onFiltersApplied();
  }

  private onFiltersApplied() {
    this.updateUrlQueryParametersFromFilters();
    this.emitFilters();
  }

  /**
   * Updates the URL's query parameters with the current values of the table
   * filters.
   *
   * Example:
   *   filter = { customerName = ['name1', 'name2'] }
   *   URL query parameters: ?customerName=name1&customerName=name2
   */
  private async updateUrlQueryParametersFromFilters() {
    const queryParameters = Object.entries(this.filters)
      .filter(([_, value]) => !isEmpty(value))
      .map(([key, arrayValue]) => {
        let stringValues = arrayValue;

        if (typeof arrayValue[0] === 'number') {
          // AlertWorkflowStatus filter
          stringValues = arrayValue.map((valueItem: number) => AlertWorkflowStatus[valueItem]);
        } else if (arrayValue[0] instanceof Date) {
          stringValues = arrayValue.map((valueItem: Date) => dateToShortISOString(valueItem));
        }

        return { [key]: stringValues };
      })
      .reduce((resultObject, currentValue) => ({ ...resultObject, ...currentValue }), {});

    this.isUpdatingUrlQueryParametersFromFilters = true;
    await this.$router.replace({ query: queryParameters });
    this.isUpdatingUrlQueryParametersFromFilters = false;
  }

  /**
   * Updates the filter values with the values from the URL's query parameters.
   *
   * For each query parameters the value of the matching filter is updated by
   * deserializing the query parameter values. Invalid values are ignored.
   *
   * Example:
   *   URL query parameters: ?status=New&customerName=InvalidStatus
   *   filter = { status = [AlertWorkflowStatus.New] }
   */
  @Watch('$route.query')
  // public for testing
  updateFiltersFromUrlQueryParameters() {
    if (this.isUpdatingUrlQueryParametersFromFilters) {
      // When the filters change, the URL is updated, so no need to update the
      // filters again
      return;
    }

    this.filters = structuredClone(InboxFilters.defaultFilterValues);

    (Object.entries(this.$route.query ?? {}) as [keyof InboxFilterValues, any][]).forEach(
      ([key, value]) => {
        if (TEXT_LIST_FILTERS.includes(key)) {
          this.filters[key] = Array.isArray(value) ? value : ([value] as any);
        } else if (DATE_RANGE_FILTERS.includes(key)) {
          this.filters[key] = this.getDateRangeFilterValueFromQueryParameter(key, value) as any;
        } else if (key === 'status') {
          this.filters.status = this.getStatusFilterValueFromQueryParameter(value);
        }
      },
    );

    if (this.areApplying) {
      // Don't emit if the URL doesn't contain filters
      this.emitFilters();
    }
  }

  private getDateRangeFilterValueFromQueryParameter(
    key: keyof InboxFilterValues,
    value: string | Array<string | null>,
  ): [Date, Date] {
    if (!Array.isArray(value) || value.length < 2) {
      return InboxFilters.defaultFilterValues[key] as [Date, Date];
    }

    const dateRange = (value as string[])
      .slice(0, 2)
      .map((stringDate: string) => new Date(stringDate))
      .filter((date) => isDate(date));

    if (dateRange.length !== 2) {
      // Some of the dates was invalid, return default filter value
      return InboxFilters.defaultFilterValues[key] as [Date, Date];
    }

    return dateRange as [Date, Date];
  }

  private getStatusFilterValueFromQueryParameter(
    queryParameterValue: string | Array<string | null>,
  ): AlertWorkflowStatus[] {
    return getEnumValuesFromQueryParameterOrDefault(
      queryParameterValue,
      AlertWorkflowStatus,
      InboxFilters.defaultFilterValues.status,
    );
  }

  private get areApplying(): boolean {
    return (
      this.areTextListFiltersApplying ||
      this.areDateRangeFiltersApplying ||
      !isEmpty(this.filters.status)
    );
  }

  private get areTextListFiltersApplying(): boolean {
    return TEXT_LIST_FILTERS.some(
      (textListFilterKey) =>
        !areArraysEqual(
          this.filters[textListFilterKey as keyof InboxFilterValues] as string[],
          InboxFilters.defaultFilterValues[
            textListFilterKey as keyof InboxFilterValues
          ] as string[],
        ),
    );
  }

  private get areDateRangeFiltersApplying(): boolean {
    return DATE_RANGE_FILTERS.some(
      (dateRangeFilterKey) => !isNil(this.filters[dateRangeFilterKey as keyof InboxFilterValues]),
    );
  }

  private emitFilters() {
    this.$emit('filters-change', this.filters, this.areApplying);
  }
}

const TEXT_LIST_FILTERS = [
  'subsidiaryName',
  'customerName',
  'equipmentNumber',
  'equipmentName',
  'equipmentModel',
  'assigneeName',
  'alertMetric',
  'crmCaseNumber',
];
const DATE_RANGE_FILTERS = ['createdDate', 'processingDate', 'postponedDate', 'resolutionDate'];
