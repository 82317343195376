// --------------------------------------------------------------------------------
// <copyright file="numberOfBendsKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { metricsService } from '@/services/metrics.service';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { PieChartKpiWidgetGenerator } from './abstract/pieChartKpiWidgetGenerator';
import { DataWithTimeSpan } from './chartsData';
import { devicesService } from '@/services/devices.service';
import { ProcedureName } from './abstract/chartGenerator';
import { BEND_SUMMARY_BASE_COLOR } from '@/utils/color';
import { isNil } from '@/utils/misc';

export interface NumberOfBendsDataPerDevice extends DataWithTimeSpan {
  deviceId: string;
  bends: number;
}

/**
 * Actually 'Bend Count' widget.
 */
export class NumberOfBendsKpiWidgetGenerator extends PieChartKpiWidgetGenerator<NumberOfBendsDataPerDevice> {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    const mainColor = BEND_SUMMARY_BASE_COLOR;
    super(procedure, tenantIdDh, mainColor);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ): Promise<NumberOfBendsDataPerDevice[] | null> {
    return mockData.slice(0, selectedDevices.length)
      .map((deviceDataEntries, deviceIndex) =>
        deviceDataEntries.map((dataEntry) =>
          ({
            ...dataEntry,
            deviceId: selectedDevices[deviceIndex],
          })
        )
      ).flat();

    return await metricsService.getDevicesMetrics<NumberOfBendsDataPerDevice[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  protected override getMaxDataEntry(primaryData: NumberOfBendsDataPerDevice[]): number {
    return Math.max(...primaryData.map((x) => x.bends));
  }

  protected override getSeriesData(primaryData: NumberOfBendsDataPerDevice[]): any[] {
    return primaryData.map((x) => ({
      name: devicesService.store.findByStringId(x.deviceId)!.name,
      value: x.bends,
    }));
  }
}

const mockData: Array<NumberOfBendsDataPerDevice[]> = [
  [
    {
      'bends': 207,
      'deviceId': 'to be replaced',
      'isprimary': 1,
    },
    {
      'bends': 170,
      'deviceId': 'to be replaced',
      'isprimary': 0,
    },
  ],
  [
    {
      'bends': 205,
      'deviceId': 'to be replaced',
      'isprimary': 1,
    },
    {
      'bends': 177,
      'deviceId': 'to be replaced',
      'isprimary': 0,
    },
  ],
  [
    {
      'bends': 245,
      'deviceId': 'to be replaced',
      'isprimary': 1,
    },
    {
      'bends': 220,
      'deviceId': 'to be replaced',
      'isprimary': 0,
    },
  ],
  [
    {
      'bends': 180,
      'deviceId': 'to be replaced',
      'isprimary': 1,
    },
    {
      'bends': 154,
      'deviceId': 'to be replaced',
      'isprimary': 0,
    },
  ],
  [
    {
      'bends': 216,
      'deviceId': 'to be replaced',
      'isprimary': 1,
    },
    {
      'bends': 201,
      'deviceId': 'to be replaced',
      'isprimary': 0,
    },
  ],
];