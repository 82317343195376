
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TimeZone } from '@/models/timeZone';
import { ValidationProvider } from 'vee-validate';
import { mastersService } from '@/services/masters.service';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TimezonesSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: undefined })
  public rules?: any;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: TimeZone[] = [];

  public mounted(): void {
    this.items = this.getItems();
  }
  public getItems() {
    const timezones = mastersService.store.getTimezones();
    timezones.forEach((timezone) => {
      timezone.name = this.$i18n.t('timezone.' + timezone.id).toString();
    });
    timezones.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else {
        return 0;
      }
    });
    return timezones;
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }

  private get required(): boolean {
    return this.rules?.required || this.rules?.includes?.('required');
  }
}
