// --------------------------------------------------------------------------------
// <copyright file="laserConsumptionOverviewTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { LaserAccumulatedConsumptionKxSeriesGeneratorHelper } from './laserAccumulatedConsumptionKxSeriesGeneratorHelper';
import { abbreviateNumber } from '@/utils/number';
import { LaserConsumptionTimelineData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, SeriesOption } from 'echarts';
import { WidgetEnum } from '../enums/WidgetEnum';
import { isCategoryXAxis } from '@/utils/charts';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { TooltipFormatter } from '@/models/Charts/tooltipFormatter';
import { XAXisOption } from 'echarts/types/dist/shared';
import { uniq } from '@/utils/array';
import { metricsService } from '@/services/metrics.service';

export class LaserConsumptionOverviewTimelineGenerator extends ChartGenerator<
  LaserConsumptionTimelineData[]
> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ) {
    const deviceId = selectedDevices[0];
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<LaserConsumptionTimelineData[]>(
      WidgetEnum.TechnologyLaserConsumptionTimeline,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom,
        dateTo,
        dateGrouping: timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserConsumptionTimelineData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);

    return {
      legend: {
        icon: 'roundRect',
        type: 'scroll',
        bottom: 0,
        formatter: LaserConsumptionOverviewTimelineGenerator.legendFormatter,
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      dataZoom: [
        {
          type: 'inside',
          filterMode: 'weakFilter',
        },
      ],
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: TooltipFormatter.build(parameters.timeAxisSpan)
          .withSeriesNameGenerator(LaserConsumptionOverviewTimelineGenerator.translateSeriesName)
          .withSuffix('kWh')
          .get(),
      },
      grid: {
        top: 10,
        left: 10,
        right: 20,
        bottom: 30,
        containLabel: true,
      },
      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        axisLabel: {
          show: true,
          hideOverlap: true,
        },
        axisTick: { show: true },
        splitLine: {
          show: false,
        },
        data: isCategoryAxis ? uniq(data.map((dataItem) => dataItem.bucket)) : undefined,
      } as XAXisOption,
      yAxis: {
        name: i18n.t('report.kwh').toString(),
        nameTextStyle: {
          align: 'left',
        },
        type: 'value',
        scale: true,
        max: (value) => value.max * 1.2,
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value: number) => abbreviateNumber(value),
        },
      },
      series: this.generateSeries(data, isCategoryAxis),
    };
  }

  private generateSeries(
    data: LaserConsumptionTimelineData[],
    isCategoryAxis: boolean,
  ): SeriesOption[] {
    return new LaserAccumulatedConsumptionKxSeriesGeneratorHelper(
      data,
      0,
      isCategoryAxis,
    ).generate();
  }

  private static legendFormatter(seriesName: string): string {
    return LaserConsumptionOverviewTimelineGenerator.translateSeriesName(seriesName);
  }

  private static translateSeriesName(seriesName: string): string {
    const powerLevel = seriesName.match(/\d+/)![0];
    return i18n.t(`report.kx_power`, [powerLevel]).toString();
  }
}
