// --------------------------------------------------------------------------------
// <copyright file="axios-interceptor-error.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Routes } from '@/router/routes';
import axios from 'axios';
import router from '@/router';
import { ToastProgrammatic as Toast } from 'buefy';
import environment from '@/utils/environment';

function getDetailedMessage(error: any): string {
  if (error.response.data.detail) {
    const title = error.response.data.title;
    try {
      const detail = JSON.parse(error.response.data.detail);
      if (detail.IsError && detail.DefaultMessage) {
        return `${title}: ${detail.DefaultMessage}`;
      }
    } catch {
      const status = error.response?.status;
      if (status === 409 || status === 404) {
        return `${title}: ${error.response.data.detail}`;
      } else if (status === 500 || status === 400) {
        return error.response.data.detail;
      }
    }
  }
  return error.response.data.title;
}

export function ResponseErrorInterceptor(appInsights: any) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.message === 'canceled') {
        // Request canceled by user, do not track the exception
        return;
      }
      if (error instanceof Error) {
        appInsights.trackException({ exception: error });
      } else {
        appInsights.trackException({
          exception: new Error(error),
        });
      }

      if (error.response?.status === 401) {
        // If token is expired and no access is allowed, route to Login
        router.push({ name: Routes.Login });
      } else if (
        error.response?.status !== 401 &&
        // error.response?.status !== 403 &&
        error.response &&
        error.response.data &&
        error.response.data.title
      ) {
        const detailedError = getDetailedMessage(error);
        Toast.open({
          duration: 5000,
          message: `Error: ${detailedError}`,
          position: 'is-top',
          type: 'is-danger',
        });
      }
      if (environment.isProductionEnvironment()) {
        // eslint-disable-next-line
        console.error('Unhandled data info:', error.response?.data);
      }
      return new Promise((resolve, reject) => {
        reject(error);
      });
    },
  );
}
