// --------------------------------------------------------------------------------
// <copyright file="FilterTimeSpanEnum.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum FilterTimeSpanEnum {
  None = 0,
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}
