
import { Component, Vue } from 'vue-property-decorator';
import { PersistentActions } from '@/store/persistent/enums';
import { User, UserAuthenticationType } from '@/models/user';
import { UserType } from '@/models/userType';
import {
  canAccessCareConsoleSettingsMenu,
  canAccessControlList,
  canAccessDataPlatforms,
  canAccessTechnologyConsole,
  hasCurrentUserPermission,
} from '@/utils/permissionUtils';
import PasswordResetModal from './users/PasswordResetModal.vue';
import { usersService } from '@/services/users.service';
import AdminNotificationModal from '@/components/AdminNotificationModal.vue';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import CareConsoleSettingsMenu from '@/views/careConsole/CareConsoleSettingsMenu.vue';
import NotificationsMenu from '@/components/NotificationsMenu.vue';
import { downloadTermsAndConditionsPdf } from '@/utils/misc';
import i18n from '@/i18n';
import NewFeatureBadge from './NewFeatureBadge.vue';
import { tenantsService } from '@/services/tenants.service';
import { modalService } from '@/library-services/modal.service';

/**
 * Header used on all pages showing the page title and several menus (user,
 * settings...).
 */
@Component({
  components: {
    NotificationsMenu,
    AdminNotificationModal,
    CareConsoleSettingsMenu,
    NewFeatureBadge,
  },
  computed: {
    canAccessControlList: () => canAccessControlList(),
    canAccessDataPlatforms: () => canAccessDataPlatforms(),
  },
})
export default class Header extends Vue {
  private readonly onlineHelpLink =
    'https://onlinehelp.bystronic.com/BySoft_Insight/en-us/Content/Home.htm?apikey=148ad74c-384b-4471-8abb-87cba0b9f24e';

  private async logout() {
    this.$spinner.showSpinner();
    this.$store.dispatch(PersistentActions.SetCurrentUser, {});
    await this.$auth.logout().finally(() => this.$spinner.removeSpinner());
  }

  private async downloadTermsAndConditions() {
    this.$spinner.showSpinner();
    await downloadTermsAndConditionsPdf().finally(() => this.$spinner.removeSpinner());
  }

  private openAdminNotification() {
    modalService.open({
      parent: this,
      component: AdminNotificationModal,
    });
  }

  private resetPassword() {
    modalService.open({
      parent: this,
      component: PasswordResetModal,
      props: {
        id: this.user!.id,
        email: this.user!.email,
      },
    });
  }

  private get canChangePassword(): boolean {
    return this.user?.authenticationType === UserAuthenticationType.Local;
  }

  private get canSendNotifications(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get user(): User {
    return usersService.store.current();
  }

  private get isBetaEnabled(): boolean {
    return this.user.isBetaEnabled;
  }

  private get wccEnabled() {
    const customer = tenantsService.store.current();
    return this.user.consoles.includes(ConsoleEnum.WCC) && (!customer || customer.hasDatahub);
  }

  private get canAccessSubsidiaries(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get showSettings(): boolean {
    return !(this.user.isTechnologyUser || this.user.isServiceUserType);
  }

  private get showEServiceCenterSettingsMenu(): boolean {
    return this.isEServiceCenter && canAccessCareConsoleSettingsMenu();
  }

  private get isEServiceCenter(): boolean {
    return this.$route.path.includes('e-service-center');
  }

  private get showTechnologyConsole(): boolean {
    return this.isTechnologyConsole && canAccessTechnologyConsole();
  }

  private get isTechnologyConsole(): boolean {
    return this.$route.path.includes('technology-console');
  }

  private get showInfoButton(): boolean {
    return !(this.showTechnologyConsole || this.showEServiceCenterSettingsMenu);
  }

  private get canAccessCustomers(): boolean {
    return hasCurrentUserPermission(UserType.CustomerUser);
  }

  private get canAccessUsers(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get canAccessEquipment(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get userInitials(): string {
    return this.user?.name
      ? this.user.name
          .split(' ')
          .map((nameWord) => nameWord[0])
          .slice(0, 2)
          .join('')
      : '';
  }

  private get canEnableBeta(): boolean {
    return this.user.hasAccessToBeta;
  }

  private clickBD() {
    this.$emit('click-burger-desktop');
  }

  private clickBM() {
    this.$emit('click-burger-mobile');
  }

  private get breadcrumb() {
    if (this.user.isTechnologyUser) {
      return i18n.t(`report.technology`).toString();
    }

    if (this.user.isServiceUserType) {
      return i18n.t('e_service_center.breadcrumb').toString();
    }

    if (this.$route.name?.includes('-')) {
      if (this.$route.name?.includes('kpi') || this.$route.name?.includes('control')) {
        return i18n.t(`report.${this.$route.name}`).toString();
      }
      return i18n.t(`report.${this.$route.name!.split('-')[1]}`).toString();
    }

    return i18n.t(`report.${this.$route.name}`).toString();
  }
}
