// --------------------------------------------------------------------------------
// <copyright file="WidgetType.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum WidgetType {
  Undefined = 0,
  Echarts = 1,
  CameraView = 2,
  Map = 3,
  Slot = 4,
  Table = 5,
  KPI = 6,
}
