
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mastersService } from '@/services/masters.service';
import { TagInput } from '@/components/common/tagInput';

@Component({
  components: { TagInput },
})
export default class EventCodesFilter extends Vue {
  // We need it to be nullable because of EventsTableExpandedView.
  // See comment on select*.
  @Prop({ default: () => [] })
  private value!: string[] | null;

  @Prop({ default: 'is-bottom-left' })
  private position!: string;

  private get availableCodes(): string[] {
    return mastersService.store.getEventCodes();
  }
}
