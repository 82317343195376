// --------------------------------------------------------------------------------
// <copyright file="shiftModel.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Shift } from './shift';

export class ShiftModel {
  static readonly NONE_ID = -1;

  static GetEmpty(): ShiftModel {
    return new ShiftModel(0, '', 0, []);
  }
  constructor(
    public id: number,
    public name: string,
    public tenantId: number,
    public shifts: Shift[],
  ) {}
}
