
import 'leaflet/dist/leaflet.css';
import 'leaflet';
import { LMap, LTooltip, LTileLayer, LCircleMarker, LControl } from 'vue2-leaflet';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { MapData, Marker, NoDataException } from '@/models/Maps/mapGenerator';
import { isEmpty } from '@/utils/misc';

@Component({
  components: {
    LMap,
    LTooltip,
    LTileLayer,
    LCircleMarker,
    LControl,
  },
})
export default class MapWidget extends Vue {
  @Prop({ required: true })
  private getData!: () => Promise<MapData | null>;

  @Ref('map')
  private map!: LMap;

  @Ref('wrapper')
  private wrapper!: Element;

  private observer!: ResizeObserver;
  private isFirstResize = true;

  private data: MapData | null = null;
  private markers: Marker[] = [];

  private noData = false;

  private async mounted() {
    this.map.mapObject.attributionControl.remove();
    this.observer = new ResizeObserver(this.onMapResize);
    this.observer.observe(this.map.$el);
  }

  public async update(): Promise<void> {
    const loading = this.$buefy.loading.open({
      container: this.wrapper,
    });
    try {
      this.noData = false;
      this.data = await this.getData();
      this.markers = this.data!.markers.map((marker) => ({
        ...marker,
        color: marker.color ?? '#6a5ffe',
        radius: marker.radius ?? 20,
      })).sort((a, b) => ((a.zIndexOffset ?? 0) > (b.zIndexOffset ?? 0) ? 1 : -1));
      this.map.mapObject.scrollWheelZoom.enable();
      this.map.mapObject.dragging.enable();
      this.map.mapObject.addControl(this.map.mapObject.zoomControl);
      this.map.mapObject.doubleClickZoom.enable();
    } catch (e) {
      if (e instanceof NoDataException) {
        this.noData = true;
        this.markers = [];
        this.map.mapObject.scrollWheelZoom.disable();
        this.map.mapObject.dragging.disable();
        this.map.mapObject.zoomControl.remove();
        this.map.mapObject.doubleClickZoom.disable();
      }
    } finally {
      this.$nextTick(() => {
        this.fitBounds();
      });
    }
    loading.close();
  }

  private fitBounds(): void {
    const bounds = this.markers.map((marker) => marker.coordinates);
    if (isEmpty(bounds)) {
      // Coordinates to show the world when no data is available
      const northLatitude = 66;
      const southLatitude = -50;
      const westLongitude = -150;
      const eastLongitude = 170;
      this.map.mapObject.fitBounds(
        [
          [northLatitude, westLongitude],
          [southLatitude, westLongitude],
          [northLatitude, eastLongitude],
          [southLatitude, eastLongitude],
        ],
        {
          padding: [0.25, 0.25],
        },
      );
    } else {
      this.map.mapObject.fitBounds(bounds, {
        padding: [0.25, 0.25],
      });
    }
  }

  private onMapResize() {
    if (this.isFirstResize) {
      this.isFirstResize = false;
      return;
    }

    this.map.mapObject.invalidateSize();
    this.fitBounds();
  }

  private beforeDestroy() {
    this.observer.unobserve(this.map.$el);
  }
}
