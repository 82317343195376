// --------------------------------------------------------------------------------
// <copyright file="businessLineChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import i18n from '@/i18n';
import { abbreviateNumber } from '@/utils/number';
import { Color } from '@/utils/color';
import { EChartsOption, LineSeriesOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { WidgetEnum } from '../enums/WidgetEnum';
import {
  mockQuotesConvertedAmountTimelineData,
  mockSalesTimelineData,
} from './mockWidgetSelectorData';
import { Logger } from '@/utils/logger';
import { isCategoryXAxis } from '@/utils/charts';
import { TooltipFormatter } from '@/models/Charts/tooltipFormatter';
import { AxisPointerLabelFormatter } from '@/models/Charts/axisPointerLabelFormatter';
import { metricsService } from '@/services/metrics.service';

export default class BusinessLineChartGenerator extends ChartGenerator<any[]> {
  private timeAxisSpan: FilterTimeAxisSpanEnum | undefined;

  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    this.timeAxisSpan = timeAxisSpan;

    return metricsService.getSMBSMetricsBC<any[]>(
      this.procedure,
      this.tenantIdDh,
      {
        startDate,
        endDate,
        axisTimespan: timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: any[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const dates = data.map((d: any) => d.date);
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);

    return {
      title: {
        show: false,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
            formatter: new AxisPointerLabelFormatter(parameters.timeAxisSpan, isCategoryAxis).get(),
          },
        },
        confine: true,
        formatter: TooltipFormatter.build(parameters.timeAxisSpan)
          .withSeriesTranslationPrefix('report.business_console.')
          .get(),
      },
      legend: {
        formatter: (name: string) => i18n.t(`report.business_console.${name}`).toString(),
        bottom: 0,
        type: 'scroll',
      },
      dataZoom: [
        {
          show: false,
          realtime: true,
          height: '2',
          top: '95%',
        },
        {
          show: true,
          type: 'inside',
          realtime: true,
        },
      ],
      grid: {
        containLabel: true,
        left: '3%',
        right: '4%',
        bottom: 40,
        top: 32,
      },
      xAxis: [
        {
          type: isCategoryAxis ? 'category' : 'time',
          boundaryGap: false,
          data: isCategoryAxis ? dates : undefined,
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
        },
      ],
      series: this.generateSeries(data, parameters.targets, isCategoryAxis),
    };
  }

  override getMockData(): any {
    switch (this.procedure) {
      case WidgetEnum.QuotesConvertedAmountTimeline:
        return mockQuotesConvertedAmountTimelineData();
      case WidgetEnum.SalesTimeline:
        return mockSalesTimelineData();
      default:
        Logger.error(`Missing mock data for ${this.procedure}`);
        return null;
    }
  }

  private generateSeries(
    data: any[],
    targets: number[] | undefined,
    isCategoryAxis: boolean,
  ): LineSeriesOption[] {
    const targetAmount = this.getSoldAmountTarget(targets);

    return Object.keys(data[0])
      .filter((field) => field !== 'date')
      .map((fieldName: string) => ({
        name: fieldName,
        type: 'line',
        areaStyle: {},
        emphasis: {
          focus: 'series',
        },
        markArea: !targetAmount
          ? undefined
          : {
              itemStyle: {
                color: Color.Green,
                opacity: 0.1,
              },
              data: [
                [
                  {
                    yAxis: targetAmount,
                  },
                  {
                    yAxis: Number.MAX_SAFE_INTEGER,
                  },
                ],
              ],
            },
        data: isCategoryAxis
          ? data.map((d: any) => d[fieldName])
          : data.map((d: any) => [d.date, d[fieldName]]),
      }));
  }

  private getSoldAmountTarget(targets: number[] | undefined): number | undefined {
    if (targets?.length !== 3) {
      return undefined;
    }
    const timeSpanFactor = this.getTimeSpanFactor();
    // Targets for SalesTimeline chart
    const [monthlyPaidTarget, r3Target, r4Target] = targets;
    return (monthlyPaidTarget * timeSpanFactor) / r3Target / r4Target;
  }

  private getTimeSpanFactor(): number {
    switch (this.timeAxisSpan) {
      case FilterTimeAxisSpanEnum.Day:
        // mean of days per year / months
        return 1 / (365.25 / 12);
      case FilterTimeAxisSpanEnum.Week:
        // (mean of days per year / months) * days per week
        return (1 / (365.25 / 12)) * 7;
      case FilterTimeAxisSpanEnum.Month:
        return 1;
      case FilterTimeAxisSpanEnum.Quarter:
        return 3;
      case FilterTimeAxisSpanEnum.Year:
        return 12;
      default:
        Logger.error('Invalid time axis span', this.timeAxisSpan);
        return 1;
    }
  }
}
