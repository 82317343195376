// --------------------------------------------------------------------------------
// <copyright file="Measurement.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum Measurements {
  Unknown = 0,
  Metric = 1,
  Imperial = 2,
}
