
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';

import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { dateRangeToDateStrings, yearToDate } from '@/utils/dates';
import { translateFilterTimeAxisSpanEnum } from '@/utils/i18n';
import LayoutGrid from '../dashboard/LayoutGrid.vue';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import { indexedWidgetDefinitions } from '@/utils/misc';

@Component({
  methods: { translateFilterTimeAxisSpanEnum },
  components: {
    WidgetContainer,
    DateGroupingFilter,
    LayoutGrid,
  },
})
export default class Sales extends Vue {
  // Filters
  private dateRangeSelection = yearToDate();
  private selectedTimeAxisSpan = FilterTimeAxisSpanEnum.Month;

  private gridLayout = availableDashboardLayouts[DashboardLayout.TwoByThree];

  private widgetDefinitions: WidgetDefinition[] = [];

  @Ref('bDatepicker')
  private bDatepicker: any;

  private created() {
    this.widgetDefinitions = this.createWidgetDefinitions();
  }

  private createWidgetDefinitions(): WidgetDefinition[] {
    return indexedWidgetDefinitions(
      [
        WidgetEnum.SalesTimeline,
        WidgetEnum.SalesCustomerGroups,
        WidgetEnum.SalesFunnel,
        WidgetEnum.SalesTopSalespeople,
        WidgetEnum.SalesFigures,
        WidgetEnum.SalesUnpaidDistribution,
      ].map(
        (widget) =>
          new WidgetDefinition(
            widget,
            [],
            this.dateRangeString,
            [],
            undefined,
            this.selectedTimeAxisSpan,
          ),
      ),
    );
  }

  @Watch('dateRangeString')
  @Watch('selectedTimeAxisSpan')
  private updateFilterOnWidgetDefinitions() {
    this.widgetDefinitions = this.widgetDefinitions.map((widgetDefinition) =>
      widgetDefinition.getCopy({
        timeFilter: this.dateRangeString,
        axisSpan: this.selectedTimeAxisSpan,
      }),
    );
  }

  private updateWidgetDefinition(index: number, widgetDefinition: WidgetDefinition) {
    this.$set(this.widgetDefinitions, index, widgetDefinition);
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRangeSelection);
  }

  private toggleDatepicker() {
    this.bDatepicker.toggle();
  }

  private threeDotsInnerPosition(index: number): string {
    if ((index + 1) % 3 === 0) {
      return 'is-left';
    } else {
      return 'is-right';
    }
  }
}
