
import { Component, Vue } from 'vue-property-decorator';
import { Alert } from '@/models/alert';
import TableWithActions, {
  truncatedValueColumnEllipsisTitle,
} from '@/components/tableWithActions/TableWithActions.vue';
import CareAlertCreationWizard from '@/components/alerts/CareAlertCreationWizard.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import CareAlertEditionModal from '@/components/alerts/CareAlertEditionModal.vue';
import { alertsService } from '@/services/alerts.service';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { isNil } from '@/utils/misc';
import i18n from '@/i18n';
import { listToCommaSeparatedString } from '@/utils/array';
import { translateDateGrouping } from '@/utils/dates';
import {
  matchesDateGrouping,
  matchesMetric,
  matchesOperator,
  matchesThreshold,
  matchesVariable,
  formatWithUnit,
  translateKpi,
  translateOperator,
  translateVariable,
} from '@/utils/alerts';
import { dialogService } from '@/library-services/dialog.service';
import { modalService } from '@/library-services/modal.service';

@Component({
  methods: {
    matchesDateGrouping,
    matchesMetric,
    matchesOperator,
    matchesVariable,
    matchesThreshold,
    formatWithUnit,
    listToCommaSeparatedString,
    isNil,
    translateDateGrouping,
    translateKpi,
    translateOperator,
    translateVariable,
    truncatedValueColumnEllipsisTitle,
  },
  computed: {
    FilterTimeAxisSpanEnum: () => FilterTimeAxisSpanEnum,
  },
  components: { TableActionButton, TableWithActions },
})
export default class CareConsoleAlerts extends Vue {
  private alerts: Alert[] = [];

  private isLoading = false;

  private created() {
    this.updateAlerts();
  }

  private async updateAlerts() {
    this.isLoading = true;
    await alertsService
      .getCareConsoleAlerts()
      .then((data) => (this.alerts = data))
      .finally(() => (this.isLoading = false));
  }

  private createAlert() {
    modalService.open({
      parent: this,
      component: CareAlertCreationWizard,
      events: {
        save: () => this.updateAlerts(),
      },
    });
  }

  private editAlert(alert: Alert) {
    modalService.open({
      parent: this,
      component: CareAlertEditionModal,
      events: {
        save: () => this.updateAlerts(),
      },
      props: {
        alert: Alert.GetCopy(alert),
      },
    });
  }

  private onDeleteClicked(alert: Alert): void {
    const messageParams = {
      deviceName: alert.deviceName,
      metric: i18n.t(`alert.metric.${alert.metric}`),
    };

    dialogService.confirmWithPromise({
      title: i18n.t('dialog.confirm_title').toString(),
      message: i18n.t('alert.dialog.delete_message', messageParams).toString(),
      confirmText: i18n.t('dialog.confirm_delete').toString(),
      cancelText: i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteDevice(alert.id!),
    });
  }

  private async deleteDevice(alertId: number) {
    await alertsService
      .delete(alertId)
      .then(() => (this.alerts = this.alerts.filter((alert) => alert.id !== alertId)));
  }
}
