
import { iterable } from '@/utils/array';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HealthBar extends Vue {
  @Prop({ required: true })
  private levelMaximums!: number[];

  @Prop({ required: true, default: 50 })
  private value!: number;

  private get max(): number {
    return Math.max(...this.levelMaximums);
  }

  private get levelSizes(): number[] {
    return this.levelMaximums.map((levelMaximum, index) =>
      index === 0 ? levelMaximum : levelMaximum - this.levelMaximums[index - 1],
    );
  }

  private get levelStartValues(): number[] {
    return this.levelMaximums.map((_, index) => (index === 0 ? 0 : this.levelMaximums[index - 1]));
  }

  private get valueLevelIndex(): number {
    const eLevelMaximum = this.levelMaximums[this.levelMaximums.length - 1];
    // This condition may happen, so adjust the value for findIndex to work.
    const adjustedValue = this.value <= eLevelMaximum ? this.value : eLevelMaximum;
    return this.levelMaximums.findIndex((levelMaximum) => adjustedValue <= levelMaximum);
  }

  private isLevelFullyFilled(index: number): boolean {
    if (index === this.levelMaximums.length - 1) {
      return true;
    }
    return this.value >= this.levelMaximums[index];
  }

  private getLevelSegmentValue(index: number): number {
    //   value  index
    // [##    |      | ]
    if (this.isValueInPreviousSegment(index)) {
      return 0;
    }
    //      value index
    // [##|##          ]
    if (this.isValueInSegment(index)) {
      return this.value - this.levelStartValues[index];
    }
    //          index  value
    // [######|#######|#    ]
    return this.levelSizes[index];
  }

  private isValueInPreviousSegment(index: number) {
    return this.value < this.levelStartValues[index];
  }

  private isValueInSegment(index: number) {
    return !this.isValueInPreviousSegment(index) && this.value <= this.levelMaximums[index];
  }

  private getBoundarySegmentValue(index: number): number {
    if (this.value > this.levelStartValues[index] && this.value <= this.levelMaximums[index]) {
      return this.levelMaximums[index] - this.value;
    }
    return this.levelSizes[index];
  }

  private get tooltipLabel(): string {
    return ['A', 'B', 'C', 'D', 'E'][this.valueLevelIndex];
  }

  private get boundaryPositionClass(): string {
    return this.getBoundaryPositionClass(this.value);
  }

  private getBoundaryPositionClass(value: number): string {
    const valuePercentage = this.max !== 0 ? (value * 100) / this.max : 0;
    // value may be greater than the E level maximum, so limit it to 100 % with min.
    return `left-${Math.min(Math.round(valuePercentage), 100)}`;
  }

  private getRoundSegmentEndLeftPosition(index: number) {
    const value = iterable(index + 1)
      .map((n) => this.getLevelSegmentValue(n))
      .reduce((a, b) => a + b, 0);
    return this.getBoundaryPositionClass(value);
  }

  private get hasData(): boolean {
    return this.levelMaximums.some((levelMaximum) => levelMaximum !== 0) || this.value !== 0;
  }
}
