var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('widget-container',{attrs:{"widgetDefinition":_vm.deviceOverviewWidgetDefinition},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('div',{staticClass:"device"},[(_vm.device)?_c('device-image',{attrs:{"device":_vm.device,"ratio":"3by1"}}):_vm._e(),_c('div',{staticClass:"device-name-and-model"},[_c('div',{staticClass:"model"},[_vm._v(" "+_vm._s(_vm.isEmpty(data) || _vm.isEmpty(data.MachineType) ? _vm.$t('care.overview.unknown_model') : data.MachineType)+" ")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.device.name))])]),_c('div',{staticClass:"info-table"},[_vm._l(([
          'EquipmentNumber',
          'ByVisionVersion',
          'ByMotionVersion',
          'CuttingHeadType',
          'SW_Version',
        ]),function(field,index){return _c('div',{key:index,staticClass:"info-element",attrs:{"data-testid":`field-${field}`}},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t(`report.overview_device_card.${field}`))+" ")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.getValue(data, field))+" ")])])}),_c('div',{staticClass:"info-element",attrs:{"data-testid":"field-automation"}},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('care.overview.automation')))]),(_vm.isEmpty(data))?_c('div',[_vm._v(" "+_vm._s(_vm.$t('no_data'))+" ")]):_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.$t(`care.overview.automation_values.${!_vm.isEmpty(data.ByTransVersion)}`))+" ")])])],2)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }