
import { Component, Vue } from 'vue-property-decorator';
import { UserAlertStatus } from '@/models/userAlertStatus';
import { notificationsService } from '@/services/notifications.service';
import { UserCustomNotificationStatus } from '@/models/customNotifications';

import { AlertWorkflowNotification } from '@/models/AlertWorkflowNotification';
import { isEmpty } from '@/utils/misc';
import NotificationCard from '@/components/NotificationCard.vue';
import { router } from '@/library-services/e-service-center-router';

@Component({
  components: { NotificationCard },
  methods: { isEmpty },
})
export default class NotificationsMenu extends Vue {
  private get notifications(): Array<
    UserAlertStatus | AlertWorkflowNotification | UserCustomNotificationStatus
  > {
    return notificationsService.store.getAll();
  }

  private async onNotificationClicked(
    notification: UserAlertStatus | AlertWorkflowNotification | UserCustomNotificationStatus,
  ) {
    if (notification instanceof AlertWorkflowNotification) {
      await router.goToInboxFilteredByEquipment(notification.customerName, notification.deviceName);
    } else {
      this.dismissNotification(notification);
    }
  }

  private dismissNotification(notificationStatus: UserAlertStatus | UserCustomNotificationStatus) {
    this.$spinner.showSpinner();
    const isCustomNotification = notificationStatus instanceof UserCustomNotificationStatus;
    notificationsService
      .dismiss(notificationStatus.id, isCustomNotification)
      .then(() => this.refreshNotifications())
      .finally(() => this.$spinner.removeSpinner());
  }

  private dismissAllNotifications() {
    this.$spinner.showSpinner();

    notificationsService
      .dismissAll()
      .then(() => this.refreshNotifications())
      .finally(() => this.$spinner.removeSpinner());
  }

  private async refreshNotifications() {
    this.$spinner.showSpinner();
    try {
      const notifications = await notificationsService.get();
    } finally {
      this.$spinner.removeSpinner();
    }
  }
}
