// --------------------------------------------------------------------------------
// <copyright file="pagination.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export interface Paged<T> {
  results: T[];

  page: number;
  total_results: number;
  total_pages: number;
}

export function emptyPaged<T>(): Paged<T> {
  return {
    results: [],

    page: 1,
    total_results: 0,
    total_pages: 0,
  };
}
