
import { Component, Prop, Vue } from 'vue-property-decorator';
import { tenantsService } from '@/services/tenants.service';
import { ValidationProvider } from 'vee-validate';
import { shiftModelsService } from '@/services/shiftModels.service';
import { ShiftModel } from '@/models/shiftModel';
import { Logger } from '@/utils/logger';
import i18n from '@/i18n';
import { isNil } from '@/utils/misc';
import { Shift } from '@/models/shift';
import { shiftsService } from '@/services/shifts.service';

@Component({
  methods: { isNil },
  computed: {
    NO_SHIFT_MODEL_ID: () => ShiftModel.NONE_ID,
  },
  components: {
    ValidationProvider,
  },
})
export default class ShiftModelSelect extends Vue {
  @Prop()
  private value?: number;

  /** Uses current tenant's if not specified. */
  @Prop()
  private tenantId?: number;

  @Prop({ default: undefined })
  private rules?: any;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  private shiftModels: ShiftModel[] = [];
  private isLoading = false;

  private async mounted() {
    this.shiftModels = await this.fetchShiftModels();
  }

  private async fetchShiftModels(): Promise<ShiftModel[]> {
    this.isLoading = true;

    try {
      const tenantId = this.tenantId ?? tenantsService.store.current()!.id;
      return await shiftModelsService.get(tenantId);
    } catch (e) {
      Logger.error('Error loading shift models', e);
      return [];
    } finally {
      this.isLoading = false;
    }
  }

  private get triggerLabel(): string {
    if (isNil(this.value)) {
      return i18n.t('inputs.shift_model_select.placeholder').toString();
    }

    if (this.value === ShiftModel.NONE_ID) {
      return i18n.t('inputs.shift_model_select.no_shift_model').toString();
    }

    return this.selectedShiftModel?.name ?? '';
  }

  private get selectedShiftModel(): ShiftModel | undefined {
    return this.shiftModels.find((shiftModel) => shiftModel.id === this.value);
  }

  private getShifts(shiftModelId: number): Shift[] {
    return shiftsService.store.getAllByShiftModelId(shiftModelId);
  }

  private onShiftModelSelected(shiftModelId: number) {
    this.$emit('input', shiftModelId);
    this.$emit('change', shiftModelId);
  }

  private get isRequired(): boolean {
    return this.rules?.required || this.rules?.includes?.('required');
  }
}
