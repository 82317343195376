
import { Component, Prop, Vue } from 'vue-property-decorator';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import { ImportantMessageLevel } from '@/models/Charts/chartsData';
import i18n from '@/i18n';

@Component({
  components: {
    'click-menu': ClickMenu,
  },
})
export default class EventTypeFilter extends Vue {
  @Prop({ default: [] })
  private value!: ImportantMessageLevel[];

  @Prop({ default: 'is-bottom-left' })
  private position!: string;

  private ids = Object.values(ImportantMessageLevel);

  private get names(): string[] {
    return Object.values(ImportantMessageLevel).map((messageLevel) =>
      i18n.t(`report.${messageLevel.toLowerCase()}`).toString(),
    );
  }
}
