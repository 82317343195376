
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class ControlledSidebar extends Vue {
  @Prop({ required: true, type: Boolean })
  private open!: boolean;

  @Prop({ default: false, type: Boolean })
  private dimmer!: boolean;

  @Prop({ default: false, type: Boolean })
  private expandable!: boolean;

  @Prop({ default: '33%' })
  private width!: string;

  private expanded = false;

  @Watch('open')
  private watchOpen(isOpenNow: boolean, wasOpen: boolean) {
    if (wasOpen && !isOpenNow) {
      this.expanded = false;
    }
  }
}
