// --------------------------------------------------------------------------------
// <copyright file="drawerInformationTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { Color } from '@/utils/color';
import { DrawerInformationTimelineData } from './chartsData';
import { EChartsOption, SeriesOption } from 'echarts';
import { abbreviateNumber } from '@/utils/number';
import { GeneratorParams } from './generatorParams';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { iterable, uniq } from '@/utils/array';
import { tooltipFormatter } from './drawerInformationTimelineTooltipFormatter';
import { isCategoryXAxis } from '@/utils/charts';
import { metricsService } from '@/services/metrics.service';

export class DrawerInformationTimelineGenerator extends ChartGenerator<
  DrawerInformationTimelineData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
    params: { [key: string]: any },
  ) {
    const deviceId = selectedDevices[0];
    return metricsService.getSSCMetrics<DrawerInformationTimelineData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: timeSpan[0],
        dateTo: timeSpan[1],
        dateGrouping: timeAxisSpan,
        aggregate: params.aggregate,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: DrawerInformationTimelineData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);
    const uniqueDates = uniq(data.map((dataItem) => dataItem.bucket));

    const showRemovedSeconds = parameters.removedSecondsSelected ?? true;
    const showPD = parameters.pdSelected ?? true;
    const showPowerAmplitude = parameters.powerAmplitudeSelected ?? true;

    return {
      title: [
        {
          top: '-1%',
          left: 'center',
          text: i18n.t('report.lower-drawer').toString(),
          textStyle: {
            fontSize: 18,
          },
        },
        {
          top: '44%',
          left: 'center',
          text: i18n.t('report.upper-drawer').toString(),
          textStyle: {
            fontSize: 18,
          },
        },
      ],
      legend: {
        icon: 'roundRect',
        formatter: (name: string) =>
          i18n.t(`report.drawer_information_timeline.${name}`).toString(),
        bottom: 0,
        selected: {
          [REMOVED_SECONDS_SERIES]: showRemovedSeconds,
          [PD_SERIES]: showPD,
          [POWER_AMPLITUDE_SERIES]: showPowerAmplitude,
        },
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) =>
          tooltipFormatter(params, data, parameters.timeAxisSpan!, isCategoryAxis),
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'weakFilter',
          xAxisIndex: [LOWER_DRAWER_X_AXIS_INDEX, UPPER_DRAWER_X_AXIS_INDEX],
          bottom: '8%',
          labelFormatter: '',
          height: '3%',
        },
        {
          type: 'inside',
          filterMode: 'weakFilter',
          xAxisIndex: [LOWER_DRAWER_X_AXIS_INDEX, UPPER_DRAWER_X_AXIS_INDEX],
        },
      ],
      grid: [
        {
          top: '5%',
          left: 40,
          right: 80,
          height: '35%',
        },
        {
          top: '50%',
          left: 40,
          right: 80,
          height: '35%',
        },
      ],
      xAxis: iterable(2).map((index) => ({
        gridIndex: index,
        type: isCategoryAxis ? 'category' : 'time',
        data: isCategoryAxis ? uniqueDates : undefined,
        axisLabel: {
          show: index === 2,
          hideOverlap: true,
        },
        axisTick: { show: index === 2 },
      })),
      yAxis: iterable(2)
        .map((i) => [
          {
            gridIndex: i,
            name: 's',
            type: 'value' as const,
            scale: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: REMOVED_COLOR,
              },
            },
            axisLabel: {
              formatter: (value: number) => abbreviateNumber(value),
            },
            show: showRemovedSeconds,
          },
          {
            gridIndex: i,
            name: i === 0 ? i18n.t('report.drawer_information_timeline.pd').toString() : '',
            nameTextStyle: {
              align: 'left' as const,
              lineHeight: 0,
            },
            type: 'value' as const,
            position: 'right' as const,
            scale: true,
            // Round to the next tens value (22 -> 30)
            max: (value: any) => Math.ceil(value.max / 10) * 10,
            axisLine: {
              show: true,
              lineStyle: {
                color: PD_COLOR,
              },
            },
            splitLine: {
              show: true,
            },
            axisLabel: {
              formatter: (value: number) => abbreviateNumber(value),
            },
            show: showPD,
          },
          {
            gridIndex: i,
            name:
              i === 0
                ? i18n
                    .t('report.drawer_information_timeline.power_amplitude_abbreviation')
                    .toString()
                : '',
            nameTextStyle: {
              align: 'left' as const,
              lineHeight: 0,
            },
            type: 'value' as const,
            position: 'right' as const,
            offset: showPD ? 40 : 0,
            // Round to the next tens value (22 -> 30)
            max: (value: any) => Math.ceil(value.max / 10) * 10,
            axisLine: {
              show: true,
              lineStyle: {
                color: POWER_AMPLITUDE_COLOR,
              },
            },
            axisLabel: {
              formatter: (value: number) => abbreviateNumber(value),
            },
            show: showPowerAmplitude,
          },
        ])
        .flat(),
      series: this.generateSeries(data, isCategoryAxis),
      media: [
        {
          query: {
            maxWidth: 350,
          },
          option: {
            title: [
              {
                textStyle: {
                  fontSize: 14,
                },
              },
              {
                textStyle: {
                  fontSize: 14,
                },
              },
            ],
          },
        },
      ],
    };
  }

  private generateSeries(
    data: DrawerInformationTimelineData[],
    isCategoryAxis: boolean,
  ): SeriesOption[] {
    const powerAmplitudeData = this.getSeriesData('powerAmplitude', data, isCategoryAxis);

    return [
      {
        data: this.getSeriesData('lowerDrawerRemovedSeconds', data, isCategoryAxis),
        type: 'bar',
        name: REMOVED_SECONDS_SERIES,
        emphasis: { focus: 'series' },
        color: REMOVED_COLOR,
        xAxisIndex: LOWER_DRAWER_X_AXIS_INDEX,
        yAxisIndex: LOWER_DRAWER_Y_AXIS_REMOVED_INDEX,
      },
      {
        data: this.getSeriesData('lowerDrawerPD', data, isCategoryAxis),
        type: 'line',
        name: PD_SERIES,
        emphasis: { focus: 'series' },
        color: PD_COLOR,
        xAxisIndex: LOWER_DRAWER_X_AXIS_INDEX,
        yAxisIndex: LOWER_DRAWER_Y_AXIS_PD_INDEX,
        connectNulls: true,
      },
      {
        data: powerAmplitudeData,
        type: 'line',
        name: POWER_AMPLITUDE_SERIES,
        emphasis: { focus: 'series' },
        color: POWER_AMPLITUDE_COLOR,
        areaStyle: {
          opacity: 0.4,
        },
        lineStyle: {
          width: 0,
        },
        xAxisIndex: LOWER_DRAWER_X_AXIS_INDEX,
        yAxisIndex: LOWER_DRAWER_Y_AXIS_POWER_AMPLITUDE_INDEX,
        connectNulls: true,
      },
      {
        data: this.getSeriesData('upperDrawerRemovedSeconds', data, isCategoryAxis),
        type: 'bar',
        name: REMOVED_SECONDS_SERIES,
        emphasis: { focus: 'series' },
        color: REMOVED_COLOR,
        xAxisIndex: UPPER_DRAWER_X_AXIS_INDEX,
        yAxisIndex: UPPER_DRAWER_Y_AXIS_REMOVED_INDEX,
      },
      {
        data: this.getSeriesData('upperDrawerPD', data, isCategoryAxis),
        type: 'line',
        name: PD_SERIES,
        emphasis: { focus: 'series' },
        color: PD_COLOR,
        xAxisIndex: UPPER_DRAWER_X_AXIS_INDEX,
        yAxisIndex: UPPER_DRAWER_Y_AXIS_PD_INDEX,
        connectNulls: true,
      },
      {
        data: powerAmplitudeData,
        type: 'line',
        name: POWER_AMPLITUDE_SERIES,
        emphasis: { focus: 'series' },
        color: POWER_AMPLITUDE_COLOR,
        areaStyle: {
          opacity: 0.4,
        },
        lineStyle: {
          width: 0,
        },
        xAxisIndex: UPPER_DRAWER_X_AXIS_INDEX,
        yAxisIndex: UPPER_DRAWER_Y_AXIS_POWER_AMPLITUDE_INDEX,
        connectNulls: true,
      },
    ] as SeriesOption[]; // null values aren't defined in types but they work
  }

  private getSeriesData(
    fieldName: keyof DrawerInformationTimelineData,
    data: DrawerInformationTimelineData[],
    isCategoryAxis: boolean,
  ) {
    return isCategoryAxis
      ? data.map((dataItem) => dataItem[fieldName])
      : data.map((dataItem) => [dataItem.bucket, dataItem[fieldName]]);
  }
}

export const REMOVED_COLOR = Color.Purple;
export const PD_COLOR = Color.Blue;
export const POWER_AMPLITUDE_COLOR = Color.Orange;

export const REMOVED_SECONDS_SERIES = 'removed_seconds';
export const PD_SERIES = 'pd';
export const POWER_AMPLITUDE_SERIES = 'power_amplitude';

export const LOWER_DRAWER_CHART_INDEX = 0;
export const UPPER_DRAWER_CHART_INDEX = 1;

export const LOWER_DRAWER_X_AXIS_INDEX = LOWER_DRAWER_CHART_INDEX;
export const UPPER_DRAWER_X_AXIS_INDEX = UPPER_DRAWER_CHART_INDEX;

export const LOWER_DRAWER_Y_AXIS_REMOVED_INDEX = 0;
export const UPPER_DRAWER_Y_AXIS_REMOVED_INDEX = 3;

export const LOWER_DRAWER_Y_AXIS_PD_INDEX = 1;
export const UPPER_DRAWER_Y_AXIS_PD_INDEX = 4;

export const LOWER_DRAWER_Y_AXIS_POWER_AMPLITUDE_INDEX = 2;
export const UPPER_DRAWER_Y_AXIS_POWER_AMPLITUDE_INDEX = 5;
