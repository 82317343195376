// --------------------------------------------------------------------------------
// <copyright file="toast.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2023
// </copyright>
// --------------------------------------------------------------------------------

import { ToastProgrammatic as Toast } from "buefy";
import i18n from '@/i18n';

class ToastService {
  success(messageKey: string, messageParameters: any = {}) {
    this.translatingToast(
      {
        messageKey,
      },
      messageParameters,
    );
  }

  error(messageKey: string, messageParameters: any = {}) {
    this.translatingToast(
      {
        messageKey,
        type: "is-danger",
      },
      messageParameters,
    );
  }

  private translatingToast(params: any, messageParameters: any = {}) {
    Toast.open({
      ...params,
      message: i18n.t(params.messageKey, messageParameters),
    });
  }

  toast(params: any | string) {
    Toast.open(params);
  }
}

export const toastService = new ToastService();
