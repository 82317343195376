// --------------------------------------------------------------------------------
// <copyright file="logger.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------
/* eslint-disable no-console */

import environment from '@/utils/environment';

export class Logger {
  public static error(message?: any, ...optionalParams: any[]) {
    if (!environment.isProductionEnvironment()) {
      console.error(message, ...optionalParams);
    }
  }

  public static warn(message?: any, ...optionalParams: any[]) {
    if (!environment.isProductionEnvironment()) {
      console.warn(message, ...optionalParams);
    }
  }

  public static info(message?: any, ...optionalParams: any[]) {
    if (!environment.isProductionEnvironment()) {
      console.info(message, ...optionalParams);
    }
  }
}
