
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { dateRangeToDateStrings } from '@/utils/dates';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { LayoutSlot } from '@/views/dashboard/layout-grid';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class DrawerInformation extends Vue {
  @Prop()
  private deviceId!: string;

  @Prop({ required: true })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  private timelineChartWidgetDefinition: WidgetDefinition | null = null;
  private barChartsWidgetDefinition: WidgetDefinition | null = null;

  private gridLayout: LayoutSlot[] = [
    { column: 0, row: 0, width: 8, height: 8 },
    { column: 8, row: 0, width: 4, height: 8 },
  ];

  private mounted() {
    this.createWidgetDefinition();
  }

  @Watch('deviceId')
  @Watch('dateRange')
  @Watch('dateGrouping')
  private createWidgetDefinition() {
    this.initializeTimelineChartWidgetDefinition();
    this.initializeBarChartsWidgetDefinition();
  }

  private initializeTimelineChartWidgetDefinition() {
    if (this.timelineChartWidgetDefinition === null) {
      this.timelineChartWidgetDefinition = new WidgetDefinition(
        WidgetEnum.TechnologyDrawerInformationTimeline,
        this.deviceId!,
        this.stringDateRange,
        [],
        undefined,
        this.dateGrouping,
      ).withIndex(0);
    } else {
      this.timelineChartWidgetDefinition = this.timelineChartWidgetDefinition.getCopy({
        deviceId: this.deviceId!,
        timeFilter: this.stringDateRange,
        axisSpan: this.dateGrouping,
      });
    }
  }

  private initializeBarChartsWidgetDefinition() {
    if (this.barChartsWidgetDefinition === null) {
      this.barChartsWidgetDefinition = new WidgetDefinition(
        WidgetEnum.TechnologyDrawerCycleTimes,
        this.deviceId!,
        this.stringDateRange,
        [],
      ).withIndex(1);
    } else {
      this.barChartsWidgetDefinition = this.barChartsWidgetDefinition.getCopy({
        deviceId: this.deviceId!,
        timeFilter: this.stringDateRange,
      });
    }
  }

  private onTimelineChartWidgetDefinitionChange(widgetDefinition: WidgetDefinition) {
    this.timelineChartWidgetDefinition = widgetDefinition;
  }

  private get stringDateRange(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }
}
