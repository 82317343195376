
import { Device } from '@/models/device';
import { Component, Prop, Vue } from 'vue-property-decorator';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import { MachineType } from '@/models/enums/MachineType';
import { isEmpty } from '@/utils/misc';
import { devicesService } from '@/services/devices.service';

@Component({
  methods: { isEmpty },
  components: {
    ClickMenu,
  },
})
export default class EquipmentFilter extends Vue {
  @Prop({ default: () => [] })
  private value!: string[];

  @Prop({ required: true })
  private type!: MachineType;

  @Prop({ default: false, type: Boolean })
  private single!: boolean;

  private get deviceIds(): string[] {
    return this.devices.map((device) => device.deviceId);
  }

  private get deviceNames(): string[] {
    return this.devices.map((device) => device.name);
  }

  private get devices(): Device[] {
    return devicesService.store.get().filter((device) => device.getType() === this.type);
  }
}
