
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { Target } from '@/models/target';
import { PersistentGetters } from '@/store/persistent/enums';
import { TargetsMetric } from '@/models/enums/TargetsMetric';

@Component
export default class TargetsMetricsSelect extends Vue {
  @Prop({ required: true })
  public console!: ConsoleEnum;

  @Prop({ default: '' })
  public value!: TargetsMetric;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: TargetsMetric[] = [];

  public mounted(): void {
    this.getItems();
  }
  public async getItems() {
    const targets: Target[] = this.$store.getters[PersistentGetters.GetTargets];
    this.items = targets
      .filter((t: Target) => t.consoleId === this.console)
      .map((t: Target) => t.targetMetric);
  }

  public handleSelect(value: TargetsMetric) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
