
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { dateRangeToDateStrings } from '@/utils/dates';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LayoutSlot } from '@/views/dashboard/layout-grid';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class DriveValues extends Vue {
  @Prop()
  private deviceId!: string;

  @Prop({ required: true })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  private driveCurrentTimelineWidgetDefinition: WidgetDefinition | null = null;

  private gridLayout: LayoutSlot[] = [
    { column: 0, row: 0, width: 8, height: 8 },
    { column: 8, row: 0, width: 4, height: 4 },
    { column: 8, row: 4, width: 4, height: 4 },
  ];

  private mounted() {
    this.initializeDriveCurrentTimelineWidgetDefinition();
  }

  @Watch('deviceId')
  @Watch('dateRange')
  @Watch('dateGrouping')
  private initializeDriveCurrentTimelineWidgetDefinition() {
    if (!this.driveCurrentTimelineWidgetDefinition) {
      this.driveCurrentTimelineWidgetDefinition = new WidgetDefinition(
        WidgetEnum.TechnologyDriveCurrentTimeline,
        this.deviceId!,
        this.stringDateRange,
        [],
        undefined,
        this.dateGrouping,
      ).withIndex(0);
    } else {
      this.driveCurrentTimelineWidgetDefinition = this.driveCurrentTimelineWidgetDefinition.getCopy(
        {
          deviceId: this.deviceId,
          timeFilter: this.stringDateRange,
          axisSpan: this.dateGrouping,
        },
      );
    }
  }

  private updateDriveCurrentTimelineWidgetDefinition(wd: WidgetDefinition) {
    this.driveCurrentTimelineWidgetDefinition = wd;
  }

  private get stringDateRange(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }

  private get driveCurrentDifferenceHistogramWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.TechnologyDriveCurrentDifferenceHistogram,
      this.deviceId!,
      this.stringDateRange,
      [],
    ).withIndex(1);
  }

  private get driveAccumulatedCurrentDifferenceTimelineWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.TechnologyDriveAccumulatedCurrentDifferenceTimeline,
      this.deviceId!,
      this.stringDateRange,
      [],
      undefined,
      this.dateGrouping,
    ).withIndex(2);
  }

  private getWidgetInfoPosition(index: number): 'is-bottom-left' | 'is-bottom-right' {
    if (index === 0) {
      return 'is-bottom-right';
    }
    return 'is-bottom-left';
  }
}
