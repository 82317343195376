// --------------------------------------------------------------------------------
// <copyright file="getters.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { GetterTree } from 'vuex';
import RootState from '@/store/rootState';

const getters: GetterTree<RootState, RootState> = {
  spinners: (rootState: RootState) => rootState.spinners,
  termsAcceptedSession: (rootState: RootState) => rootState.termsAcceptedSession,
  userInfoLoaded: (rootState: RootState) => rootState.userInfoLoaded,
  customers: (rootState: RootState) => rootState.customers,
};

export default getters;
