
import { Wizard } from '@/components/wizard';
import { Tenant as Customer } from '@/models/tenant';
import { User } from '@/models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PersonalDataWizardStep from './steps/PersonalDataWizardStep.vue';
import CustomerMembershipWizardStep from './steps/CustomerMembershipWizardStep.vue';
import SummaryWizardStep from './steps/SummaryWizardStep.vue';
import PermissionsWizardStep from './steps/PermissionsWizardStep.vue';
import ConsolesWizardStep from './steps/ConsolesWizardStep.vue';
import { UserUpdate, usersService } from '@/services/users.service';
import { tenantsService as customersService } from '@/services/tenants.service';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { areArraysEqual } from '@/utils/array';
import { UserType } from '@/models/userType';
import { currentUser, hasCurrentUserPermission } from '@/utils/permissionUtils';
import { isNil } from '@/utils/misc';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  components: {
    ConsolesWizardStep,
    CustomerMembershipWizardStep,
    PermissionsWizardStep,
    PersonalDataWizardStep,
    SummaryWizardStep,
    Wizard,
  },
})
export default class CustomerUserWizard extends Vue {
  @Prop({ default: null })
  private user!: User | null;

  @Prop({ default: null })
  private customer!: Customer | null;

  private userCopy: User | null = null;
  private customerCopy: Customer | null = null;

  private originalConsoles: ConsoleEnum[] = [];
  private originalLocations: number[] = [];

  private isCustomerLoading = false;

  private async created() {
    this.userCopy = this.user ?? User.empty();
    this.customerCopy = this.customer;

    if (this.isEditing) {
      this.originalConsoles = this.userCopy.consoles;
      this.originalLocations = this.userCopy.locations;

      if (this.isOpenedFromUsersPage) {
        await this.loadCustomer();
      }
    } else if (this.isOpenedFromCustomerUsersList) {
      this.userCopy.customerId = this.customer!.id;
      this.userCopy.tenantName = this.customer!.name;
    }
  }

  private async loadCustomer() {
    if (this.customerCopy?.id === this.userCopy?.customerId) {
      return;
    }
    this.isCustomerLoading = true;
    try {
      this.customerCopy = await customersService.getById(this.userCopy!.customerId);
    } finally {
      this.isCustomerLoading = false;
    }
  }

  private get authorizedConsoles() {
    const consoles = [];
    if (this.customerCopy?.hasDatahub) {
      consoles.push(ConsoleEnum.WCC);
    }
    if (this.customerCopy?.hasSmbs && currentUser().isGlobalAdmin) {
      consoles.push(ConsoleEnum.BC, ConsoleEnum.SFC);
    }
    return consoles;
  }

  private get shouldHideMembershipStep(): boolean {
    // Only show when a new user is being created from the Users view
    return this.isEditing || this.isOpenedFromCustomerUsersList;
  }

  private get isOpenedFromUsersPage(): boolean {
    return !this.isOpenedFromCustomerUsersList;
  }

  private get isOpenedFromCustomerUsersList(): boolean {
    return this.customer !== null;
  }

  private get isDashboardResetNeeded(): boolean {
    if (this.isCreating) {
      return false;
    }
    return (
      !areArraysEqual(this.userCopy!.consoles, this.originalConsoles) ||
      !areArraysEqual(this.userCopy!.locations, this.originalLocations)
    );
  }

  private async applyChanges() {
    if (this.isEditing) {
      const updateUser: UserUpdate = User.GetCopy(this.userCopy!);
      updateUser.isAcceptedTermsOfAgreement = undefined;
      updateUser.isAutoplay = undefined;
      updateUser.autoplayPeriod = undefined;
      updateUser.dashboards = undefined;
      await usersService.update(updateUser, this.authorizedConsoles);
    } else {
      await usersService.create(this.userCopy!);
    }
    this.$emit('save');
    this.$emit('close');
  }

  private async submit() {
    if (this.isDashboardResetNeeded) {
      dialogService.confirmWithPromise({
        title: this.$i18n.t('dialog.confirm_title').toString(),
        message: this.$i18n.t('user.update').toString(),
        confirmText: this.$i18n.t('dialog.confirm_save').toString(),
        cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
        type: 'is-primary',
        onConfirm: async () => await this.applyChanges(),
      });
    } else {
      await this.applyChanges();
    }
  }

  private get isEditing(): boolean {
    return !this.isCreating;
  }

  private get isCreating(): boolean {
    return isNil(this.user);
  }

  private get canEdit(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }
}
