
import GenericParam from './GenericParam.vue';
import { Component } from 'vue-property-decorator';
import { ParamList } from '@/models/enums/WidgetEnum';
import i18n from '@/i18n';

@Component({})
export default class ListParam extends GenericParam<ParamList> {
  private get value(): string {
    return this.paramValue ?? this.paramInfo.defaultValue ?? this.paramInfo.values[0];
  }

  private sendUpdate(value: string) {
    if (value !== this.value) {
      this.$emit('update', { [this.paramName]: value });
    }
  }

  private getName(value: string) {
    return i18n.t(`widget_params.${this.label + '_' + value}`);
  }
}
