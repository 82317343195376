
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Device } from '@/models/device';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { Logger } from '@/utils/logger';
import { isEmpty } from '@/utils/misc';
import { LocaleMessage } from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import WidgetContainer from './widgetContainer/WidgetContainer.vue';
import { devicesService } from '@/services/devices.service';
import { usersService } from '@/services/users.service';
import { tenantsService } from '@/services/tenants.service';
import i18n from '@/i18n';
import { LayoutSlot, TOTAL_COLUMNS, TOTAL_ROWS } from '@/views/dashboard/layout-grid';

export interface AddWidgetPayload {
  widget: WidgetEnum;
  deviceId?: string;
}

type GroupedWidgets = Record<string, WidgetEnum[]>;
type GroupedWidgetDefinitions = Record<string, WidgetDefinition[]>;

@Component({
  computed: {
    ConsoleEnum: () => ConsoleEnum,
  },
  components: {
    WidgetContainer,
  },
})
export default class WidgetSelector extends Vue {
  @Prop({ required: true })
  private selectedSlot!: LayoutSlot;

  private selectedWidgetDefinition: WidgetDefinition | null = null;
  private selectedDevice: string | null = null;
  private selectedConsole = ConsoleEnum.WCC;

  private get userConsoles(): ConsoleEnum[] {
    const currentUser = usersService.store.current();
    const currentTenant = tenantsService.store.current()!;
    const consoles: ConsoleEnum[] = [];
    if (currentTenant.hasDatahub) {
      if (currentUser.consoles.includes(ConsoleEnum.WCC) || currentUser.isGlobalAdmin) {
        consoles.push(ConsoleEnum.WCC);
      }
    }
    if (currentTenant.hasSmbs) {
      if (currentUser.consoles.includes(ConsoleEnum.BC) || currentUser.isGlobalAdmin) {
        consoles.push(ConsoleEnum.BC);
      }
      if (currentUser.consoles.includes(ConsoleEnum.SFC) || currentUser.isGlobalAdmin) {
        consoles.push(ConsoleEnum.SFC);
      }
    }
    return consoles;
  }

  private get laserDevices(): Device[] {
    return devicesService.store.cutDevices();
  }

  private get bendingDevices(): Device[] {
    return devicesService.store.bendDevices();
  }

  private get tubesDevices(): Device[] {
    return devicesService.store.tubeDevices();
  }

  private get hasLaser(): boolean {
    return !isEmpty(this.laserDevices);
  }

  private get hasBending(): boolean {
    return !isEmpty(this.bendingDevices);
  }

  private get hasTubes(): boolean {
    return !isEmpty(this.tubesDevices);
  }

  private get specificDeviceNotOverview(): boolean {
    return this.selectedDevice?.substring(2) !== 'overview';
  }

  private get slotAllowsBigWidget(): boolean {
    return this.selectedSlot.height === TOTAL_ROWS && this.selectedSlot.width >= TOTAL_COLUMNS / 2;
  }

  private get showFactoryOverviewWarning(): boolean {
    return (
      !this.slotAllowsBigWidget &&
      this.selectedWidgetDefinition?.widget === WidgetEnum.FactoryOverview
    );
  }

  private get showGanttChartWarning(): boolean {
    return (
      !this.slotAllowsBigWidget &&
      (this.selectedWidgetDefinition?.widget === WidgetEnum.LaserStatesGantt ||
        this.selectedWidgetDefinition?.widget === WidgetEnum.TubeStatesGantt)
    );
  }

  private get isAcceptButtonDisabled(): boolean {
    return (
      !this.slotAllowsBigWidget &&
      (this.selectedWidgetDefinition?.widget === WidgetEnum.FactoryOverview ||
        this.selectedWidgetDefinition?.widget === WidgetEnum.LaserStatesGantt ||
        this.selectedWidgetDefinition?.widget === WidgetEnum.TubeStatesGantt)
    );
  }

  private get wccCutWidgets(): GroupedWidgets {
    const commonWidgets = {
      '': [WidgetEnum.CutStatus],
      'report.comparative_data': [
        WidgetEnum.LaserAvailability,
        WidgetEnum.LaserProductivity,
        WidgetEnum.LaserOee,
        WidgetEnum.LaserStarveBlock,
        WidgetEnum.LaserOutputScrap,
        WidgetEnum.LaserCuttingTime,
      ],
      'report.historic_data': [
        WidgetEnum.LaserAvailabilityHist,
        WidgetEnum.LaserProductivityHist,
        WidgetEnum.LaserOeeHist,
        WidgetEnum.LaserCuttingTimeHist,
        WidgetEnum.LaserStarveBlockHist,
        WidgetEnum.LaserOutputAndScrapTimeline,
      ],
    };
    if (this.selectedDevice === 'L-overview') {
      return {
        ...commonWidgets,
        '': [...commonWidgets[''], WidgetEnum.LaserStatesGantt],
      };
    } else {
      return {
        ...commonWidgets,
        '': [...commonWidgets[''], WidgetEnum.CameraView],
      };
    }
  }

  private get wccBendWidgets(): GroupedWidgets {
    const commons = {
      '': [WidgetEnum.BendStatus],
      'report.comparative_data': [
        WidgetEnum.BendingAvailability,
        WidgetEnum.BendingTimeBetweenBend,
        WidgetEnum.BendingPerformancePart,
        WidgetEnum.BendingTopParts,
      ],
      'report.historic_data': [
        WidgetEnum.BendingAvailabilityHist,
        WidgetEnum.BendingTimeBetweenBendHist,
        WidgetEnum.BendingPerformancePartHist,
        WidgetEnum.BendingPerformanceHist,
        WidgetEnum.BendingNumberOfBendsTimeline,
      ],
    };

    if (this.selectedDevice === 'B-overview') {
      return commons;
    } else {
      return {
        ...commons,
        '': [...commons[''], WidgetEnum.CameraView],
      };
    }
  }

  private get wccTubeWidgets(): GroupedWidgets {
    const commonWidgets = {
      '': [WidgetEnum.TubeStatus],
      'report.comparative_data': [
        WidgetEnum.TubeAvailability,
        WidgetEnum.TubeProductivity,
        WidgetEnum.TubeOee,
        WidgetEnum.TubeStarveBlock,
        WidgetEnum.TubeOutputScrap,
        WidgetEnum.TubeCuttingTime,
      ],
      'report.historic_data': [
        WidgetEnum.TubeAvailabilityHist,
        WidgetEnum.TubeProductivityHist,
        WidgetEnum.TubeOeeHist,
        WidgetEnum.TubeCuttingTimeHist,
        WidgetEnum.TubeStarveBlockHist,
        WidgetEnum.TubeOutputScrapHist,
      ],
    };

    if (this.selectedDevice === 'T-overview') {
      return {
        ...commonWidgets,
        '': [...commonWidgets[''], WidgetEnum.TubeStatesGantt],
      };
    } else {
      return {
        ...commonWidgets,
        '': [...commonWidgets[''], WidgetEnum.CameraView],
      };
    }
  }

  private get wccWidgets(): GroupedWidgets {
    if (!this.selectedDevice) {
      return { '': [] };
    } else if (this.selectedDevice === 'F-overview') {
      return { '': [WidgetEnum.FactoryOverview] };
    } else if (this.selectedDevice!.startsWith('L-')) {
      return this.wccCutWidgets;
    } else if (this.selectedDevice!.startsWith('B-')) {
      return this.wccBendWidgets;
    } else if (this.selectedDevice!.startsWith('T-')) {
      return this.wccTubeWidgets;
    } else {
      Logger.error('Unknown device type found');
      return { '': [] };
    }
  }

  private get bcWidgets(): GroupedWidgets {
    return {
      'report.overview': [
        WidgetEnum.SalesMap,
        WidgetEnum.QuotesMap,
        WidgetEnum.OpenSalesFunnel,
        WidgetEnum.BusinessOverviewFigures,
      ],
      'report.quotes': [
        WidgetEnum.QuotesAbcCustomers,
        WidgetEnum.QuotesConvertedAmountTimeline,
        WidgetEnum.QuotesConversionRates,
        WidgetEnum.QuotesSize,
        WidgetEnum.QuotesFigures,
        WidgetEnum.QuotesTopQuotedMaterialsTimeline,
        WidgetEnum.QuotesQuotedMaterialsList,
        WidgetEnum.QuotesConvertedMap,
      ],
      'report.sales': [
        WidgetEnum.SalesCustomerGroups,
        WidgetEnum.SalesTopSalespeople,
        WidgetEnum.SalesFunnel,
        WidgetEnum.SalesFigures,
        WidgetEnum.SalesTimeline,
        WidgetEnum.SalesUnpaidDistribution,
      ],
    };
  }

  private get sfcWidgets(): GroupedWidgets {
    return {
      'report.manufacturing': [
        WidgetEnum.ManufacturingPOStatus,
        WidgetEnum.ManufacturingFinishedPOEstimatedVsActualPOL,
        WidgetEnum.ManufacturingReleasedPOStatus,
        WidgetEnum.ManufacturingPOLStatusDueDate,
        WidgetEnum.ManufacturingFigures,
        WidgetEnum.ManufacturingPOFinishedDueDate,
      ],
    };
  }

  private get widgets(): GroupedWidgets {
    switch (this.selectedConsole) {
      case ConsoleEnum.WCC:
        return this.wccWidgets;
      case ConsoleEnum.BC:
        return this.bcWidgets;
      case ConsoleEnum.SFC:
        return this.sfcWidgets;
      default:
        Logger.error('Unknown console received in Widget Selector', this.selectedConsole);
        return { '': [] };
    }
  }

  private get widgetDefinitions(): GroupedWidgetDefinitions {
    return Object.fromEntries(
      Object.entries(this.widgets).map(([label, widgets]) => [
        label,
        widgets.map((widget) => {
          return new WidgetDefinition(
            widget,
            this.specificDeviceNotOverview ? [this.selectedDevice!] : [],
            FilterTimeSpanEnum.None,
            [],
          );
        }),
      ]),
    );
  }

  private mounted() {
    this.selectedConsole = this.userConsoles[0] ?? ConsoleEnum.WCC;
    this.selectedDevice = this.getDefaultConsoleDevice(this.selectedConsole);
    this.selectedWidgetDefinition = Object.values(this.widgetDefinitions)[0][0];
  }

  private onSelectedConsoleInput(console: ConsoleEnum) {
    this.selectedConsole = console;
    this.selectedDevice = this.getDefaultConsoleDevice(this.selectedConsole);
    this.selectedWidgetDefinition = Object.values(this.widgetDefinitions)[0][0];
  }

  private onSelectedDeviceInput(device: string) {
    this.selectedDevice = device;
    this.selectedWidgetDefinition = Object.values(this.widgetDefinitions)[0][0];
  }

  private onSelectedWidgetClick(wd: WidgetDefinition) {
    this.selectedWidgetDefinition = wd;
  }

  private getDefaultConsoleDevice(console: ConsoleEnum): string | null {
    switch (console) {
      case ConsoleEnum.WCC:
        return 'F-overview';
      default:
        return null;
    }
  }

  private onAddWidget() {
    if (!this.selectedWidgetDefinition) {
      return;
    }
    const widget = this.selectedWidgetDefinition!.widget;
    if (
      (widget === WidgetEnum.FactoryOverview ||
        widget === WidgetEnum.LaserStatesGantt ||
        widget === WidgetEnum.TubeStatesGantt) &&
      !this.slotAllowsBigWidget
    ) {
      return;
    }
    if (this.selectedConsole === ConsoleEnum.WCC) {
      this.$emit('add-widget', {
        widget,
        deviceId: this.specificDeviceNotOverview ? this.selectedDevice : '',
      });
    } else {
      this.$emit('add-widget', {
        widget,
      });
    }
    this.$emit('close');
  }

  private consoleName(console: ConsoleEnum): LocaleMessage {
    switch (console) {
      case ConsoleEnum.BC:
        return i18n.t('category_menu.BC');
      case ConsoleEnum.SFC:
        return i18n.t('category_menu.SFC');
      case ConsoleEnum.WCC:
        return i18n.t('category_menu.WCC');
      default:
        return '';
    }
  }
}
