
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from '@/models/location';
import GenericKeyCopyModal from '@/components/GenericKeyCopyModal.vue';
import { locationsService } from '@/services/locations.service';

@Component({
  components: {
    GenericKeyCopyModal,
  },
})
export default class ConnectionStringModal extends Vue {
  @Prop()
  private location!: Location;

  private loading = false;
  private connectionString: string | null = null;

  private async mounted() {
    this.loading = true;
    try {
      const apiKey = await locationsService.getApiKey(this.location.id);
      this.connectionString = [
        `Endpoint=${window.location.host}`,
        `LocationId=${this.location.id}`,
        `ApiKey=${apiKey}`,
      ].join(';');
    } catch (e) {
      this.$emit('close');
    } finally {
      this.loading = false;
    }
  }
}
