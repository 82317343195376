// --------------------------------------------------------------------------------
// <copyright file="driveAccumulatedCurrentDifferenceTimelineTooltipFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { getTooltipTitle } from '@/utils/charts';
import { DriveAccumulatedCurrentDifferenceData } from './chartsData';
import {
  getHeadVersionChangeDataHtml,
  isHeadVersionChangeTooltipParamItem,
} from './cuttingHeadCommons';
import { GeneratorParams } from './generatorParams';
import i18n from '@/i18n';

export function tooltipFormatter(
  params: any,
  parameters: GeneratorParams,
  isCategoryAxis: boolean,
  data: DriveAccumulatedCurrentDifferenceData[],
): string {
  let driveCurrentDifferenceHtml = '';
  let cuttingHeadVersionChangeHtml = '';

  const dateGrouping = parameters.timeAxisSpan!;

  params.forEach((paramItem: any) => {
    if (isDriveCurrentDifferenceTooltipParamItem(paramItem)) {
      driveCurrentDifferenceHtml += getNumericValueTooltipHtml(paramItem);
    } else if (isHeadVersionChangeTooltipParamItem(paramItem)) {
      cuttingHeadVersionChangeHtml += getHeadVersionChangeDataHtml(paramItem, data, dateGrouping);
    }
  });

  return `
    <div data-testid="time-title" style="font-size: 15px">
      ${getTooltipTitle(params[0].axisValue, dateGrouping, isCategoryAxis)}
    </div>

    ${driveCurrentDifferenceHtml}
    ${cuttingHeadVersionChangeHtml}
  `;
}

function isDriveCurrentDifferenceTooltipParamItem(paramItem: any) {
  return paramItem.seriesType === 'line';
}

function getNumericValueTooltipHtml(seriesParam: any): string {
  const value: number = seriesParam.value[1]; // ['ISO date', value]
  const unit = seriesParam.seriesName === 'capacitive_temperature' ? 'ºC' : 'mA';
  const formattedValue = `${i18n.n(value, { maximumFractionDigits: 2 })} ${unit}`;
  return getValueTooltipHtml(seriesParam, formattedValue);
}

function getValueTooltipHtml(seriesParam: any, value: string): string {
  return `
  <div
    data-testid="series-value-${seriesParam.axisIndex}-${seriesParam.seriesName}"
    style="display: flex; flex-direction: row"
  >
    <div style="flex-grow: 1">
      ${seriesParam.marker}
      ${i18n.t(`report.${seriesParam.seriesName}`)}
    </div>
    <div style="font-weight: bold; padding-left: 3rem">${value}</div>
  </div>
  `;
}
