
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

@Component({
  computed: {
    MIN: () => AutoplayInterval.MIN,
    MAX: () => AutoplayInterval.MAX,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AutoplayInterval extends Vue {
  static readonly MIN = 15;
  static readonly MAX = 300;

  @Prop({ required: true })
  private autoplayInterval!: number;

  private enteredInterval: number | null = null;

  private get validationRules() {
    return {
      interval: {
        required: true,
        min_value: AutoplayInterval.MIN,
        max_value: AutoplayInterval.MAX,
      },
    };
  }

  private mounted() {
    this.enteredInterval = this.autoplayInterval;
  }

  private async onSubmit() {
    this.$emit('save', this.enteredInterval);
    this.closeModal();
  }

  private closeModal() {
    this.$emit('close');
  }
}
