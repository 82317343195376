// --------------------------------------------------------------------------------
// <copyright file="openSalesFunnelGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import moment from 'moment';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { OpenSalesFunnelData } from './chartsData';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockOpenSalesFunnelData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export default class OpenSalesFunnelGenerator extends ChartGenerator<OpenSalesFunnelData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
  ) {
    const startDate = this.getStartDateOrUndefined(timeSpan);
    const endDate = this.getEndDateOrUndefined(timeSpan);
    const date = moment().format('YYYY-MM-DD');
    return metricsService.getSMBSMetricsBC<OpenSalesFunnelData[]>(
      this.procedure,
      this.tenantIdDh,
      { date, startDate, endDate, axisTimespan: timeAxisSpan },
      this.controller,
    );
  }

  override updateOptions(
    data: OpenSalesFunnelData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const datum = data[0];
    const noTotal = this.totalIsNull(datum);
    const openIsZero = this.openIsZero(datum);
    const max = Object.values(datum).reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE);
    const openData = {
      name: 'open',
      type: 'funnel' as const,
      left: '10%',
      width: '80%',
      sort: 'none' as const,
      data: [
        {
          value: datum.open_quotes,
          name: i18n.t('report.quotes').toString(),
          itemStyle: {
            color: '#5470c6',
          },
        },
        {
          value: datum.open_sold,
          name: i18n.t('report.business_console.nSalesOrders').toString(),
          itemStyle: {
            color: '#fac858',
          },
        },
        {
          value: datum.open_invoiced,
          name: i18n.t('report.business_console.invoices').toString(),
          itemStyle: {
            color: '#ee6666',
          },
        },
      ],
      z: this.currentZIndex(datum),
      itemStyle: {
        opacity: 1,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: { show: false },
      labelLine: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
      },
      top: 0,
      bottom: '12%',
      max,
    };
    const totalData = {
      name: 'total',
      type: 'funnel' as const,
      left: '10%',
      width: '80%',
      sort: 'none' as const,
      data: [
        {
          value: datum.quotes,
          name: i18n.t('report.quotes').toString(),
          itemStyle: {
            color: '#90a2da',
          },
        },
        {
          value: datum.sold,
          name: i18n.t('report.business_console.nSalesOrders').toString(),
          itemStyle: {
            color: '#fbdb92',
          },
        },
        {
          value: datum.invoiced,
          name: i18n.t('report.business_console.invoices').toString(),
          itemStyle: {
            color: '#f49b9b',
          },
        },
      ],
      label: { show: false },
      labelLine: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
      },
      z: this.targetZIndex(datum),
      top: 0,
      bottom: '12%',
      max,
    };

    const series = [];

    if (!openIsZero) {
      series.push(openData);
    }

    if (!noTotal) {
      series.push(totalData);
    }

    return {
      title: {
        show: false,
      },
      legend: {
        data: [
          i18n.t('report.quotes'),
          i18n.t('report.business_console.nSalesOrders'),
          i18n.t('report.business_console.invoices'),
        ],
        type: 'scroll',
        bottom: 0,
        top: 'auto',
      },
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          const { seriesName, name } = params;
          const expectedTooltip = `
          <div style="display: flex; flex-direction: row; font-size: 12px">
            <div style="flex-grow: 1">
              ${seriesName === 'target' ? '<b>' : ''}
              ${i18n.t('report.business_console.open')}
              ${seriesName === 'target' ? '</b>' : ''}
            </div>
            <div style="padding-left: 3em">${i18n.n(
              Math.round(this.getOpenValue(datum, name) * 100) / 100,
            )}</div>
          </div>
          `;
          const currentTooltip = `
          <div style="display: flex; flex-direction: row; font-size: 12px">
            <div style="flex-grow: 1">
              ${seriesName === 'current' ? '<b>' : ''}
              ${i18n.t('report.business_console.total')}
              ${seriesName === 'current' ? '</b>' : ''}
            </div>
            <div style="padding-left: 3em">${i18n.n(
              Math.round(this.getTotalValue(datum, name) * 100) / 100,
            )}</div>
          </div>
          `;
          return `
          <p>${name}</p>
          ${noTotal ? '' : expectedTooltip}
          ${openIsZero ? '' : currentTooltip}
          `;
        },
      },
      series,
    };
  }

  override getMockData(): OpenSalesFunnelData[] | null {
    return mockOpenSalesFunnelData();
  }

  private totalIsNull(data: OpenSalesFunnelData): boolean {
    return [data.quotes, data.sold, data.invoiced].every((t) => t === null);
  }

  private openIsZero(data: OpenSalesFunnelData): boolean {
    return [data.open_quotes, data.open_sold, data.open_invoiced].every((t) => t === 0);
  }

  private getOpenValue(data: OpenSalesFunnelData, key: string): number {
    switch (key) {
      case i18n.t('report.quotes'):
        return data.open_quotes;
      case i18n.t('report.business_console.nSalesOrders'):
        return data.open_sold;
      case i18n.t('report.business_console.invoices'):
        return data.open_invoiced;
      default:
        return 0;
    }
  }

  private getTotalValue(data: OpenSalesFunnelData, key: string): number {
    switch (key) {
      case i18n.t('report.quotes'):
        return data.quotes;
      case i18n.t('report.business_console.nSalesOrders'):
        return data.sold;
      case i18n.t('report.business_console.invoices'):
        return data.invoiced;
      default:
        return 0;
    }
  }

  private targetZIndex(data: OpenSalesFunnelData) {
    return this.isTargetAbove(data) ? 100 : 0;
  }

  private currentZIndex(data: OpenSalesFunnelData) {
    return this.isTargetAbove(data) ? 0 : 100;
  }

  private isTargetAbove(data: OpenSalesFunnelData): boolean {
    return (
      [
        data.open_quotes - data.quotes,
        data.open_sold - data.sold,
        data.open_invoiced - data.invoiced,
      ].reduce((a, b) => a + b, 0) >= 0
    );
  }

  private getStartDateOrUndefined(
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): string | undefined {
    return (timeSpan as [string, string])?.[0];
  }

  private getEndDateOrUndefined(
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): string | undefined {
    return (timeSpan as [string, string])?.[1];
  }
}
