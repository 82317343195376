// --------------------------------------------------------------------------------
// <copyright file="tooltipFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { getDateTooltipLabel } from '@/utils/charts';
import { isNil } from '@/utils/misc';

export type SuffixFunction = (params: any) => string;

export class TooltipFormatter {
  static readonly DEFAULT_SERIES_TRANSLATION_PREFIX = 'report.';

  private static Builder = class {
    private configuration: TooltipConfiguration = {
      titleGenerator: dateWithGroupingTitleGenerator,
      seriesNameGenerator: (seriesName) =>
        translationPrefixSeriesNameGenerator(
          seriesName,
          TooltipFormatter.DEFAULT_SERIES_TRANSLATION_PREFIX,
        ),
    };

    constructor(
      private dateGrouping: FilterTimeAxisSpanEnum | undefined,
      private roundValues: boolean,
    ) {}

    withTitleGenerator(generator: TooltipTitleGenerator) {
      this.configuration.titleGenerator = generator;
      return this;
    }

    withSuffix(suffix: string | SuffixFunction) {
      this.configuration.suffix = suffix;
      return this;
    }

    withSeriesTranslationPrefix(prefix: string | undefined) {
      if (!isNil(prefix)) {
        this.withSeriesNameGenerator((seriesName) =>
          translationPrefixSeriesNameGenerator(seriesName, prefix),
        );
      } else {
        this.withSeriesNameGenerator(undefined);
      }
      return this;
    }

    withSeriesNameGenerator(generator: ((seriesName: string) => string) | undefined) {
      this.configuration.seriesNameGenerator = generator;
      return this;
    }

    withTextOnMissingValue(text: string) {
      this.configuration.textOnMissingValue = text;
      return this;
    }

    get() {
      const tooltipFormatter = new TooltipFormatter(
        this.dateGrouping,
        this.roundValues,
        this.configuration,
      );

      return (params: any) => tooltipFormatter.format(params);
    }
  };

  static build(dateGrouping: FilterTimeAxisSpanEnum | undefined, roundValues = false) {
    return new this.Builder(dateGrouping, roundValues);
  }

  private constructor(
    private dateGrouping: FilterTimeAxisSpanEnum | undefined,
    private roundValues: boolean,
    private configuration: TooltipConfiguration,
  ) {}

  private format(params: any): string {
    return this.getTitleHtml(params) + this.getSeriesValuesHtml(params);
  }

  private getTitleHtml(params: any) {
    return `
    <div class="tooltip-title" data-testid="title">
      ${this.configuration.titleGenerator(params, this.dateGrouping)}
    </div>
    `;
  }

  private getSeriesValuesHtml(params: any) {
    return params
      .filter((paramItem: any) => this.configuration.textOnMissingValue || this.hasValue(paramItem))
      .reduce((html: string, paramItem: any) => html + this.getNumericValueHtml(paramItem), '');
  }

  private getNumericValueHtml(seriesParam: any): string {
    // ['ISO date', value] or value depending on the date grouping
    const value = Array.isArray(seriesParam.value) ? seriesParam.value?.[1] : seriesParam.value;
    const suffix = this.getSuffix(seriesParam);

    if (isNil(value)) {
      return this.getValueHtml(seriesParam, this.configuration.textOnMissingValue!);
    }

    if (this.roundValues) {
      return this.getValueHtml(
        seriesParam,
        `${i18n.n(value, { maximumFractionDigits: 0 })}${suffix}`,
      );
    }

    return this.getValueHtml(
      seriesParam,
      `${i18n.n(value, { maximumFractionDigits: 2 })}${suffix}`,
    );
  }

  private getSuffix(seriesParam: any) {
    if (isNil(this.configuration.suffix)) {
      return '';
    }

    if (typeof this.configuration.suffix === 'string') {
      return ` ${this.configuration.suffix}`;
    } else {
      return ` ${this.configuration.suffix(seriesParam)}`;
    }
  }

  private getValueHtml(seriesParam: any, value: string): string {
    return `
    <div
      class="tooltip-series-value-row"
      data-testid="series-value-${seriesParam.seriesName}"
    >
      <div class="series-marker-name">
        ${seriesParam.marker}
        
        <span data-testid="series-name">
          ${this.getTranslatedSeriesName(seriesParam.seriesName)}
        </span>
      </div>
      <div class="series-value" data-testid="series-value">
        ${value}
      </div>
    </div>
    `;
  }

  private getTranslatedSeriesName(seriesName: string) {
    if (!this.configuration.seriesNameGenerator) {
      // Some series names may not need to be translated
      return seriesName;
    }

    return this.configuration.seriesNameGenerator(seriesName);
  }

  private hasValue(paramItem: any) {
    return (
      (Array.isArray(paramItem.value) && !isNil(paramItem.value?.[1])) ||
      (!Array.isArray(paramItem.value) && !isNil(paramItem.value))
    );
  }
}

interface TooltipConfiguration {
  titleGenerator: TooltipTitleGenerator;
  textOnMissingValue?: string;
  suffix?: string | SuffixFunction;
  seriesNameGenerator?: (seriesName: string) => string;
}

export type TooltipTitleGenerator = (
  params: any,
  dateGrouping: FilterTimeAxisSpanEnum | undefined,
) => string;

export function dateWithGroupingTitleGenerator(
  params: any,
  dateGrouping: FilterTimeAxisSpanEnum | undefined,
): string {
  return `${getDateTooltipLabel(params[0].axisValueLabel, dateGrouping)}`;
}

export function translationPrefixSeriesNameGenerator(
  seriesName: string,
  translationPrefix: string,
): string {
  return i18n.t(`${translationPrefix}${seriesName}`).toString();
}
