// --------------------------------------------------------------------------------
// <copyright file="tenant.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { isNil } from '@/utils/misc';
import moment from 'moment/moment';
import { Measurements } from '@/models/enums/Measurement';

class Tenant {
  constructor(
    public id: number,
    public name: string,
    public tenantIdDh: number,
    public email: string | null,
    public foreName: string | null,
    public lastName: string | null,
    public sapNumber: string | null, // required
    public currencyCode: string | null, // non-nullable in database
    public measurement: Measurements | null, // non-nullable in database
    public timeZone: string,
    public subsidiaryId: number | null, // non-nullable in database
    public startAt: Date | null,
    public endAt: Date | null,
    public bystronicOrderNumber: string | null,
    public bystronicOrderPerson: string | null,
    public customerOrderNumber: string | null,
    public customerOrderPerson: string | null,
    public subsidiary: string | null,
    public subsidiaryCode: number | null,
    public hasDatahub: boolean,
    public hasSmbs: boolean,
    public channel0Name: string,
    public channel1Name: string,
    public channel2Name: string,
    public channel3Name: string,
    public hasDailyReport: boolean,
    public hasWeeklyReport: boolean,
    public hasMonthlyReport: boolean,
    public hasQuarterlyReport: boolean,
    public hasAnnualReport: boolean,
    public maxDataPlatformVersion: string | null,
  ) {}

  static gasName(tenant: Tenant, channel: number | string | null) {
    switch (channel) {
      case 0:
      case 'channel0':
        return tenant.channel0Name;
      case 1:
      case 'channel1':
        return tenant.channel1Name;
      case 2:
      case 'channel2':
        return tenant.channel2Name;
      case 3:
      case 'channel3':
        return tenant.channel3Name;
      default:
        return i18n.t('report.unknown').toString();
    }
  }

  hasExpired(): boolean {
    return moment(this.endAt).isBefore();
  }

  get hasDataPlatform(): boolean {
    return !isNil(this.maxDataPlatformVersion);
  }

  static GetEmpty(): Tenant {
    return new Tenant(
      0,
      '',
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      'UTC',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      false,
      false,
      'channel 0',
      'channel 1',
      'channel 2',
      'channel 3',
      false,
      false,
      false,
      false,
      false,
      null,
    );
  }

  static GetCopy(original: Tenant): Tenant {
    return new Tenant(
      original.id,
      original.name,
      original.tenantIdDh,
      original.email,
      original.foreName,
      original.lastName,
      original.sapNumber,
      original.currencyCode,
      original.measurement,
      original.timeZone,
      original.subsidiaryId,
      original.startAt,
      original.endAt,
      original.bystronicOrderNumber,
      original.bystronicOrderPerson,
      original.customerOrderNumber,
      original.customerOrderPerson,
      original.subsidiary,
      original.subsidiaryCode,
      original.hasDatahub,
      original.hasSmbs,
      original.channel0Name,
      original.channel1Name,
      original.channel2Name,
      original.channel3Name,
      original.hasDailyReport,
      original.hasWeeklyReport,
      original.hasMonthlyReport,
      original.hasQuarterlyReport,
      original.hasAnnualReport,
      original.maxDataPlatformVersion,
    );
  }
}

export { Tenant, Tenant as Customer };