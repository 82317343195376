
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { Component, Ref, Vue } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';
import { dateRangeToDateStrings, yearToDate } from '@/utils/dates';
import LayoutGrid from '../dashboard/LayoutGrid.vue';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';

@Component({
  components: {
    WidgetContainer,
    DateGroupingFilter,
    LayoutGrid,
  },
})
export default class BusinessConsoleOverview extends Vue {
  @Ref('bDatepicker')
  private bDatepicker: any;

  private selectedAxisTimespan = FilterTimeAxisSpanEnum.Month;
  private dateRangeSelection = yearToDate();
  private gridLayout = availableDashboardLayouts[DashboardLayout.BCOverviewLike];

  private get widgetDefinitions(): WidgetDefinition[] {
    return [
      this.openSalesFunnelWidgetDefinition,
      this.businessOverviewFiguresWidgetDefinition,
      this.salesMapWidgetDefinition,
    ].map((wd, index) => wd.withIndex(index));
  }

  private get openSalesFunnelWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.OpenSalesFunnel,
      [],
      this.dateRangeString,
      [],
      undefined,
      this.selectedAxisTimespan,
    );
  }

  private get businessOverviewFiguresWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(WidgetEnum.BusinessOverviewFigures, [], this.dateRangeString, []);
  }

  private get salesMapWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(WidgetEnum.SalesMap, [], this.dateRangeString, []);
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRangeSelection);
  }

  private toggleDatepicker() {
    this.bDatepicker.toggle();
  }
}
