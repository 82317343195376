// --------------------------------------------------------------------------------
// <copyright file="laserAccumulatedConsumptionKxSeriesGeneratorHelper.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { LaserConsumptionTimelineData } from './chartsData';
import { LaserConsumptionKxSeriesGeneratorHelper } from '@/models/Charts/laserConsumptionKxSeriesGeneratorHelper';

export class LaserAccumulatedConsumptionKxSeriesGeneratorHelper extends LaserConsumptionKxSeriesGeneratorHelper {
  constructor(data: LaserConsumptionTimelineData[], axesIndex: number, isCategoryAxis: boolean) {
    super(data, axesIndex, isCategoryAxis);
  }

  protected getSeriesData(powerLevel: number, uniqueDates: string[]): any[] {
    const powerFilteredData = this.data.filter((d) => d.kPower === powerLevel);

    let index = 0;
    let lastValue = 0;

    return uniqueDates.map((date) => {
      while (index < powerFilteredData.length && powerFilteredData[index].bucket <= date) {
        lastValue = powerFilteredData[index].kPowerConsumptionKwh;
        ++index;
      }

      return this.isCategoryAxis ? lastValue : [date, lastValue];
    });
  }
}
