
import { Component, Prop, Vue } from 'vue-property-decorator';
import { locationsService } from '@/services/locations.service';
import { Location } from '@/models/location';
import { Tenant } from '@/models/tenant';

@Component
export default class ModalCreationModal extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  public location: Location = Location.GetEmpty();

  private isSubmitting = false;

  private mounted() {
    this.location.tenantId = this.tenant.id;
  }

  public async onSubmit() {
    this.isSubmitting = true;
    locationsService
      .create(this.location)
      .then(() => {
        this.$emit('save');
        this.$emit('close');
      })
      .finally(() => (this.isSubmitting = false));
  }

  public get isEnabled() {
    if (this.location && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public handleChange(event: any): void {
    this.location = Location.GetCopy(this.location);
  }
}
