
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserAlertStatus } from '@/models/userAlertStatus';
import { UserCustomNotificationStatus } from '@/models/customNotifications';
import moment from 'moment';
import { AlertWorkflowNotification } from '@/models/AlertWorkflowNotification';
import i18n from '@/i18n';

@Component({})
export default class NotificationCard extends Vue {
  @Prop({ required: true })
  private notification!:
    | UserAlertStatus
    | AlertWorkflowNotification
    | UserCustomNotificationStatus;

  private getNotificationMessage() {
    if (this.notification instanceof UserAlertStatus) {
      return this.getUserAlertStatusNotificationMessage(this.notification);
    } else if (this.notification instanceof UserCustomNotificationStatus) {
      return this.notification.messageTxt;
    } else {
      // instanceof AlertWorkflowNotification
      return i18n.t('alert.alert_workflow_notification_message', {
        deviceName: this.notification.deviceName,
        customerName: this.notification.customerName,
      });
    }
  }

  private getUserAlertStatusNotificationMessage(notification: UserAlertStatus) {
    const params: any = {
      metric: this.$i18n.t(`alert.metric.${notification.metric}`),
      operator: this.$i18n.t(`alert.operator.${notification.operator}`),
      thresholdValue: notification.thresholdValue.toString(),
      device: notification.deviceName ?? this.$i18n.t('alert.all_devices'),
      currentValue: this.$i18n.n(notification.currentValue, { maximumFractionDigits: 2 }),
    };

    return this.$i18n.t('alert.message', params);
  }

  private formatTimestamp(wrongTimestamp: string) {
    // It's 'wrong' because the timezone is missing
    const date = moment(`${wrongTimestamp}+00:00`);
    return date.format('YYYY-MM-DD, HH:mm:ss');
  }
}
