
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableActionButton2 from '@/components/tableWithActions/TableActionButton2.vue';
import TableWithActions2 from '@/components/tableWithActions/TableWithActions2.vue';
import { devicesService } from '@/services/devices.service';
import { isEmpty, valueOrDefault } from '@/utils/misc';
import { toastService } from '@/library-services/toast.service';
import { alertsService } from '@/services/alerts.service';
import { usersService } from '@/services/users.service';
import { User } from '@/models/user';
import { matchIgnoringCase } from '@/utils/table';
import { Route } from 'vue-router';
import { router } from '@/library-services/e-service-center-router';
import i18n from '@/i18n';
import EquipmentFilters from '@/views/careConsole/EquipmentFilters.vue';
import {
  EquipmentEServiceData,
  EquipmentFilterValues,
  SubscribedUser,
} from '@/views/careConsole/commons/model';
import {
  FilterMatcher,
  containsSubscribers,
  createTextListMatcher,
  hasSubscriptions,
  isCurrentUserSubscribed,
  createTextListModelMatcher,
} from './commons/matchers';
import { formatUserNames } from './commons/utils';

@Component({
  methods: { valueOrDefault, isEmpty, formatUserNames },
  components: {
    EquipmentFilters,
    TableWithActions2,
    TableActionButton,
    TableActionButton2,
  },
})
export default class EServiceCenterEquipment extends Vue {
  private equipmentList: EquipmentEServiceData[] = [];
  private selectedEquipmentList: EquipmentEServiceData[] = [];

  private isLoading = false;

  private filterMatchers: Record<keyof EquipmentFilterValues, FilterMatcher> = {
    mySubscriptions: isCurrentUserSubscribed,
    noSubscriptions: hasSubscriptions,
    customerName: createTextListMatcher('customerName'),
    name: createTextListMatcher('name'),
    model: createTextListModelMatcher('model'),
    equipmentNumber: createTextListMatcher('equipmentNumber'),
    locationName: createTextListMatcher('locationName'),
    subsidiaryName: createTextListMatcher('subsidiaryName'),
    subscribers: containsSubscribers,
  };

  @Ref('filters')
  private filters?: EquipmentFilters;

  // beforeRouteUpdate only detects parameter changes in the same route, so we
  // need beforeRouteEnter to detect navigation to Customers page (URL path change).
  private beforeRouteEnter(to: Route, from: Route, next: (vm: any) => void) {
    next(async (vm: any) => (vm.equipmentList = await vm.fetchEquipmentList()));
  }

  private modelMatcher(equipmentItem: EquipmentEServiceData, searchText: string): boolean {
    const displayedModel = valueOrDefault(
      equipmentItem.model,
      i18n.t('e_service_center.equipment_list.unknown_model').toString(),
    );
    return matchIgnoringCase(displayedModel, searchText);
  }

  private subscribedUsersMatcher(
    equipmentItem: EquipmentEServiceData,
    searchText: string,
  ): boolean {
    const subscribedUsersNames = equipmentItem.subscribedUsers.map((user) => user.name).join('');
    return matchIgnoringCase(subscribedUsersNames, searchText);
  }

  private async goToEquipmentCareConsole(equipmentItem: EquipmentEServiceData) {
    await router.goToEquipmentCareConsole(equipmentItem.customerIdDh, equipmentItem.stringId);
  }

  private async goToEquipmentWorkCenterConsole(equipmentItem: EquipmentEServiceData) {
    await router.goToEquipmentWorkCenterConsole(equipmentItem.customerIdDh, equipmentItem.stringId);
  }

  private isSubscribed(equipmentItem: EquipmentEServiceData): boolean {
    return equipmentItem.subscribedUsers.some((user) => user.id === this.user.id);
  }

  private async subscribeToSelectedEquipmentAlerts() {
    try {
      await alertsService.subscribeAll(this.selectedEquipmentIds);

      toastService.success('e_service_center.subscribe_selected.success');
      this.equipmentList = await this.fetchEquipmentList();
    } catch (e) {
      toastService.error('e_service_center.subscribe_selected.error');
    }
  }

  private async unsubscribeFromSelectedEquipmentAlerts() {
    try {
      await alertsService.unsubscribeAll(this.selectedEquipmentIds);

      toastService.success('e_service_center.unsubscribe_selected.success');
      this.equipmentList = await this.fetchEquipmentList();
    } catch (e) {
      toastService.error('e_service_center.unsubscribe_selected.error');
    }
  }

  private async subscribeToEquipmentAlerts(equipmentItem: EquipmentEServiceData) {
    try {
      await alertsService.subscribe(equipmentItem.id);

      toastService.success('e_service_center.subscribe.success', {
        deviceName: equipmentItem.name,
      });
      this.equipmentList = await this.fetchEquipmentList();
    } catch (e) {
      toastService.error('e_service_center.subscribe.error', { deviceName: equipmentItem.name });
    }
  }

  private async unsubscribeFromEquipmentAlerts(equipmentItem: EquipmentEServiceData) {
    try {
      await alertsService.unsubscribe(equipmentItem.id);

      toastService.success('e_service_center.unsubscribe.success', {
        deviceName: equipmentItem.name,
      });
      this.equipmentList = await this.fetchEquipmentList();
    } catch (e) {
      toastService.error('e_service_center.unsubscribe.error', { deviceName: equipmentItem.name });
    }
  }

  private async fetchEquipmentList() {
    this.isLoading = true;
    try {
      if (this.$route.params.customerIdDh) {
        return await devicesService.getEServiceData(+this.$route.params.customerIdDh);
      } else {
        return await devicesService.getEServiceData();
      }
    } finally {
      this.isLoading = false;
    }
  }

  @Watch('equipmentList')
  private clearFilters() {
    this.filters?.clearAllFilters();
  }

  private getSubscribedUsersBadgeTooltip(users: SubscribedUser[]): string {
    return users.map((user) => user.name).join(', ');
  }

  private get user(): User {
    return usersService.store.current();
  }

  private get selectedEquipmentIds(): number[] {
    return this.selectedEquipmentList.map((equipmentItem) => equipmentItem.id);
  }
}
