// --------------------------------------------------------------------------------
// <copyright file="bendStatusOverviewChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { EChartsOption, SeriesOption } from 'echarts';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { GeneratorParams } from './generatorParams';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import {
  BendSpeedFlowStates,
  BendState,
  BendStateDurationData,
  BendStateOverview,
  BendStatesOverviewData,
} from '@/models/Charts/chartsData';
import { getBendFlowSpeedColor, getBendStateColor } from '@/utils/color';
import { formatPercentage } from '@/utils/number';
import Color from 'color';

/**
 * Chart generator for the 'Status overview' widget.
 *
 * Returns Echarts options to draw a one-dimensional heatmap chart where each
 * square represents the value for a certain date.
 *
 * The colors of the squares range from lighter (higher values) to darker (lower
 * values).
 */
export default class BendStatusOverviewChartGenerator extends ChartGenerator<BendStatesOverviewData> {
  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ): Promise<BendStatesOverviewData | null> {
    return mockData;

    /* Pending implementation in the backend.
    return metricsService.getDevicesMetrics<BendStatesOverviewData[]>(
      this.procedure,
      {
        deviceIds: selectedDevices,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
    */
  }

  override updateOptions(
    data: BendStatesOverviewData,
    parameters: GeneratorParams = { paramValues: { chart: BendStateOverview } },
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      title: {
        show: false,
      },
      grid: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      xAxis: {
        type: 'value',
        show: false,
      },
      yAxis: {
        type: 'category',
        show: false,
      },
      tooltip: {
        show: false,
      },
      series: this.generateSeries(data, parameters.paramValues.chart),
    };
  }

  private createSeriesForBendState<T extends BendState | BendSpeedFlowStates>(
    data: BendStateDurationData<T>[],
    getColor: (state: string) => string,
  ) {
    const totalTime = data.reduce((acc, value) => acc + value.seconds, 0);
    if (totalTime <= 0) {
      return [];
    }
    return this.createSeries(data, totalTime, getColor);
  }

  private generateSeries(data: BendStatesOverviewData, chart: BendStateOverview): SeriesOption[] {
    if (chart === BendStateOverview.ProductionMode) {
      return this.createSeriesForBendState(data.current_period.productionMode, getBendStateColor);
    }
    return this.createSeriesForBendState(data.current_period.flowSpeed, getBendFlowSpeedColor);
  }

  private createSeries<T extends BendState | BendSpeedFlowStates>(
    statesData: BendStateDurationData<T>[],
    totalStateTime: number,
    getColor: (state: string) => string,
  ): SeriesOption[] {
    return statesData.map((item) => {
      const color = new Color(getColor(item.state)).fade(0.25).hexa();
      return {
        type: 'bar' as const,
        name: item.state.toString(),
        stack: 'total',
        color,
        label: {
          show: true,
          fontWeight: 'bold',
          position: 'insideLeft',
          distance: 5,
          formatter: (params: any) => formatPercentage(Math.round(params.value * 1000) / 10),
        },
        emphasis: {
          focus: 'series',
        },
        barWidth: '100%',
        data: [item.seconds / totalStateTime],
      } as SeriesOption;
    });
  }
}

const mockData: BendStatesOverviewData = {
  'current_period': {
    'productionMode': [
      {
        'state': 'automatic',
        'seconds': 9961,
      },
      {
        'state': 'semi-automatic',
        'seconds': 7184,
      },
      {
        'state': 'non-productive',
        'seconds': 11654,
      },
    ],
    'flowSpeed': [
      {
        'state': BendSpeedFlowStates.HighFlow,
        'seconds': 6633,
      },
      {
        'state': BendSpeedFlowStates.LowFlow,
        'seconds': 22166,
      },
      {
        'state': BendSpeedFlowStates.NoFlow,
        'seconds': 0,
      },
    ],
  },
  'previous_period': {
    'productionMode': [
      {
        'state': 'automatic',
        'seconds': 8203,
      },
      {
        'state': 'semi-automatic',
        'seconds': 5916,
      },
      {
        'state': 'non-productive',
        'seconds': 9598,
      },
    ],
    'flowSpeed': [
      {
        'state': BendSpeedFlowStates.HighFlow,
        'seconds': 5463,
      },
      {
        'state': BendSpeedFlowStates.LowFlow,
        'seconds': 18256,
      },
      {
        'state': BendSpeedFlowStates.NoFlow,
        'seconds': 0,
      },
    ],
  },
};
