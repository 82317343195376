// --------------------------------------------------------------------------------
// <copyright file="driveCurrentDifferenceHistogramGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { DriveCurrentDifferenceHistogramData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, SeriesOption } from 'echarts';
import { metricsService } from '@/services/metrics.service';
import { doesAnyDataComeFromNewerCuttingHeadSoftware } from './cuttingHeadCommons';

export class DriveCurrentDifferenceHistogramGenerator extends ChartGenerator<
  DriveCurrentDifferenceHistogramData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string],
  ) {
    const deviceId = selectedDevices[0];
    return metricsService.getSSCMetrics<DriveCurrentDifferenceHistogramData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: timeSpan[0],
        dateTo: timeSpan[1],
        versionInformation: true,
        interruptIfV4: true,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: DriveCurrentDifferenceHistogramData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    if (doesAnyDataComeFromNewerCuttingHeadSoftware(data)) {
      return {
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: i18n.t('widget_info_text.no_data_for_ch').toString(),
              overflow: 'break',
              width: 300,
              fontSize: '18px',
              fontFamily: 'Inter',
              fontWeight: 'bold',
              textAlign: 'center',
            } as any,
          },
        ],
      };
    }
    const sortedData = data
      .filter((x) => !x.cuttingHeadSoftwareVersion)
      .sort((a, b) => a.k_diffs - b.k_diffs);
    return {
      grid: {
        top: '4%',
        bottom: 30,
        left: 8,
        right: 8,
        containLabel: true,
      },
      legend: {
        show: true,
        bottom: 0,
        selectedMode: false,
      },
      tooltip: {
        trigger: 'axis',
        show: true,
        confine: true,
      },
      xAxis: {
        type: 'category',
        data: this.getValues(sortedData),
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value: number) => i18n.n(value),
        },
      },
      series: this.series(sortedData),
    };
  }

  private series(dataArray: DriveCurrentDifferenceHistogramData[]): SeriesOption[] {
    return [
      {
        name: i18n.t(`report.difference_frequency`).toString(),
        type: 'bar',
        emphasis: {
          focus: 'series',
        },
        data: this.getValueFrequencies(dataArray),
      },
    ];
  }

  private getValues(dataArray: DriveCurrentDifferenceHistogramData[]) {
    return dataArray.map((valueItem) => valueItem.k_diffs);
  }

  private getValueFrequencies(dataArray: DriveCurrentDifferenceHistogramData[]) {
    return dataArray.map((valueItem) => valueItem.counts);
  }
}
