
import { Component, Prop, Vue } from 'vue-property-decorator';
import HealthIndicator from '@/components/careConsole/HealthIndicator.vue';
import { DeviceElementHealthData, HealthChartsData } from '@/models/Charts/chartsData';
import { isEmpty } from '@/utils/misc';
import { HealthTimelineGenerator } from '@/models/Charts/healthTimelineGenerator';

@Component({
  methods: { isEmpty },
  components: {
    HealthIndicator,
  },
})
export default class HealthIndicatorsContainer extends Vue {
  @Prop({ required: true, default: [] })
  private data!: DeviceElementHealthData[];

  @Prop({ default: false })
  private loading!: boolean;

  private getKpis(dataItem: DeviceElementHealthData): Record<string, number | undefined> {
    const kpis: Record<string, number | undefined> = {
      max: dataItem.max,
      average: dataItem.average,
      median: dataItem.median,
      min: dataItem.min,
    };

    if (dataItem.filteredMax) {
      kpis['filteredMax'] = dataItem.filteredMax;
    }

    return kpis;
  }

  // FIXME: We should be able to inline this code into the template, but it
  // seems unit tests are using a different SFC parser. We should be able to
  // fix this by using the same package versions as in the DataPlatform project.
  private getValue(dataItem: DeviceElementHealthData) {
    // We show filteredMax on all indicators but the Drawer cycles one
    return dataItem?.filteredMax ?? dataItem?.max ?? 0;
  }

  private getLevelMaximums(dataItem: DeviceElementHealthData): number[] {
    return [dataItem.a, dataItem.b, dataItem.c, dataItem.d, dataItem.e];
  }

  private showDecimals(dataItem: DeviceElementHealthData): boolean {
    return ['temperatures', 'lensDrive'].includes(dataItem.deviceElement);
  }

  private getUnit(dataItem: DeviceElementHealthData): string {
    return HealthTimelineGenerator.getUnit(dataItem.deviceElement as keyof HealthChartsData);
  }
}
