
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Wizard, WizardStep } from '@/components/wizard';
import { Customer } from '@/models/tenant';
import { Device } from '@/models/device';
import { areArraysEqual } from '@/utils/array';
import { AlertKPI, AlertMetric, AlertOperator } from '@/models/enums/AlertsEnums';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { isEmpty } from '@/utils/misc';
import { AlertsDefinition } from '@/models/alertsDefinition';
import { alertsService } from '@/services/alerts.service';
import TenantsListSelector from '@/components/alerts/TenantsListSelector.vue';
import DevicesListSelector from '@/components/alerts/DevicesListSelector.vue';
import KpiSelectionForm from '@/components/alerts/KpiSelectionForm.vue';
import VariableSelectionForm from '@/components/alerts/VariableSelectionForm.vue';
import SourceSelectionForm from '@/components/alerts/SourceSelectionForm.vue';
import EventCodesSelectionForm from '@/components/alerts/EventCodesSelectionForm.vue';

interface FormData {
  customers: Customer[];
  devices: Device[];
  kpi: AlertKPI | null;
  operator: AlertOperator;
  threshold: number | null;
  dateGrouping: FilterTimeAxisSpanEnum;
  variable: string | null;
  sources: string[];
  eventCodes: string[];
}

@Component({
  components: {
    SourceSelectionForm,
    VariableSelectionForm,
    DevicesListSelector,
    TenantsListSelector,
    KpiSelectionForm,
    EventCodesSelectionForm,
    Wizard,
    WizardStep,
  },
  methods: {
    isEmpty,
  },
})
export default class CareAlertCreationWizard extends Vue {
  private formData: FormData = {
    customers: [],
    devices: [],
    kpi: null,
    operator: AlertOperator.None,
    threshold: null,
    dateGrouping: FilterTimeAxisSpanEnum.None,
    variable: null,
    sources: [],
    eventCodes: [],
  };

  private previouslySelectedTenantsIds: number[] = [];

  @Ref('main-form')
  private mainForm!: KpiSelectionForm;

  @Ref('devicesListSelector')
  private devicesListSelector: any;

  @Ref('sourceSelectionForm')
  private sourceSelectionForm!: SourceSelectionForm;

  private async submit() {
    const alertDefinition: AlertsDefinition = {
      deviceIds: this.formData.devices.map((device) => device.id),
      metric: this.formData.kpi!.kpi,
      operator: this.formData.operator,
      threshold: +this.formData.threshold!,
      dateGrouping: this.formData.dateGrouping,
      variable: this.formData.variable,
      eventSources: this.formData.sources,
      eventCodes: this.formData.eventCodes,
    };

    return await alertsService.createMultiple(alertDefinition).then(() => {
      this.$emit('save');
      this.$emit('close');
    });
  }

  private onDeviceSelectionEnter(fromPreviousPage: boolean) {
    if (!fromPreviousPage) {
      return;
    }
    if (this.hasTenantsSelectionChanged()) {
      this.formData.devices = [];
      this.devicesListSelector.refreshDevices();
    }
    this.previouslySelectedTenantsIds = this.selectedTenantIds;
  }

  private hasTenantsSelectionChanged(): boolean {
    return !areArraysEqual(this.previouslySelectedTenantsIds, this.selectedTenantIds);
  }

  private get isMainFormValid(): boolean {
    return (
      this.formData.kpi !== null &&
      this.formData.operator !== AlertOperator.None &&
      this.formData.threshold !== null &&
      this.formData.dateGrouping !== FilterTimeAxisSpanEnum.None &&
      this.mainForm.isValid()
    );
  }

  private async onSourceSelectionEnter() {
    this.formData.eventCodes = [];
    this.formData.variable = null;
    await this.sourceSelectionForm.refreshMessageSources();
  }

  private onVariableSelectionEnter() {
    this.formData.eventCodes = [];
    this.formData.sources = [];
  }

  private onEventCodeSelectionEnter() {
    this.formData.variable = null;
    this.formData.sources = [];
  }

  private isSourcesFormValid(): boolean {
    return this.sourceSelectionForm.isValid();
  }

  private get kpiRequiresSource(): boolean {
    if (this.formData.kpi === null) {
      return false;
    }
    return [AlertMetric.ErrorCountBySource, AlertMetric.WarningCountBySource].includes(
      this.formData.kpi!.kpi,
    );
  }

  private get kpiRequiresVariable(): boolean {
    return !isEmpty(this.formData.kpi?.variableValues);
  }

  private get kpiRequiresEventCode(): boolean {
    if (this.formData.kpi === null) {
      return false;
    }
    return [AlertMetric.ErrorCount, AlertMetric.WarningCount].includes(this.formData.kpi!.kpi);
  }

  private get selectedTenantIds(): number[] {
    return this.formData.customers.map((customer) => customer.id);
  }
}
