// --------------------------------------------------------------------------------
// <copyright file="poFinishedDueDateGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import i18n from '@/i18n';
import { getColor } from '@/utils/color';
import { abbreviateNumber, decimalFormatter } from '@/utils/number';
import { GeneratorParams } from './generatorParams';
import { ManufacturingPOFinishedDueDateData } from './chartsData';
import { EChartsOption, SeriesOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockPOFinishedDueDateData } from './mockWidgetSelectorData';
import { metricsService } from '@/services/metrics.service';

export const DEFAULT_DAYS_PER_INTERVAL = 5;

export class POFinishedDueDateGenerator extends ChartGenerator<
  ManufacturingPOFinishedDueDateData[]
> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];

    return metricsService.getSMBSMetricsSFC<ManufacturingPOFinishedDueDateData[]>(
      this.procedure,
      this.tenantIdDh,
      { paramNumber: params?.daysPerInterval ?? DEFAULT_DAYS_PER_INTERVAL, startDate, endDate },
      this.controller,
    );
  }

  override getMockData(): ManufacturingPOFinishedDueDateData[] | null {
    return mockPOFinishedDueDateData();
  }

  override updateOptions(
    data: ManufacturingPOFinishedDueDateData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        valueFormatter: decimalFormatter,
      },
      legend: {
        bottom: 0,
        selectedMode: false,
      },
      grid: {
        top: 28,
        bottom: 40,
        left: 15,
        right: 8,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisLabel: { rotate: 60 },
      },
      yAxis: {
        type: 'value',
        name: i18n.t('hours_abbreviation').toString(),
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f4f4f4',
            width: 1,
          },
        },
        axisLabel: {
          formatter: (value: number) => abbreviateNumber(value),
        },
      },
      series: this.series(data),
    };
  }

  private series(data: ManufacturingPOFinishedDueDateData[]): SeriesOption {
    return {
      name: i18n.t(`report.shop_floor_console.finished`).toString(),
      type: 'bar',
      data: this.getSeriesData(data),
      itemStyle: {
        color: getColor(3, 0),
      },
    };
  }

  private getSeriesData(data: ManufacturingPOFinishedDueDateData[]): Array<[string, number]> {
    return data.map((dataItem) => [
      this.generateRangeLabel(dataItem.range_start, dataItem.range_end),
      dataItem.estimated_time_hours,
    ]);
  }

  private generateRangeLabel(start: number, end: number) {
    return start === end ? start.toString() : `[${start}, ${end}]`;
  }
}
