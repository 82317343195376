
import { Component, Prop, Vue } from 'vue-property-decorator';
import HealthIndicator from '@/components/careConsole/HealthIndicator.vue';
import { isEmpty } from '@/utils/misc';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import HealthBar from '@/components/careConsole/HealthBar.vue';
import ModuleEventsIndicator from '@/components/careConsole/ModuleEventsIndicator.vue';
import { dateRangeToDateStrings } from '@/utils/dates';
import { defaultDateRange } from '@/views/careConsole/commons/utils';

@Component({
  methods: { isEmpty },
  components: {
    ModuleEventsIndicator,
    HealthBar,
    WidgetContainer,
    HealthIndicator,
  },
})
export default class ModuleEventsIndicators extends Vue {
  @Prop({ required: true, default: () => defaultDateRange() })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private deviceId!: string;

  private get widgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareOverviewModuleEventsIndicators,
      this.deviceId,
      this.dateRangeString,
    );
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }
}
