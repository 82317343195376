// --------------------------------------------------------------------------------
// <copyright file="compositeWidgetDefinition.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import WidgetDefinition, { AbstractWidgetDefinition, CopyParams } from './widgetDefinition';

export class CompositeWidgetEntry {
  constructor(
    public widgetDefinition: WidgetDefinition,
    public icon: string,
    public iconPack?: string,
  ) {}

  getCopy(params: CopyParams): CompositeWidgetEntry {
    return new CompositeWidgetEntry(
      this.widgetDefinition.getCopy(params),
      this.icon,
      this.iconPack,
    );
  }
}

export class CompositeWidgetDefinition implements AbstractWidgetDefinition {
  constructor(
    public left: CompositeWidgetEntry,
    public right: CompositeWidgetEntry,
    public index?: number,
  ) {}

  isComposite(): this is CompositeWidgetDefinition {
    return true;
  }

  clone(): CompositeWidgetDefinition {
    return new CompositeWidgetDefinition(this.left, this.right, this.index);
  }

  updateAndGetCopy(copyParams: CopyParams): CompositeWidgetDefinition {
    const clone = this.clone();
    clone.left = clone.left.getCopy(copyParams);
    clone.right = clone.right.getCopy(copyParams);
    return clone;
  }

  withIndex(index: number): CompositeWidgetDefinition {
    this.index = index;
    return this;
  }
}

export type WidgetEntry = WidgetDefinition | CompositeWidgetDefinition;
export type WidgetList = WidgetEntry[];
