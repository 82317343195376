
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import { addAutoRemovedEventListener } from '@/utils/misc';

@Component({})
export default class Sidebar extends Vue {
  private previousWidth?: number;

  @ProvideReactive('isSidebarExpanded')
  private expand = window.innerWidth >= 1367;

  toggle(): void {
    this.expand = !this.expand;
  }

  private created() {
    addAutoRemovedEventListener(this, 'resize', this.adjustExpandStatusOnWindowResize);
  }

  private adjustExpandStatusOnWindowResize() {
    const width = window.innerWidth;
    if (width !== this.previousWidth && width >= 1367 !== this.expand) {
      this.previousWidth = width;
      this.expand = width >= 1367;
    }
  }
}
