// --------------------------------------------------------------------------------
// <copyright file="tenantCutConfig.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export class TenantCutConfig {
  constructor(
    public id: number,
    public channel0Name: string,
    public channel1Name: string,
    public channel2Name: string,
    public channel3Name: string,
  ) {}
}
