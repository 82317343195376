// --------------------------------------------------------------------------------
// <copyright file="laserGanttChartTableGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import {
  GanttTableData,
  LASER_GANTT_STATES,
  LaserGanttState,
  LaserGanttTableData,
} from '../chartsData';
import { AbstractGanttChartTableGenerator } from './abstractGanttChartTableGenerator';
import { isEmpty } from '@/utils/misc';
import { metricsService } from '@/services/metrics.service';
import { ProcedureName } from '../abstract/chartGenerator';
import { devicesService } from '@/services/devices.service';
import { getStateColour } from '@/utils/color';
import i18n from '@/i18n';

export class LaserGanttChartTableGenerator extends AbstractGanttChartTableGenerator<LaserGanttState> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ): Promise<LaserGanttTableData[] | null> {
    const deviceIds: string[] = isEmpty(selectedDevices)
      ? devicesService.store.cutDevicesIds()
      : selectedDevices;
    return await metricsService.getDevicesMetrics<LaserGanttTableData[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds,
        shifts: selectedShifts,
      },
      this.controller,
    );
  }

  protected override getStateColor(state: string, index: number): string {
    return getStateColour(state);
  }

  protected override getStates(data: GanttTableData<LaserGanttState>[]): LaserGanttState[] {
    return LASER_GANTT_STATES;
  }

  protected override getLocalizedStateName(state: string): string {
    return i18n.t(`widget_info_text.gantt_chart_table.states.${state}`).toString();
  }
}
