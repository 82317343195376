
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MaterialGroup } from '@/models/materialGroup';
import { materialGroupsService } from '@/services/materialGroups.service';
import { Tenant } from '@/models/tenant';
import { isEmpty } from '@/utils/misc';

@Component
export default class MaterialGroupCreationModal extends Vue {
  @Prop({ required: true, default: MaterialGroup.GetEmpty() })
  private tenant!: Tenant;

  private materialGroup: MaterialGroup = MaterialGroup.GetEmpty();

  private isSubmitting = false;

  public handleChange(event: any): void {
    this.materialGroup = { ...this.materialGroup, [event.target.name]: event.target.value };
  }

  public get canSubmit() {
    return !isEmpty(this.materialGroup.name);
  }

  private onSubmit() {
    this.isSubmitting = true;
    this.materialGroup.tenantId = this.tenant.id;

    materialGroupsService
      .create(this.materialGroup)
      .then(() => {
        this.$emit('save');
        this.closeModal();
      })
      .finally(() => (this.isSubmitting = false));
  }

  private closeModal() {
    this.$emit('close');
  }
}
