
import { isVueComponent } from '@/utils/misc';
import { CreateElement, VNode } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  /* eslint-disable-next-line */
  render: function (this: SlotComponent, createElement: CreateElement): VNode {
    if (isVueComponent(this.component)) {
      return createElement(
        this.tag,
        {},
        this.scoped
          ? this.component.$scopedSlots[this.name]!(this.props)
          : this.component.$slots[this.name],
      );
    }
    return createElement();
  },
})
export default class SlotComponent extends Vue {
  @Prop()
  private component!: Vue;

  @Prop({ default: 'default' })
  private name!: string;

  @Prop({ default: false, type: Boolean })
  private scoped!: boolean;

  @Prop()
  private props?: Record<string, any>;

  @Prop({ default: 'div' })
  private tag!: string;

  @Prop({ default: 'hook:updated' })
  private event!: string;

  private refresh() {
    this.$forceUpdate();
  }

  private created() {
    if (isVueComponent(this.component)) {
      this.component.$on(this.event, this.refresh);
    }
  }

  private beforeDestroy() {
    if (isVueComponent(this.component)) {
      this.component.$off(this.event, this.refresh);
    }
  }
}
