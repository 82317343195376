// --------------------------------------------------------------------------------
// <copyright file="state.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import RootState from '@/store/rootState';

const state: RootState = {
  spinners: 0,
  termsAcceptedSession: false,
  userInfoLoaded: false,
  customers: [],
};

export default state;
