
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { AlertKPI } from '@/models/enums/AlertsEnums';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mastersService } from '@/services/masters.service';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class SourceSelectionForm extends Vue {
  @Prop({ required: true })
  private kpi!: AlertKPI;

  @Prop({ default: () => [] })
  private sources!: string[];

  private selectedSources: string[] = [];
  private messageSources: string[] = [];
  private availableMessageSources: string[] = [];
  private filteredMessageSources: string[] = [];

  private loading = false;

  @Ref('sourcesFormValidationObserver')
  private sourcesFormValidationObserver: any;

  private mounted() {
    this.selectedSources = this.sources;
  }

  private get validationRules(): any {
    return {
      sources: 'required',
    };
  }

  async refreshMessageSources() {
    this.loading = true;
    this.messageSources = await mastersService.getEventSources();
    this.filteredMessageSources = this.availableMessageSources = this.messageSources;
    this.loading = false;
  }

  private onSourceAdded(newSource: string) {
    this.availableMessageSources = this.filteredMessageSources =
      this.availableMessageSources.filter((source) => source !== newSource);
    this.$emit('input', { sources: this.selectedSources });
  }

  private onSourceRemoved(removedSource: string) {
    this.availableMessageSources = this.filteredMessageSources = this.messageSources.filter(
      (source) => !this.selectedSources.includes(source),
    );
    this.$emit('input', { sources: this.selectedSources });
  }

  isValid(): boolean {
    return this.sourcesFormValidationObserver.flags.valid;
  }

  private updateFilteredSources(filter: string): void {
    this.filteredMessageSources = this.availableMessageSources.filter((source) =>
      source.toLowerCase().includes(filter.toLowerCase()),
    );
  }
}
