// --------------------------------------------------------------------------------
// <copyright file="utils.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { isEmpty } from '@/utils/misc';
import { SubscribedUser } from './model';
import moment from 'moment';

const MAX_USER_NAME_LENGTH = 12;

export function formatUserNames(users: SubscribedUser[]): string {
  if (isEmpty(users)) {
    return '';
  }

  return truncateIfLong(users[0].name);
}

export function truncateIfLong(name: string): string {
  if (name.length <= MAX_USER_NAME_LENGTH) {
    return name;
  }

  return name.slice(0, MAX_USER_NAME_LENGTH) + '…';
}

export function defaultDateRange(): [Date, Date] {
  const oneWeekAgo = moment().subtract(1, 'week').toDate();
  return [oneWeekAgo, new Date()];
}
