
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/models/user';
import { tenantsService } from '@/services/tenants.service';
import { Tenant } from '@/models/tenant';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Subsidiary } from '@/models/subsidiary';
import { isEmpty, isNil } from '@/utils/misc';
import { Logger } from '@/utils/logger';
import { usersService } from '@/services/users.service';

@Component({})
export default class EmailsSelect extends Vue {
  @Prop({ default: false, type: Boolean })
  private required!: boolean;

  @Prop()
  private customerId?: number;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({})
  private defaultValues!: string[];

  /*
    One of either 'emails', 'tenants' or 'subsidiaries', models
    what kind of selector we need.
  */
  @Prop({ default: 'emails' })
  private selectionEntity!: 'emails' | 'tenants' | 'subsidiaries';

  @Prop({ default: false, type: Boolean })
  private selectAllInit!: boolean;

  @Prop({ default: true, type: Boolean })
  private showLabel!: boolean;

  @Prop({ default: () => () => true })
  private filterUsers!: (user: User, index?: number, array?: User[]) => boolean;

  @Prop({ default: false, type: Boolean })
  private loading!: boolean;

  private items: User[] | Tenant[] | Subsidiary[] = [];
  private data: string[] = [];
  private tags: string[] = [];
  private resultArray: string[] = [];
  private nameAndIds: Map<string, number> = new Map<string, number>();

  private itemsAreLoading = false;

  private async mounted() {
    this.data = [];
    this.tags = this.defaultValues ?? [];

    await this.loadData();

    if (this.selectAllInit) {
      this.selectAll();
    }
  }

  private async loadData() {
    this.itemsAreLoading = true;
    try {
      switch (this.selectionEntity) {
        case 'emails':
          await this.getEmails();
          break;
        case 'tenants':
          await this.getTenants();
          break;
        case 'subsidiaries':
          await this.getSubsidiaries();
          break;
        default:
          throw new Error(`Prop selectionEntity='${this.selectionEntity}' must be
						either 'emails', 'tenants' or 'subsidiaries'`);
      }
    } finally {
      this.itemsAreLoading = false;
    }
  }

  @Watch('defaultValues')
  initializeTag() {
    this.tags = this.defaultValues;
  }

  private selectAll() {
    this.tags = [...this.data];
    this.onInput(this.tags);
  }

  private clearAll() {
    this.tags = [];
    this.onInput(this.tags);
  }

  private getResultArray(text: any) {
    this.resultArray = this.data.filter((option) => {
      return (
        option.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0 &&
        this.tags.indexOf(option) === -1
      );
    });
  }

  private get user(): User {
    return usersService.store.current();
  }

  private onInput(names: string[]) {
    this.$emit(
      'input',
      names.map((name: string) => ({
        id: this.nameAndIds.get(name),
        name,
      })),
    );
  }

  private getLabel(): string | undefined {
    if (!this.showLabel) {
      return undefined;
    }

    return this.getLabelAndPlaceholder().label;
  }

  private getPlaceholder(): string {
    return this.getLabelAndPlaceholder().placeholder;
  }

  private async getEmails() {
    const customerId = this.user.customerId ?? this.customerId;
    if (isNil(customerId)) {
      Logger.error('Missing customer id from which to get its users');
      throw new Error('Missing customer id from which to get its users');
    }

    this.items = (await tenantsService.getUsers(customerId)).filter(this.filterUsers);
    this.items.forEach((item: User) => {
      this.data.push(item.email);
      this.nameAndIds.set(item.email, item.id);
    });
  }

  private async getTenants() {
    this.items = await tenantsService.get();
    this.items.forEach((tenant: Tenant) => {
      this.data.push(tenant.name);
      this.nameAndIds.set(tenant.name, tenant.id);
    });
  }

  private async getSubsidiaries() {
    this.items = await subsidiariesService.get();
    this.items.forEach((subsidiary) => {
      this.data.push(subsidiary.name);
      this.nameAndIds.set(subsidiary.name, subsidiary.id);
    });
  }

  private getLabelAndPlaceholder(): { label: string; placeholder: string } {
    switch (this.selectionEntity) {
      case 'emails':
        return {
          label: this.$i18n.t('inputs.alerts_email_select.label').toString(),
          placeholder: this.$i18n.t('inputs.alerts_email_select.placeholder').toString(),
        };
      case 'tenants':
        return {
          label: this.$i18n.t('tenant.plural').toString(),
          placeholder: this.$i18n.t('tenant.select').toString(),
        };
      case 'subsidiaries':
        return {
          label: this.$i18n.t('inputs.alerts_subsidiary_select.label').toString(),
          placeholder: this.$i18n.t('inputs.alerts_subsidiary_select.placeholder').toString(),
        };
    }
  }

  private get tagsIsEmpty() {
    return isEmpty(this.tags);
  }

  private get componentIsLoading(): boolean {
    return this.loading || this.itemsAreLoading;
  }
}
