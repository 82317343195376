
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import {
  CompositeWidgetDefinition,
  CompositeWidgetEntry,
  WidgetEntry,
} from '@/models/Charts/compositeWidgetDefinition';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import IconSwitch from '@/components/switch/IconSwitch.vue';
import WidgetInfo from '@/components/Charts/WidgetInfo.vue';
import SingleWidgetContainer from './SingleWidgetContainer.vue';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';

/**
 * Component to show a widget with mode switch if a composite widget is provided
 *  instead of a widget definition.
 *
 * Two properties (coming from Chart) are available to the default slot:
 *
 * - data: Contains the data retrieved by the DataRetriever defined for the
 *   widget. Beware that it will be null until the data is retrieved. This is
 *   to allow the slot to handle the "no data" case (tables, for example).
 * - loading: true while the data is being retrieved.
 *
 * Usage:
 * <widget-container
 *   :widgetDefinition="widgetDefinition"
 *   v-slot="{ data, loading }"
 * >
 *     <div v-if="!isEmpty(data)" class="default-slot">
 *       {{ data.foo }}
 *     </div>
 * </widget-container>
 */
@Component({
  components: {
    SingleWidgetContainer,
    IconSwitch,
    WidgetInfo,
  },
})
export default class WidgetContainer extends Vue {
  @Prop({ required: true })
  private widgetDefinition!: WidgetEntry;

  @Prop({ default: false, type: Boolean })
  private selfManagedFilters!: boolean;

  @Prop({ default: 'is-right' })
  private threeDotsInnerPosition!: string;

  @Prop({ default: 'is-bottom-right' })
  private widgetInfoPosition!: string;

  @Prop({ default: true, type: Boolean })
  private autorefresh!: boolean;

  @Prop({ default: false, type: Boolean })
  private isWidgetSelector!: boolean;

  @Prop()
  private customerNo?: string;

  @Inject({ default: undefined })
  private $grid?: LayoutGrid;

  private showFirstWidget = true;

  private created() {
    this.$grid?.refreshWidgets();
  }

  private beforeDestroy() {
    this.$grid?.refreshWidgets();
  }

  private onWidgetDefinitionChanged(widgetDefinition: WidgetDefinition) {
    if (this.hasManyDefinitions()) {
      const wd = this.widgetDefinition as CompositeWidgetDefinition;
      const leftWasUpdated = wd.left.widgetDefinition.widget === widgetDefinition.widget;
      const updatedCompositeWidgetDefinition = wd.clone();
      if (leftWasUpdated) {
        updatedCompositeWidgetDefinition.left.widgetDefinition = widgetDefinition;
      } else {
        updatedCompositeWidgetDefinition.right.widgetDefinition = widgetDefinition;
      }
      this.$emit('change', updatedCompositeWidgetDefinition);
    } else {
      this.$emit('change', widgetDefinition);
    }
  }

  private onSwitchImage(isRightOptionSelected: boolean) {
    // Value will be true when switch is to the right
    this.showFirstWidget = !isRightOptionSelected;
    this.resetPreviousWidgetParamValues(isRightOptionSelected);
  }

  private resetPreviousWidgetParamValues(isRightOptionSelected: boolean) {
    if (isRightOptionSelected) {
      (this.widgetDefinition as CompositeWidgetDefinition).left.widgetDefinition.paramValues = {};
    } else {
      (this.widgetDefinition as CompositeWidgetDefinition).right.widgetDefinition.paramValues = {};
    }
  }

  private hasManyDefinitions(): boolean {
    return this.widgetDefinition.isComposite();
  }

  private get widgetEntry(): CompositeWidgetEntry | undefined {
    if (this.hasManyDefinitions()) {
      const wd = this.widgetDefinition as CompositeWidgetDefinition;
      return this.showFirstWidget ? wd.left : wd.right;
    } else {
      return undefined;
    }
  }

  private get resolvedWidgetDefinition(): WidgetDefinition {
    if (this.hasManyDefinitions()) {
      return this.widgetEntry!.widgetDefinition;
    } else {
      return this.widgetDefinition as WidgetDefinition;
    }
  }

  private get leftIcon(): string | undefined {
    const wd = this.widgetDefinition as CompositeWidgetDefinition;
    return wd.left.icon;
  }

  private get leftIconPack(): string | undefined {
    const wd = this.widgetDefinition as CompositeWidgetDefinition;
    return wd.left.iconPack;
  }

  private get rightIcon(): string | undefined {
    const wd = this.widgetDefinition as CompositeWidgetDefinition;
    return wd.right.icon;
  }

  private get rightIconPack(): string | undefined {
    const wd = this.widgetDefinition as CompositeWidgetDefinition;
    return wd.right.iconPack;
  }

  private get listeners(): any {
    // We need to filter out 'change' event from this component before passing
    // it to Chart to avoid emitting two 'change' events. One from this
    // component plus the one defined in Chart.
    return Object.fromEntries(
      Object.entries(this.$listeners).filter(([eventName, _]) => eventName !== 'change'),
    );
  }
}
