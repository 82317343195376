
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { isNil, uniqueId } from '@/utils/misc';
import { DateTimeFormatOptions } from 'vue-i18n';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import moment from 'moment';
import i18n from '@/i18n';
import { DateTimeGranularity } from '@/components/common/dateTimeRangePicker/DateTimeGranularity';

const MINUTES_FIELD_SELECTOR = '.timepicker .control:nth-child(3) select';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class DateTimeRangePicker extends Vue {
  @Prop({ default: null })
  private value!: [Date | null, Date | null] | null;

  @Prop({ default: DateTimeGranularity.SECONDS })
  private granularity!: DateTimeGranularity;

  private startDateTime: Date | null = null;
  private endDateTime: Date | null = null;

  private canCloseRangeDropdown = true;

  @Ref('validationObserver')
  private validationObserver: any;

  @Ref('startDateTimeValidationProvider')
  private startDateTimeValidationProvider: any;

  @Ref('endDateTimeValidationProvider')
  private endDateTimeValidationProvider: any;

  @Ref('startDateTimePicker')
  private startDateTimePicker: any;

  @Ref('endDateTimePicker')
  private endDateTimePicker: any;

  private componentId = uniqueId();

  private get validationRules() {
    return {
      startDateTime: {
        required: true,
        start_before_end: '@endDateTime',
      },
      endDateTime: {
        required: true,
        end_after_start: '@startDateTime',
      },
    };
  }

  @Ref('dropdown')
  private dropdown: any;

  // immediate so we don't have to update from mounted
  @Watch('value', { immediate: true })
  private updateStartAndEndFields() {
    this.startDateTime = this.value?.[0] ?? null;
    this.endDateTime = this.value?.[1] ?? null;
  }

  // immediate so we don't have to update from mounted
  @Watch('granularity', { immediate: true })
  enableDisableMinutesFields() {
    this.$nextTick(() => {
      this.updateMinutesFieldDisabledStatus(this.startDateTimePicker);
      this.updateMinutesFieldDisabledStatus(this.endDateTimePicker);
    });
  }

  private updateMinutesFieldDisabledStatus(dateTimePickerComponent: any) {
    const selectElement = dateTimePickerComponent.$el.querySelector(MINUTES_FIELD_SELECTOR);

    if (this.hasMinutes) {
      selectElement.removeAttribute('disabled');
    } else {
      selectElement.setAttribute('disabled', 'disabled');
    }
  }

  private get formattedValue(): string | null {
    if (isNil(this.value)) {
      return null;
    }

    return this.value
      .map((date) =>
        isNil(date) ? i18n.t('date_time_range_picker.unset') : this.formatDateTime(date!),
      )
      .join(' - ');
  }

  private formatDateTime(date: Date) {
    const formatOptions: DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',

      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Intl.DateTimeFormat(undefined, formatOptions).format(date);
  }

  private onAcceptClicked() {
    this.$emit('input', [this.startDateTime, this.endDateTime]);
    this.dropdown.toggle();
  }

  private onDropdownOpenStatusChanged(isOpen: boolean) {
    if (!isOpen) {
      // Reset the fields back to their initial values. Otherwise, if they've
      // been changed, they'll preserve the same values when the dropdown is
      // opened again.
      this.updateStartAndEndFields();
      this.validationObserver.reset();
    }
  }

  private onDateTimePickerDropdownOpenStatusChanged(isOpen: boolean) {
    // To avoid the range dropdown being closed when clicking outside a
    // date-time picker modal, we disable closing it while a selector dropdown
    // is open.
    this.canCloseRangeDropdown = !isOpen;
  }

  /**
   * Resets the fields validation so only one field is marked as invalid.
   *
   * We need to do this because, in the way the rules are defined, when a field
   * validation fails, the other also does. So, to avoid the two fields being
   * marked as invalid, when we modify one, we reset the validation.
   *
   * @private
   */
  private resetValidation() {
    // The $nextTick is needed, don't bother looking for it in the documentation ¬¬
    this.$nextTick(() => this.validationObserver.reset());
  }

  get granularityCSSClasses(): Record<string, boolean> {
    return {
      'has-time': this.hasTime,
      'has-calendar': this.hasCalendar,
    };
  }

  get hasCalendar(): boolean {
    return [
      DateTimeGranularity.HOURS,
      DateTimeGranularity.SECONDS,
      DateTimeGranularity.DAYS,
      DateTimeGranularity.MONTHS,
    ].includes(this.granularity);
  }

  get hasTime(): boolean {
    return [DateTimeGranularity.HOURS, DateTimeGranularity.SECONDS].includes(this.granularity);
  }

  get hasMinutes(): boolean {
    return this.hasSeconds;
  }

  get hasSeconds(): boolean {
    return this.granularity === DateTimeGranularity.SECONDS;
  }
}

extend('start_before_end', {
  params: ['endDateTime'],
  validate(value: Date, { endDateTime }: Record<string, any>) {
    return isNil(endDateTime) || moment(value).isBefore(endDateTime);
  },
  message: i18n.t('date_time_range_picker.start_before_end_validation_error').toString(),
});

extend('end_after_start', {
  params: ['startDateTime'],
  validate(value: Date, { startDateTime }: Record<string, any>) {
    return isNil(startDateTime) || moment(value).isAfter(startDateTime);
  },
  message: i18n.t('date_time_range_picker.end_after_start_validation_error').toString(),
});
