
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ModuleEventsIndicatorData } from '@/models/Charts/chartsData';
import ProgressBar from '@/components/careConsole/ProgressBar.vue';
import { EventColor } from '@/utils/color';
import { formatPercentage } from '@/utils/number';

@Component({
  computed: {
    EventColor: () => EventColor,
  },
  components: { ProgressBar },
  methods: { formatPercentage },
})
export default class ModuleEventsIndicator extends Vue {
  @Prop()
  private data!: ModuleEventsIndicatorData;

  private get errorsPercentage(): number {
    return this.percentage(this.data.errors, this.data.totalErrors);
  }

  private get warningsPercentage(): number {
    return this.percentage(this.data.warnings, this.data.totalWarnings);
  }

  private percentage(value: number, total: number): number {
    if (total === 0) {
      return 0;
    }

    return (value / total) * 100;
  }
}
