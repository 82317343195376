
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserType } from '@/models/userType';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import {
  openCustomerUserWizard,
  openGlobalAdminWizard,
  openServiceGlobalUserWizard,
  openServiceUserWizard,
  openSubsidiaryAdminWizard,
  openTechnologyUserWizard,
} from './modals';
import { values } from '@/utils/enum';

@Component({
  computed: {
    UserType: () => UserType,
  },
})
export default class UserCreationDropdown extends Vue {
  @Prop({ default: () => values(UserType) })
  private allowedTypes!: UserType[];

  @Prop()
  private subsidiaryId?: number;

  private isTypeEnabled(type: UserType): boolean {
    return this.allowedTypes.includes(type);
  }

  private openGlobalAdminWizard() {
    openGlobalAdminWizard(this, null, this.onWizardClosed);
  }

  private async openSubsidiaryAdminWizard() {
    await openSubsidiaryAdminWizard(this, null, this.subsidiaryId, this.onWizardClosed);
  }

  private async openCustomerWizard() {
    await openCustomerUserWizard(this, null, this.onWizardClosed);
  }

  private openServiceGlobalWizard() {
    openServiceGlobalUserWizard(this, null, this.onWizardClosed);
  }

  private async openServiceWizard() {
    await openServiceUserWizard(this, null, this.subsidiaryId, this.onWizardClosed);
  }

  private openTechnologyWizard() {
    openTechnologyUserWizard(this, null, this.onWizardClosed);
  }

  private onWizardClosed() {
    return this.$emit('close');
  }

  private get canCreateGlobalAdmin(): boolean {
    return hasCurrentUserPermission(UserType.GlobalAdmin);
  }

  private get canCreateSubsidiaryAdmin(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get canCreateCustomer(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get canCreateEService(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get canCreateTechnology(): boolean {
    return hasCurrentUserPermission(UserType.GlobalAdmin);
  }
}
