// --------------------------------------------------------------------------------
// <copyright file="factoryOverviewGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { Device } from '../device';
import store from '@/store';
import { PersistentGetters } from '@/store/persistent/enums';
import { Color, getStateColour } from '@/utils/color';
import moment from 'moment';
import { FactoryOverviewData } from './chartsData';
import { DefaultLabelFormatterCallbackParams, EChartsOption } from 'echarts';
import { mockFactoryOverviewData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export class FactoryOverviewGenerator extends ChartGenerator<FactoryOverviewData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
  ): Promise<FactoryOverviewData[]> {
    return metricsService.getDevicesMetrics<FactoryOverviewData[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        shifts: selectedShifts,
        deviceIds: [],
      },
      this.controller,
    );
  }

  override updateOptions(
    data: FactoryOverviewData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const clientWidth = 'clientWidth' in parameters ? parameters.clientWidth ?? 300 : 300;
    const clientHeight = 'clientHeight' in parameters ? parameters.clientHeight ?? 300 : 300;
    const devices: Device[] = store.getters[PersistentGetters.GetDevices];
    const deviceNames: Map<string, string> = new Map<string, string>();
    const activeDevs = devices.map((d) => d.deviceId);
    const filteredActiveDevsInData = data.filter((dev) => activeDevs.includes(dev.deviceid));
    for (const dev of devices) {
      deviceNames.set(dev.deviceId, dev.name);
    }
    return {
      tooltip: {
        confine: true,
        formatter: (params: any) => ('state' in params.data ? params.data.state ?? '' : ''),
        extraCssText: 'z-index: 1',
      },
      series: {
        type: 'sunburst',
        data: this.getSeriesData(filteredActiveDevsInData, deviceNames),
        radius: [Math.min(90, clientHeight / 20, clientWidth / 20), '90%'],
        itemStyle: {
          borderRadius: 0,
          borderWidth: 2,
        },
        levels: [
          {},
          {
            label: {
              formatter: (params: DefaultLabelFormatterCallbackParams) => params.name,
              rotate: 0,
            },
            itemStyle: {
              color: '#C0C0C0',
            },
          },
          {
            label: {
              formatter: (params: any) => {
                const timeMsg: string = moment(`${params.data.lastTs}+00:00`).format('HH:mm:ss');
                const deviceName = deviceNames.get(params.name) as string;
                if (deviceName.length <= 10) {
                  return deviceName + '\n' + timeMsg;
                } else {
                  return (
                    deviceName.substring(0, 4) +
                    '...' +
                    deviceName.substring(deviceName.length - 3) +
                    '\n' +
                    timeMsg
                  );
                }
              },
              rotate: 'tangential',
            },
          },
          {
            label: {
              formatter: (params: DefaultLabelFormatterCallbackParams) => params.name,
              rotate: 'tangential',
            },
            itemStyle: {
              color: '#C0C0C0',
            },
          },
          {
            label: {
              formatter: (params: any) =>
                i18n.n(params.value * 100, { maximumFractionDigits: 2 }) + '%',
              rotate: 'tangential',
            },
          },
        ],
      },
    };
  }

  override getMockData(): FactoryOverviewData[] | null {
    return mockFactoryOverviewData();
  }

  private getSeriesData(data: FactoryOverviewData[], deviceNames: Map<string, string>) {
    data.sort((a, b) => {
      const nameA: string = deviceNames.get(a.deviceid) ?? '';
      const nameB: string = deviceNames.get(b.deviceid) ?? '';

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
    return [
      {
        name: i18n.t('report.laser_label').toString(),
        children: data
          .filter((dataItem) => dataItem.deviceid.startsWith('L-'))
          .map(
            (dataItem) =>
              new Object({
                name: dataItem.deviceid,
                children: [
                  {
                    name: i18n.t('report.productivity_abbr').toString(),
                    value: 1,
                    state: i18n.t('report.productivity').toString(),
                    children: [
                      {
                        value: dataItem.productivity,
                        itemStyle: { color: this.getValueColour(dataItem.productivity) },
                      },
                    ],
                  },
                  {
                    name: i18n.t('report.availability_abbr').toString(),
                    value: 1,
                    state: i18n.t('report.availability').toString(),
                    children: [
                      {
                        value: dataItem.availability,
                        itemStyle: { color: this.getValueColour(dataItem.availability) },
                      },
                    ],
                  },
                ],
                itemStyle: {
                  color: getStateColour(dataItem.state),
                },
                lastTs: dataItem.timestamp_utc,
                state: i18n.t('report.' + dataItem.state),
              }),
          ),
      },
      {
        name: i18n.t('report.bending_label').toString(),
        children: data
          .filter((dataItem) => dataItem.deviceid.startsWith('B-'))
          .map(
            (dataItem) =>
              new Object({
                name: dataItem.deviceid,
                children: [
                  {
                    name: i18n.t('report.productivity_abbr').toString(),
                    value: 1,
                    state: i18n.t('report.productivity').toString(),
                    children: [
                      {
                        value: dataItem.productivity,
                        itemStyle: { color: this.getValueColour(dataItem.productivity) },
                      },
                    ],
                  },
                  {
                    name: i18n.t('report.availability_abbr').toString(),
                    value: 1,
                    state: i18n.t('report.availability').toString(),
                    children: [
                      {
                        value: dataItem.availability,
                        itemStyle: { color: this.getValueColour(dataItem.availability) },
                      },
                    ],
                  },
                ],
                itemStyle: {
                  color: getStateColour(dataItem.state),
                },
                lastTs: dataItem.timestamp_utc,
                state: i18n.t('report.' + dataItem.state),
              }),
          ),
      },
      {
        name: i18n.t('report.tubes_label').toString(),
        children: data
          .filter((dataItem) => dataItem.deviceid.startsWith('T-'))
          .map(
            (dataItem) =>
              new Object({
                name: dataItem.deviceid,
                children: [
                  {
                    name: i18n.t('report.productivity_abbr').toString(),
                    value: 1,
                    state: i18n.t('report.productivity').toString(),
                    children: [
                      {
                        value: dataItem.productivity,
                        itemStyle: { color: this.getValueColour(dataItem.productivity) },
                      },
                    ],
                  },
                  {
                    name: i18n.t('report.availability_abbr').toString(),
                    value: 1,
                    state: i18n.t('report.availability').toString(),
                    children: [
                      {
                        value: dataItem.availability,
                        itemStyle: { color: this.getValueColour(dataItem.availability) },
                      },
                    ],
                  },
                ],
                itemStyle: {
                  color: getStateColour(dataItem.state),
                },
                lastTs: dataItem.timestamp_utc,
                state: i18n.t('report.' + dataItem.state),
              }),
          ),
      },
    ];
  }

  private getValueColour(value: number) {
    return value < 0.66 ? (value > 0.33 ? Color.Orange : Color.Red) : Color.Green;
  }
}
