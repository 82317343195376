// --------------------------------------------------------------------------------
// <copyright file="driveCurrentTimelineGeneratorTooltipFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { DriveCurrentTimelineData } from './chartsData';
import { getTooltipTitle } from '@/utils/charts';
import { GeneratorParams } from './generatorParams';
import {
  getChartTitleTooltipHtml,
  getHeadVersionChangeDataHtml,
  isHeadVersionChangeTooltipParamItem,
} from './cuttingHeadCommons';

export function tooltipFormatter(
  params: any,
  parameters: GeneratorParams,
  isCategoryAxis: boolean,
  data: DriveCurrentTimelineData[],
): string {
  let driveCurrentChartHtml = '';
  let tempCapacitiveChartHtml = '';
  let cuttingHeadVersionChangeHtml = '';
  let diffDriveCurrentChartHtml = '';

  const dateGrouping = parameters.timeAxisSpan!;

  params.forEach((paramItem: any) => {
    if (isDriveCurrentTooltipParamItem(paramItem)) {
      driveCurrentChartHtml += getNumericValueTooltipHtml(paramItem);
    } else if (isTemperatureCapacitiveTooltipParamItem(paramItem)) {
      tempCapacitiveChartHtml += getNumericValueTooltipHtml(paramItem);
    } else if (isHeadVersionChangeTooltipParamItem(paramItem)) {
      cuttingHeadVersionChangeHtml += getHeadVersionChangeDataHtml(paramItem, data, dateGrouping);
    } else if (isDriveCurrentDifferenceTooltipParamItem(paramItem)) {
      diffDriveCurrentChartHtml += getNumericValueTooltipHtml(paramItem);
    }
  });

  const title =
    driveCurrentChartHtml + tempCapacitiveChartHtml + diffDriveCurrentChartHtml === ''
      ? ''
      : getChartTitleTooltipHtml('drive_current');

  return `
    <div data-testid="time-title" style="font-size: 15px">
      ${getTooltipTitle(params[0].axisValue, dateGrouping, isCategoryAxis)}
    </div>

		${title}
    ${driveCurrentChartHtml}
    ${tempCapacitiveChartHtml}
    ${diffDriveCurrentChartHtml}
    ${cuttingHeadVersionChangeHtml}
  `;
}

function isDriveCurrentTooltipParamItem(paramItem: any) {
  return paramItem.seriesName === 'drive_current';
}

function isTemperatureCapacitiveTooltipParamItem(paramItem: any) {
  return paramItem.seriesName === 'capacitive_temperature';
}

function isDriveCurrentDifferenceTooltipParamItem(paramItem: any) {
  return paramItem.seriesName === 'current_difference';
}

function getNumericValueTooltipHtml(seriesParam: any): string {
  const value: number = seriesParam.value[1]; // ['ISO date', value]
  const unit = seriesParam.seriesName === 'capacitive_temperature' ? 'ºC' : 'mA';
  const formattedValue = `${i18n.n(value, { maximumFractionDigits: 2 })} ${unit}`;
  return getValueTooltipHtml(seriesParam, formattedValue);
}

function getValueTooltipHtml(seriesParam: any, value: string): string {
  return `
  <div
    data-testid="series-value-${seriesParam.axisIndex}-${seriesParam.seriesName}"
    style="display: flex; flex-direction: row"
  >
    <div style="flex-grow: 1">
      ${seriesParam.marker}
      ${i18n.t(`report.${seriesParam.seriesName}`)}
    </div>
    <div style="font-weight: bold; padding-left: 3rem">${value}</div>
  </div>
  `;
}
