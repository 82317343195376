
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import DeviceImage from '@/components/devices/DeviceImage.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Device } from '@/models/device';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { DeviceVersion } from '@/services/metrics.service';
import { isEmpty, isNil } from '@/utils/misc';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    WidgetContainer,
    DeviceImage,
  },
  methods: {
    isEmpty,
  },
})
export default class DeviceCard extends Vue {
  @Prop({ required: true })
  private device!: Device;

  private get deviceOverviewWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareOverviewDevice,
      this.device.deviceId,
      undefined,
      [],
    ).hideInfo();
  }

  private getValue(data: DeviceVersion | null, property: keyof DeviceVersion): string | number {
    if (isEmpty(data)) {
      return this.$t('no_data').toString();
    }
    if (isNil(data![property])) {
      return '-';
    }
    return data![property]!;
  }
}
