
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Routes } from '@/router/routes';
import { User } from '@/models/user';
import LanguageSelector from './language/LanguageSelector.vue';
import { getLocaleFullName } from '@/locale/dictLocale';
import {
  canAccessBusinessConsole,
  canAccessEServiceCenter,
  canAccessShopFloorConsole,
  canAccessTechnologyConsole,
  canAccessWorkCenterConsole,
} from '@/utils/permissionUtils';

import { Sidebar as BysoftSidebar, SidebarEntry } from '@/components/common/sidebar';
import { usersService } from '@/services/users.service';
import { devicesService } from '@/services/devices.service';
import { isEmpty } from '@/utils/misc';
import { BetaFeature } from '@/models/enums/BetaFeature';
import { modalService } from '@/library-services/modal.service';
import { currentLocale } from '@/utils/i18n';

/**
 * Provides the application menu to access the different pages and the language
 * selector.
 */
@Component({
  components: {
    BysoftSidebar,
    SidebarEntry,
  },
  methods: {
    canAccessBusinessConsole,
    canAccessShopFloorConsole,
    canAccessTechnologyConsole,
    canAccessEServiceCenter,
  },
  computed: {
    Routes: () => Routes,
  },
})
export default class Sidebar extends Vue {
  @Ref('sidebar')
  private sidebar!: BysoftSidebar;

  private isRouteActive(path: string): boolean {
    if (path === '/') {
      // all routes include '/', so use === in this case
      return this.$route.path === path;
    }

    return this.$route.path.startsWith(path.replace('overview', ''));
  }

  private routeTo(route: Routes) {
    if (this.$route.name !== route) {
      this.$router.push({ name: route });
    }
  }

  private routeToWithParams(route: Routes) {
    if (this.$route.name !== route) {
      this.$router.push({
        name: route,
        params: {
          selectedDevicesInput: JSON.stringify([]), // Router props have to be strings
        },
      });
    }
  }

  private openSelectLanguage() {
    modalService.open({
      parent: this,
      component: LanguageSelector,
      customClass: 'language-selector-modal',
    });
  }

  toggle() {
    this.sidebar.toggle();
  }

  private get showCut(): boolean {
    return this.user.hasCustomer && !isEmpty(devicesService.store.cutDevices());
  }

  private get showBend(): boolean {
    return this.user.hasCustomer && !isEmpty(devicesService.store.bendDevices());
  }

  private get showTube(): boolean {
    return this.user.hasCustomer && !isEmpty(devicesService.store.tubeDevices());
  }

  private get showWorkCenterConsole(): boolean {
    return canAccessWorkCenterConsole() && (this.showCut || this.showBend || this.showTube);
  }

  private canAccessDashboard(): boolean {
    return !(this.user.isTechnologyUser || this.user.isServiceUserType);
  }

  private get user(): User {
    return usersService.store.current();
  }

  private get fullLocaleName() {
    return getLocaleFullName(currentLocale());
  }

  private get hasWorkCenterConsoleCutIntradayStatesBetaFeature() {
    return this.user.hasBetaFeature(BetaFeature.WorkCenterConsoleCutIntradayStates);
  }
}
