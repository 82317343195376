import { render, staticRenderFns } from "./CareConsoleMainView.vue?vue&type=template&id=a8b0aff6&scoped=true"
import script from "./CareConsoleMainView.vue?vue&type=script&lang=ts"
export * from "./CareConsoleMainView.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b0aff6",
  null
  
)

export default component.exports