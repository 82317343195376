
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import {
  CompositeWidgetDefinition,
  CompositeWidgetEntry,
} from '@/models/Charts/compositeWidgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { dateRangeToDateStrings, yearToDate } from '@/utils/dates';
import { translateFilterTimeAxisSpanEnum } from '@/utils/i18n';
import LayoutGrid from '../dashboard/LayoutGrid.vue';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import { indexedWidgetDefinitions } from '@/utils/misc';

@Component({
  components: {
    WidgetContainer,
    DateGroupingFilter,
    LayoutGrid,
  },
})
export default class Quotes extends Vue {
  private widgetDefinitions: Array<WidgetDefinition | CompositeWidgetDefinition> = [];

  private selectedTimeAxisSpan = FilterTimeAxisSpanEnum.Month;
  private dateRangeSelection = yearToDate();
  private gridLayout = availableDashboardLayouts[DashboardLayout.TwoByThree];

  @Ref('bDatepicker')
  private bDatepicker: any;

  private created() {
    this.widgetDefinitions = this.createWidgetDefinitions();
  }

  @Watch('dateRangeString')
  @Watch('selectedTimeAxisSpan')
  private updateFilterOnWidgetDefinitions() {
    this.widgetDefinitions = this.widgetDefinitions.map((item) =>
      item.isComposite()
        ? item.updateAndGetCopy({
            timeFilter: this.dateRangeString,
            axisSpan: this.selectedTimeAxisSpan,
          })
        : item.getCopy({
            timeFilter: this.dateRangeString,
            axisSpan: this.selectedTimeAxisSpan,
          }),
    );
  }

  private updateWidgetDefinition(index: number, widgetDefinition: WidgetDefinition) {
    this.$set(this.widgetDefinitions, index, widgetDefinition);
  }

  private createWidgetDefinitions() {
    const topQuotedMaterialsTimelineWidgetDefinition = new WidgetDefinition(
      WidgetEnum.QuotesTopQuotedMaterialsTimeline,
      [],
      this.dateRangeString,
      [],
      undefined,
      this.selectedTimeAxisSpan,
    );

    const quotedMaterialsListWidgetDefinition = new WidgetDefinition(
      WidgetEnum.QuotesQuotedMaterialsList,
      [],
      this.dateRangeString,
      [],
      undefined,
      this.selectedTimeAxisSpan,
    );

    return indexedWidgetDefinitions([
      new WidgetDefinition(
        WidgetEnum.QuotesConvertedAmountTimeline,
        [],
        this.dateRangeString,
        [],
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        WidgetEnum.QuotesAbcCustomers,
        [],
        this.dateRangeString,
        [],
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        WidgetEnum.QuotesConversionRates,
        [],
        this.dateRangeString,
        [],
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        WidgetEnum.QuotesSize,
        [],
        this.dateRangeString,
        [],
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        WidgetEnum.QuotesFigures,
        [],
        this.dateRangeString,
        [],
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new CompositeWidgetDefinition(
        new CompositeWidgetEntry(topQuotedMaterialsTimelineWidgetDefinition, 'calendar'),
        new CompositeWidgetEntry(quotedMaterialsListWidgetDefinition, 'format-list-bulleted-type'),
      ),
    ]);
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRangeSelection);
  }

  private toggleDatepicker() {
    this.bDatepicker.toggle();
  }

  private get selectedAxisTimespanTranslated() {
    return translateFilterTimeAxisSpanEnum(this.selectedTimeAxisSpan);
  }
}
