
import { Component, Prop, Vue } from 'vue-property-decorator';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import { DashboardLayout } from '@/models/Charts/dashboardDefinition';

@Component({
  components: {
    ClickMenu,
  },
})
export default class LayoutDropdown extends Vue {
  @Prop({ required: true })
  private value!: DashboardLayout;

  private get names(): string[] {
    return this.ids.map(() => '');
  }

  private get ids(): number[] {
    return Object.keys(DashboardLayout)
      .filter((x) => !isNaN(Number(x)))
      .map((x) => Number(x))
      .slice(0, this.itemIcons.length);
    // The slice hides the two new layouts, which don't have an icon
  }

  private get itemIcons(): string[] {
    return [
      '1x1',
      '1x2',
      '2x2',
      '2x3',
      'bc-overview-like',
      'inverse-bc-overview-like',
      '2x1',
      '2x3-merge-top-right',
      '2x3-merge-top-left',
      '2x3-merge-bottom-right',
      '2x3-merge-right',
      '2x3-merge-left',
      '2x3-two-double-two-single',
    ];
  }
}
