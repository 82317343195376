
import { Subsidiary } from '@/models/subsidiary';
import { Routes } from '@/router/routes';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Component, Vue } from 'vue-property-decorator';
import SubsidiaryProperties from './SubsidiaryProperties.vue';
import SubsidiaryUsers from './SubsidiaryUsers.vue';

@Component({
  components: {
    'sc-subsidiary-properties': SubsidiaryProperties,
    'sc-subsidiary-users': SubsidiaryUsers,
  },
})
export default class SubsidiaryDetail extends Vue {
  private id = 0;
  private subsidiary = Subsidiary.GetEmpty();

  private async created(): Promise<void> {
    if (!this.$route.params || !this.$route.params.id) {
      await this.$router.push({ name: Routes.Subsidiaries });
    } else {
      this.id = +this.$route.params.id;
      await this.getSubsidiaryById(this.id);
    }
  }

  private async getSubsidiaryById(id: number) {
    this.$spinner.showSpinner();
    this.subsidiary = await subsidiariesService
      .getById(id)
      .finally(() => this.$spinner.removeSpinner());
  }
}
