
import { Tenant } from '@/models/tenant';
import { Routes } from '@/router/routes';
import { tenantsService } from '@/services/tenants.service';
import { Component, Vue } from 'vue-property-decorator';
import CustomerProperties from './CustomerProperties.vue';
import CustomerUsers from './CustomerUsers.vue';
import Devices from '@/views/Devices.vue';
import ShiftModels from '@/views/customers/ShiftModels.vue';
import { User } from '@/models/user';
import { UserType } from '@/models/userType';
import BendPartCategoryConfig from '@/views/customers/BendPartCategoryConfig.vue';
import CutGasConfig from '@/views/customers/CutGasTypeConfig.vue';
import CustomerAlerts from './CustomerAlerts.vue';
import MaterialGroups from '@/views/customers/MaterialGroups.vue';
import CustomerTarget from './CustomerTarget.vue';
import CustomerLocations from './CustomerLocations.vue';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import CustomerEmailReport from './CustomerEmailReport.vue';
import { usersService } from '@/services/users.service';
import { Logger } from '@/utils/logger';
import i18n from '@/i18n';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { toastService } from '@/library-services/toast.service';

@Component({
  computed: {
    Tab: () => Tab,
  },
  components: {
    CustomerProperties,
    CustomerUsers,
    'customer-devices': Devices,
    ShiftModels,
    BendPartCategoryConfig,
    CutGasConfig,
    MaterialGroups,
    CustomerAlerts,
    CustomerTarget,
    CustomerLocations,
    CustomerEmailReport,
  },
})
export default class CustomerDetail extends Vue {
  private tenant = Tenant.GetEmpty();
  private selectedTab = Tab.Properties;

  private async created() {
    await this.redirectToTenantsPageIfRouteParameterIsMissing('id');

    this.tenant = await this.fetchTenant(+this.$route.params.id);
  }

  private async fetchTenant(id: number): Promise<Tenant> {
    this.$spinner.showSpinner();
    try {
      return await tenantsService.getById(id);
    } finally {
      this.$spinner.removeSpinner();
    }
  }

  private onTenantUpdate(updatedTenant: Tenant) {
    this.tenant = updatedTenant;
  }

  private get canAccessShifts(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessCategoryConfiguration(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessCutConfiguration(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessMaterialGroups(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessAlerts(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get canAccessTargets(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && (this.wccEnabled || this.bcEnabled);
  }

  private get canAccessEmailReports(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin) && this.wccEnabled;
  }

  private get wccEnabled() {
    return !!this.user?.consoles.includes(ConsoleEnum.WCC) && this.tenant.hasDatahub;
  }

  private get bcEnabled() {
    return (
      this.tenant.hasSmbs &&
      (this.user?.consoles.includes(ConsoleEnum.BC) || this.user?.isGlobalAdmin)
    );
  }

  private get user(): User {
    return usersService.store.current();
  }

  private async redirectToTenantsPageIfRouteParameterIsMissing(parameter: string) {
    if (!this.$route.params[parameter]) {
      Logger.error(`Missing '${parameter}' route parameter. Redirecting to Tenants...`);
      toastService.error(`Missing '${parameter}' route parameter`);
      await this.$router.push({ name: Routes.Tenants });
      throw Error(`Missing '${parameter}' route parameter. Redirecting to Tenants...`);
    }
  }
}

enum Tab {
  Properties,
  Devices,
  Users,
  Locations,
  ShiftModels,
  CutGas,
  BendPartCategory,
  MaterialGroups,
  Alerts,
  Targets,
  EmailReports,
}
