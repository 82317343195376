// --------------------------------------------------------------------------------
// <copyright file="table.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { normalizeString } from './string';
import { getFieldPathValue, isEmpty, isNil } from './misc';
import moment from 'moment';
import { FilterMatcher } from '@/views/careConsole/commons/matchers';

export function generateLocalizedCustomSearch<T>(
  key: string,
  localizationKeyFn: (value: string) => string,
): (item: any, searchTerm: string) => boolean {
  return (item, searchTerm) => {
    if (isEmpty(item[key])) {
      return false;
    }
    const localizationKey = localizationKeyFn(String(item[key]));
    const normalizedTranslatedValue = normalizeString(i18n.t(localizationKey).toString());
    return normalizedTranslatedValue.includes(normalizeString(searchTerm));
  };
}

export function matchIgnoringCase(cellText: string | null, searchText: string): boolean {
  return !!cellText?.toLowerCase().trim().includes(searchText.toLowerCase().trim());
}

export function matchesDate(
  date: Date | null,
  searchText: string,
  formatter: (date: Date) => string = formatDate,
): boolean {
  const formattedDate = isNil(date) ? '' : formatter(date);
  return matchIgnoringCase(formattedDate, searchText);
}

export function formatDate(date: Date | null): string {
  if (isNil(date)) {
    return '';
  }

  return moment(date).format('DD/MM/YYYY');
}

export function createDateRangeMatcher<T>(fieldPath: string): FilterMatcher {
  return (row: T, filterValue: [Date, Date] | null | undefined) => {
    if (isNil(filterValue)) {
      return true;
    }

    const value: Date | null = getFieldPathValue(row, fieldPath);

    if (isNil(value)) {
      return false;
    }

    const dateRangeEnd = moment(filterValue[1]).endOf('day').valueOf();

    return value.getTime() >= filterValue[0].getTime()
      && value.getTime() <= dateRangeEnd;
  };
}

export function formatDateWithTime(date: Date | null): string {
  if (isNil(date)) {
    return '';
  }

  return moment(date).format('DD/MM/YYYY, HH:mm');
}

/**
 * Function to add a data-testid attribute to a table column.
 *
 * To be passed to a b-table-column's th-attrs property. Only used for unit
 * tests.
 **/
export function addDataTestIdAttributeToTh(id: string) {
  return () => ({
    'data-testid': id,
  });
}
