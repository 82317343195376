// --------------------------------------------------------------------------------
// <copyright file="getters.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { User } from '@/models/user';
import { GetterTree } from 'vuex';
import RootState from '../rootState';
import PersistentState from './persistentState';
import { Device } from '@/models/device';
import { Tenant } from '@/models/tenant';

const getters: GetterTree<PersistentState, RootState> = {
  getLocale: (rootState: PersistentState) => rootState.locale,
  getTimezones: (rootState: PersistentState) => rootState.timezones,
  getCurrencyCodes: (rootState: PersistentState) => rootState.currencyCodes,
  getMeasurements: (rootState: PersistentState) => rootState.measurements,
  getDeviceBaseModels: (rootState: PersistentState) => rootState.deviceBaseModels,
  currentUser: (rootState: PersistentState) => User.GetCopy(rootState.currentUser),
  getNotifications: (rootState: PersistentState) => rootState.notifications,
  getTenant: (rootState: PersistentState) =>
    rootState.tenant ? Tenant.GetCopy(rootState.tenant) : null,
  getDevices: (rootState: PersistentState) =>
    rootState.devices.map((device) => Device.GetCopy(device)),
  getShifts: (rootState: PersistentState) => rootState.shifts,
  getTargets: (rootState: PersistentState) => rootState.targets,
  getUserTargets: (rootState: PersistentState) => rootState.userTargets,
  getKpiGroups: (rootState: PersistentState) => rootState.kpiGroups,
  getEventModules: (rootState: PersistentState) => rootState.eventModules,
  getEventCodes: (rootState: PersistentState) => rootState.eventCodes,
};

export default getters;
