// --------------------------------------------------------------------------------
// <copyright file="auth.service.plugin.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import AuthService from '@/services/auth.service';

export const authServicePlugin = {
  install(vue: any) {
    vue.prototype.$auth = new AuthService();
  },
};
