// --------------------------------------------------------------------------------
// <copyright file="flexible-layout-grid.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export type GridRow = number[];

/**
 * Defines a grid layout by specifying rows with the number of columns per slot.
 */
export class FlexibleLayoutGridDefinition {
  private static readonly MAX_COLUMNS = 12;

  /**
   * Creates a new FlexibleLayoutGridDefinition from the definition in
   * `rowDefinitions`.
   *
   * @param rowDefinitions array of `GridRow`s, where each GridRow is just the
   *   number of columns for that slot.
   * @throws Error if some of the row definitions exceeds `MAX_COLUMNS` columns.
   */
  constructor(private readonly rowDefinitions: GridRow[]) {
    this.validateRowDefinitions();
  }

  private validateRowDefinitions() {
    for (const row of this.rowDefinitions) {
      const totalColumns = row.reduce(
        (totalWidth, currentColumnWidth) => totalWidth + currentColumnWidth,
        0,
      );

      if (totalColumns > FlexibleLayoutGridDefinition.MAX_COLUMNS) {
        throw new Error(`Row definition exceeds the maximum number of columns (${FlexibleLayoutGridDefinition.MAX_COLUMNS}): ${row}`);
      }
    }
  }

  /**
   * Returns a copy of the array of `GridRows` defined.
   */
  get rows(): GridRow[] {
    return [...this.rowDefinitions];
  }
}
