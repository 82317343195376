
import { Device } from '@/models/device';
import environment from '@/utils/environment';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DeviceImage extends Vue {
  @Prop({ required: true })
  private device!: Device;

  @Prop({ required: false, default: '3by1' })
  private ratio!: string;

  private get blobStorageName(): string {
    return environment.getDataStorageBlobName();
  }
}
