
import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '@/router/routes';
import { Tenant } from '@/models/tenant';
import { tenantsService } from '@/services/tenants.service';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';

@Component({
  computed: {
    Routes: () => Routes,
  },
  components: {
    TableWithActions,
  },
})
export default class Overview extends Vue {
  public routes = Routes;
  private isLoading = true;

  public tenants: Tenant[] = [];

  public async created() {
    await this.getTenants();
  }

  private async getTenants() {
    this.tenants = await tenantsService.get();
    this.isLoading = false;
  }

  private selectRow(data: Tenant) {
    this.$router.push({
      name: Routes.TechnologyConsoleTenantDevices,
      params: { tenantIdDh: data.tenantIdDh.toString() },
    });
  }
}
