// --------------------------------------------------------------------------------
// <copyright file="components.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export function listenersWithPrefix(
  prefix: string,
  _this: Vue,
  // eslint-disable-next-line @typescript-eslint/ban-types
): Record<string, Function | Function[]> {
  const _prefix = prefix.endsWith(':') ? prefix : `${prefix}:`;
  return Object.fromEntries(
    Object.entries(_this.$listeners)
      .filter(([ev]) => ev.startsWith(_prefix))
      .map(([ev, fn]) => [ev.replace(_prefix, ''), fn]),
  );
}
