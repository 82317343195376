// --------------------------------------------------------------------------------
// <copyright file="widgetFilters.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import WidgetDefinition, { ParamValues } from '@/models/Charts/widgetDefinition';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { areArraysEqual } from '@/utils/array';

type TimeFilter = FilterTimeSpanEnum | string[] | undefined;

function shiftsChanged(newShifts: number[], oldShifts: number[]) {
  return !areArraysEqual(newShifts, oldShifts);
}

function timeFilterChanged(newFilter: TimeFilter, oldFilter: TimeFilter) {
  if (Array.isArray(newFilter) && Array.isArray(oldFilter)) {
    return !areArraysEqual(newFilter, oldFilter);
  }
  return newFilter !== oldFilter;
}

function devicesChanged(newDevices: string | string[], oldDevices: string | string[]) {
  if (Array.isArray(newDevices) && Array.isArray(oldDevices)) {
    return !areArraysEqual(newDevices, oldDevices);
  }
  return newDevices !== oldDevices;
}

function axisSpanChanged(
  newAxis: FilterTimeAxisSpanEnum | undefined,
  oldAxis: FilterTimeAxisSpanEnum | undefined,
) {
  return newAxis !== oldAxis;
}

function paramValuesChanged(newParamValues: ParamValues, oldParamValues: ParamValues) {
  if (Object.keys(newParamValues).length !== Object.keys(oldParamValues).length) {
    return true;
  }

  return Object.keys(newParamValues).some(
    (key) => !oldParamValues.hasOwnProperty(key) || newParamValues[key] !== oldParamValues[key],
  );
}

/*
  Input arguments:
    - newValue: The WidgetDefinition after the change
    - oldValue: The WidgetDefinition prior to the change

  Output value:
    Whether the filters affecting the stored procedure's value have been
    changed. This will determine whether or not the SP needs to be called
    due to this change or not. Changes that do not require new data include
    target changes, aggregate selections, and stacked columns vs. non-stacked
    lines switches.
*/
export function filterChanged(newValue: WidgetDefinition, oldValue: WidgetDefinition): boolean {
  return (
    shiftsChanged(newValue.shifts, oldValue.shifts) ||
    timeFilterChanged(newValue.timeFilter, oldValue.timeFilter) ||
    devicesChanged(newValue.deviceId, oldValue.deviceId) ||
    axisSpanChanged(newValue.axisSpan, oldValue.axisSpan) ||
    paramValuesChanged(newValue.paramValues, oldValue.paramValues)
  );
}
