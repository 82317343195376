// --------------------------------------------------------------------------------
// <copyright file="enums.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum PersistentMutations {
  SetLocale = 'setLocale',
  SetTimezones = 'setTimezones',
  SetCurrencyCodes = 'setCurrencyCodes',
  SetMeasurements = 'setMeasurements',
  SetDeviceBaseModels = 'setDeviceBaseModels',
  SetCurrentUser = 'setCurrentUser',
  SetNotifications = 'setNotifications',
  SetTenant = 'setTenant',
  SetDevices = 'setDevices',
  SetShifts = 'setShifts',
  SetTargets = 'setTargets',
  SetUserTargets = 'setUserTargets',
  SetKPIGroups = 'setKpiGroups',
  SetEventModules = 'setEventModules',
  SetEventCodes = 'setEventCodes',
  ResetState = 'resetState',
}

export enum PersistentActions {
  SetLocale = 'persistent/setLocale',
  SetTimezones = 'persistent/setTimezones',
  SetCurrencyCodes = 'persistent/setCurrencyCodes',
  SetMeasurements = 'persistent/setMeasurements',
  SetDeviceBaseModels = 'persistent/setDeviceBaseModels',
  SetCurrentUser = 'persistent/setCurrentUser',
  SetNotifications = 'persistent/setNotifications',
  SetTenant = 'persistent/setTenant',
  SetDevices = 'persistent/setDevices',
  SetShifts = 'persistent/setShifts',
  SetTargets = 'persistent/setTargets',
  SetUserTargets = 'persistent/setUserTargets',
  SetKPIGroups = 'persistent/setKpiGroups',
  SetEventModules = 'persistent/setEventModules',
  SetEventCodes = 'persistent/setEventCodes',
  ResetState = 'persistent/resetState',
}

export enum PersistentGetters {
  GetLocale = 'persistent/getLocale',
  GetTimezones = 'persistent/getTimezones',
  GetCurrencyCodes = 'persistent/getCurrencyCodes',
  GetMeasurements = 'persistent/getMeasurements',
  GetDeviceBaseModels = 'persistent/getDeviceBaseModels',
  CurrentUser = 'persistent/currentUser',
  GetNotifications = 'persistent/getNotifications',
  GetTenant = 'persistent/getTenant',
  GetDevices = 'persistent/getDevices',
  GetShifts = 'persistent/getShifts',
  GetTargets = 'persistent/getTargets',
  GetUserTargets = 'persistent/getUserTargets',
  GetKPIGroups = 'persistent/getKpiGroups',
  GetEventModules = 'persistent/getEventModules',
  GetEventCodes = 'persistent/getEventCodes',
}
