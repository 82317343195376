// --------------------------------------------------------------------------------
// <copyright file="alerts.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------
import { AlertOccurrence } from '@/models/alertOccurrence';
import { AlertMetric, AlertOperator } from '@/models/enums/AlertsEnums';
import i18n from '@/i18n';
import { translateDateGrouping } from '@/utils/dates';
import { Alert } from '@/models/alert';
import { alertsService } from '@/services/alerts.service';
import { decimalFormatter } from './number';
import { isNil } from './misc';
import { matchIgnoringCase } from '@/utils/table';
import { AlertWorkflow, AlertWorkflowStatus } from '@/models/alertWorkflow';

export function matchesMetric(alert: Alert | AlertOccurrence, searchText: string) {
  return matchIgnoringCase(translateKpi(alert.metric), searchText);
}

export function matchesOperator(alert: Alert | AlertOccurrence, searchText: string) {
  return matchIgnoringCase(translateOperator(alert.operator), searchText);
}

export function matchesDateGrouping(alert: Alert | AlertOccurrence, searchText: string) {
  return matchIgnoringCase(translateDateGrouping(alert.dateGrouping!), searchText);
}

export function matchesThreshold(alert: Alert | AlertOccurrence, searchText: string) {
  const unit = alertsService.store.getAlertMetricUnit(alert.metric);
  return (
    matchIgnoringCase(alert.threshold.toString(), searchText) ||
    (unit && matchIgnoringCase(translateUnit(unit, alert.threshold as number), searchText))
  );
}

export function matchesVariable(alert: Alert | AlertOccurrence, searchText: string) {
  return matchIgnoringCase(translateVariable(alert.variable, alert.metric), searchText);
}

export function matchesEventSource(alert: Alert | AlertOccurrence, searchText: string): boolean {
  return alert.eventSources?.some((source) => matchIgnoringCase(source, searchText)) ?? false;
}

export function matchesEventCode(alert: Alert | AlertOccurrence, searchText: string): boolean {
  return alert.eventCodes?.some((code) => matchIgnoringCase(code, searchText)) ?? false;
}

export function translateKpi(kpi: AlertMetric): string {
  return i18n.t(`alert.metric.${kpi}`).toString();
}

export function translateOperator(operator: AlertOperator): string {
  return i18n.t(`alert_operators.${AlertOperator[operator]}`).toString();
}

export function translateUnit(unit: string | null, value: number | null): string {
  return unit ? i18n.tc(unit, value ?? 0).toString() : '';
}

export function translateVariable(variable: string | undefined, metric: AlertMetric): string {
  if (isNil(variable)) {
    return '';
  }
  return i18n.t(`wizards.create_care_alert.variables.${metric}.${variable}`).toString();
}

export function translateWorkflowStatus(workflowStatus: AlertWorkflowStatus): string {
  return i18n.t(`alert.workflow_status.${workflowStatus}`).toString();
}

export function formatWithUnit(value: number, metric: AlertMetric): string {
  const unit = alertsService.store.getAlertMetricUnit(metric);
  return decimalFormatter(value) + (unit ? ' ' + translateUnit(unit, value) : '');
}

export function alertMetricSorter(a: AlertWorkflow, b: AlertWorkflow, isAscending: boolean) {
  const aText = translateKpi(a.alertOccurrence.metric);
  const bText = translateKpi(b.alertOccurrence.metric);
  return isAscending ? aText.localeCompare(bText) : bText.localeCompare(aText);
}
