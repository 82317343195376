// --------------------------------------------------------------------------------
// <copyright file="alertOccurrenceTableRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { AlertOccurrence } from '@/models/alertOccurrence';
import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { metricsService } from '@/services/metrics.service';

export class AlertOccurrenceTableRetriever extends DataRetriever<AlertOccurrence[]> {
  constructor(procedure: ProcedureName) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<AlertOccurrence[] | null> {
    const dateRange = timeSpan as [string, string];
    return metricsService.getAlertOccurrences(
      selectedDevices[0],
      dateRange[0],
      dateRange[1],
      this.controller,
    );
  }
}
