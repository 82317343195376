
import { Component, Prop, Vue } from 'vue-property-decorator';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { translateFilterTimeSpanEnum } from '@/utils/i18n';
import { values } from '@/utils/enum';

@Component({
  methods: { translateFilterTimeSpanEnum },
  components: {
    ClickMenu,
  },
})
export default class TimeSpanFilter extends Vue {
  @Prop({ default: FilterTimeSpanEnum.Day })
  private value!: FilterTimeSpanEnum;

  @Prop({ default: '' })
  private size!: string;

  private get ids(): FilterTimeSpanEnum[] {
    return values(FilterTimeSpanEnum).filter((value) => value !== FilterTimeSpanEnum.None);
  }

  private get names(): string[] {
    return this.ids.map((x: FilterTimeSpanEnum) => translateFilterTimeSpanEnum(x));
  }
}
