// --------------------------------------------------------------------------------
// <copyright file="quotesAbcCustomersOverviewGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { formatPercentage as formatPercentage } from '@/utils/number';
import { EChartsOption } from 'echarts';
import { ProcedureName } from './abstract/chartGenerator';
import { QuotesAbcCustomersData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import QuotesAbcCustomersGenerator from './quotesAbcCustomersGenerator';

export default class QuotesAbcCustomersOverviewGenerator extends QuotesAbcCustomersGenerator {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    super(procedure, tenantIdDh);
  }

  override updateOptions(
    data: QuotesAbcCustomersData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const options = super.updateOptions(data, parameters, prevOptions);

    return {
      ...options,
      tooltip: {
        confine: true,
        formatter: (info: any) => {
          if (!info.data.selection?.id) {
            // non-leaf nodes don't have the selection field
            return '';
          }
          const category = info.data.path.split('/')[0];
          const customer = data.find((el) => el.no_ === info.data.selection.id)!;
          const formattedRatio = formatPercentage(
            (100 * customer.converted_amount) / customer.amount,
          );

          return `
          <div>
            <div style="display:flex;flex-direction:row;column-gap:1em;">
              <p style="flex-grow:1;">${info.name}</p>
              <p>${info.marker}<b>${i18n.t(
            'widget_info_text.quotes_abc_customers_tooltip_category',
            {
              category,
            },
          )}</b></p>
            </div>
            <div style="display:flex;flex-direction:row;column-gap:1em;font-size: 0.9em">
              <p style="flex-grow:1;">${i18n.t(
                'widget_info_text.quotes_abc_customers_tooltip_amount',
                {},
              )}:</p>
              <p><b>${i18n.n(customer.amount)}</b></p>
            </div>
            <div style="display:flex;flex-direction:row;column-gap:1em;font-size: 0.9em">
              <p style="flex-grow:1;">${i18n.t(
                'widget_info_text.quotes_abc_customers_tooltip_converted_amount',
              )}:</p>
              <p><b>${i18n.n(info.value)}</b></p>
            </div>
            <div style="display:flex;flex-direction:row;column-gap:1em;font-size: 0.9em">
              <p style="flex-grow:1;">${i18n.t(
                'widget_info_text.quotes_abc_customers_tooltip_number_quotes',
              )}:</p>
              <p><b>${i18n.n(customer.n_quotes)}</b></p>
            </div>
            <div style="display:flex;flex-direction:row;column-gap:1em;font-size: 0.9em">
              <p style="flex-grow:1;">${i18n.t(
                'widget_info_text.quotes_abc_customers_tooltip_number_converted_quotes',
              )}:</p>
              <p><b>${i18n.n(customer.n_accepted)}</b></p>
            </div>
            <div style="display:flex;flex-direction:row;column-gap:1em;font-size: 0.9em">
              <p style="flex-grow:1;">${i18n.t(
                'widget_info_text.quotes_abc_customers_tooltip_ratio',
              )}:</p>
              <p><b>${formattedRatio}</b></p>
            </div>
          </div>
          `;
        },
      },
    };
  }
}
