
import { Component, Vue } from 'vue-property-decorator';
import LocationsSelect from '@/components/inputs/LocationsSelect.vue';

@Component({
  components: {
    'sc-locations-select': LocationsSelect,
  },
})
export default class DataHubConfigModal extends Vue {
  public modalVisible = false;
  public locationId = 0;
  public tenantId = 0;

  public async openModal(tenantId: number) {
    if (tenantId) {
      this.tenantId = tenantId;
    }
    this.modalVisible = true;
  }

  public onSubmit() {
    this.modalVisible = false;
    this.$emit('onClose', this.locationId);
  }
}
