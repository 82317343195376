
import { Component, Vue, Watch } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Routes } from '@/router/routes';
import LayoutGrid from '../dashboard/LayoutGrid.vue';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import { usersService } from '@/services/users.service';
import { User } from '@/models/user';
import { CutLayoutTab } from '@/components/common/CutLayoutTab';
import { BetaFeature } from '@/models/enums/BetaFeature';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class GanttChart extends Vue {
  private gridLayout = availableDashboardLayouts[DashboardLayout.OneByOne];

  private mounted() {
    if (
      this.hasWorkCenterConsoleCutIntradayStatesBetaFeature &&
      this.$route.name === Routes.CutGanttChart
    ) {
      this.goToCutBetaGanttChart();
    }
  }

  private get wd(): WidgetDefinition {
    return this.$route.name === Routes.CutGanttChart
      ? new WidgetDefinition(WidgetEnum.LaserStatesGantt, [], 1, [])
      : new WidgetDefinition(WidgetEnum.TubeStatesGantt, [], 1, []);
  }

  @Watch('hasWorkCenterConsoleCutIntradayStatesBetaFeature')
  private redirectToIntradayStatesWhenEnabled(isBetaEnabled: boolean, wasBetaEnabled: boolean) {
    if (!wasBetaEnabled && isBetaEnabled && this.$route.name === Routes.CutGanttChart) {
      this.goToCutBetaGanttChart();
    }
  }

  private goToCutBetaGanttChart() {
    this.$router.push({
      name: Routes.Cut,
      hash: `#${CutLayoutTab[CutLayoutTab.CutIntradayStates]}`,
    });
  }

  private get currentUser(): User {
    return usersService.store.current();
  }

  private get hasWorkCenterConsoleCutIntradayStatesBetaFeature() {
    return this.currentUser.hasBetaFeature(BetaFeature.WorkCenterConsoleCutIntradayStates);
  }
}
