// --------------------------------------------------------------------------------
// <copyright file="axisPointerLabelFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { decimalFormatter } from '@/utils/number';
import moment from 'moment/moment';
import { isNil } from '@/utils/misc';

export const AXIS_DIMENSION_X = 'x';
export const AXIS_DIMENSION_Y = 'y';

export class AxisPointerLabelFormatter {
  constructor(
    private dateGrouping: FilterTimeAxisSpanEnum | undefined,
    private isCategoryAxis: boolean,
    private roundValues = false,
  ) {}

  get() {
    return (params: any) => this.format(new LabelParamsHelper(params));
  }

  private format(params: LabelParamsHelper): string {
    if (params.isYAxis()) {
      if (this.roundValues) {
        return decimalFormatter(params.getValue(), 0);
      }
      return decimalFormatter(params.getValue());
    } else {
      // 'x' axis dimension
      return this.formatXAxisLabel(params);
    }
  }

  private formatXAxisLabel(params: LabelParamsHelper) {
    switch (this.dateGrouping) {
      case FilterTimeAxisSpanEnum.Hour:
        return moment(params.getValue()).format('YYYY-MM-DD HH:mm');
      case FilterTimeAxisSpanEnum.Day:
      case FilterTimeAxisSpanEnum.Week:
      case FilterTimeAxisSpanEnum.Month:
      case FilterTimeAxisSpanEnum.Quarter:
      default:
        return this.isCategoryAxis ? params.getValue() : params.getTimeSeriesAxisLabel();
    }
  }
}

class LabelParamsHelper {
  constructor(private params: any) {}

  isYAxis(): boolean {
    return this.params.axisDimension === AXIS_DIMENSION_Y;
  }

  getValue(): any {
    return this.params.value;
  }

  getTimeSeriesAxisLabel(): any {
    // Not sure why this happens but, when zooming in too much, Echarts sends
    // undefined here
    if (isNil(this.params.seriesData?.[0]?.value)) {
      return '';
    }

    return this.params.seriesData[0].value[0];
  }
}
