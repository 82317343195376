// --------------------------------------------------------------------------------
// <copyright file="materialGroups.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { responseHandler } from './response.handler';
import { MaterialGroup } from '@/models/materialGroup';

class MaterialGroupsService {
  get(tenantId?: number): Promise<MaterialGroup[]> {
    return axios
      .get<MaterialGroup[]>(`${BaseUrl}/materialgroups` + (tenantId ? '?tenantId=' + tenantId : ''))
      .then((response: any) => {
        responseHandler.handleDates(response.data);
        return response.data;
      });
  }

  getDetails(id: number): Promise<MaterialGroup> {
    return axios
      .get<MaterialGroup>(`${BaseUrl}/materialgroups/getdetails/${id}`)
      .then((response: any) => {
        responseHandler.handleDates(response.data);
        return response.data;
      });
  }

  create(materialGroup: MaterialGroup) {
    return axios.post(`${BaseUrl}/materialgroups/`, materialGroup).then((response: any) => {
      return response.data;
    });
  }

  delete(id: number): Promise<void> {
    return axios.delete(`${BaseUrl}/materialgroups/${id}`).then((response: any) => {
      return response.data;
    });
  }
}

export const materialGroupsService = new MaterialGroupsService();
