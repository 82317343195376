
import { WizardStep } from '@/components/wizard';
import { Membership, MembershipType, User } from '@/models/user';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SubsidiariesSelect from '@/components/inputs/SubsidiariesSelect.vue';
import { isEmpty, isNil, Nullable } from '@/utils/misc';
import TenantsSelect from '@/components/inputs/TenantsSelect.vue';
import { extend, ValidationObserver } from 'vee-validate';
import i18n from '@/i18n';
import { usersService } from '@/services/users.service';

@Component({
  methods: { isEmpty, isNil },
  computed: {
    MembershipType: () => MembershipType,
  },
  components: {
    SubsidiariesSelect,
    TenantsSelect,
    ValidationObserver,
    WizardStep,
  },
})
export default class ServiceMembershipWizardStep extends Vue {
  @Prop({ required: true })
  private value!: User;

  @Prop({ default: false, type: Boolean })
  private fixedSubsidiary!: boolean;

  private formData: FormData = {
    subsidiaryId: null,
    subsidiaryName: '',
    otherMemberships: [],
  };

  private get isCurrentUserSubsidiaryAdmin() {
    return usersService.store.current().isSubsidiaryAdmin;
  }

  private get validationRules() {
    return {
      other_subsidiary: {
        different_value: [this.formData.subsidiaryId],
        duplicated_subsidiaries: [
          this.formData.otherMemberships.filter(
            (membership) => membership.type === MembershipType.Subsidiary,
          ),
        ],
      },
      other_customer: {
        duplicated_customers: [
          this.formData.otherMemberships.filter(
            (membership) => membership.type === MembershipType.Customer,
          ),
        ],
      },
    };
  }

  @Watch('value', { immediate: true })
  private updateFormDataFromValue() {
    this.formData.subsidiaryId = this.value.subsidiaryId;
    this.formData.subsidiaryName = this.value.subsidiaryName;
    this.formData.otherMemberships = [...this.value.otherMemberships];
  }

  private addSubsidiaryMembership() {
    this.formData.otherMemberships.push({ ...EMPTY_SUBSIDIARY_MEMBERSHIP });
  }

  private addCustomerMembership() {
    this.formData.otherMemberships.push({ ...EMPTY_CUSTOMER_MEMBERSHIP });
  }

  private removeMembership(index: number) {
    this.formData.otherMemberships.splice(index, 1);
  }

  private get isLocalFormDataValid() {
    return (
      !isNil(this.formData.subsidiaryId) &&
      // HACK: Because of the use of slots for wizard steps we haven't managed
      // to make validation-observer work properly, so we have to validate here
      // again by hand.
      !this.isMainSubsidiarySelectedAsOtherSubsidiaryToo()
    );
  }

  private isMainSubsidiarySelectedAsOtherSubsidiaryToo(): boolean {
    return this.formData.otherMemberships
      .filter((membership) => membership.type === MembershipType.Subsidiary)
      .some((membership) => membership.id === this.formData.subsidiaryId);
  }

  private onLeave() {
    const modifiedUser = User.GetCopy(this.value);
    modifiedUser.subsidiaryId = this.formData.subsidiaryId;
    modifiedUser.subsidiaryName = this.formData.subsidiaryName;
    modifiedUser.otherMemberships = this.getValidMemberships();

    this.$emit('input', modifiedUser);
  }

  /**
   * Returns memberships from formData.otherMemberships excluding the
   * invalid ones.
   *
   * The user might've added a membership and left it empty or just selected the
   * type.
   */
  private getValidMemberships(): Membership[] {
    return this.formData.otherMemberships.filter(
      (membership) => !isNil(membership.id),
    ) as Membership[];
  }
}

extend('different_value', {
  params: ['main_value'],
  validate(value: any, { main_value }: any) {
    return value !== main_value;
  },
  message: i18n.t('validation.different_subsidiary').toString(),
});

extend('duplicated_subsidiaries', {
  params: ['values'],
  validate(value: any, { values }: any) {
    return (values as Membership[]).filter((membership) => membership.id === value).length === 1;
  },
  message: i18n.t('validation.duplicated_subsidiaries').toString(),
});

extend('duplicated_customers', {
  params: ['values'],
  validate(value: any, { values }: any) {
    return (values as Membership[]).filter((membership) => membership.id === value).length === 1;
  },
  message: i18n.t('validation.duplicated_customers').toString(),
});

const EMPTY_SUBSIDIARY_MEMBERSHIP = Object.freeze({
  type: MembershipType.Subsidiary,
  id: null,
  name: null,
});

const EMPTY_CUSTOMER_MEMBERSHIP = Object.freeze({
  type: MembershipType.Customer,
  id: null,
  name: null,
});

interface FormData {
  subsidiaryId: number | null;
  subsidiaryName: string;
  otherMemberships: Nullable<Membership>[];
}
