// --------------------------------------------------------------------------------
// <copyright file="charts.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import moment from 'moment';

export function isCategoryXAxis(
  timeAxisSpan: FilterTimeAxisSpanEnum | undefined,
  dataLength: number,
): boolean {
  if (dataLength === 1) {
    /* If there is only one data point it has to be treated as category axis to avoid showing
    incorrect values in xAxis */
    return true;
  }
  return isCategoryAxisDateGrouping(timeAxisSpan);
}

export function isCategoryAxisDateGrouping(timeAxisSpan: FilterTimeAxisSpanEnum | undefined) {
  switch (timeAxisSpan) {
    // Category axis instead of time axis for weeks and quarters
    case FilterTimeAxisSpanEnum.Week:
    case FilterTimeAxisSpanEnum.Quarter:
      return true;
    default:
      return false;
  }
}

/** @deprecated Use getTooltipTitle instead. */
export function getDateTooltipLabel(
  datetime: string,
  timeAxisSpan: FilterTimeAxisSpanEnum | undefined,
): string {
  return datetime.substring(0, lengthOfLabel(timeAxisSpan));
}

function lengthOfLabel(timeAxisSpan: FilterTimeAxisSpanEnum | undefined): number {
  switch (timeAxisSpan) {
    case FilterTimeAxisSpanEnum.Hour:
      return 16; // 2023-01-19 12:34
    case FilterTimeAxisSpanEnum.Month:
      return 7; // 2023-01
    case FilterTimeAxisSpanEnum.Year:
      return 4; // 2023
    default:
      return 10; // 2023-01-19
  }
}

export function getTooltipTitle(
  axisValue: string | number,
  dateGrouping: FilterTimeAxisSpanEnum,
  isCategoryAxis: boolean,
): string {
  if (isCategoryAxis) {
    return axisValue.toString();
  }

  const m = moment(axisValue);
  switch (dateGrouping) {
    case FilterTimeAxisSpanEnum.Day:
      return m.format('YYYY-MM-DD');
    case FilterTimeAxisSpanEnum.Week:
      return m.format('YYYY-[W]ww');
    case FilterTimeAxisSpanEnum.Month:
      return m.format('YYYY-MM');
    case FilterTimeAxisSpanEnum.Quarter:
      return m.format('YYYY-[Q]Q');
    case FilterTimeAxisSpanEnum.Year:
      return m.format('YYYY');
    default:
      return m.format('YYYY-MM-DD, HH:mm');
  }
}
