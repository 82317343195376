// --------------------------------------------------------------------------------
// <copyright file="eventTypeByModuleGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { EventTypeByModuleData, ImportantMessageLevel, MessageLevel } from './chartsData';
import { BarSeriesOption, EChartsOption, XAXisComponentOption as XAXisOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { isEmpty } from '@/utils/misc';
import { EventColor } from '@/utils/color';
import { metricsService } from '@/services/metrics.service';

export class EventTypeByModuleGenerator extends ChartGenerator<EventTypeByModuleData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];
    const eventType = metricsService.getEventTypeParameter(params?.eventTypes);

    return metricsService.getSSCMetrics<EventTypeByModuleData[]>(
      this.procedure,
      this.tenantIdDh,
      selectedDevices[0],
      {
        // These are optional:
        dateFrom,
        dateTo,
        messageLevel: eventType,
        modules: params?.eventModules,
        eventCodes: params?.eventCodes,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: EventTypeByModuleData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const selectedMessageTypes = this.getSelectedMessageTypes(parameters.paramValues?.eventTypes);

    return {
      grid: {
        top: 8,
        bottom: 20,
        left: 10,
        right: 8,
        containLabel: true,
      },
      legend: {
        show: true,
        bottom: 0,
        formatter: (name: string) => this.translateSeriesName(name),
      },
      xAxis: {
        type: 'category',
        data: this.getModuleNames(data),
        axisLabel: {
          interval: 0,
          rotate: 60,
          overflow: 'truncate',
          width: 90,
        },
      } as XAXisOption, // overflow=truncate not recognized
      yAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {
          formatter: (value: number) => i18n.n(value),
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: (params: any) => {
          let tooltipMarkdown: string = `<p>${params[0].axisValueLabel}</p>`;
          params.forEach((item: any) => {
            if (!isNaN(item.value)) {
              tooltipMarkdown += `
                <p>
                  ${item.marker} ${this.translateSeriesName(item.seriesName)}:
                  <b style="margin-left:32px;float:right">
                    ${i18n.n(item.value, { maximumFractionDigits: 2 })}
                  </b>
                </p>
              `;
            }
          });
          return tooltipMarkdown;
        },
      },
      series: this.series(data, selectedMessageTypes),
    };
  }

  private getSelectedMessageTypes(eventTypes?: ImportantMessageLevel[]): ImportantMessageLevel[] {
    return eventTypes === undefined || isEmpty(eventTypes)
      ? Object.values(ImportantMessageLevel) // all by default
      : eventTypes;
  }

  private series(
    data: EventTypeByModuleData[],
    selectedMessageTypes: ImportantMessageLevel[],
  ): BarSeriesOption[] {
    const messageTypeColors = {
      [ImportantMessageLevel.Warning]: EventColor.Warning,
      [ImportantMessageLevel.Error]: EventColor.Error,
    };

    // HACK: This grouping is to be implemented later in SQL function
    // get_error_tab_bars
    const byModuleData = this.getModuleNames(data).map((module) => {
      const warningsItem = data.find(
        (item) => item.module === module && item.type === MessageLevel.Warning,
      );
      const errorsItem = data.find(
        (item) => item.module === module && item.type === MessageLevel.Error,
      );

      return {
        module,
        warnings: warningsItem?.count ?? 0,
        errors: errorsItem?.count ?? 0,
      };
    });

    return selectedMessageTypes.map((messageType) => ({
      type: 'bar',
      name: messageType,
      color: messageTypeColors[messageType],
      emphasis: {
        focus: 'series',
      },
      stack: 'total',
      data: this.getLevelMessageCounts(byModuleData, messageType),
    }));
  }

  private getLevelMessageCounts(byModuleData: any[], level: string): number[] {
    return byModuleData.map((item) =>
      level === MessageLevel.Warning ? item.warnings : item.errors,
    );
  }

  private getModuleNames(data: EventTypeByModuleData[]): string[] {
    return this.removeDuplicates(
      data.map((item) => item.module).sort((a, b) => a.localeCompare(b)),
    );
  }

  private removeDuplicates(names: string[]): string[] {
    return [...new Set(names)];
  }

  private translateSeriesName(name: string) {
    return i18n.t(`report.${name.toLowerCase()}_plural`).toString();
  }
}
