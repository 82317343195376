// --------------------------------------------------------------------------------
// <copyright file="bendPartCategory.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { BendPartCategory } from '@/models/bendPartCategory';
import { BaseUrl } from '@/models/constants';
import axios from 'axios';

class BendPartCategoryService {
  get(tenantId: number): Promise<BendPartCategory> {
    return axios
      .get<BendPartCategory>(`${BaseUrl}/bendpartcategories/getbytenant/${tenantId}`)
      .then((response: any) => {
        return response.data;
      });
  }

  update(bendPartCategory: BendPartCategory) {
    return axios.put(`${BaseUrl}/bendpartcategories/`, bendPartCategory).then((response: any) => {
      return response.data;
    });
  }
}

export const bendPartCategoryService = new BendPartCategoryService();
