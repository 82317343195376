// --------------------------------------------------------------------------------
// <copyright file="ShiftOverflowEnum.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum ShiftOverflowEnum {
  Unspecified = 0,
  NextDay = 1,
  PreviousDay = 2,
}
