
import { isEmpty } from '@/utils/misc';
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import { LayoutSlot } from '@/views/dashboard/layout-grid';
import { VNode } from 'vue';

@Component({})
export default class LayoutGrid extends Vue {
  @Provide()
  private $grid: LayoutGrid = this;

  @Prop({ required: true })
  private layout!: LayoutSlot[];

  @Prop({ default: false })
  private editMode!: boolean;

  private widgetIndices: number[] = [];

  private mounted() {
    this.refreshWidgets();
  }

  private get tabletRowsCount(): number {
    return this.slotsWithWidget
      .map((slot) => slot.height)
      .reduce((total: number, height: number) => total + height, 0);
  }

  refreshWidgets(): void {
    this.widgetIndices = Object.entries(this.$slots)
      .filter(([_, slots]) => !isEmpty(slots))
      .map(([name, _]) => +name.replace('widget-', ''));
  }

  private getSlotTabletRowClasses(index: number, height: number): string[] {
    if (this.editMode) {
      return [];
    }

    const startRow = this.getSlotStartRowOnTablet(index);
    return [`tablet-row-from-${startRow}`, `tablet-row-to-${startRow + height}`];
  }

  private getSlotStartRowOnTablet(index: number): number {
    // Adjust index to slotsWithWidget
    const adjustedIndex = index - this.getNumberOfEmptySlotsBeforeIndex(index);

    return (
      this.slotsWithWidget
        .map((slot) => slot.height)
        .slice(0, adjustedIndex)
        .reduce((total: number, height: number) => total + height, 0) + 1
    );
  }

  private getNumberOfEmptySlotsBeforeIndex(index: number) {
    return this.layout.slice(0, index).filter((_, i) => !this.isThereWidgetInSlot(i)).length;
  }

  private get slotsWithWidget(): LayoutSlot[] {
    return this.layout.filter((_, index) => this.isThereWidgetInSlot(index));
  }

  private isThereWidgetInSlot(index: number): boolean {
    return this.widgetIndices.includes(index);
  }
}
