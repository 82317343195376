import { render, staticRenderFns } from "./MaterialGroups.vue?vue&type=template&id=1207b162&scoped=true"
import script from "./MaterialGroups.vue?vue&type=script&lang=ts"
export * from "./MaterialGroups.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1207b162",
  null
  
)

export default component.exports