// --------------------------------------------------------------------------------
// <copyright file="customNotifications.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { AlertAction } from '@/models/enums/AlertsEnums';

export class UserCustomNotificationStatus {
  constructor(public id: number, public messageTxt: string, public timestampNotification: string) {}
}

export class CustomNotification {
  constructor(
    public messageTxt: string,
    public subject: string | undefined,
    public tenantIds: string,
    public subsidiaryIds: string,
    public sendToAll: boolean,
    public action: AlertAction,
  ) {}
}
