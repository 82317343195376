// --------------------------------------------------------------------------------
// <copyright file="quotesConversionRatesDetailedGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import i18n from '@/i18n';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { QuotesConversionRatesData } from './chartsData';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockQuotesConversionRatesData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export class QuotesConversionRatesDetailedGenerator extends ChartGenerator<
  QuotesConversionRatesData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    const kpiName = this.procedure.replace('_gauge', '');
    return metricsService.getSMBSMetricsBC<QuotesConversionRatesData[]>(
      kpiName,
      this.tenantIdDh,
      { startDate, endDate, axisTimespan: timeAxisSpan, customerNo },
      this.controller,
    );
  }

  override updateOptions(
    data: QuotesConversionRatesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const pixelRatio = window.devicePixelRatio;
    const fontSizeInner = pixelRatio > 1 ? 13 : 16;
    const offsetCenterDprY1 = pixelRatio > 1 ? '60%' : '55%';
    const offsetCenterDprY2 = pixelRatio > 1 ? '95%' : '90%';

    // Without a customerNo we'd get 3 items, one for each A/B/C category.
    // The client belongs to one category, so we just get one item.
    const ratio =
      'clientWidth' in parameters ? parameters.clientWidth! / parameters.clientHeight! : undefined;
    const hideInIpad =
      /iPad/.test(navigator.userAgent) &&
      window.innerWidth === 1024 &&
      window.innerWidth > window.innerHeight;
    const radius = !!ratio && ratio < 1 && !hideInIpad ? '60%' : '80%';

    return {
      title: {
        show: false,
      },
      grid: {
        left: '3%',
        right: '15%',
        bottom: '10%',
        containLabel: true,
      },
      series: [
        {
          splitNumber: 5,
          type: 'gauge',
          radius,
          axisLabel: {
            formatter: (value: number) => i18n.n(value),
          },
          max: parameters.maxValue,
          anchor: {
            show: false,
          },
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: pixelRatio > 1 ? 25 : 35,
              dashOffset: 13,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            distance: -37,
            length: 0,
          },
          data: [
            {
              value: data[0].amount_conv_rate * 100,
              name: i18n.t('report.business_console.amount_conv_rate').toString(),
              title: { offsetCenter: ['0%', '40%'] },
              detail: {
                fontSize: fontSizeInner,
                offsetCenter: ['0%', offsetCenterDprY1],
              },
            },
            {
              value: data[0].quotes_conv_rate * 100,
              name: i18n.t('report.business_console.quotes_conv_rate').toString(),
              title: { offsetCenter: ['0%', '75%'] },
              detail: {
                fontSize: fontSizeInner,
                offsetCenter: ['0%', offsetCenterDprY2],
              },
            },
          ],
          detail: {
            width: 30,
            height: 10,
            fontSize: pixelRatio > 1 ? 15 : 18,
            color: 'inherit',
            borderColor: 'inherit',
            borderRadius: 0,
            borderWidth: 0,
            formatter: (value: number) =>
              i18n.n(value, { maximumFractionDigits: 2 }) + parameters.unit,
          },
          title: {
            fontSize: pixelRatio > 1 ? 10 : 13,
          },
        },
        {
          type: 'gauge',
          radius,
          pointer: { show: true, length: '100%' },
          max: parameters.maxValue,
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: { show: false },
          axisLine: { show: false },
          data: [
            {
              value: 70,
              detail: { show: false },
            },
          ],
        },
      ],
    };
  }

  override getMockData(): QuotesConversionRatesData[] | null {
    return mockQuotesConversionRatesData();
  }
}
