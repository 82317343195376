
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CurrencyCode } from '@/models/currencyCode';
import { ValidationProvider } from 'vee-validate';
import { mastersService } from '@/services/masters.service';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class CurrencyCodesSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: undefined })
  public rules?: any;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: CurrencyCode[] = [];

  public mounted(): void {
    this.items = this.getItems();
  }
  public getItems() {
    return mastersService.store.getCurrencyCodes();
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }

  private get required(): boolean {
    return this.rules?.required || this.rules?.includes?.('required');
  }
}
