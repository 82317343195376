// --------------------------------------------------------------------------------
// <copyright file="cutStatesIntradayGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import moment from 'moment';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { getStateColour } from '@/utils/color';
import { CutStatesIntradayData } from './chartsData';
import { EChartsOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export default class CutStatesIntradayGenerator extends ChartGenerator<CutStatesIntradayData[]> {
  private readonly states: string[] = ['wait', 'error', 'idle', 'stop', 'offline', 'work'];

  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    return metricsService.getDevicesMetrics<CutStatesIntradayData[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  updateLegend() {
    return this.onlyLegend();
  }

  override updateOptions(
    data: CutStatesIntradayData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const device = parameters.device;
    const omittedStates = parameters.omittedStates;

    return {
      textStyle: {
        fontFamily: 'Inter',
      },
      title: {
        text: device,
        top: 'bottom',
        textAlign: 'center',
        left: 'middle',
        textStyle: {
          fontSize: 15,
          fontWeight: 'normal',
        },
      },
      grid: [
        {
          top: '8%',
          left: '8%',
          right: '8%',
          bottom: '20%',
        },
      ],
      xAxis: {
        type: 'time',
        axisLabel: {
          show: true,
        },
        show: data.length !== 0,
      },
      yAxis: {
        type: 'value',
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => {
          return `
          <div style="font-size: 15px; font-weight: bold">
            ${moment(params[0].axisValue).format('HH:mm')}
          </div>
          `.concat(
            ...params.map(
              (series: any) => `
          <div style="display: flex; flex-direction: row">
            <div style="flex-grow: 1">${series.marker} ${series.seriesName}</div>
            <div style="padding-left: 3rem">${Math.round(series.value[1] * 100) / 100}</div>
          </div>
          `,
            ),
          );
        },
      },
      legend: {
        show: false,
      },
      series: this.states
        .filter((state) => !omittedStates!.includes(i18n.t(`report.${state}`).toString()))
        .map((state) => ({
          name: i18n.t(`report.${state}`).toString(),
          type: 'line',
          symbol: 'none',
          itemStyle: {
            color: getStateColour(state),
          },
          data: data.flatMap((entry) =>
            entry.last
              ? [
                  [entry.timestamp, (entry as any)[`${state}_time`]],
                  [moment(entry.timestamp).add(1, 'second').toISOString(), null],
                  [moment(entry.timestamp).add(2, 'seconds').toISOString(), 0],
                ]
              : [[entry.timestamp, (entry as any)[`${state}_time`]]],
          ),
        })),
      graphic:
        data.length === 0
          ? {
              elements: [
                {
                  type: 'text',
                  top: 'middle',
                  left: 'center',
                  style: {
                    text: 'No data',
                    font: '1rem Inter',
                  },
                },
              ],
            }
          : undefined,
    };
  }

  private onlyLegend() {
    return {
      textStyle: {
        fontFamily: 'Inter',
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        show: false,
      },
      legend: {
        top: 0,
        type: 'scroll' as const,
      },
      series: this.states.map((state) => ({
        name: i18n.t(`report.${state}`).toString(),
        type: 'line' as const,
        symbol: 'none',
        itemStyle: {
          color: getStateColour(state),
        },
      })),
    };
  }
}
