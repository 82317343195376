// --------------------------------------------------------------------------------
// <copyright file="userTargets.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { CreateUserTarget, UserTarget } from '@/models/userTarget';
import { Target } from '@/models/target';
import { PersistentActions, PersistentGetters } from '@/store/persistent/enums';
import store from '@/store';
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';

class UserTargetsService {
  private readonly baseUrl = `${BaseUrl}/user-targets`;

  store = new (class {
    getAll(): UserTarget[] {
      return store.getters[PersistentGetters.GetUserTargets];
    }

    get(targetMetric: TargetsMetric | null): UserTarget | undefined {
      return this.getAll().find((userTarget) => userTarget.targetMetric === targetMetric);
    }

    async setAll(userTargets: UserTarget[]) {
      await store.dispatch(PersistentActions.SetUserTargets, userTargets);
    }

    getAllTargets(): Target[] {
      return store.getters[PersistentGetters.GetTargets];
    }

    async setAllTargets(targets: Target[]) {
      await store.dispatch(PersistentActions.SetTargets, targets);
    }

    getConsoleUserTargets(console: ConsoleEnum): UserTarget[] {
      const consoleTargets = userTargetsService.store.getConsoleTargetMetrics(console);
      return this.getAll().filter((userTarget) => consoleTargets.includes(userTarget.targetMetric));
    }

    private getConsoleTargetMetrics(console: ConsoleEnum): TargetsMetric[] {
      return this.getAllTargets()
        .filter((target) => target.consoleId === console)
        .map((target) => target.targetMetric);
    }

    getMetricNumberOfTargetValues(targetsMetric: TargetsMetric | null): number {
      return (
        this.getAllTargets().find((target) => target.targetMetric === targetsMetric)?.nTargets ?? 0
      );
    }

    async clear() {
      await store.dispatch(PersistentActions.SetUserTargets, []);
    }
  })();

  async getForTenant(tenantId: number): Promise<UserTarget[]> {
    const response = await axios.get<UserTarget[]>(`${this.baseUrl}/${tenantId}`);
    await this.store.setAll(response.data);
    return response.data;
  }

  async delete(id: number): Promise<void> {
    return await axios.delete(`${this.baseUrl}/${id}`);
  }

  async create(target: CreateUserTarget) {
    return await axios.post(this.baseUrl, target);
  }

  update(id: number, target: CreateUserTarget) {
    return axios.put(this.baseUrl, {
      ...target,
      id,
    });
  }

  async getTargets(): Promise<Target[]> {
    const response = await axios.get<Target[]>(`${this.baseUrl}/targets`);
    await this.store.setAllTargets(response.data);
    return response.data;
  }
}

export const userTargetsService = new UserTargetsService();
