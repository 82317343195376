
import { Component, Prop, Vue } from 'vue-property-decorator';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import WidgetContainer from '../Charts/widgetContainer/WidgetContainer.vue';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { dateRangeToDateStrings } from '@/utils/dates';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { ImportantMessageLevel } from '@/models/Charts/chartsData';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { LayoutSlot } from '@/views/dashboard/layout-grid';
import { defaultDateRange } from '@/views/careConsole/commons/utils';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class CuttingHead extends Vue {
  @Prop({ required: true, default: () => defaultDateRange() })
  private dateRange!: [Date, Date];

  @Prop({ required: true, default: FilterTimeAxisSpanEnum.Month })
  private timeAxisSpan!: FilterTimeAxisSpanEnum;

  @Prop()
  private deviceId!: string;

  private gridLayout: LayoutSlot[] = [
    { column: 0, row: 0, width: 8, height: 8 },
    { column: 8, row: 0, width: 4, height: 4 },
    { column: 8, row: 4, width: 4, height: 4 },
  ];

  private get widgetDefinitions(): WidgetDefinition[] {
    return [
      this.diodesAndDrawerCyclesTimelineWidgetDefinition,
      this.eventsTimelineWidgetDefinition,
      this.temperaturesWidgetDefinition,
    ].map((widget, index) => widget.withIndex(index));
  }

  private get diodesAndDrawerCyclesTimelineWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareCuttingHeadDiodesAndDrawerCyclesTimeline,
      this.deviceId,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      this.timeAxisSpan,
    ).hideTitle();
  }

  private get eventsTimelineWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareCuttingHeadEventsTimeline,
      this.deviceId,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      this.timeAxisSpan,
      undefined,
      {
        eventTypes: [ImportantMessageLevel.Error],
        eventModules: ['Cutting Head'],
      },
    );
  }

  private get temperaturesWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareCuttingHeadTemperatures,
      this.deviceId,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      this.timeAxisSpan,
    );
  }
}
