
import { WizardStep } from '@/components/wizard';
import { User, UserAuthenticationType } from '@/models/user';
import { isEmpty } from '@/utils/misc';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import i18n from '@/i18n';
import { usersService } from '@/services/users.service';

/**
 * User creation wizard step to enter data like name, email, password...
 *
 * Props:
 * - value: `User` instance that is being created/edited.
 * - isEdit: `true` to set the form in edition mode. Otherwise, it will be set
 *   in creation mode.
 */
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    WizardStep,
  },
  computed: {
    UserAuthenticationType: () => UserAuthenticationType,
  },
  methods: {
    isEmpty,
  },
})
export default class PersonalDataWizardStep extends Vue {
  @Prop({ required: true })
  private value!: User;

  @Prop({ default: false })
  private isEdit!: boolean;

  private repeatPassword = '';
  private authenticationTypes = [UserAuthenticationType.Local, UserAuthenticationType.Microsoft];

  @Ref('emailValidationProvider')
  private emailValidationProvider!: any;

  private async onLeave(): Promise<boolean> {
    if (this.isEdit) {
      // The email can't be edited, so it'll always exist
      return true;
    }

    const emailExists = await usersService.emailExists(this.value.email);
    if (emailExists) {
      this.emailValidationProvider.setErrors([i18n.t('validation_message.email_exists')]);
    }
    return !emailExists;
  }

  private get passwordRequired() {
    return this.value.authenticationType === UserAuthenticationType.Local;
  }

  private onInput() {
    this.$emit('input', this.value);
  }

  @Watch('value.authenticationType')
  private watchAuthenticationType(value: UserAuthenticationType) {
    if (value === UserAuthenticationType.Microsoft) {
      this.value.password = undefined;
      this.repeatPassword = '';
    }
  }

  private get canChooseAuthenticationType() {
    return !this.value.isServiceUserType && !this.value.isTechnologyUser;
  }

  private get authenticationTypeDisabledReason() {
    const prefix = 'wizards.user_creation.personal_data.user_authentication_type';

    if (this.isEdit) {
      return i18n.t(`${prefix}.disabled_because_editing`);
    }

    if (!this.canChooseAuthenticationType) {
      return i18n.t(`${prefix}.disabled_because_of_user_type`);
    }

    return undefined;
  }

  private get validationRules() {
    return {
      name: 'required',
      email: {
        required: true,
        email: true,
      },
      authenticationType: {
        required: true,
        oneOf: [UserAuthenticationType.Microsoft, UserAuthenticationType.Local],
      },
      password: {
        required: this.value.authenticationType === UserAuthenticationType.Local,
        max: 256,
        password_repeat: [this.repeatPassword],
      },
    };
  }
}
