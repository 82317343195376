// --------------------------------------------------------------------------------
// <copyright file="drawerCycleTimesTooltipFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';

export class DrawerCycleTimesTooltipFormatter {
  constructor(private data: any) {}

  get() {
    return (params: any) => this.format(params);
  }

  private format(params: any): string {
    const unit = this.isCuttingSeries(params) ? ` h` : ` s`;
    const cycles = this.getCyclesText(params);
    const formattedValue = `${i18n.n(params.value, { maximumFractionDigits: 2 })}${unit}${cycles}`;

    return this.getValueHtml(params, formattedValue);
  }

  private isCuttingSeries(seriesParam: any): boolean {
    return seriesParam.seriesName.includes('cutting');
  }

  private getCyclesText(seriesParam: any): string {
    if (seriesParam.name !== 'average') {
      // There's always 1 cycle for 'current'/'last', so it makes no sense to show it
      return '';
    }

    const seriesCycleFieldMap: any = {
      'lower-drawer-cutting': 'lower_drawer_inserted_cycles',
      'upper-drawer-cutting': 'upper_drawer_inserted_cycles',

      'lower-drawer-removed': 'lower_drawer_removed_cycles',
      'upper-drawer-removed': 'upper_drawer_removed_cycles',
    };

    const cyclesField = seriesCycleFieldMap[seriesParam.seriesName];
    const cycles = this.data[cyclesField];
    return ` (${cycles} ${i18n.tc('report.cycles', cycles)})`;
  }

  private getValueHtml(seriesParam: any, value: string): string {
    return `
    <div
      class="tooltip-series-value-row"
      data-testid="series-value-${seriesParam.seriesName}"
    >
      <div class="series-marker-name">
        ${seriesParam.marker}
        
        <span data-testid="series-name">
          ${i18n.t(`report.${seriesParam.name}`)}
        </span>
      </div>
      <div class="series-value" data-testid="series-value">
        ${value}
      </div>
    </div>
    `;
  }
}
