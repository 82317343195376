var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{staticClass:"update-target",on:{"submit":function($event){$event.preventDefault();return _vm.updateTarget()}}},[_c('b-field',[_c('targets-metrics-select',{attrs:{"console":_vm.console,"required":true,"disabled":true},model:{value:(_vm.targetMetric),callback:function ($$v) {_vm.targetMetric=$$v},expression:"targetMetric"}})],1),_c('b-field',{staticClass:"required",attrs:{"message":!_vm.validInputs[0] ? _vm.$t('target.target_value.not_valid') : '',"label":_vm.targetMetric === _vm.TargetsMetric.LaserStarveBlock ||
      _vm.targetMetric === _vm.TargetsMetric.TubeStarveBlock
        ? _vm.$t('target.target_value.starve')
        : _vm.$t('target.target_value.label'),"type":{ 'is-danger': !_vm.validInputs[0] }}},[_c('b-input',{attrs:{"required":""},on:{"input":function($event){return _vm.checkValidity($event, 0)}},model:{value:(_vm.targetValue1),callback:function ($$v) {_vm.targetValue1=$$v},expression:"targetValue1"}})],1),(
      _vm.targetMetric === _vm.TargetsMetric.LaserStarveBlock ||
      _vm.targetMetric === _vm.TargetsMetric.TubeStarveBlock
    )?_c('b-field',{attrs:{"message":!_vm.validInputs[1] ? _vm.$t('target.target_value.not_valid') : '',"label":_vm.targetMetric === _vm.TargetsMetric.LaserStarveBlock ||
      _vm.targetMetric === _vm.TargetsMetric.TubeStarveBlock
        ? _vm.$t('target.target_value.block')
        : _vm.$t('target.target_value.label'),"type":{ 'is-danger': !_vm.validInputs[1] }}},[_c('b-input',{attrs:{"required":""},on:{"input":function($event){return _vm.checkValidity($event, 1)}},model:{value:(_vm.targetValue2),callback:function ($$v) {_vm.targetValue2=$$v},expression:"targetValue2"}})],1):_vm._e(),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"action-button",attrs:{"disabled":!_vm.validInputs.every(Boolean),"icon-left":"content-save-outline","loading":_vm.isSubmitting},on:{"click":function($event){return _vm.updateTarget()}}},[_vm._v(" "+_vm._s(_vm.$t('target.title_update'))+" ")]),_c('b-button',{staticClass:"action-button",attrs:{"icon-left":"close","disabled":_vm.isSubmitting},on:{"click":function($event){return _vm.cancelUpdateTarget()}}},[_vm._v(" "+_vm._s(_vm.$t('action.cancel'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }