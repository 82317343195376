
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isNil } from '@/utils/misc';

@Component({
  methods: { isNil },
})
export default class ProgressBar extends Vue {
  @Prop({ required: true })
  private value: number | null | undefined;

  @Prop({ default: 100 })
  private max!: number;

  @Prop({ default: '#6a5ffe' })
  private color!: string;

  private get roundedSegmentEndPositionCSS(): any {
    const percentage = this.max !== 0 ? (this.value! / this.max) * 100 : 0;
    return { left: `${percentage}%` };
  }
}
