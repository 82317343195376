/*
 * --------------------------------------------------------------------------------
 * <copyright file="SortOrder.ts" company="Bystronic Laser AG">
 *  Copyright (C) Bystronic Laser AG 2021-2024
 * </copyright>
 * --------------------------------------------------------------------------------
 */

export enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}