// --------------------------------------------------------------------------------
// <copyright file="outputScrapChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { abbreviateNumber } from '@/utils/number';
import i18n from '@/i18n';
import { LaserOutputScrapData } from './chartsData';
import { EChartsOption } from 'echarts';
import { mockLaserOutputScrapData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { TooltipFormatter } from '@/models/Charts/tooltipFormatter';
import { getUnitTransform } from '@/utils/measurement';
import { metricsService } from '@/services/metrics.service';

export class OutputScrapChartGenerator extends ChartGenerator<LaserOutputScrapData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    return metricsService.getDevicesMetrics<LaserOutputScrapData[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserOutputScrapData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const currentOutputs: Map<string, number> = new Map<string, number>();
    const currentOutputsList: number[] = [];
    const currentScraps: Map<string, number> = new Map<string, number>();
    const currentScrapsList: number[] = [];
    const previousOutputs: Map<string, number> = new Map<string, number>();
    const previousOutputsList: number[] = [];
    const previousScraps: Map<string, number> = new Map<string, number>();
    const previousScrapsList: number[] = [];
    const materialGroups: Set<string> = new Set<string>();

    const unitTransform: (x: number) => number = getUnitTransform(!!parameters.convertToLbs);

    data.forEach((element) => {
      if (!materialGroups.has(element.group_name)) {
        materialGroups.add(element.group_name);
      }
      if (element.isprimary === 1) {
        currentOutputs.set(element.group_name, unitTransform(element.output));
        currentScraps.set(element.group_name, unitTransform(element.scrap));
      } else {
        previousOutputs.set(element.group_name, unitTransform(element.output));
        previousScraps.set(element.group_name, unitTransform(element.scrap));
      }
    });
    materialGroups.forEach((key: string) => {
      if (currentOutputs.has(key)) {
        currentOutputsList.push(currentOutputs.get(key)!);
      } else {
        currentOutputsList.push(0);
      }
      if (currentScraps.has(key)) {
        currentScrapsList.push(currentScraps.get(key)!);
      } else {
        currentScrapsList.push(0);
      }
      if (previousOutputs.has(key)) {
        previousOutputsList.push(previousOutputs.get(key)!);
      } else {
        previousOutputsList.push(0);
      }
      if (previousScraps.has(key)) {
        previousScrapsList.push(previousScraps.get(key)!);
      } else {
        previousScrapsList.push(0);
      }
    });
    const materialGroupsList = Array.from(materialGroups);

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: TooltipFormatter.build(parameters.timeAxisSpan)
          .withSeriesTranslationPrefix(undefined)
          .withTextOnMissingValue('-')
          .get(),
      },
      legend: {
        top: '91%',
        type: 'scroll',
        data: [
          i18n.t('report.output'),
          i18n.t('report.scrap'),
          i18n.t('report.previousoutput'),
          i18n.t('report.previousscrap'),
        ],
      },
      grid: {
        left: '3%',
        right: '10%',
        bottom: '10%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: materialGroupsList,
          axisLabel: {
            hideOverlap: true,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: parameters.yAxisName ?? '',
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
        },
      ],
      series: [
        {
          name: i18n.t('report.output').toString(),
          type: 'bar' as const,
          stack: 'Current',
          emphasis: {
            focus: 'series' as const,
          },
          data: currentOutputsList,
        },
        {
          name: i18n.t('report.scrap').toString(),
          type: 'bar' as const,
          stack: 'Current',
          emphasis: {
            focus: 'series' as const,
          },
          data: currentScrapsList,
        },
        {
          name: i18n.t('report.previousoutput').toString(),
          type: 'bar' as const,
          stack: 'Previous',
          emphasis: {
            focus: 'series' as const,
          },
          data: previousOutputsList,
        },
        {
          name: i18n.t('report.previousscrap').toString(),
          type: 'bar' as const,
          stack: 'Previous',
          emphasis: {
            focus: 'series' as const,
          },
          data: previousScrapsList,
        },
      ],
    };
  }

  override getMockData(): LaserOutputScrapData[] | null {
    return mockLaserOutputScrapData();
  }
}
