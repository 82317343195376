// --------------------------------------------------------------------------------
// <copyright file="devices.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Device } from '@/models/device';
import { DeviceBaseModel, DeviceModelType } from '@/models/deviceBaseModel';
import { responseHandler } from './response.handler';
import { DeviceStatus } from '@/models/deviceStatus';
import { User } from '@/models/user';
import { PersistentActions, PersistentGetters } from '@/store/persistent/enums';
import store from '@/store';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { usersService } from './users.service';
import { EquipmentEServiceData } from '@/views/careConsole/commons/model';
import { DeviceCuttingHeadVersionHistoryDTO } from '@/models/deviceCuttingHeadVersionHistoryDTO';

class DevicesService {
  store = new (class {
    get(): Device[] {
      return store.getters[PersistentGetters.GetDevices];
    }

    findByStringId(deviceId: string): Device | undefined {
      return this.get().find((device) => device.deviceId === deviceId);
    }

    cutDevices(): Device[] {
      return this.get().filter((device) => device.isCut());
    }

    cutDevicesIds(): string[] {
      return this.cutDevices().map((device) => device.deviceId);
    }

    bendDevices(): Device[] {
      return this.get().filter((device) => device.isBend());
    }

    tubeDevices(): Device[] {
      return this.get().filter((device) => device.isTube());
    }

    deviceIdsToIds(deviceIds: string[]): number[] {
      return this.get()
        .filter((device) => deviceIds.includes(device.deviceId))
        .map((device) => device.id);
    }

    models(): DeviceBaseModel[] {
      return store.getters[PersistentGetters.GetDeviceBaseModels];
    }

    laserModels(): DeviceBaseModel[] {
      return this.models().filter((model) => model.type === DeviceModelType.Laser);
    }

    bendModels(): DeviceBaseModel[] {
      return this.models().filter((model) => model.type === DeviceModelType.Bending);
    }

    tubeModels(): DeviceBaseModel[] {
      return this.models().filter((model) => model.type === DeviceModelType.Tubes);
    }

    bvcModels(): DeviceBaseModel[] {
      return this.models().filter((model) => model.code1 === 'BVC');
    }

    isBVC(modelId: string) {
      return devicesService.store.bvcModels().some((model) => model.deviceBaseModelId === modelId);
    }

    getDeviceBaseModelName(deviceBaseModelId: string): string {
      return (
        this.models().find((model) => model.deviceBaseModelId === deviceBaseModelId)!.name ?? ''
      );
    }

    async add(device: Device) {
      await this.updateAll([...this.get(), device]);
    }

    async updateAll(devices: Device[]) {
      return await store.dispatch(PersistentActions.SetDevices, devices);
    }
  })();

  private get currentUser(): User {
    return usersService.store.current();
  }

  async getAll(): Promise<Device[]> {
    return this.getAllHelper();
  }

  async getAllByCustomerId(customerId: number): Promise<Device[]> {
    return this.getAllHelper(customerId);
  }

  private async getAllHelper(tenantId?: number | null): Promise<Device[]> {
    const devices = await axios
      .get<Device[]>(`${BaseUrl}/devices`, {
        params: {
          ...(!!tenantId && { tenantId }),
        },
      })
      .then((response) => response.data.map((deviceData) => this.deserializeDevice(deviceData)));

    await this.store.updateAll(devices);
    return devices;
  }

  private deserializeDevice(deviceData: any): Device {
    responseHandler.handleDates(deviceData);
    return Device.GetCopy(deviceData);
  }

  async delete(deviceId: string): Promise<void> {
    return axios.delete(`${BaseUrl}/devices/${deviceId}`);
  }

  async update(device: Device): Promise<void> {
    return axios.put(`${BaseUrl}/devices/`, device);
  }

  async create(device: Device): Promise<void> {
    return axios.post(`${BaseUrl}/devices/`, device);
  }

  async getDeviceBaseModels(): Promise<DeviceBaseModel[]> {
    if (
      !(this.currentUser.consoles.includes(ConsoleEnum.WCC) || this.currentUser.isServiceUserType)
    ) {
      return Promise.resolve([]);
    }

    const response = await axios.get<DeviceBaseModel[]>(`${BaseUrl}/devices/devicebasemodels`);
    await store.dispatch(PersistentActions.SetDeviceBaseModels, response.data);
    return response.data;
  }

  async getDeviceBaseModelCount(deviceBaseModelId: string): Promise<number> {
    return axios
      .get<DeviceBaseModel[]>(`${BaseUrl}/devices/devicebasemodelcount/` + deviceBaseModelId)
      .then((response: any) => {
        return response.data.count;
      });
  }

  async getDevicesStatus(tenantId?: number | null): Promise<DeviceStatus[]> {
    return axios
      .get<Device[]>(`${BaseUrl}/devices/devicestatus` + (tenantId ? '?tenantId=' + tenantId : ''))
      .then((response: any) => {
        responseHandler.handleDates(response.data);
        return response.data;
      });
  }

  getEServiceData(customerIdDh?: number): Promise<EquipmentEServiceData[]> {
    return axios
      .get<EquipmentEServiceData[]>(`${BaseUrl}/devices/e-service-center`, {
        params: { customerIdDh },
      })
      .then((response) => response.data);
  }

  async getDevicesFromTenants(tenantIds: number[]): Promise<Device[]> {
    const devices = await this.getAll();
    return devices.filter((device) => tenantIds.includes(device.tenantId));
  }

  async getDeviceCuttingHeadVersionsHistory(
    tenantIdDh: number,
    deviceId: string,
    controller: AbortController,
  ): Promise<DeviceCuttingHeadVersionHistoryDTO[]> {
    return axios
      .get<DeviceCuttingHeadVersionHistoryDTO[]>(
        `${BaseUrl}/devices/cutting-head-version-history`,
        {
          params: {
            tenantIdDh,
            deviceId,
          },
          signal: controller.signal,
        },
      )
      .then((response) => response.data);
  }
}

export const devicesService = new DevicesService();
