
import { Component, Vue } from 'vue-property-decorator';
import { getLocaleFullName } from '@/locale/dictLocale';
import { localeService } from '@/library-services/locale.service';
import { currentLocale } from '@/utils/i18n';

/**
 * Shows a list of languages for the user to choose the UI language.
 */
@Component({
  methods: { getLocaleFullName },
})
export default class LanguageSelector extends Vue {
  private locales = localeService.availableLocales;

  private async setLocale(locale: string) {
    await localeService.setCurrentLocale(locale);
    this.$emit('select-language', locale);
    this.$emit('close');
  }

  private get currentLocale() {
    return currentLocale();
  }
}
