
import { CutCurrentWorkloadDataMessage, MessageLevel } from '@/models/Charts/chartsData';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableWithActions2 from '../tableWithActions/TableWithActions2.vue';
import moment from 'moment';
import { generateLocalizedCustomSearch } from '@/utils/table';

@Component({
  components: {
    TableWithActions2,
  },
  methods: {
    generateLocalizedCustomSearch,
  },
})
export default class CutCellControlActiveMessages extends Vue {
  @Prop({ required: true })
  private messages!: CutCurrentWorkloadDataMessage[];

  private getMessageTagType(row: CutCurrentWorkloadDataMessage) {
    if (row.messageLevel === MessageLevel.Error) {
      return 'is-danger';
    }
    if (row.messageLevel === MessageLevel.Warning) {
      return 'is-warning';
    }
    return 'is-info';
  }

  private formatTimestamp(timestamp: Date | null): string {
    if (!timestamp) {
      return '';
    }
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
  }
}
