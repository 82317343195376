
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { indexedWidgetDefinitions } from '@/utils/misc';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { FlexibleLayoutGridDefinition } from '@/views/dashboard/flexible-layout-grid';
import FlexibleLayoutGrid from '@/views/dashboard/FlexibleLayoutGrid.vue';

/**
 * Content of the Summary tab on the Bend page.
 *
 * Receives the Bend page's filter values as props to apply them to the widgets.
 */
@Component({
  components: {
    FlexibleLayoutGrid,
    WidgetContainer,
  },
})
export default class BendSummary extends Vue {
  @Prop({ required: true })
  private selectedDevices!: string[];

  @Prop({ required: true })
  private selectedTimespan!: FilterTimeSpanEnum;

  @Prop({ required: true })
  private selectedShifts!: number[];

  @Prop({ required: true })
  private selectedTimeAxisSpan!: FilterTimeAxisSpanEnum;

  private gridLayout = new FlexibleLayoutGridDefinition([
    [3, 3, 3, 3],
    [5, 7],
    [8, 4],
  ]);
  private widgetDefinitions: WidgetDefinition[] = [];
  private areWidgetDefinitionsReady = false;

  private mounted() {
    this.widgetDefinitions = this.createWidgetDefinitions();
    this.areWidgetDefinitionsReady = true;
  }

  private createWidgetDefinitions(): WidgetDefinition[] {
    return indexedWidgetDefinitions(
      [
        WidgetEnum.NumberOfBendsKpiWidget, // 'Bend Count Efficiency'
        WidgetEnum.BendsPerPartKpiWidget, // 'Peak Hourly Bending'
        WidgetEnum.BendingToolChangesKpiWidget, // 'Tool Changes'
        WidgetEnum.BendingProductionOutputKpiWidget,
        WidgetEnum.BendStatusOverview,
        WidgetEnum.BendProductivityFigures,
      ].map(
        (widgetEnum) =>
          new WidgetDefinition(
            widgetEnum,
            this.selectedDevices,
            this.selectedTimespan,
            this.selectedShifts,
            undefined,
            this.selectedTimeAxisSpan,
          ).hideInfo(),
      ),
    );
  }

  @Watch('selectedDevices')
  @Watch('selectedTimespan')
  @Watch('selectedShifts')
  @Watch('selectedTimeAxisSpan')
  private updateFilterOnWidgetDefinitions() {
    this.widgetDefinitions = this.widgetDefinitions.map((widgetDefinition) =>
      widgetDefinition.getCopy({
        deviceId: this.selectedDevices,
        timeFilter: this.selectedTimespan,
        shifts: this.selectedShifts,
        axisSpan: this.selectedTimeAxisSpan,
      }),
    );
  }
}
