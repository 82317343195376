// --------------------------------------------------------------------------------
// <copyright file="quotesConversionRatesGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import i18n from '@/i18n';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { QuotesConversionRatesData } from './chartsData';
import { EChartsOption, SeriesOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockQuotesConversionRatesData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export class QuotesConversionRatesGenerator extends ChartGenerator<QuotesConversionRatesData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    return metricsService.getSMBSMetricsBC<QuotesConversionRatesData[]>(
      this.procedure,
      this.tenantIdDh,
      { startDate, endDate, axisTimespan: timeAxisSpan, customerNo },
      this.controller,
    );
  }

  override updateOptions(
    data: QuotesConversionRatesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    // To show the y-axis from A (top) to C (bottom)
    const reversedData = data.slice().reverse();

    return {
      legend: {
        bottom: 0,
      },
      grid: {
        top: 16,
        bottom: 40,
        left: 8,
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        axisLabel: {
          formatter: (value: number) => i18n.n(value),
        },
      },
      yAxis: {
        data: reversedData.map((dataItem) => Object.values(dataItem)[0]),
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: (params: any) => {
          let tooltipMarkdown: string = `<p>${params[0].axisValueLabel}</p>`;
          params.forEach((item: any) => {
            if (!isNaN(item.value)) {
              tooltipMarkdown +=
                `<p>${item.marker} ${item.seriesName}:` +
                `<b style="margin-left:32px;float:right">${i18n.n(item.value, {
                  maximumFractionDigits: 2,
                })}</b></p>`;
            }
          });
          return tooltipMarkdown;
        },
      },
      series: this.generateSeries(reversedData),
    };
  }

  private generateSeries(data: QuotesConversionRatesData[]): SeriesOption[] {
    return Object.keys(data[0])
      .filter((key) => key !== 'category')
      .map((key) => ({
        name: i18n.t(`report.business_console.${key}`).toString(),
        type: 'bar',
        data: data.map((item) => item[key as keyof QuotesConversionRatesData]),
      }));
  }

  override getMockData(): QuotesConversionRatesData[] | null {
    return mockQuotesConversionRatesData();
  }
}
