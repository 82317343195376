// --------------------------------------------------------------------------------
// <copyright file="drawerCycleTimesGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { DrawerCycleTimesData } from './chartsData';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { DrawerCycleTimesTooltipFormatter } from '@/models/Charts/drawerCycleTimesTooltipFormatter';
import { metricsService } from '@/services/metrics.service';

export class DrawerCycleTimesGenerator extends ChartGenerator<DrawerCycleTimesData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string],
  ) {
    const deviceId = selectedDevices[0];
    return metricsService.getSSCMetrics<DrawerCycleTimesData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: timeSpan[0],
        dateTo: timeSpan[1],
      },
      this.controller,
    );
  }

  override updateOptions(
    data: DrawerCycleTimesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const chartDimensions = {
      width: '45%',
      height: '40%',
    };

    return {
      title: [
        // lower drawer
        {
          top: parameters.clientWidth! > 350 ? '2%' : '1%',
          left: 'center',
          text: i18n.t('report.lower-drawer').toString(),
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 18 : 14,
          },
        },
        {
          top: parameters.clientWidth! > 350 ? '1%' : '-1%',
          left: parameters.clientWidth! > 350 ? '25%' : '28%',
          textAlign: 'center',
          text: ' ',
          subtext: i18n.t('report.cutting_cycle_times').toString(),
          subtextStyle: {
            fontSize: parameters.clientWidth! > 350 ? 13 : 10.5,
          },
        },
        {
          top: parameters.clientWidth! > 350 ? '1%' : '-1%',
          left: parameters.clientWidth! > 350 ? '70%' : '65%',
          textAlign: 'center',
          text: ' ',
          subtext: i18n.t('report.removed_cycle_times').toString(),
          subtextStyle: {
            fontSize: parameters.clientWidth! > 350 ? 13 : 10.5,
          },
        },
        // upper drawer
        {
          top: parameters.clientWidth! > 350 ? '52%' : '51%',
          left: 'center',
          text: i18n.t('report.upper-drawer').toString(),
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 18 : 14,
          },
        },
        {
          top: parameters.clientWidth! > 350 ? '51%' : '49%',
          left: parameters.clientWidth! > 350 ? '25%' : '28%',
          textAlign: 'center',
          text: ' ',
          subtext: i18n.t('report.cutting_cycle_times').toString(),
          subtextStyle: {
            fontSize: parameters.clientWidth! > 350 ? 13 : 10.5,
          },
        },
        {
          top: parameters.clientWidth! > 350 ? '51%' : '49%',
          left: parameters.clientWidth! > 350 ? '70%' : '65%',
          textAlign: 'center',
          text: ' ',
          subtext: i18n.t('report.removed_cycle_times').toString(),
          subtextStyle: {
            fontSize: parameters.clientWidth! > 350 ? 13 : 10.5,
          },
        },
      ],
      grid: [
        {
          top: '8%',
          left: 0,
          ...chartDimensions,
          containLabel: true,
        },
        {
          top: '8%',
          right: 40,
          ...chartDimensions,
          containLabel: true,
        },
        {
          top: '55%',
          left: 0,
          ...chartDimensions,
          containLabel: true,
        },
        {
          top: '55%',
          right: 40,
          ...chartDimensions,
          containLabel: true,
        },
      ],
      tooltip: {
        confine: true,
        formatter: new DrawerCycleTimesTooltipFormatter(data[0]).get(),
      },
      xAxis: Array(4)
        .fill(0)
        .map((_, i) => ({
          gridIndex: i,
          type: 'category',
          data:
            parameters.clientWidth! > 350
              ? [
                  'average',
                  // Left column -> Cutting cycles (current)
                  // Right column -> Cycles in removed state (last)
                  i % 2 === 0 ? 'current_cycle' : 'last_cycle',
                ]
              : ['average_abbr', i % 2 === 0 ? 'current_abbr' : 'last'],
          axisLabel: {
            fontSize: parameters.clientWidth! > 350 ? 13 : 10.5,
            formatter: (value: any) => i18n.t(`report.${value}`),
          },
        })),
      yAxis: Array(4)
        .fill(0)
        .map((_, i) => ({
          gridIndex: i,
          type: 'value',
          show: false,
        })),
      series: this.generateSeries(data, parameters.clientWidth!),
    };
  }

  private generateSeries(data: DrawerCycleTimesData[], clientWidth: number): BarSeriesOption[] {
    return [
      { drawer: 'lower', status: 'cutting', unit: 'hour' },
      { drawer: 'lower', status: 'removed', unit: 'second' },
      { drawer: 'upper', status: 'cutting', unit: 'hour' },
      { drawer: 'upper', status: 'removed', unit: 'second' },
    ].map(({ drawer, status, unit }, i) => ({
      data: this.getSeriesData(drawer, status, unit, data[0]),
      type: 'bar' as const,
      name: `${drawer}-drawer-${status}`,
      label: {
        show: true,
        fontSize: clientWidth > 350 ? 13 : 10,
        fontWeight: 400,
        position: clientWidth > 350 ? ('inside' as const) : ('insideBottomRight' as const),
        rotate: clientWidth > 350 ? 0 : 90,
        align: clientWidth > 350 ? 'center' : 'left',
        formatter: DrawerCycleTimesGenerator.labelFormatter.bind(null, unit),
      },
      xAxisIndex: i,
      yAxisIndex: i,
    }));
  }

  private getSeriesData(
    drawer: string,
    status: string,
    unit: string,
    dataItem: DrawerCycleTimesData,
  ) {
    const averageField = `${drawer}_drawer_${status}_${unit}_avg` as keyof DrawerCycleTimesData;
    const currentField = `${drawer}_drawer_${status}_${unit}s` as keyof DrawerCycleTimesData;

    return [
      {
        value: dataItem[averageField],
        itemStyle: {
          color: status === 'cutting' ? '#22b14c' : '#a349a4',
        },
      },
      {
        value: dataItem[currentField],
        itemStyle: {
          color: status === 'cutting' ? '#b4e61c' : '#c5bbe6',
        },
      },
    ];
  }

  private static labelFormatter(unit: string, params: any): string {
    const formattedTime = i18n.n(params.data.value).toString();
    return i18n.t(`report.${unit}s_value`).toString().replace('%1', formattedTime);
  }
}
