// --------------------------------------------------------------------------------
// <copyright file="technologyOverviewFiguresRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

// tslint:disable: member-access
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DataRetriever } from '../abstract/chartGenerator';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { metricsService } from '@/services/metrics.service';

export interface TechnologyOverviewFigures {
  HourCutting: number;
  HourICP: number;
  HourPowerOn: number;
  MinuteGlassRemoved: number;
  ProtGlassCycles: number;
  ProtGlasTopCycles: number; // This is not a typo
  ProtGlasTopRemovedMins: number; // This isn't a typo either
}

export class TechnologyOverviewFiguresRetriever extends DataRetriever<TechnologyOverviewFigures> {
  constructor(procedure: WidgetEnum, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string] | FilterTimeSpanEnum,
  ): Promise<TechnologyOverviewFigures | null> {
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics(
      this.procedure,
      this.tenantIdDh,
      selectedDevices[0],
      {
        dateFrom,
        dateTo,
      },
      this.controller,
    );
  }
}
