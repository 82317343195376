
import { Component, Prop, Vue } from 'vue-property-decorator';
import { devicesService } from '@/services/devices.service';
import TenantsSelect from '../inputs/TenantsSelect.vue';
import { Tenant } from '@/models/tenant';
import { Device } from '@/models/device';
import { DeviceBaseModel, DeviceModelType } from '@/models/deviceBaseModel';
import { Mode } from '@/store/enums';
import { User } from '@/models/user';
import { UserType } from '@/models/userType';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import LocationsSelect from '@/components/inputs/LocationsSelect.vue';
import moment from 'moment';
import { usersService } from '@/services/users.service';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { today } from '@/utils/dates';
import i18n from '@/i18n';
import { isNil } from '@/utils/misc';
import ShiftModelSelect from '@/components/inputs/ShiftModelSelect.vue';
import RequiredFieldsHint from '../forms/RequiredFieldsHint.vue';

const IP_WITH_OPTIONAL_PORT_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?::(\d|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]))?$/;

@Component({
  methods: { isNil },
  components: {
    RequiredFieldsHint,
    ShiftModelSelect,
    TenantsSelect,
    LocationsSelect,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class DeviceFormModal extends Vue {
  @Prop({ required: true })
  private mode!: Mode;

  @Prop()
  private tenant?: Tenant;

  @Prop()
  private deviceIn?: Device;

  private isSubmitting = false;

  private formData: Partial<Device> = {
    name: '',
    tenantId: 0,
    locationId: 0,
    deviceBaseModelId: '',
    billable: true,
    hasWCC: false,
    hasTechnology: false,
    hasCare: false,
    ip: '',
    sscIp: '',
    equipmentId: '',
    cameraIp: '',
    startDate: undefined,
    endDate: undefined,
    technologyStartDate: undefined,
    technologyEndDate: undefined,
    careStartDate: undefined,
    careEndDate: undefined,
    shiftModelId: undefined,
  };
  private sameDeviceBaseModelCount = 0;
  private dateTimeSegment: string | undefined = undefined;

  private get validationRules() {
    return {
      name: {
        required: true,
        regex: /^[\w\p{L} .,-]{1,80}$/u,
      },
      tenantId: 'required',
      location: 'required',
      baseModelName: 'required',
      ip: {
        required: true,
        regex: IP_WITH_OPTIONAL_PORT_REGEX,
      },
      sscIp: {
        required: true,
        regex: IP_WITH_OPTIONAL_PORT_REGEX,
      },
      equipmentId: {
        required: true,
        max: 15,
      },
      cameraIp: {
        required: false,
        regex: IP_WITH_OPTIONAL_PORT_REGEX, // With regular expression no max validation is needed
      },
    };
  }

  private async created() {
    if (this.deviceIn) {
      this.formData = Device.GetCopy(this.deviceIn) as any;
      this.formData.shiftModelId ??= -1;
    } else if (this.isCreateMode) {
      this.formData.id = 0; // FIXME: this shouldn't be needed
      // !: Creation is only allowed from Devices tab from a tenant, so
      // this.tenant must be defined.
      this.formData.tenantId = this.tenant!.id;
    }
  }

  private get actionTitle() {
    if (this.isCreateMode) {
      return i18n.t('action.create').toString();
    } else if (this.isEditMode) {
      if (!this.canEditEquipmentNameAndShift) {
        return i18n.t('action.view').toString();
      } else {
        return i18n.t('action.save').toString();
      }
    } else {
      return i18n.t('action.close').toString();
    }
  }

  private get calculatedDeviceId(): string {
    if (this.isCreateMode && this.tenant) {
      let selectedDeviceBaseModel: DeviceBaseModel | undefined;
      let typePrefix = '';
      if (this.formData.deviceBaseModelId) {
        selectedDeviceBaseModel = this.storeDeviceBaseModels.find(
          (dm) => dm.deviceBaseModelId === this.formData.deviceBaseModelId,
        );
        typePrefix = selectedDeviceBaseModel?.type.charAt(0).toUpperCase() + '-';
      }
      let tenantPrefix = '';
      if (this.formData.tenantId !== undefined && this.formData.tenantId > 0) {
        tenantPrefix = this.tenant.tenantIdDh.toString().padStart(4, '0').substring(0, 4) + '-';
      }

      this.dateTimeSegment = this.dateTimeSegment ?? moment().format('YYYYMMDDHHmm-');

      return (
        typePrefix +
        tenantPrefix +
        this.dateTimeSegment +
        (this.sameDeviceBaseModelCount + 1).toString().padStart(3, '0')
      );
    } else if (this.isEditMode) {
      return this.formData.deviceId!;
    } else {
      return i18n.t('device.name').toString();
    }
  }

  private async handleDeviceBaseModelIdSelected(modelId: string) {
    this.sameDeviceBaseModelCount = await devicesService.getDeviceBaseModelCount(
      this.formData.deviceBaseModelId!,
    );

    if (!devicesService.store.isBVC(modelId)) {
      this.formData.hasTechnology = false;
      this.formData.hasCare = false;
    }
  }

  private onWorkCenterSwitchChanged(isEnabled: boolean) {
    if (isEnabled) {
      this.formData.endDate = null;
      this.formData.startDate = today();
    }
  }

  private onTechnologySwitchChanged(isEnabled: boolean) {
    if (isEnabled) {
      this.formData.technologyEndDate = null;
      this.formData.technologyStartDate = today();
    }
  }

  private onLocationInput(location: number) {
    this.$set(this.formData, 'locationId', location);
  }

  private onCareSwitchChanged(isEnabled: boolean) {
    if (isEnabled) {
      this.formData.careEndDate = null;
      this.formData.careStartDate = today();
      this.enableWorkCenterConsole();
    }
  }

  private enableWorkCenterConsole() {
    this.formData.hasWCC = true;
    this.onWorkCenterSwitchChanged(true);
  }

  private async onSubmit() {
    if (!this.isBVCDevice) {
      this.formData.sscIp = undefined;
    }

    const device = this.createDeviceFromFormData(this.formData);

    this.isSubmitting = true;
    if (this.isCreateMode) {
      device.deviceId = this.calculatedDeviceId;

      devicesService
        .create(device)
        .then(() => {
          this.$emit('save');
          this.$emit('close');
        })
        .finally(() => (this.isSubmitting = false));
    } else if (this.isEditMode) {
      devicesService
        .update(device)
        .then(() => {
          this.$emit('save');
          this.$emit('close');
        })
        .finally(() => (this.isSubmitting = false));
    }
  }

  private createDeviceFromFormData(data: Partial<Device>): Device {
    return new Device(
      data.id!, // Not from the form, but from deviceIn
      data.deviceId!,
      data.name!,
      data.tenantId!,
      data.tenantName!, // Not from the form, but from deviceIn
      data.deviceBaseModelId!,
      data.deviceBaseModelName!, // Not from the form, but from deviceIn
      data.ip!,
      data.equipmentId!,
      data.hasWCC!,
      data.locationId!,
      data.hasCare!,
      data.hasTechnology!,
      data.billable,
      data.cameraIp,
      data.sscIp,
      data.startDate,
      data.endDate,
      data.technologyStartDate,
      data.technologyEndDate,
      data.careStartDate,
      data.careEndDate,
      data.status, // Not from the form, but from deviceIn
      data.shiftModelId === -1 ? null : data.shiftModelId,
    );
  }

  private get canToggleWorkCenterConsole(): boolean {
    return this.canEditEquipment && !this.formData.hasCare;
  }

  private get canEnableCareConsole(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get canEnableTechnologyConsole(): boolean {
    return this.canEnableCareConsole;
  }

  private get canEditEquipment(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get canEditEquipmentNameAndShift(): boolean {
    return hasCurrentUserPermission(UserType.CustomerAdmin);
  }

  private get currentUser(): User {
    return usersService.store.current();
  }

  private get isBVCDevice(): boolean {
    return devicesService.store.isBVC(this.formData.deviceBaseModelId!);
  }

  private get storeDeviceBaseModels(): DeviceBaseModel[] {
    return devicesService.store.models();
  }

  private get deviceModelTypes(): string[] {
    return Object.values(DeviceModelType);
  }

  private onDateInput(date: Date | null, field: keyof Device) {
    if (!date) {
      return;
    }

    (this.formData as any)[field] = this.removeTimezoneOffset(date);
  }

  private removeTimezoneOffset(date: Date): Date {
    return moment(date).subtract(date.getTimezoneOffset(), 'minutes').toDate();
  }

  private getDeviceModelsByType(value: DeviceModelType): DeviceBaseModel[] {
    switch (value) {
      case DeviceModelType.Laser:
        return devicesService.store.laserModels();
      case DeviceModelType.Bending:
        return devicesService.store.bendModels();
      case DeviceModelType.Tubes:
        return devicesService.store.tubeModels();
      default:
        return [];
    }
  }

  private get canClearEndDate(): boolean {
    return !isNil(this.formData.endDate) && this.canEditEquipment;
  }

  private get canClearTechnologyEndDate(): boolean {
    return !isNil(this.formData.technologyEndDate) && this.canEditEquipment;
  }

  private get canClearCareEndDate(): boolean {
    return !isNil(this.formData.careEndDate) && this.canEditEquipment;
  }

  private get isEditMode(): boolean {
    return this.mode === Mode.Edit;
  }

  private get isCreateMode(): boolean {
    return this.mode === Mode.Create;
  }
}
