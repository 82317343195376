// --------------------------------------------------------------------------------
// <copyright file="eventTypeByTimeGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { ImportantMessageLevel, EventTypeByTimeData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { isCategoryXAxis } from '@/utils/charts';
import { XAXisOption } from 'echarts/types/dist/shared';
import { isEmpty } from '@/utils/misc';
import { uniq } from '@/utils/array';
import { EventColor } from '@/utils/color';
import { tooltipFormatter } from '@/models/Charts/eventTypeByTimeTooltipFormatter';
import { metricsService } from '@/services/metrics.service';

export class EventTypeByTimeGenerator extends ChartGenerator<EventTypeByTimeData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];
    const eventType = metricsService.getEventTypeParameter(params?.eventTypes);

    return metricsService.getSSCMetrics<EventTypeByTimeData[]>(
      this.procedure,
      this.tenantIdDh,
      selectedDevices[0],
      {
        // These are optional:
        dateFrom,
        dateTo,
        dateGrouping: timeAxisSpan,
        messageLevel: eventType,
        modules: params?.eventModules,
        eventCodes: params?.eventCodes,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: EventTypeByTimeData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);
    const selectedEventTypes = this.getSelectedEventTypes(parameters.paramValues?.eventTypes);

    const dates = uniq(data.map((item: EventTypeByTimeData) => item.date));

    return {
      grid: {
        top: 8,
        bottom: 40,
        left: 8,
        right: 8,
        containLabel: true,
      },
      legend: {
        show: true,
        bottom: 0,
        selectedMode: selectedEventTypes.length > 1,
        formatter: (name: string) => this.translateSeriesName(name),
      },
      dataZoom: [
        {
          type: 'inside',
        },
      ],
      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        axisLabel: {
          hideOverlap: true,
        },
        data: isCategoryAxis ? dates : undefined,
      } as XAXisOption, // type=category doesn't have data property
      yAxis: {
        minInterval: 1,
        boundaryGap: [0, 0.01],
        axisLabel: {
          formatter: (value: number) => i18n.n(value),
        },
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: (params: any) => tooltipFormatter(params, data, parameters.timeAxisSpan!),
      },
      series: this.series(data, isCategoryAxis, selectedEventTypes),
    };
  }

  private getSelectedEventTypes(eventTypes?: ImportantMessageLevel[]): ImportantMessageLevel[] {
    return eventTypes === undefined || isEmpty(eventTypes)
      ? Object.values(ImportantMessageLevel) // all by default
      : eventTypes;
  }

  private series(
    data: EventTypeByTimeData[],
    isCategoryAxis: boolean,
    selectedEventTypes: ImportantMessageLevel[],
  ): BarSeriesOption[] {
    const eventTypeColors = {
      [ImportantMessageLevel.Warning]: EventColor.Warning,
      [ImportantMessageLevel.Error]: EventColor.Error,
    };

    return selectedEventTypes.map((eventType) => ({
      type: 'bar',
      name: eventType,
      color: eventTypeColors[eventType],
      emphasis: {
        focus: 'series',
      },
      stack: 'total',
      data: this.getTypeEventData(data, eventType, isCategoryAxis),
    }));
  }

  private getTypeEventData(
    data: EventTypeByTimeData[],
    type: string,
    isCategoryAxis: boolean,
  ): Array<[string, number] | number> {
    return data
      .filter((item, index) => {
        // Avoid having two data points for different types and same date.
        // See 'Override test' unit test to better understand this.
        const currentItemOverridesPrevious =
          item.date === data?.[index - 1]?.date && type !== item.type;
        const currentItemOverridesNext =
          item.date === data?.[index + 1]?.date && type !== item.type;
        return !currentItemOverridesPrevious && !currentItemOverridesNext;
      })
      .map((item) => {
        // Avoids the area continuing up until the next data point
        const eventsCount = item.type === type ? item.count : 0;

        return isCategoryAxis ? eventsCount : [item.date, eventsCount];
      });
  }

  private translateSeriesName(name: string) {
    return i18n.t(`report.${name.toLowerCase()}_plural`).toString();
  }
}
