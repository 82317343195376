
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceView } from '@/views/careConsole/commons/navigation';

@Component({
  computed: {
    DeviceView: () => DeviceView,
  },
})
export default class DeviceViewSwitch extends Vue {
  @Prop({ default: DeviceView.Care })
  private value!: DeviceView | null;

  private internalValue: DeviceView | null = null;

  created() {
    this.internalValue = this.value;
  }
}
