
import { Component, Watch } from 'vue-property-decorator';
import GenericParam from './GenericParam.vue';
import { ParamSlider } from '@/models/enums/WidgetEnum';
import { abbreviateNumber } from '@/utils/number';
import { isNil } from '@/utils/misc';

@Component({})
export default class SliderParam extends GenericParam<ParamSlider> {
  private get rangeIndexes() {
    if (!isNil(this.paramValue)) {
      // The param value is expected to be Record<number, number>,
      // where the key is the index of the slider tick and the value is the value of the tick.
      // The emitted data has the same schema.
      return Object.keys(this.paramValue).map((x) => Number(x));
    }
    return this.paramInfo.defaultValue ?? [this.minTickIndex, this.maxTickIndex];
  }

  private get minTickIndex(): number {
    return 0;
  }

  private get maxTickIndex(): number {
    return this.paramInfo.tickValues.length - 1;
  }

  private tickIndexToValue(tickIndex: number) {
    return this.paramInfo.tickValues[tickIndex];
  }

  private formatNumber(tickIndex: number) {
    return abbreviateNumber(this.tickIndexToValue(tickIndex));
  }

  private sendUpdate(rangeIndexes: number[]) {
    if (rangeIndexes === this.rangeIndexes) {
      return;
    }

    this.$emit('update', {
      [this.paramName]: Object.fromEntries(
        rangeIndexes.map((index) => [index, this.tickIndexToValue(index)]),
      ),
    });
  }

  @Watch('paramInfo')
  private onParentParameterChanged(newParams: ParamSlider, oldParams: ParamSlider) {
    if (this.isFullRangeSelected(oldParams)) {
      this.sendUpdate([this.rangeIndexes[0], this.maxTickIndex]);
    } else {
      const maxIndex =
        this.rangeIndexes[1] <= this.maxTickIndex ? this.rangeIndexes[1] : this.maxTickIndex;
      const minIndex = this.rangeIndexes[0] < maxIndex ? this.rangeIndexes[0] : maxIndex - 1;
      this.sendUpdate([minIndex, maxIndex]);
    }
  }

  private isFullRangeSelected(oldParams: ParamSlider): boolean {
    return this.rangeIndexes[0] === 0 && this.rangeIndexes[1] === oldParams.tickValues.length - 1;
  }
}
