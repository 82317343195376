// --------------------------------------------------------------------------------
// <copyright file="multiLineChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import i18n from '@/i18n';
import { EChartsOption } from 'echarts';
import { abbreviateNumber } from '@/utils/number';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { LineChartMode } from '../enums/LineChartMode';
import { LaserOutputScrapHistoricalData } from './chartsData';
import { mockLaserOutputScrapHistData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { isCategoryXAxis, getTooltipTitle } from '@/utils/charts';
import { uniq } from '@/utils/array';
import { groupByDate } from '@/utils/dates';
import { AxisPointerLabelFormatter } from '@/models/Charts/axisPointerLabelFormatter';
import { getUnitTransform } from '@/utils/measurement';
import { metricsService } from '@/services/metrics.service';

/*
  Extra options:
    upperLineName: string
    lowerLineName: string
    groupKey: string
*/
export class MultiLineChartGenerator extends ChartGenerator<any> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum | number,
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];

    return metricsService.getDevicesMetrics<any[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        startDate,
        endDate,
        timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: any[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const groups: string[] = Array.from(new Set(data.map((x: any) => x[parameters.groupKey!])));

    const stackedColumns = parameters.paramValues?.mode === LineChartMode.StackedColumns;
    const type = stackedColumns ? 'bar' : ('line' as const);
    const stack = stackedColumns ? ('total' as const) : undefined;

    const yAxisUnit = 'yAxisName' in parameters ? ' ('.concat(parameters.yAxisName!, ')') : '';

    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);

    let xAxisValues: string[] = isCategoryAxis ? data.map((x) => x.date) : [];
    xAxisValues = [...new Set(xAxisValues)];

    const upperLineName = parameters.upperLineName!;
    const lowerLineName = parameters.lowerLineName!;

    const byDateData = groupByDate(
      data,
      this.getDates(data),
      (sourceValuesObject, currentItem) => ({
        ...sourceValuesObject,
        [currentItem.group_name]: {
          [upperLineName]: currentItem[upperLineName],
          [lowerLineName]: currentItem[lowerLineName],
        },
      }),
    );

    const averageMark: any[] = [];
    const markPointData: any[] = [];

    if (parameters.aggregates) {
      for (const aggregate of parameters.aggregates) {
        if (aggregate === 'Max' || aggregate === 'Min') {
          markPointData.push({ name: '', type: aggregate.toLowerCase() });
        } else {
          averageMark.push({ type: 'average', itemStyle: { color: 'rgba(207, 90, 90, 1)' } });
        }
      }
    }
    return {
      title: {
        show: false,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
            formatter: new AxisPointerLabelFormatter(parameters.timeAxisSpan, isCategoryAxis).get(),
          },
          animation: false,
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: this.formatter(parameters.timeAxisSpan!, isCategoryAxis),
      },
      legend: {
        top: '91%',
        type: 'scroll',
        data: groups,
        // Avoid overriding enabled legend items if updateOptions is called on legend change
        selected: parameters.legendParams?.selected ?? {},
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
        label: {
          fontSize: 5,
        },
      },
      dataZoom: [
        {
          show: false,
          realtime: true,
          height: '2',
          xAxisIndex: [0, 1],
          top: '95%',
        },
        {
          type: 'inside',
          realtime: true,
          xAxisIndex: [0, 1],
        },
      ],
      grid: [
        {
          left: '50',
          right: '50',
          top: '30',
          bottom: '55%',
        },
        {
          left: '50',
          right: '50',
          top: '58%',
          bottom: '50',
        },
      ],
      xAxis: [
        {
          type: isCategoryAxis ? 'category' : 'time',
          data: isCategoryAxis ? xAxisValues : undefined,
          name: 'xAxisName' in parameters ? parameters.xAxisName : '',
          boundaryGap: false,
          axisLine: { onZero: true },
          axisLabel: { margin: 17 },
        },
        {
          gridIndex: 1,
          type: isCategoryAxis ? 'category' : 'time',
          data: isCategoryAxis ? xAxisValues : undefined,
          boundaryGap: false,
          axisLine: { onZero: true },
          axisLabel: { show: false },
        },
      ],
      yAxis: [
        {
          name: `Output${yAxisUnit}`,
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#f4f4f4', // default #ccc
              width: 1,
            },
          },
          nameTextStyle: {
            fontWeight: 'bold',
          },
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
        },
        {
          gridIndex: 1,
          name: `Scrap${yAxisUnit}`,
          type: 'value',
          inverse: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#f4f4f4',
              shadowColor: '#efefef',
              width: 1,
            },
          },
          nameTextStyle: {
            fontWeight: 'bold',
          },
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
        },
      ],
      series: groups.sort().flatMap((group: string) => [
        // Upper half series
        {
          name: group,
          type,
          stack,
          symbolSize: 8,
          data: this.getGroupData(
            byDateData,
            group,
            upperLineName,
            isCategoryAxis,
            parameters.convertToLbs,
          ),
          markPoint: {
            data: markPointData,
            label: {
              formatter: (params: any) => i18n.n(params.value, { maximumFractionDigits: 2 }),
            },
          },
          markLine: {
            data: averageMark,
            label: {
              formatter: (params: any) => i18n.n(params.value, { maximumFractionDigits: 2 }),
            },
          },
        },
        // Lower half series
        {
          name: group,
          type,
          stack: !!stack ? `${stack}_1` : undefined,
          xAxisIndex: 1,
          yAxisIndex: 1,
          symbolSize: 8,
          data: this.getGroupData(
            byDateData,
            group,
            lowerLineName,
            isCategoryAxis,
            parameters.convertToLbs,
          ),
          markPoint: {
            data: markPointData,
            label: {
              formatter: (params: any) => i18n.n(params.value, { maximumFractionDigits: 2 }),
            },
          },
          markLine: {
            data: averageMark,
            label: {
              formatter: (params: any) => i18n.n(params.value, { maximumFractionDigits: 2 }),
            },
          },
        },
      ]),
    };
  }

  private getGroupData(
    byDateData: any[],
    group: string,
    valueKey: string,
    isCategoryAxis: boolean,
    convertToLbs: boolean = false,
  ) {
    const unitTransform: (x: number) => number = getUnitTransform(convertToLbs);
    if (isCategoryAxis) {
      return byDateData.map((item) => unitTransform(item[group]?.[valueKey] ?? 0));
    } else {
      return byDateData.map((item) => [item.date, unitTransform(item[group]?.[valueKey] ?? 0)]);
    }
  }

  private getDates(data: any[]): string[] {
    return uniq(data.map((item) => item.date));
  }

  override getMockData(): LaserOutputScrapHistoricalData[] | null {
    return mockLaserOutputScrapHistData();
  }

  private formatter(timeAxisSpan: FilterTimeAxisSpanEnum, isCategoryAxis: boolean) {
    return (params: any) => {
      const res = `<p>${getTooltipTitle(params[0].axisValue, timeAxisSpan, isCategoryAxis)}</p>`;
      let output: boolean = false;
      let scrap: boolean = false;
      let outputPart = `<p><b>${i18n.t('report.output')}</b></p>`;
      let scrapPart = `<p><b>${i18n.t('report.scrap')}</b></p>`;
      params.forEach((item: any) => {
        if (item.value !== undefined) {
          const value = isNaN(item.value[1]) ? item.value : item.value[1];
          if (item.axisIndex === 0) {
            output = true;
            outputPart +=
              `<p>${item.marker} ${item.seriesName}:` +
              `<b style="margin-left:32px;float:right">${i18n.n(value, {
                maximumFractionDigits: 2,
              })}</b></p>`;
          } else {
            scrap = true;
            scrapPart +=
              `<p>${item.marker} ${item.seriesName}:` +
              `<b style="margin-left:32px;float:right">${i18n.n(value, {
                maximumFractionDigits: 2,
              })}</b></p>`;
          }
        }
      });
      return `${res}${output ? outputPart : ''}${scrap ? scrapPart : ''}`;
    };
  }
}
