// --------------------------------------------------------------------------------
// <copyright file="mapGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { DataRetriever } from '../Charts/abstract/chartGenerator';

export type Coordinate = [number, number];

export interface Marker {
  coordinates: Coordinate;
  tooltip?: () => string;
  radius?: number;
  color?: string;
  zIndexOffset?: number;
}

export interface MapData {
  markers: Marker[];
}

export class NoDataException extends Error {}

export default abstract class MapGenerator extends DataRetriever<MapData> {}
