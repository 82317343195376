// --------------------------------------------------------------------------------
// <copyright file="salesCustomerGroupsGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { GeneratorParams } from './generatorParams';
import { getColor } from '@/utils/color';
import moment from 'moment';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { SalesCustomerGroupsData } from './chartsData';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockSalesCustomerGroupsData } from './mockWidgetSelectorData';
import { metricsService } from '@/services/metrics.service';

export default class SalesCustomerGroupsGenerator extends ChartGenerator<
  SalesCustomerGroupsData[]
> {
  private readonly levels = [
    {
      itemStyle: {
        gapWidth: 5,
      },
    },
    {
      itemStyle: {
        gapWidth: 1,
      },
    },
  ];

  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    return metricsService.getSMBSMetricsBC<SalesCustomerGroupsData[]>(
      this.procedure,
      this.tenantIdDh,
      { date: moment().format('YYYY-MM-DD'), startDate, endDate },
      this.controller,
    );
  }

  override updateOptions(
    data: SalesCustomerGroupsData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const categories = ['A', 'B', 'C'];
    return {
      title: {
        show: false,
      },
      legend: {
        show: true,
        type: 'scroll',
        itemStyle: {
          color: '#5e5e5e',
        },
        bottom: 0,
        data: [
          i18n.t('report.business_console.sold_amt'),
          i18n.t('report.business_console.invoiced_amt'),
          i18n.t('report.business_console.paid_amt'),
        ],
        selectedMode: 'single',
      },
      tooltip: {
        confine: true,
        formatter: (params: any) => {
          if (!params.data?.path) {
            // root node doesn't have a path
            return '';
          }

          const { name, path } = params.data;
          const [category] = path.split('/');
          return `
            ${name}&nbsp;<span style="font-size: 12px;">
              ${i18n.t('report.category', { category })}
            </span>
          `;
        },
      },
      series: [
        {
          name: i18n.t('report.business_console.sold_amt').toString(),
          type: 'treemap',
          levels: this.levels,
          breadcrumb: {
            top: 0,
            bottom: 'auto',
          },
          leafDepth: 3,
          data: categories.map((category, i) => ({
            name: category,
            path: category,
            children: data
              .filter((entry) => entry.category_sales === category)
              .map((entry) => ({
                value: entry.sales,
                name: entry.customer,
                path: `${category}/${entry.customer}`,
              })),
            itemStyle: {
              color: getColor(i, 0),
            },
          })),
        },
        {
          name: i18n.t('report.business_console.invoiced_amt').toString(),
          type: 'treemap',
          levels: this.levels,
          breadcrumb: {
            top: 0,
            bottom: 'auto',
          },
          data: categories.map((category, i) => ({
            name: category,
            path: category,
            children: data
              .filter((entry) => entry.category_invoice === category)
              .map((entry) => ({
                value: entry.sales_invoiced,
                name: entry.customer,
                path: `${category}/${entry.customer}`,
              })),
            itemStyle: {
              color: getColor(i, 0),
            },
          })),
        },
        {
          name: i18n.t('report.business_console.paid_amt').toString(),
          type: 'treemap',
          levels: this.levels,
          breadcrumb: {
            top: 0,
            bottom: 'auto',
          },
          data: categories.map((category, i) => ({
            name: category,
            path: category,
            children: data
              .filter((entry) => entry.category_paid === category)
              .map((entry) => ({
                value: entry.invoices_paid,
                name: entry.customer,
                path: `${category}/${entry.customer}`,
              })),
            itemStyle: {
              color: getColor(i, 0),
            },
          })),
        },
      ],
    };
  }

  override getMockData(): SalesCustomerGroupsData[] | null {
    return mockSalesCustomerGroupsData();
  }
}
