
import { Component, Vue, Prop, Ref, Emit } from 'vue-property-decorator';
import TimeAxisSpanButton from '@/components/TimeAxisSpanButton.vue';
import TargetsMenu from '@/components/TargetsMenu.vue';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import AggregateButton from '@/components/AggregateButton.vue';
import ThreeDotParamEntry from './ThreeDotParamEntry.vue';
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import QuickSearchItem from '@/models/metrics/quickSearchItem';
import { Params } from '@/models/enums/WidgetEnum';

@Component({
  components: {
    'timeaxisspan-button': TimeAxisSpanButton,
    TargetsMenu,
    AggregateButton,
    ThreeDotParamEntry,
    'quick-search-button': () => import('../QuickSearchButton.vue'),
  },
})
export default class ThreeDotsOptions extends Vue {
  @Prop({ default: false })
  private hasAxisSpanFilter!: boolean;

  @Prop({ default: false })
  private hasDownload!: boolean;

  @Prop({ default: false })
  private hasAggregates!: boolean;

  @Prop({ default: false })
  private hasTargets!: boolean;

  @Prop({ default: 'is-right' })
  private innerMenuPosition!: string;

  @Prop()
  private targets!: number[];

  @Prop()
  private aggregates!: string[];

  @Prop({ default: FilterTimeAxisSpanEnum.Day })
  private chosenTimespan!: FilterTimeAxisSpanEnum;

  @Prop({ default: null })
  private targetsMetric!: TargetsMetric | null;

  @Prop()
  private params?: Params;

  @Prop()
  private paramValues?: { [key: string]: any };

  @Prop()
  private quickSearchFunction?: () => Promise<QuickSearchItem>;

  @Ref('targetsMenu')
  private targetsMenu!: TargetsMenu;

  @Ref('timeAxisSpanButton')
  private timeAxisSpanButton!: TimeAxisSpanButton;

  @Ref('aggregateButton')
  private aggregateButton!: AggregateButton;

  private toggleTargetsMenu() {
    this.targetsMenu.toggle();
  }

  private toggleTimeAxisSpanButton() {
    this.timeAxisSpanButton.toggle();
  }

  private toggleAggregateButton() {
    this.aggregateButton.toggle();
  }

  private targetChange(targets: number[]) {
    this.$emit('target-change', targets);
  }

  private downloadData() {
    this.$emit('download-data');
  }

  private hasParams(): boolean {
    return !!this.params;
  }

  private hasQuickSearch(): boolean {
    return !!this.quickSearchFunction;
  }

  @Emit('quick-search-select')
  private quickSearchSelect(params: QuickSearchItem) {
    // Empty
  }

  private get onlyShowsParams(): boolean {
    return (
      !(
        this.hasDownload ||
        this.hasTargets ||
        this.hasQuickSearch() ||
        this.hasAxisSpanFilter ||
        this.hasAggregates
      ) && this.hasParams()
    );
  }
}
