
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import { isEmpty } from '@/utils/misc';
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { userTargetsService } from '@/services/userTargets.service';

@Component
export default class TargetsMenu extends Vue {
  @Prop({ default: null })
  private targets!: number[] | null;

  @Prop({ default: null })
  private targetsMetric!: TargetsMetric | null;

  @Prop({ default: 'is-right' })
  private position!: string;

  private validInputs = [true, true];
  private editTarget0 = -1;
  private editTarget1 = -1;
  private isComponentInitialized = false;

  @Ref('dropdown')
  private dropdown: any;

  private mounted() {
    this.initialize();
  }

  private initialize() {
    this.editTarget0 = this.targets?.[0] ?? 0;
    this.editTarget1 = this.targets?.[1] ?? 0;
    this.isComponentInitialized = true;
  }

  private updateTarget() {
    const arrayOfNumbers: number[] = [Number(this.editTarget0)];
    if (this.editTarget1) {
      arrayOfNumbers.push(Number(this.editTarget1));
    }
    this.toggle();
    this.$emit('target-change', arrayOfNumbers);
  }

  private get isFormValid() {
    if (this.targetAmount === 2) {
      return !!this.editTarget0 && !!this.editTarget1 && this.validInputs.every(Boolean);
    } else {
      return !!this.editTarget0 && this.validInputs[0];
    }
  }

  private onDropdownOpenStatusChanged(isOpen: boolean) {
    if (isOpen) {
      this.initialize();
    }
  }

  private checkValidity(value: string, index: number) {
    if (isEmpty(value) || !value.trim()) {
      this.validInputs[index] = false;
    } else {
      const targetValue = +value;
      this.validInputs[index] = this.targetValueWithinBounds(targetValue) && !isNaN(targetValue);
    }
  }

  private targetValueWithinBounds(targetValue: number): boolean {
    if (
      this.targetsMetric === TargetsMetric.LaserOee ||
      this.targetsMetric === TargetsMetric.TubeOee
    ) {
      return targetValue >= 0 && targetValue < 100;
    } else {
      return targetValue >= 0 && targetValue <= 1_000_000;
    }
  }

  private get targetAmount(): number {
    return userTargetsService.store.getMetricNumberOfTargetValues(this.targetsMetric);
  }

  // Called by ThreeDotsOptions
  toggle() {
    this.dropdown.toggle();
  }
}
