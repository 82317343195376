// --------------------------------------------------------------------------------
// <copyright file="groupedBarChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
import { getColor } from '@/utils/color';
import { EChartsOption, SeriesOption } from 'echarts';
import { WidgetEnum } from '../enums/WidgetEnum';
import { mockBendingTimeBetweenBendData, mockBendingTopPartsData } from './mockWidgetSelectorData';
import { Logger } from '@/utils/logger';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

/*
  Extra options:
    isPrimaryOnly: boolean    |  whether there is one single series or not
    groupKey: string          | name of the key for grouping e.g. 'category_name'
    valueKey: string          | name of value holder e.g. 'time_between_bends'
    metricName: string        | legend name when isPrimaryOnly (instead of current/previous)
*/
export class GroupedBarChartGenerator extends ChartGenerator<any[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    return metricsService.getDevicesMetrics<any[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: any[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const series: SeriesOption[] = [];
    const yAxisTitles = new Set<string>();
    const primaryValues = new Map<string, number>();
    const secondaryValues = new Map<string, number>();

    data.forEach((dataset: any) => {
      const groupName: string = dataset[parameters.groupKey!];
      if (!yAxisTitles.has(groupName)) {
        yAxisTitles.add(groupName);
      }
      if (dataset.isprimary === 1) {
        primaryValues.set(groupName, dataset[parameters.valueKey!]);
      } else {
        secondaryValues.set(groupName, dataset[parameters.valueKey!]);
      }
    });

    const primaryValuesArray: number[] = [];
    const secondaryValuesArray: number[] = [];

    yAxisTitles.forEach((x) => {
      primaryValuesArray.push(primaryValues.get(x) ?? 0);
      secondaryValuesArray.push(secondaryValues.get(x) ?? 0);
    });

    series.push({
      name: parameters.isPrimaryOnly
        ? i18n.t(`report.${parameters.metricName}`).toString()
        : i18n.t('report.current').toString(),
      type: 'bar' as const,
      data: primaryValuesArray,
      label: parameters.isPrimaryOnly
        ? {
            position: [5, '50%'],
            verticalAlign: 'middle',
            textBorderWidth: -1,
            formatter: '{b}',
            show: true,
          }
        : undefined,
      // Use green for better text contrast when we've got only one series
      color: parameters.isPrimaryOnly ? getColor(1, 0) : undefined,
    });
    if (!parameters.isPrimaryOnly) {
      series.push({
        name: i18n.t('report.previous').toString(),
        type: 'bar' as const,
        data: secondaryValuesArray,
      });
    }
    const legend = parameters.isPrimaryOnly
      ? [i18n.t(`report.${parameters.metricName}`).toString()]
      : this.getLegend(data, primaryValues, secondaryValues);

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: (params: any) => {
          let html = `<p>${this.yAxisLabelFormatter(params[0].axisValueLabel)}</p>`;
          params.forEach((item: any) => {
            if (!isNaN(item.value)) {
              html +=
                `<p>${item.marker} ${item.seriesName}:` +
                `<b style="margin-left:32px;float:right">${i18n.n(item.value, {
                  maximumFractionDigits: 2,
                })}</b></p>`;
            }
          });
          return html;
        },
      },
      legend: {
        bottom: 0,
        data: legend,
        selectedMode: series.length === 1 ? false : 'multiple',
      },
      grid: {
        top: 16,
        bottom: 40,
        left: 8,
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        name: parameters.xAxisName ?? '',
        axisLabel: {
          formatter: (value: number) => i18n.n(value),
        },
      },
      yAxis: {
        type: 'category',
        data: Array.from(yAxisTitles),
        axisLabel: {
          show: !parameters.isPrimaryOnly,
          formatter: this.yAxisLabelFormatter.bind(this),
        },
        axisTick: {
          show: !parameters.isPrimaryOnly,
        },
      },
      series,
    };
  }

  override getMockData(): any[] | null {
    switch (this.procedure) {
      case WidgetEnum.BendingTimeBetweenBend:
        return mockBendingTimeBetweenBendData();
      case WidgetEnum.BendingTopParts:
        return mockBendingTopPartsData();
      default:
        Logger.error(`Missing mock data for ${this.procedure}`);
        return null;
    }
  }

  private getLegend(
    data: any[],
    primaryValues: Map<string, number>,
    secondaryValues: Map<string, number>,
  ) {
    const legend: string[] = [];
    if (primaryValues.size > 0) {
      legend.push(i18n.t('report.current').toString());
    }
    if (secondaryValues.size > 0) {
      legend.push(i18n.t('report.previous').toString());
    }
    return legend;
  }

  private yAxisLabelFormatter(name: string): string {
    if (this.procedure === WidgetEnum.BendingTimeBetweenBend) {
      return i18n.t(`report.${name}`).toString();
    } else {
      return name;
    }
  }
}
