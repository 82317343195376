
import { Component, Prop, Vue } from 'vue-property-decorator';
import HealthBar from './HealthBar.vue';
import { abbreviateNumber, decimalFormatter } from '@/utils/number';

@Component({
  methods: { abbreviateNumber, decimalFormatter },
  components: {
    HealthBar,
  },
})
export default class HealthIndicator extends Vue {
  @Prop({ required: true })
  private title!: string;

  @Prop({ required: true })
  private kpis!: Record<string, number>;

  @Prop({ required: true })
  private levelMaximums!: number[];

  @Prop({ required: true })
  private value!: number;

  @Prop({ default: false })
  private decimals!: boolean;

  @Prop()
  private unit?: string;

  private getKpiKey(index: number): string {
    return this.kpiEntries[index][0];
  }

  private getKpiValue(index: number): number {
    return this.kpiEntries[index][1];
  }

  private get kpiEntries(): Array<[string, number]> {
    return Object.entries(this.kpis);
  }

  private get noData(): boolean {
    return this.levelMaximums.every((x) => x === 0) && this.value === 0;
  }
}
