// --------------------------------------------------------------------------------
// <copyright file="actions.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { CurrencyCode } from '@/models/currencyCode';
import { Measurement } from '@/models/measurement';
import { TimeZone } from '@/models/timeZone';
import { ActionContext, ActionTree } from 'vuex';
import RootState from '../rootState';
import { PersistentMutations } from './enums';
import PersistentState from './persistentState';
import { DeviceBaseModel } from '@/models/deviceBaseModel';
import { User } from '@/models/user';
import { UserAlertStatus } from '@/models/userAlertStatus';
import { Device } from '@/models/device';
import { Tenant } from '@/models/tenant';
import { Shift } from '@/models/shift';
import { Target } from '@/models/target';
import { AlertKPIGroup } from '@/models/enums/AlertsEnums';
import { UserTarget } from '@/models/userTarget';

const actions: ActionTree<PersistentState, RootState> = {
  setLocale: (store: ActionContext<PersistentState, RootState>, locale: string) => {
    store.commit(PersistentMutations.SetLocale, locale);
  },
  setTimezones: (store: ActionContext<PersistentState, RootState>, timezones: TimeZone[]) => {
    store.commit(PersistentMutations.SetTimezones, timezones);
  },
  setCurrencyCodes: (
    store: ActionContext<PersistentState, RootState>,
    currencyCodes: CurrencyCode[],
  ) => {
    store.commit(PersistentMutations.SetCurrencyCodes, currencyCodes);
  },
  setMeasurements: (
    store: ActionContext<PersistentState, RootState>,
    measurements: Measurement[],
  ) => {
    store.commit(PersistentMutations.SetMeasurements, measurements);
  },
  setDeviceBaseModels: (
    store: ActionContext<PersistentState, RootState>,
    deviceBaseModels: DeviceBaseModel[],
  ) => {
    store.commit(PersistentMutations.SetDeviceBaseModels, deviceBaseModels);
  },
  setCurrentUser: (store: ActionContext<PersistentState, any>, value: User) => {
    store.commit(PersistentMutations.SetCurrentUser, value);
  },
  setNotifications: (store: ActionContext<PersistentState, any>, value: UserAlertStatus[]) => {
    store.commit(PersistentMutations.SetNotifications, value);
  },
  setTenant: (store: ActionContext<PersistentState, any>, value: Tenant) => {
    store.commit(PersistentMutations.SetTenant, value);
  },
  setDevices: (store: ActionContext<PersistentState, any>, value: Device[]) => {
    store.commit(PersistentMutations.SetDevices, value);
  },
  setShifts: (store: ActionContext<PersistentState, any>, value: Shift[]) => {
    store.commit(PersistentMutations.SetShifts, value);
  },
  setTargets: (store: ActionContext<PersistentState, any>, value: Target[]) => {
    store.commit(PersistentMutations.SetTargets, value);
  },
  setUserTargets: (store: ActionContext<PersistentState, any>, userTargets: UserTarget[]) => {
    store.commit(PersistentMutations.SetUserTargets, userTargets);
  },
  setKpiGroups: (store: ActionContext<PersistentState, any>, value: AlertKPIGroup) => {
    store.commit(PersistentMutations.SetKPIGroups, value);
  },
  setEventModules: (store: ActionContext<PersistentState, any>, value: string[]) => {
    store.commit(PersistentMutations.SetEventModules, value);
  },
  setEventCodes: (store: ActionContext<PersistentState, any>, value: string[]) => {
    store.commit(PersistentMutations.SetEventCodes, value);
  },
  resetState: (store: ActionContext<PersistentState, RootState>) => {
    store.commit(PersistentMutations.ResetState);
  },
};

export default actions;
