
import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { Routes } from '@/router/routes';
import { tenantsService } from '@/services/tenants.service';
import { devicesService } from '@/services/devices.service';
import ShiftFilter from '@/components/ShiftFilter.vue';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';
import Temperatures from '@/components/technologyConsole/Temperatures.vue';
import Overview from '@/components/technologyConsole/Overview.vue';
import DrawerInformation from '@/components/technologyConsole/DrawerInformation.vue';
import Laser from '@/components/technologyConsole/Laser.vue';
import DriveValues from '@/components/technologyConsole/DriveValues.vue';
import { Logger } from '@/utils/logger';
import { isEmpty } from '@/utils/misc';
import i18n from '@/i18n';
import OldTimeZoneIndicator from '@/components/OldTimeZoneIndicator.vue';
import moment from 'moment';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { Route } from 'vue-router';
import { ImportantMessageLevel } from '@/models/Charts/chartsData';
import EventModuleFilter from '@/components/EventModuleFilter.vue';
import EventTypeFilter from '@/components/EventTypeFilter.vue';
import EventCodesFilter from '@/components/EventCodesFilter.vue';
import { DeviceCuttingHeadVersionHistoryDTO } from '@/models/deviceCuttingHeadVersionHistoryDTO';
import Events from '@/components/careConsole/Events.vue';
import { versionIsGreaterThanOrEqualTo } from '@/utils/version';
import { TechnologyConsoleTab } from '@/models/enums/TechnologyConsoleTab';
import { getAllDevicesByCustomerId } from '@/use-cases/devices';
import { toastService } from '@/library-services/toast.service';

@Component({
  components: {
    EventTypeFilter,
    EventModuleFilter,
    EventCodesFilter,
    TimeZoneIndicator: OldTimeZoneIndicator,
    ShiftFilter,
    ClickMenu,
    DateGroupingFilter,
    'overview-tab': Overview,
    'temperatures-tab': Temperatures,
    'drawer-information-tab': DrawerInformation,
    'laser-tab': Laser,
    'drive-values-tab': DriveValues,
    'events-tab': Events,
  },
  computed: {
    TechnologyConsoleTab: () => TechnologyConsoleTab,
  },
})
export default class TechnologyConsoleDevice extends Vue {
  private activeTab: number = TechnologyConsoleTab.Overview;

  private dateRangeSelection = TechnologyConsoleDevice.defaultDateRange();
  private selectedTimeAxisSpan = FilterTimeAxisSpanEnum.Hour;
  private selectedEventTypes = [ImportantMessageLevel.Error];
  private selectedEventModules: string[] = [];
  private selectedEventCodes: string[] = [];

  @ProvideReactive('technologyTenantIdDh')
  private technologyTenantIdDh!: number | null;

  private technologyDevices: Device[] | null = null;
  private deviceCuttingHeadVersionHistory: DeviceCuttingHeadVersionHistoryDTO[] = [];

  private dataReady = false;

  private tenantTimeZone: string | null = null;

  private controller = new AbortController();

  private beforeRouteUpdate(to: Route, from: Route, next: (_?: boolean) => void) {
    this.technologyTenantIdDh = +to.params.tenantIdDh;
    // to.hash returns the whole hash section, including the # character.
    // Thus the call to substring is required
    const hash = to.hash?.substring(1);
    if (hash !== TechnologyConsoleTab[this.activeTab]) {
      if (
        hash === TechnologyConsoleTab[TechnologyConsoleTab.DriveValues] &&
        !this.isDriveValuesTabVisible
      ) {
        return next(false);
      }
      this.activeTab = TechnologyConsoleTab[hash as keyof typeof TechnologyConsoleTab];
    }
    return next();
  }

  private async created() {
    // Tenant ID is initialized here to avoid issues with test mocks
    this.technologyTenantIdDh = +this.$route.params.tenantIdDh;

    await this.loadDeviceCuttingHeadVersionHistory();

    if (isEmpty(this.$route.hash)) {
      if (this.activeTab === TechnologyConsoleTab.DriveValues && !this.isDriveValuesTabVisible) {
        this.activeTab = TechnologyConsoleTab.Overview;
      }
      await this.$router.replace({
        hash: TechnologyConsoleTab[this.activeTab],
      });
    } else {
      if (
        TechnologyConsoleTab[this.$route.hash.substring(1) as keyof typeof TechnologyConsoleTab] ===
          TechnologyConsoleTab.DriveValues &&
        !this.isDriveValuesTabVisible
      ) {
        await this.$router.replace({
          hash: TechnologyConsoleTab[TechnologyConsoleTab.Overview],
        });
      }
      this.activeTab =
        TechnologyConsoleTab[this.$route.hash.substring(1) as keyof typeof TechnologyConsoleTab];
    }

    try {
      await this.fetchTechnologyData();
    } catch (error) {
      Logger.error(error);
      await this.$router.push({ name: Routes.Index });
      return;
    }

    this.dataReady = true;
  }

  private async loadDeviceCuttingHeadVersionHistory() {
    this.controller?.abort();
    this.controller = new AbortController();
    this.deviceCuttingHeadVersionHistory = await devicesService.getDeviceCuttingHeadVersionsHistory(
      this.technologyTenantIdDh!,
      this.selectedDeviceId,
      this.controller,
    );
  }

  private get devices(): Device[] {
    return this.technologyDevices ?? [];
  }

  private get deviceIds(): string[] {
    return this.devices.map((device) => device.deviceId);
  }

  private get deviceNames(): string[] {
    return this.devices.map((device) => device.name);
  }

  private get tenantDevicesLink() {
    return {
      name: Routes.TechnologyConsoleTenantDevices,
      params: { tenantIdDh: this.$route.params.tenantIdDh },
    };
  }

  private get hasEventModuleFilter() {
    return this.activeTab === TechnologyConsoleTab.ErrorsAndWarnings;
  }

  private get hasEventTypesFilter() {
    return this.activeTab === TechnologyConsoleTab.ErrorsAndWarnings;
  }

  private get hasEventCodesFilter() {
    return this.activeTab === TechnologyConsoleTab.ErrorsAndWarnings;
  }

  private get hasNoneOption(): boolean {
    return [
      TechnologyConsoleTab.Temperatures,
      TechnologyConsoleTab.DrawerInformation,
      TechnologyConsoleTab.DriveValues,
    ].includes(this.activeTab);
  }

  private get selectedDeviceId(): string {
    return this.$route.params.deviceId;
  }

  private async fetchTechnologyData() {
    if (!this.$route.params.tenantIdDh) {
      toastService.error(`Missing tenantIdDh route parameter`);
      throw Error('Missing tenantIdDh route parameter');
    }

    const technologyTenant = await tenantsService.getByIdDh(+this.$route.params.tenantIdDh);
    this.tenantTimeZone = technologyTenant.timeZone;
    this.technologyDevices = await getAllDevicesByCustomerId(technologyTenant.id);

    if (isEmpty(this.technologyDevices)) {
      toastService.error(`No devices found for tenant ${this.$route.params.tenantIdDh}`);
      throw Error(`No devices found for tenant ${this.$route.params.tenantIdDh}`);
    }
  }

  private async onDeviceChanged(id: string) {
    await this.$router.replace({
      name: Routes.TechnologyConsoleDevice,
      params: { deviceId: id },
      hash: this.$route.hash,
    });
  }

  private getSelectedDeviceName(): string | undefined {
    return this.devices.find((device) => device.deviceId === this.selectedDeviceId)?.name;
  }

  private toggleDatepicker() {
    (this.$refs.desktopDatePicker as any)?.toggle?.();
  }

  private get isDriveValuesTabVisible(): boolean {
    // The DriveValues tab must only be hidden if all the entries in the history are greater than or equal to 4.0.
    // If there is no history, the tab must not be hidden.
    return (
      this.deviceCuttingHeadVersionHistory.length === 0 ||
      this.deviceCuttingHeadVersionHistory.some(
        (h) => !versionIsGreaterThanOrEqualTo('4.0', h.version),
      )
    );
  }

  private beforeDestroy() {
    this.controller.abort();
  }

  @Watch('activeTab')
  private watchActiveTab() {
    // Avoid redundant navigation error on page reload
    if (!this.$route.hash.includes(TechnologyConsoleTab[this.activeTab])) {
      this.$router.replace({
        hash: TechnologyConsoleTab[this.activeTab],
      });
    }
  }

  @Watch('selectedDeviceId')
  private async watchSelectedDeviceId() {
    await this.loadDeviceCuttingHeadVersionHistory();
    if (!this.isDriveValuesTabVisible && this.activeTab === TechnologyConsoleTab.DriveValues) {
      this.activeTab = TechnologyConsoleTab.Overview;
    }
  }

  private static defaultDateRange(): [Date, Date] {
    const oneWeekAgo = moment().subtract(1, 'week').toDate();
    return [oneWeekAgo, new Date()];
  }
}
