// --------------------------------------------------------------------------------
// <copyright file="validation.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ValidationRule } from 'vee-validate/dist/types/types';

export const password_repeat: ValidationRule = {
  validate: (value, args) => (args as any[])[0] === value,
};
