
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import { Mode } from '@/store/enums';
import { ShiftModel } from '@/models/shiftModel';
import { shiftModelsService } from '@/services/shiftModels.service';
import i18n from '@/i18n';

@Component
export default class ShiftModelFormModal extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  @Prop({ required: true })
  private mode!: Mode;

  @Prop({ required: true })
  private shiftModel!: ShiftModel;

  private editableShiftModel = ShiftModel.GetEmpty();

  private isSubmitting = false;

  private async mounted() {
    if (this.isCreateMode) {
      this.editableShiftModel = ShiftModel.GetEmpty();
      if (this.tenant) {
        this.editableShiftModel.tenantId = this.tenant.id;
      }
    } else {
      this.editableShiftModel = this.shiftModel;
    }
  }

  private handleChange(event: any): void {
    this.editableShiftModel = {
      ...this.editableShiftModel,
      [event.target.name]: event.target.value,
    };
  }

  private async onSubmit() {
    this.isSubmitting = true;
    try {
      if (this.isCreateMode) {
        await shiftModelsService.create(this.editableShiftModel);
      } else {
        await shiftModelsService.update(this.editableShiftModel);
      }
      this.$emit('save');
      this.$emit('close');
    } finally {
      this.isSubmitting = false;
    }
  }

  private get isEnabled() {
    if (this.editableShiftModel && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  private get isEditMode(): boolean {
    return this.mode === Mode.Edit;
  }

  private get isCreateMode(): boolean {
    return this.mode === Mode.Create;
  }

  private get actionTitle() {
    if (this.isCreateMode) {
      return i18n.t('action.create').toString();
    } else if (this.isEditMode) {
      return i18n.t('action.save').toString();
    } else {
      return i18n.t('action.close').toString();
    }
  }
}
