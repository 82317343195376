// --------------------------------------------------------------------------------
// <copyright file="response.handler.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

class ResponseHandler {
  handleDates(body: any) {
    const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?$/;

    const dateTimeOffsetFormat =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?([+-]\d{2}\:\d{2})?$/;

    if (body === null || body === undefined || typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value && typeof value === 'string' && isoDateFormat.test(value)) {
        body[key] = new Date(value);
      } else if (value && typeof value === 'string' && dateTimeOffsetFormat.test(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.handleDates(value);
      }
    }

    return body;
  }
}

export const responseHandler = new ResponseHandler();
