// --------------------------------------------------------------------------------
// <copyright file="currency.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import getSymbolFromCurrency from 'currency-symbol-map';
import { mastersService } from '@/services/masters.service';
import { tenantsService } from '@/services/tenants.service';

export const currency: () => string = () => {
  const curr = mastersService.store
    .getCurrencyCodes()
    .find((cur: any) => tenantsService.store.current()?.currencyCode === cur.id);
  return !!curr ? getSymbolFromCurrency(curr?.name) ?? '' : '';
};

export const currencyCode: () => string = () => {
  const curr = mastersService.store
    .getCurrencyCodes()
    .find((cur: any) => tenantsService.store.current()?.currencyCode === cur.id);
  return !!curr ? curr.name : '';
};
