// --------------------------------------------------------------------------------
// <copyright file="salesUnpaidDistributionGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import i18n from '@/i18n';
import { PersistentGetters } from '@/store/persistent/enums';
import { currency } from '@/utils/currency';
import { Tenant } from '@/models/tenant';
import store from '@/store';
import { userTargetsService } from '@/services/userTargets.service';
import { TargetsMetric } from '../enums/TargetsMetric';
import { abbreviateNumber, numberWithCurrency } from '@/utils/number';
import { UnpaidSalesDistributionData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import moment from 'moment';
import { mockSalesUnpaidDistributionData } from './mockWidgetSelectorData';
import { metricsService } from '@/services/metrics.service';

export class SalesUnpaidDistributionGenerator extends ChartGenerator<
  UnpaidSalesDistributionData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    const target =
      userTargetsService.store.get(TargetsMetric.SalesUnpaidDistribution)?.target1 ?? 0;
    const belowTarget = params?.belowTarget ?? false;
    const date = moment().format('YYYY-MM-DD');
    return metricsService.getSMBSMetricsBC<UnpaidSalesDistributionData[]>(
      this.procedure,
      this.tenantIdDh,
      { target, belowTarget, date, startDate, endDate },
      this.controller,
    );
  }

  override updateOptions(
    data: UnpaidSalesDistributionData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: this.formatter(data),
      },
      legend: {
        bottom: 0,
        type: 'scroll',
      },
      grid: [
        {
          left: parameters.clientWidth! > 350 ? '7%' : '13%',
          right: '5%',
          bottom: 42,
          top: 32,
        },
        {
          left: parameters.clientWidth! > 350 ? '7%' : '13%',
          right: '5%',
          bottom: 42,
          top: 32,
        },
      ],
      xAxis: [
        {
          type: 'category',
          show: false,
        },
        {
          name: currency(),
          type: 'value',
          show: true,
          position: 'bottom',
          splitLine: {
            show: false,
          },
          splitNumber: 8,
          min: data[0].bin_start,
          max: data[6].bin_end,
          interval: data[0].bin_end - data[0].bin_start,
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#f4f4f4',
              width: 1,
            },
          },
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        {
          gridIndex: 1,
          type: 'value',
          show: false,
        },
      ],
      series: this.series(data),
    };
  }

  override getMockData(): UnpaidSalesDistributionData[] | null {
    return mockSalesUnpaidDistributionData();
  }

  private formatter(data: UnpaidSalesDistributionData[]) {
    return (params: any) => {
      const bin = data.filter((_, index: number) => index === params[0].dataIndex);
      let res = `<p>${numberWithCurrency(bin[0].bin_start)}
       - ${numberWithCurrency(bin[0].bin_end)}</p>`;
      res += params
        .map((item: any) => {
          return (
            `<p>${item.marker} ${item.seriesName}:` +
            `<b style="margin-left:32px;float:right">${i18n.n(item.data, {
              maximumFractionDigits: 2,
            })}</b></p>`
          );
        })
        .join('');
      res +=
        `<p>${i18n.t('report.business_console.total_sales_orders')}:` +
        `<b style="margin-left:32px;float:right">${i18n.n(
          bin[0].freq_not_invoiced +
            bin[0].freq_not_paid_not_expired +
            bin[0].freq_not_paid_expired,
          {
            maximumFractionDigits: 2,
          },
        )}</b></p>`;
      res +=
        `<p>${i18n.t('report.business_console.max_expired_days')}:` +
        `<b style="margin-left:32px;float:right">${i18n.n(bin[0].expired_days, {
          maximumFractionDigits: 2,
        })}</b></p>`;
      return res;
    };
  }

  private get tenant(): Tenant {
    return store.getters[PersistentGetters.GetTenant];
  }

  private series(data: UnpaidSalesDistributionData[]) {
    return [
      {
        name: i18n.t('report.business_console.freq_not_invoiced').toString(),
        type: 'bar' as const,
        stack: 'one',
        data: this.data(data, 'freq_not_invoiced'),
        barWidth: '100%',
      },
      {
        name: i18n.t('report.business_console.freq_not_paid_not_expired').toString(),
        type: 'bar' as const,
        stack: 'one',
        data: this.data(data, 'freq_not_paid_not_expired'),
        barWidth: '100%',
      },
      {
        name: i18n.t('report.business_console.freq_not_paid_expired').toString(),
        type: 'bar' as const,
        stack: 'one',
        data: this.data(data, 'freq_not_paid_expired'),
        barWidth: '100%',
        color: '#ee6666',
      },
    ];
  }

  private data(data: UnpaidSalesDistributionData[], dataName: keyof UnpaidSalesDistributionData) {
    return data.map((item) => item[dataName]);
  }
}
