// --------------------------------------------------------------------------------
// <copyright file="i18n.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { localeService } from '@/library-services/locale.service';

export function currentLocale(): string {
  return localeService.currentLocale;
}

export function translateFilterTimeSpanEnum(value: FilterTimeSpanEnum): string {
  return i18n.t(`timespans.${FilterTimeSpanEnum[value].toLowerCase()}`).toString();
}

export function translateFilterTimeAxisSpanEnum(value: FilterTimeAxisSpanEnum): string {
  return i18n.t(`timespans.${FilterTimeAxisSpanEnum[Number(value)].toLowerCase()}`).toString();
}
