// --------------------------------------------------------------------------------
// <copyright file="AlertsEnums.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ConsoleEnum } from './ConsoleEnum';

export enum AlertAction {
  None = 0,
  Web = 1,
  WebAndEmail = 2,
}

export enum AlertMetric {
  // None
  None = 0,

  // WCC
  OEE = 1,
  LaserHealth = 2,

  // Care: Health
  LowerProtectionGlass = 3,
  UpperProtectionGlass = 4,
  DrawerCycleTimes = 5,
  SpindleCurrent = 6,
  Temperatures = 8,

  // Care: Events
  ErrorCount = 13,
  WarningCount = 14,
  ErrorCountBySource = 15,
  WarningCountBySource = 16,

  // Care: Cutting Head
  DrawerChanges = 21,
  PDValues = 22,
  CuttingHeadTemperatures = 23,
  CuttingHeadDrawerCycleTimes = 26,

  // Care: Laser consumption
  UsedKWh = 24,
  UsedKWhOfMultiple = 25,
}

export enum AlertOperator {
  None = 0,
  GreaterThan = 1,
  LessThan = 2,
  Equal = 3,
}

/** @deprecated At the moment, always uses valueType like numeric */
export enum AlertValueType {
  Numeric = 1,
  String = 2,
}

export interface AlertKPI {
  /** @deprecated At the moment, always uses valueType like numeric */
  valueType: AlertValueType;
  kpi: AlertMetric;
  console: ConsoleEnum;
  unit: string;
  variableValues: string[] | null;
}

export interface AlertKPIGroup {
  name: string;
  alertKpis: AlertKPI[];
}
