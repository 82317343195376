
import GenericParam from './GenericParam.vue';
import { Component } from 'vue-property-decorator';
import { ParamRadio } from '@/models/enums/WidgetEnum';

@Component({})
export default class RadioParam extends GenericParam<ParamRadio> {
  private get value(): boolean {
    return this.paramValue ?? this.paramInfo.defaultValue ?? false;
  }

  private sendUpdate(value: boolean) {
    if (value !== this.value) {
      this.$emit('update', { [this.paramName]: value });
    }
  }
}
