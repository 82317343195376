// --------------------------------------------------------------------------------
// <copyright file="cuttingHoursTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { abbreviateNumber } from '@/utils/number';
import { CuttingHoursTimelineData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, LineSeriesOption } from 'echarts';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { isCategoryXAxis } from '@/utils/charts';
import { XAXisOption } from 'echarts/types/dist/shared';
import { TooltipFormatter } from '@/models/Charts/tooltipFormatter';
import { metricsService } from '@/services/metrics.service';

export class CuttingHoursTimelineGenerator extends ChartGenerator<CuttingHoursTimelineData[]> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ) {
    const deviceId = selectedDevices[0];
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<CuttingHoursTimelineData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom,
        dateTo,
        dateGrouping: timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: CuttingHoursTimelineData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);

    return {
      legend: {
        icon: 'roundRect',
        type: 'scroll',
        bottom: 0,
        selectedMode: false,
      },
      dataZoom: [
        {
          type: 'inside',
          filterMode: 'weakFilter',
        },
      ],
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: TooltipFormatter.build(parameters.timeAxisSpan)
          .withSeriesTranslationPrefix(undefined)
          .get(),
      },
      grid: [
        {
          top: 10,
          left: 10,
          right: 20,
          bottom: 30,
          containLabel: true,
        },
      ],
      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        axisLabel: {
          show: true,
          hideOverlap: true,
        },
        axisTick: { show: true },
        splitLine: {
          show: false,
        },
        data: isCategoryAxis ? (data.map((dataItem) => dataItem.bucket) as any[]) : undefined,
      } as XAXisOption,
      yAxis: {
        name: i18n.t('hours_abbreviation').toString(),
        nameTextStyle: {
          align: 'left',
        },
        type: 'value',
        scale: true,
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value: number) => abbreviateNumber(value),
        },
      },
      series: this.generateSeries(data, isCategoryAxis),
    };
  }

  private generateSeries(
    data: CuttingHoursTimelineData[],
    isCategoryAxis: boolean,
  ): LineSeriesOption[] {
    const seriesData = this.getSeriesData('cuttingHours', data, isCategoryAxis);

    return [
      {
        data: seriesData,
        type: 'line',
        name: i18n.t('report.cutting_hours').toString(),
        emphasis: { focus: 'series' },
      },
    ];
  }

  private getSeriesData(
    fieldName: keyof CuttingHoursTimelineData,
    dataArray: CuttingHoursTimelineData[],
    isCategoryAxis: boolean,
  ) {
    return isCategoryAxis
      ? dataArray.map((dataItem) => dataItem[fieldName])
      : dataArray.map((dataItem) => [`${dataItem.bucket}`, dataItem[fieldName]]);
  }
}
