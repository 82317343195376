// --------------------------------------------------------------------------------
// <copyright file="chartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { EChartsOption } from 'echarts';
import { DetailedViewEnum, WidgetEnum } from '../../enums/WidgetEnum';
import { Logger } from '@/utils/logger';
import { GeneratorParams } from '../generatorParams';

export type ProcedureName = WidgetEnum | DetailedViewEnum;

export abstract class DataRetriever<T> {
  constructor(
    protected procedure: ProcedureName,
    protected controller: AbortController = new AbortController(),
  ) {}

  abstract getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ): Promise<T | null>;

  getMockData(): T | null {
    Logger.error(`Missing mock data for ${this.procedure}`);
    return null;
  }

  abort() {
    this.controller.abort();
    this.controller = new AbortController();
  }
}

export abstract class ChartGenerator<T> extends DataRetriever<T> {
  abstract updateOptions(
    data: T,
    parameters: GeneratorParams,
    prevOptions?: EChartsOption,
  ): EChartsOption;
}
