// --------------------------------------------------------------------------------
// <copyright file="drawerInformationTimelineTooltipFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { DrawerInformationTimelineData } from './chartsData';
import { abbreviateNumber } from '@/utils/number';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { getTooltipTitle } from '@/utils/charts';
import { Logger } from '@/utils/logger';
import { isNil } from '@/utils/misc';
import {
  LOWER_DRAWER_CHART_INDEX,
  PD_SERIES,
  POWER_AMPLITUDE_SERIES,
  REMOVED_SECONDS_SERIES,
  UPPER_DRAWER_CHART_INDEX,
} from '@/models/Charts/drawerInformationTimelineGenerator';

export function tooltipFormatter(
  params: any,
  data: DrawerInformationTimelineData[],
  dateGrouping: FilterTimeAxisSpanEnum,
  isCategoryAxis: boolean,
): string {
  let html = `
  <div data-testid="time-title" style="font-size: 15px">
  ${getTooltipTitle(params[0].axisValue, dateGrouping, isCategoryAxis)}
  </div>
  `;

  html += getChartTitleTooltipHtml('lower-drawer');
  html += getNumericValueTooltipHtml(
    params,
    LOWER_DRAWER_CHART_INDEX,
    REMOVED_SECONDS_SERIES,
    data,
  );
  html += getNumericValueTooltipHtml(params, LOWER_DRAWER_CHART_INDEX, PD_SERIES, data);
  html += getNumericValueTooltipHtml(
    params,
    LOWER_DRAWER_CHART_INDEX,
    POWER_AMPLITUDE_SERIES,
    data,
  );

  html += getChartTitleTooltipHtml('upper-drawer');
  html += getNumericValueTooltipHtml(
    params,
    UPPER_DRAWER_CHART_INDEX,
    REMOVED_SECONDS_SERIES,
    data,
  );
  html += getNumericValueTooltipHtml(params, UPPER_DRAWER_CHART_INDEX, PD_SERIES, data);
  html += getNumericValueTooltipHtml(
    params,
    UPPER_DRAWER_CHART_INDEX,
    POWER_AMPLITUDE_SERIES,
    data,
  );

  return html;
}

function getChartTitleTooltipHtml(deviceKey: string): string {
  return `
  <div data-testid="${deviceKey}-title"
    style="font-size: 15px; margin-top: 5px; font-weight: bold"
  >
    ${i18n.t(`report.${deviceKey}`)}:
  </div>
  `;
}

function getNumericValueTooltipHtml(
  params: any[],
  chartIndex: number,
  seriesName: string,
  data: DrawerInformationTimelineData[],
): string {
  const seriesParam = getSeriesParam(params, chartIndex, seriesName);
  if (!seriesParam) {
    // The series may have been disabled by the user
    return '';
  }
  const unit = getSeriesUnit(seriesName);
  // ['ISO date', value] or value depending on the date grouping
  const value = Array.isArray(seriesParam.value) ? seriesParam.value?.[1] : seriesParam.value;
  let formattedValue = isNil(value) ? `- ${unit}` : `${abbreviateNumber(value)} ${unit}`;

  if (seriesName === REMOVED_SECONDS_SERIES) {
    formattedValue = addCycles(formattedValue, data, seriesParam);
  }
  return getValueTooltipHtml(seriesParam, formattedValue);
}

function addCycles(
  formattedValue: string,
  data: DrawerInformationTimelineData[],
  seriesParam: any,
) {
  const removedTimesField = getRemovedTimesField(seriesParam);
  const cycles = data[seriesParam.dataIndex][removedTimesField] as number | null;

  if (isNil(cycles)) {
    return `${formattedValue} (${i18n.t('report.no_cycles')})`;
  }
  return `${formattedValue} (${cycles} ${i18n.tc('report.cycles', cycles)})`;
}

function getRemovedTimesField(seriesParam: any): keyof DrawerInformationTimelineData {
  switch (seriesParam.seriesIndex) {
    case 0:
      return 'lowerDrawerRemovedTimes';
    case 3:
      return 'upperDrawerRemovedTimes';
    default:
      Logger.error('Invalid seriesIndex:', seriesParam.seriesIndex);
      return 'bucket';
  }
}

function getSeriesUnit(seriesName: string) {
  switch (seriesName) {
    case REMOVED_SECONDS_SERIES:
      return 's';
    case PD_SERIES:
      return ''; // no unit
    case POWER_AMPLITUDE_SERIES:
      return 'W';
  }
}

function getValueTooltipHtml(seriesParam: any, value: string): string {
  return `
  <div
    data-testid="series-value-${seriesParam.axisIndex}-${seriesParam.seriesName}"
    style="display: flex; flex-direction: row"
  >
    <div style="flex-grow: 1">
      ${seriesParam.marker}
      ${i18n.t(`report.drawer_information_timeline.${seriesParam.seriesName}`)}
    </div>
    <div style="font-weight: bold; padding-left: 3rem">${value}</div>
  </div>
  `;
}

function getSeriesParam(params: any[], chartIndex: number, seriesName: string) {
  return params
    .filter((param: any) => param.axisIndex === chartIndex)
    .find((param: any) => param.seriesName === seriesName);
}
