// --------------------------------------------------------------------------------
// <copyright file="mutations.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { MutationTree } from 'vuex';
import RootState from '@/store/rootState';

const mutations: MutationTree<RootState> = {
  showSpinner(state: RootState) {
    state.spinners++;
  },
  removeSpinner(state: RootState) {
    if (state.spinners > 0) {
      state.spinners--;
    }
  },
  setTermsAcceptedSession(state: RootState, termsAcceptedSessionValue: boolean) {
    state.termsAcceptedSession = termsAcceptedSessionValue;
  },
  setUserInfoLoaded(state: RootState, userInfoLoadedValue: boolean) {
    state.userInfoLoaded = userInfoLoadedValue;
  },
  setCustomers(state: RootState, customersValue: any) {
    state.customers = customersValue;
  },
};

export default mutations;
