// --------------------------------------------------------------------------------
// <copyright file="dashboardMode.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum DashboardMode {
  Normal = 'Normal',
  Edition = 'Edition',
  Creation = 'Creation',
}
