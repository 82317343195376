
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { isEmpty, isNil } from '@/utils/misc';
import { Shift } from '@/models/shift';
import { Tenant } from '@/models/tenant';
import TimeZoneIndicator from '@/components/TimeZoneIndicator.vue';

@Component({
  components: { TimeZoneIndicator },
  methods: { isNil, isEmpty },
})
export default class MultipleSelectionFiltersHeader extends Vue {
  @Prop({ required: true })
  private selectedEquipment!: Device[];

  @Prop()
  private customer?: Tenant | null;

  @Prop()
  private timeZone?: string | null;

  @Prop()
  private selectedShifts?: Shift[];

  @Prop({ default: true, type: Boolean })
  private showShifts!: boolean;
}
