
import { isEmpty } from '@/utils/misc';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface License {
  license?: string;
  licenseUrl: string;
}

export interface AttributionItem {
  name: string;
  licenses: License[];
  url: string;
  version: string;
  authors: string[];
}

@Component({
  methods: {
    isEmpty,
  },
})
export default class AttributionsList extends Vue {
  @Prop({ required: true })
  private attributions!: AttributionItem[];

  private hasAuthors(item: AttributionItem): boolean {
    return item.authors?.length > 0;
  }

  private parseAuthors(authors: string[]): string {
    return authors.join(', ');
  }

  private hasLicense(item: AttributionItem): boolean {
    return item.licenses.length > 0;
  }

  private hasLicenseName(item: AttributionItem): boolean {
    return !!item.licenses[0].license;
  }

  private hasOneLicense(item: AttributionItem): boolean {
    return item.licenses.length === 1;
  }
}
