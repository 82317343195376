// --------------------------------------------------------------------------------
// <copyright file="spinner.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Store } from 'vuex';
import { RootActions } from '@/store/enums';
import RootState from '@/store/rootState';

export class SpinnerPluginService {
  constructor(private store: Store<RootState>) {}

  showSpinner() {
    this.store.dispatch(RootActions.ShowSpinner);
  }

  removeSpinner() {
    this.store.dispatch(RootActions.RemoveSpinner);
  }
}
