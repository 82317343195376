// --------------------------------------------------------------------------------
// <copyright file="enum.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { isEmpty } from '@/utils/misc';

export function keys(enumeration: Record<string, number | string>): string[] {
  return Object.keys(enumeration).filter((value) => !isNumeric(value));
}

export function values(enumeration: Record<string, number | string>): any[] {
  const objectKeys = Object.keys(enumeration).filter((value) => isNumeric(value));

  return isEmpty(objectKeys)
    ? (Object.values(enumeration) as string[])
    : objectKeys.map((value) => +value);
}

function isNumeric(value: string) {
  return !isNaN(+value);
}
