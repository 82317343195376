// --------------------------------------------------------------------------------
// <copyright file="salesFunnelGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { GeneratorParams } from './generatorParams';
import moment from 'moment';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { SalesFunnelData } from './chartsData';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockSalesFunnelData } from './mockWidgetSelectorData';
import { metricsService } from '@/services/metrics.service';

export default class SalesFunnelGenerator extends ChartGenerator<SalesFunnelData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    const date = moment().format('YYYY-MM-DD');
    const basedOnHistoricalData = params?.ratio ?? false;
    return metricsService.getSMBSMetricsBC<SalesFunnelData[]>(
      this.procedure,
      this.tenantIdDh,
      { date, startDate, endDate, axisTimespan: timeAxisSpan, basedOnHistoricalData },
      this.controller,
    );
  }

  override updateOptions(
    data: SalesFunnelData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const [datum] = data;
    const noTarget = this.targetIsNull(datum);
    const currentIsZero = this.currentIsZero(datum);
    const max = Object.values(datum).reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE);
    const currentData = {
      name: 'current',
      type: 'funnel' as const,
      left: '10%',
      width: '80%',
      sort: 'none' as const,
      data: [
        {
          value: datum.quotes,
          name: i18n.t('report.quotes').toString(),
          itemStyle: {
            color: '#5470c6',
          },
        },
        {
          value: datum.quotes_converted,
          name: i18n.t('report.business_console.converted').toString(),
          itemStyle: {
            color: '#91cc75',
          },
        },
        {
          value: datum.sold,
          name: i18n.t('report.sales').toString(),
          itemStyle: {
            color: '#fac858',
          },
        },
        {
          value: datum.invoiced,
          name: i18n.t('report.business_console.invoiced_amt').toString(),
          itemStyle: {
            color: '#ee6666',
          },
        },
        {
          value: datum.paid,
          name: i18n.t('report.business_console.paid_amt').toString(),
          itemStyle: {
            color: '#73c0de',
          },
        },
      ],
      z: this.currentZIndex(datum),
      itemStyle: {
        opacity: 1,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: { show: false },
      labelLine: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
      },
      top: 0,
      bottom: '12%',
      max,
    };
    const targetData = {
      name: 'target',
      type: 'funnel' as const,
      left: '10%',
      width: '80%',
      sort: 'none' as const,
      data: [
        {
          value: datum.quotes_target,
          name: i18n.t('report.quotes').toString(),
          itemStyle: {
            color: '#90a2da',
          },
        },
        {
          value: datum.quotes_converted_target,
          name: i18n.t('report.business_console.converted').toString(),
          itemStyle: {
            color: '#b7dea5',
          },
        },
        {
          value: datum.sold_target,
          name: i18n.t('report.sales').toString(),
          itemStyle: {
            color: '#fbdb92',
          },
        },
        {
          value: datum.invoiced_target,
          name: i18n.t('report.business_console.invoiced_amt').toString(),
          itemStyle: {
            color: '#f49b9b',
          },
        },
        {
          value: datum.paid_target,
          name: i18n.t('report.business_console.paid_amt').toString(),
          itemStyle: {
            color: '#a4d6e9',
          },
        },
      ],
      label: { show: false },
      labelLine: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
        },
      },
      z: this.targetZIndex(datum),
      top: 0,
      bottom: '12%',
      max,
    };

    const series = [];

    if (!currentIsZero) {
      series.push(currentData);
    }

    if (!noTarget) {
      series.push(targetData);
    }

    return {
      title: {
        show: false,
      },
      legend: {
        data: [
          i18n.t('report.quotes'),
          i18n.t('report.business_console.converted'),
          i18n.t('report.sales'),
          i18n.t('report.business_console.invoiced_amt'),
          i18n.t('report.business_console.paid_amt'),
        ],
        type: 'scroll',
        bottom: 0,
        top: 'auto',
      },
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (params: any) => {
          const { seriesName, name } = params;
          const expectedTooltip = `
          <div style="display: flex; flex-direction: row; font-size: 12px">
            <div style="flex-grow: 1">
              ${seriesName === 'target' ? '<b>' : ''}
              ${i18n.t('report.business_console.expected')}
              ${seriesName === 'target' ? '</b>' : ''}
            </div>
            <div style="padding-left: 3em">${i18n.n(
              Math.round(this.getExpectedValue(datum, name) * 100) / 100,
            )}</div>
          </div>
          `;
          const currentTooltip = `
          <div style="display: flex; flex-direction: row; font-size: 12px">
            <div style="flex-grow: 1">
              ${seriesName === 'current' ? '<b>' : ''}
              ${i18n.t('report.current')}
              ${seriesName === 'current' ? '</b>' : ''}
            </div>
            <div style="padding-left: 3em">${i18n.n(
              Math.round(this.getCurrentValue(datum, name) * 100) / 100,
            )}</div>
          </div>
          `;
          return `
          <p>${name}</p>
          ${noTarget ? '' : expectedTooltip}
          ${currentIsZero ? '' : currentTooltip}
          `;
        },
      },
      series,
    };
  }

  override getMockData(): SalesFunnelData[] | null {
    return mockSalesFunnelData();
  }

  private targetIsNull(data: SalesFunnelData): boolean {
    return [
      data.paid_target,
      data.invoiced_target,
      data.sold_target,
      data.quotes_converted_target,
      data.quotes_target,
    ].every((t) => t === null);
  }

  private currentIsZero(data: SalesFunnelData): boolean {
    return [data.paid, data.invoiced, data.sold, data.quotes_converted, data.quotes].every(
      (t) => t === 0,
    );
  }

  private getExpectedValue(data: SalesFunnelData, key: string): number {
    switch (key) {
      case i18n.t('report.quotes'):
        return data.quotes_target;
      case i18n.t('report.business_console.converted'):
        return data.quotes_converted_target;
      case i18n.t('report.sales'):
        return data.sold_target;
      case i18n.t('report.business_console.invoiced_amt'):
        return data.invoiced_target;
      case i18n.t('report.business_console.paid_amt'):
        return data.paid_target;
      default:
        return 0;
    }
  }

  private getCurrentValue(data: SalesFunnelData, key: string): number {
    switch (key) {
      case i18n.t('report.quotes'):
        return data.quotes;
      case i18n.t('report.business_console.converted'):
        return data.quotes_converted;
      case i18n.t('report.sales'):
        return data.sold;
      case i18n.t('report.business_console.invoiced_amt'):
        return data.invoiced;
      case i18n.t('report.business_console.paid_amt'):
        return data.paid;
      default:
        return 0;
    }
  }

  private targetZIndex(data: SalesFunnelData) {
    return this.isTargetAbove(data) ? 100 : 0;
  }

  private currentZIndex(data: SalesFunnelData) {
    return this.isTargetAbove(data) ? 0 : 100;
  }

  private isTargetAbove(data: SalesFunnelData): boolean {
    return (
      [
        data.quotes - data.quotes_target,
        data.quotes_converted - data.quotes_converted_target,
        data.sold - data.sold_target,
        data.invoiced - data.invoiced_target,
        data.paid - data.paid_target,
      ].reduce((a, b) => a + b, 0) >= 0
    );
  }
}
