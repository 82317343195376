// --------------------------------------------------------------------------------
// <copyright file="laserPowerUtilizationTimesGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
import { powerLevelColors } from '@/utils/color';
import { LaserPowerUtilizationTimesData } from './chartsData';
import { EChartsOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export class LaserPowerUtilizationTimesGenerator extends ChartGenerator<
  LaserPowerUtilizationTimesData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    const deviceId = selectedDevices[0];
    const dateRange = timeSpan as [string, string];
    return metricsService.getSSCMetrics<LaserPowerUtilizationTimesData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: dateRange[0],
        dateTo: dateRange[1],
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserPowerUtilizationTimesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      grid: {
        top: 30,
        bottom: 20,
        left: 10,
        right: 8,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: this.generateSeriesData(data).map((dataItem) => dataItem.name),
        axisLabel: {
          formatter: LaserPowerUtilizationTimesGenerator.legendFormatter,
        },
      },
      yAxis: {
        type: 'value' as const,
        name: parameters.yAxisName ?? '',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: (p: any) => {
          let tooltipMarkdown: string = ``;
          p.forEach((item: any) => {
            tooltipMarkdown += `
            <p>
                  ${item.marker} ${this.translateSeriesName(item.name)} :
                  <b style="margin-left:32px;float:right">
                  ${i18n.n(item.value)} h
            `;
          });
          return tooltipMarkdown;
        },
      },
      series: [
        {
          type: 'bar',
          data: this.generateSeriesData(data),
        },
      ],
    };
  }

  private generateSeriesData(data: LaserPowerUtilizationTimesData[]) {
    return data
      .sort((dataItem1, dataItem2) => dataItem1.kPower - dataItem2.kPower)
      .map((dataItem, i: number) => ({
        name: `k${dataItem.kPower}_power`,
        value: dataItem.hours,
        itemStyle: {
          color: powerLevelColors[dataItem.kPower],
        },
      }));
  }

  private static legendFormatter(seriesName: string): string {
    // k15 -> kx
    const normalizedName = seriesName.replace(/\d+/, 'x');
    // k15 -> 15
    const seriesNumber = seriesName.match(/\d+/)![0];
    return i18n.t(`report.${normalizedName}`, [seriesNumber]).toString();
  }

  private translateSeriesName(name: string) {
    return LaserPowerUtilizationTimesGenerator.legendFormatter(name);
  }
}
