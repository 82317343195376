
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Measurement } from '@/models/measurement';
import { ValidationProvider } from 'vee-validate';
import { mastersService } from '@/services/masters.service';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class MeasurementsSelect extends Vue {
  @Prop({ default: '' })
  public value!: number;

  @Prop({ default: undefined })
  public rules?: any;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: Measurement[] = [];

  public mounted(): void {
    this.items = this.getItems();
  }
  public getItems() {
    return mastersService.store.getMeasurementUnits();
  }

  public handleSelect(value: number) {
    this.$emit('input', value);
    this.$emit('change', value);
  }

  private get required(): boolean {
    return this.rules?.required || this.rules?.includes?.('required');
  }
}
