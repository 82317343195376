/*
 * --------------------------------------------------------------------------------
 * <copyright file="url.ts" company="Bystronic Laser AG">
 *  Copyright (C) Bystronic Laser AG 2021-2024
 * </copyright>
 * --------------------------------------------------------------------------------
 */


import { isEmpty, isNil } from '@/utils/misc';

type enumType = { [key: number]: string | number };

/**
 * Returns values from a query parameter value as values of the specified
 * numeric enumeration.
 *
 * Given a value from a query parameter ($route.query['someParameter']) it
 * returns the equivalent values from the specified enumeration, excluding null
 * and invalid values.
 *
 * Example:
 *   query parameters: ?someEnum=SomeValue
 *   enum SomeEnum { SomeValue = 1 }
 *   getEnumValuesFromQueryParameterOrDefault(this.$route.query['someEnum'], SomeEnum)
 *    -> [SomeEnum.SomeValue]
 * @param queryParameterValue Value from a query parameter
 *   ($route.query['someParameter']).
 * @param enumeration Enumeration to which the values from the parameter will
 *   be converted to.
 * @param defaultValue The value to return if no values have been extracted from
 *   the query parameters.
 */
export function getEnumValuesFromQueryParameterOrDefault<T extends enumType>(
  queryParameterValue: string | Array<string | null>,
  enumeration: T,
  defaultValue: number[] = [],
): number[] {
  const normalizedAsArrayValue = !Array.isArray(queryParameterValue)
    ? [queryParameterValue] as Array<string | null>
    : queryParameterValue as Array<string | null>;

  const enumValues = normalizedAsArrayValue
    .filter((stringValue) => !isNil(stringValue))
    .map((stringValue) => enumeration[stringValue as any] as number)
    .filter((numericValue) =>
      !isNaN(numericValue) && numericValue in enumeration,
    );

  if (isEmpty(enumValues)) {
    return defaultValue;
  }

  return enumValues;
}
