// --------------------------------------------------------------------------------
// <copyright file="horizontalBarChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { WidgetEnum } from '../enums/WidgetEnum';
import i18n from '@/i18n';
import { EChartsOption, SeriesOption } from 'echarts';
import {
  mockBendingAvailabilityData,
  mockLaserAvailabilityData,
  mockLaserStarveBlockData,
} from './mockWidgetSelectorData';
import { Logger } from '@/utils/logger';
import { GeneratorParams } from './generatorParams';
import { isEmpty } from '@/utils/misc';
import { metricsService } from '@/services/metrics.service';

export class HorizontalBarChartGenerator extends ChartGenerator<any[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    return metricsService.getDevicesMetrics<any[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: any,
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: (params: any) => {
          const filteredParams = params.filter((param: any) => param.axisDim !== 'x');
          let tooltipHtml: string = `<p>${filteredParams[0]?.axisValueLabel}</p>`;
          filteredParams.forEach((item: any) => {
            if (!isNaN(item.value)) {
              tooltipHtml +=
                `<p>${item.marker} ${item.seriesName}:` +
                `<b style="margin-left:32px;float:right">${i18n.n(item.value, {
                  maximumFractionDigits: 2,
                })}</b></p>`;
            }
          });
          return tooltipHtml;
        },
      },
      legend: {
        bottom: 0,
        data: this.generateLegend(data),
      },
      grid: {
        top: 16,
        bottom: 40,
        left: 8,
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01],
        name: parameters.xAxisName ?? '',
        axisLabel: {
          formatter: (value: number) => i18n.n(value),
          hideOverlap: true,
        },
        axisLine: { show: false },
        axisTick: { show: false },
      },
      yAxis: this.getYaxis(data, parameters.targets),
      series: this.getSeries(data, parameters.targets),
    };
  }

  override getMockData(): any[] | null {
    switch (this.procedure) {
      case WidgetEnum.LaserAvailability:
      case WidgetEnum.TubeAvailability:
        return mockLaserAvailabilityData();
      case WidgetEnum.LaserStarveBlock:
      case WidgetEnum.TubeStarveBlock:
        return mockLaserStarveBlockData();
      case WidgetEnum.BendingAvailability:
        return mockBendingAvailabilityData();
      default:
        Logger.error(`Missing mock data for ${this.procedure}`);
        return null;
    }
  }

  private getSeries(data: any, targets: number[] | undefined): SeriesOption[] {
    return (this.procedure !== WidgetEnum.LaserStarveBlock &&
      this.procedure !== WidgetEnum.TubeStarveBlock) ||
      targets == null
      ? this.series(data)
      : [
          ...this.series(data, !targets),
          {
            // NOTE: this is a support series to draw the targets lines
            yAxisIndex: 1,
            type: 'line',
            name: 'Target',
            lineStyle: {
              type: 'dotted',
              color: 'red',
              width: 1,
              borderType: 40,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              opacity: 200,
              curveness: 200,
            },
            symbol: 'none',
            data: [[targets[0], 5], [targets[0], 45], null, [targets[1], 55], [targets[1], 95]],
          },
        ];
  }

  private getYaxis(data: any, targets: number[] | undefined) {
    return (this.procedure !== WidgetEnum.LaserStarveBlock &&
      this.procedure !== WidgetEnum.TubeStarveBlock) ||
      !targets
      ? {
          type: 'category' as const,
          data: this.yAxisTitles(data),
        }
      : [
          {
            type: 'category' as const,
            data: this.yAxisTitles(data),
          },
          {
            show: false,
            type: 'value' as const,
            min: 0,
            max: 100,
            splitLine: {
              show: false,
            },
          },
        ];
  }

  private yAxisTitles(data: any) {
    if (isEmpty(data)) {
      return [];
    }
    return Object.keys(data[0])
      .slice(0, -1)
      .map((name) => i18n.t('report.' + name).toString());
  }

  private series(data: any, targets?: any) {
    let existsPrimary: boolean = false;
    const series: any[] = [];
    data.forEach((dataset: any) => {
      if (dataset.isprimary) {
        existsPrimary = true;
      }
    });

    if (!existsPrimary) {
      series.push(this.generateSeries({ isprimary: true }, targets));
    }

    if (isEmpty(data)) {
      return series;
    }

    data.forEach((dataset: any) => {
      series.push(this.generateSeries(dataset, targets));
    });
    for (let i = 0; i < 2 - data.length; ++i) {
      series.push(
        new Object({
          type: 'bar',
          data: null,
        }),
      );
    }
    return series;
  }

  private generateSeries(dataset: any, targets: any) {
    const serie: any = {};
    serie.type = 'bar';
    serie.name = this.currentPrevTranslation(dataset.isprimary);
    serie.label = {
      show: true,
      position: 'right',
      formatter: (params: any) => i18n.n(params.value, { maximumFractionDigits: 2 }),
    };

    serie.data = Object.values(dataset).slice(0, -1);
    return serie;
  }

  private generateLegend(data: any[]) {
    const legendTitles: string[] = [];
    data.forEach((element) => {
      legendTitles.push(this.currentPrevTranslation(element.isprimary));
    });
    return legendTitles;
  }

  private currentPrevTranslation(isprimary: boolean) {
    return isprimary ? i18n.t('report.current').toString() : i18n.t('report.previous').toString();
  }
}
