
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Sidebar from '@/components/Sidebar.vue';
import Spinner from '@/components/Spinner.vue';
import TermsAgreement from '@/components/TermsAgreementModal.vue';
import { PersistentActions, PersistentGetters } from './store/persistent/enums';
import { RootActions } from './store/enums';
import { mastersService } from './services/masters.service';
import { devicesService } from './services/devices.service';
import { usersService } from './services/users.service';
import { notificationsService } from './services/notifications.service';
import { User } from './models/user';
import { tenantsService } from './services/tenants.service';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { userTargetsService } from './services/userTargets.service';
import moment from 'moment';
import { addAutoRemovedEventListener } from '@/utils/misc';
import { alertsService } from './services/alerts.service';
import { Routes } from './router/routes';
import { shiftsService } from '@/services/shifts.service';
import { localeService } from '@/library-services/locale.service';
import { getAllDevicesByCustomerId } from '@/use-cases/devices';
import { canAccessWorkCenterConsole, hasCurrentUserPermission } from './utils/permissionUtils';
import { UserType } from './models/userType';

@Component({
  components: {
    'sc-header': Header,
    'sc-sidebar': Sidebar,
    'sc-spinner': Spinner,
  },
})
export default class App extends Vue {
  private showSidebar = window.innerWidth > 1024;
  private previousWidth?: number;

  @Ref('sidebar')
  private sidebar!: Sidebar;

  private created() {
    if (this.$route.name !== Routes.LoginCallback) {
      this.$auth.isLoggedIn().then((loggedIn) => {
        if (!loggedIn) {
          this.$auth.login();
        } else {
          this.getUserInfo();
        }
      });
    }
    addAutoRemovedEventListener(this, 'resize', this.adjustSidebarOnResize);
  }

  /**
   * Fetches and saves into Vuex store several useful information used throughout
   * the application.
   *
   * The Vuex store is persisted in the browser local store, so it's always
   * available after the first load. That's why the get*() methods that fetch
   * the data aren't awaited. The first time a user logs in in the page doesn't
   * finish loading until SetUserInfoLoaded is dispatched (see loadedInfoUser
   * check in Index template). The rest of initial pages (Care and Technology
   * consoles) don't need these data.
   */
  private async getUserInfo() {
    this.$spinner.showSpinner();

    try {
      const user = await usersService.me();
      // User is updated after retrieval to store a potentially modified
      // dashboard, modification done to support retrocompatibility.
      // Only applies to users who have a dashboard
      if (user.isCustomer) {
        await usersService.updateDashboards();
      }
      this.$signalr.signIn();
      if (hasCurrentUserPermission(UserType.CustomerUser)) {
        this.getTargets();
      }

      if (user.hasCustomer) {
        await Promise.all([
          this.getCustomer(user.customerId!),
          this.getDevices(user),
          this.getShifts(user.customerId!),
        ]);
        this.getUserTargets(user.customerId!);
      } else {
        await tenantsService.store.update(null);
        await devicesService.store.updateAll([]);
      }
      if (
        (hasCurrentUserPermission(UserType.CustomerAdmin) && canAccessWorkCenterConsole()) ||
        hasCurrentUserPermission(UserType.ServiceUser)
      ) {
        this.getAlertKPIs();
      }
      if (user.isServiceUserType || user.isTechnologyUser) {
        this.getEventModules();
        this.getEventCodes();
      }

      this.getTimezones();
      this.getCurrencyCodes();
      this.getMeasurements();
      this.getDeviceBaseModels();
      this.getNotifications();
    } finally {
      await this.$store.dispatch(RootActions.SetUserInfoLoaded, true);
      this.$spinner.removeSpinner();
    }

    moment.locale(localeService.store.currentLocale);
  }

  private async getCustomer(customerId: number) {
    this.$spinner.showSpinner();
    const customer = await tenantsService
      .getById(customerId)
      .finally(() => this.$spinner.removeSpinner());
    if (customer.hasExpired()) {
      await this.$auth.logout();
    }
    await tenantsService.store.update(customer);
  }

  private async getDevices(user: User) {
    if (!user.consoles.includes(ConsoleEnum.WCC)) {
      return;
    }
    this.$spinner.showSpinner();
    await getAllDevicesByCustomerId(user.customerId!);
    this.$spinner.removeSpinner();
  }

  private async getShifts(customerId: number) {
    this.$spinner.showSpinner();
    // getByTenantId already adds the shifts to the store, so we don't add them here
    await shiftsService.getByTenantId(customerId).finally(() => this.$spinner.removeSpinner());
  }

  private async getTargets() {
    this.$spinner.showSpinner();
    await userTargetsService.getTargets().finally(() => this.$spinner.removeSpinner());
  }

  private async getUserTargets(tenantId: number) {
    this.$spinner.showSpinner();
    await userTargetsService.getForTenant(tenantId).finally(() => this.$spinner.removeSpinner());
  }

  private async getTimezones() {
    this.$spinner.showSpinner();
    await mastersService.getTimezones().finally(() => this.$spinner.removeSpinner());
  }

  private async getCurrencyCodes() {
    this.$spinner.showSpinner();
    await mastersService.getCurrencyCodes().finally(() => this.$spinner.removeSpinner());
  }

  private async getMeasurements() {
    this.$spinner.showSpinner();
    await mastersService.getMeasurementUnits().finally(() => this.$spinner.removeSpinner());
  }

  private async getDeviceBaseModels() {
    this.$spinner.showSpinner();
    await devicesService.getDeviceBaseModels().finally(() => this.$spinner.removeSpinner());
  }

  private async getNotifications() {
    this.$spinner.showSpinner();
    await notificationsService.get().finally(() => this.$spinner.removeSpinner());
  }

  private async getAlertKPIs() {
    this.$spinner.showSpinner();
    const groups = await alertsService.getAlertKPIs().finally(() => this.$spinner.removeSpinner());
    await this.$store.dispatch(PersistentActions.SetKPIGroups, groups);
  }

  private async getEventModules() {
    this.$spinner.showSpinner();
    await mastersService.getEventModules().finally(() => this.$spinner.removeSpinner());
  }

  private async getEventCodes() {
    this.$spinner.showSpinner();
    await mastersService.getEventCodes().finally(() => this.$spinner.removeSpinner());
  }

  private adjustSidebarOnResize() {
    const width = window.innerWidth;
    if (width !== this.previousWidth) {
      if (width >= 1024 !== this.showSidebar) {
        this.previousWidth = width;
        this.showSidebar = width >= 1024;
      }
    }
  }

  private toggleSidebar() {
    this.sidebar.toggle();
  }

  private async toggleBeta() {
    await usersService.toggleBeta();
  }

  private get user(): User {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  private get loadedInfoUser(): boolean {
    return this.$store.getters.userInfoLoaded;
  }

  private get showTermsAgreement() {
    const persistent = this.user.isAcceptedTermsOfAgreement;
    const session = this.$store.getters.termsAcceptedSession;

    return this.user.hasCustomer && !persistent && !session;
  }

  @Watch('loadedInfoUser')
  private watchLoadedInfoUser() {
    if (this.loadedInfoUser && this.showTermsAgreement) {
      this.$buefy.modal.open({
        component: TermsAgreement,
        hasModalCard: true,
        canCancel: false,
        parent: this,
      });
    }
  }
}
