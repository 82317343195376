
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { uniqueId } from '@/utils/misc';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ChangeToInProgress extends Vue {
  @Prop()
  private value?: string;

  private formValue?: string;
  private componentId = uniqueId();

  private get validationRules() {
    return {
      crmCaseNumber: {
        max: 50,
      },
    };
  }

  private created() {
    this.formValue = this.value;
  }

  private async onSubmit() {
    this.$emit('confirmInProgress', this.formValue);
    this.$emit('close');
  }
}
