// --------------------------------------------------------------------------------
// <copyright file="healthTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { EChartsOption, LineSeriesOption } from 'echarts';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { GeneratorParams } from './generatorParams';
import {
  HealthChartDataEntry,
  HealthChartsData,
  HealthTimelineData,
} from '@/models/Charts/chartsData';
import { iterable, uniq } from '@/utils/array';
import { isCategoryXAxis } from '@/utils/charts';
import { abbreviateNumber } from '@/utils/number';
import { LevelColor } from '@/utils/color';
import { isEmpty, isNil } from '@/utils/misc';
import i18n from '@/i18n';
import { translateUnit } from '@/utils/alerts';
import { Logger } from '@/utils/logger';
import { tooltipFormatter } from '@/models/Charts/healthTimelineTooltipFormatter';
import { metricsService } from '@/services/metrics.service';

export const MISSING_DATA_VALUE = null;

export class HealthTimelineGenerator extends ChartGenerator<HealthChartsData> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
  ) {
    const deviceId = selectedDevices[0];
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<HealthChartsData>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom,
        dateTo,
        dateGrouping: timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(data: HealthChartsData, parameters: GeneratorParams = {}): EChartsOption {
    const isCategoryAxis = this.isCategoryXAxis(parameters.timeAxisSpan, data);
    const uniqueDates = this.getUniqueDates(data);

    const dimensionsSpecification = {
      chartsCount: 5,

      bottomPadding: 2,

      titleHeight: 4,
      titleTopMargin: 3,
      titleBottomMargin: 0,

      chartsLeftPadding: 44,
      chartsRightPadding: 8,
    };
    const titles = iterable(5).map((_, i) => this.getChartTitle(data, i));
    const series = this.generateSeries(data, uniqueDates, isCategoryAxis);

    return {
      title: this.getDimensions(dimensionsSpecification, titles).title,
      grid: this.getDimensions(dimensionsSpecification, titles).grid,
      color: [
        this.getSeriesColor('min'),
        this.getSeriesColor('median'),
        this.getSeriesColor('average'),
        this.getSeriesColor('filteredMax'),
        this.getSeriesColor('max'),
        '#5470c6', // health
      ],
      legend: {
        type: 'scroll',
        icon: 'roundRect',
        formatter: (name: string) => i18n.t(`health_timeline_legend.${name}`).toString(),
        bottom: 0,
        data: ['health', 'min', 'median', 'average', 'filteredMax', 'max'],
        selected: {
          health: true,
          min: false,
          median: false,
          average: false,
          filteredMax: false,
          max: false,
        },
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => tooltipFormatter(params, data, parameters.timeAxisSpan!),
      },
      visualMap: this.generateVisualMap(data, series),
      xAxis: this.getChartDataEntries(data).map((chartEntry, index) =>
        isNil(chartEntry)
          ? this.getNoDataAxisOptions(index)
          : {
              gridIndex: index,
              type: isCategoryAxis ? 'category' : 'time',
              axisLabel: {
                hideOverlap: true,
              },
              data: isCategoryAxis ? uniqueDates : undefined,
            },
      ),
      yAxis: this.getChartDataEntries(data).map((chartEntry, index) =>
        isNil(chartEntry)
          ? this.getNoDataAxisOptions(index)
          : {
              gridIndex: index,
              axisLabel: {
                formatter: (value: number) => abbreviateNumber(value),
              },
              axisPointer: {
                show: false,
              },
              max: Math.max(
                chartEntry.e,
                chartEntry.timelineData
                  .map((x) => x.max)
                  .reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE),
              ),
              name: this.getYAxisName(data, index),
              nameTextStyle: {
                padding: [0, 0, -2, 0],
              },
            },
      ),
      axisPointer: {
        show: true,
        link: [{ xAxisIndex: 'all' }],
      },
      series,
    };
  }

  private generateSeries(data: HealthChartsData, uniqueDates: string[], isCategoryAxis: boolean) {
    // Generates series options, so we can show a centered "No data" message
    // through a fake data point.
    const noDataSeriesOptions = (name: string, index: number): LineSeriesOption => ({
      type: 'line',
      xAxisIndex: index,
      yAxisIndex: index,
      ...getNoDataSeriesOptions(),
      name,
    });

    return this.getChartDataObjects(data).flatMap(([name, chartDataObject], index) =>
      isEmpty(chartDataObject)
        ? noDataSeriesOptions(name, index)
        : this.generateChartSeries(name, uniqueDates, chartDataObject!, index, isCategoryAxis),
    );
  }

  private generateChartSeries(
    chartName: keyof HealthChartsData,
    uniqueDates: string[],
    chartData: HealthChartDataEntry,
    index: number,
    isCategoryAxis: boolean,
  ): LineSeriesOption[] {
    return [
      ...this.getChartFields(chartName).map(
        (chartField) =>
          ({
            name: chartField,
            type: 'line',
            xAxisIndex: index,
            yAxisIndex: index,
            data: this.generateSeriesData(
              chartData!.timelineData,
              chartField,
              uniqueDates,
              isCategoryAxis,
            ),
            connectNulls: true,
            lineStyle: {
              type: 'dashed' as const,
            },
          } as any), // null isn't included in LineSeriesOption.data typing, but works
      ),
      {
        name: 'health',
        type: 'line',
        xAxisIndex: index,
        yAxisIndex: index,
        data: this.generateSeriesData(
          chartData!.timelineData,
          this.getSeriesFieldName('health', chartName),
          uniqueDates,
          isCategoryAxis,
        ),
        connectNulls: true,
      },
    ];
  }

  private getChartFields(chartName: keyof HealthChartsData): Array<keyof HealthTimelineData> {
    if (chartName === 'drawerCyclesTime') {
      return ['min', 'median', 'average', 'max'];
    }

    return ['min', 'median', 'average', 'filteredMax', 'max'];
  }

  private generateSeriesData(
    seriesData: HealthTimelineData[],
    fieldName: keyof HealthTimelineData,
    uniqueDates: string[],
    isCategoryAxis: boolean,
  ): Array<[string, number | null] | number | null> {
    // FIXME: Copied and adapted from LaserInstantConsumptionKxSeriesGeneratorHelper
    let index = 0;

    return uniqueDates.map((date) => {
      while (index < seriesData.length && seriesData[index].date < date) {
        ++index;
      }

      const value =
        seriesData[index]?.date === date
          ? (seriesData[index][fieldName] as number)
          : MISSING_DATA_VALUE;

      return isCategoryAxis ? value : [date, value];
    });
  }
  private getSeriesFieldName(
    seriesName: string,
    chartName: keyof HealthChartsData,
  ): keyof HealthTimelineData {
    if (seriesName === 'health') {
      return chartName === 'drawerCyclesTime' ? 'max' : 'filteredMax';
    }

    return seriesName as keyof HealthTimelineData;
  }

  private getYAxisName(data: HealthChartsData, index: number): string {
    const title = this.getKpiKey(data, index);
    return HealthTimelineGenerator.getUnit(title);
  }

  static getUnit(title: keyof HealthChartsData, value?: number): string {
    switch (title) {
      case 'lowerProtectiveGlass':
      case 'upperProtectiveGlass':
        return translateUnit('photodiode_abbreviation', value ?? null);
      case 'drawerCyclesTime':
        return translateUnit('seconds_abbreviation', value ?? null);
      case 'lensDrive':
        return translateUnit('milliampere_abbreviation', value ?? null);
      case 'temperatures':
        return translateUnit('celsius_degrees_abbreviation', value ?? null);
    }
  }

  private getChartDataObjects(
    data: HealthChartsData,
  ): Array<[keyof HealthChartsData, HealthChartDataEntry | null]> {
    return Object.entries(data) as Array<[keyof HealthChartsData, HealthChartDataEntry | null]>;
  }

  private getUniqueDates(data: HealthChartsData) {
    const allDates = this.getChartDataEntries(data)
      .flatMap((dataEntry) => dataEntry?.timelineData ?? [])
      .map((dataItem) => dataItem.date);
    return uniq(allDates).sort();
  }

  private isCategoryXAxis(
    timeAxisSpan: FilterTimeAxisSpanEnum | undefined,
    data: HealthChartsData,
  ) {
    const maxLength = this.getMaxChartDataLength(data);
    return isCategoryXAxis(timeAxisSpan, maxLength);
  }

  private getMaxChartDataLength(data: HealthChartsData) {
    return Math.max(
      ...this.getChartDataEntries(data).map((chartData) => chartData?.timelineData?.length ?? 0),
    );
  }

  private generateVisualMap(data: HealthChartsData, series: LineSeriesOption[]) {
    return this.getChartDataEntries(data).map((chartData, index) => ({
      seriesIndex: this.getHealthSeriesIndex(index, series),
      show: false,
      pieces: isNil(chartData)
        ? []
        : [
            {
              lt: chartData.a,
              color: LevelColor.A,
            },
            {
              gte: chartData.a,
              lt: chartData.b,
              color: LevelColor.B,
            },
            {
              gte: chartData.b,
              lt: chartData.c,
              color: LevelColor.C,
            },
            {
              gte: chartData.c,
              lt: chartData.d,
              color: LevelColor.D,
            },
            {
              gte: chartData.d,
              color: LevelColor.E,
            },
          ],
    }));
  }

  private getHealthSeriesIndex(chartIndex: number, series: LineSeriesOption[]) {
    const isNoDataSeries = (seriesItem: LineSeriesOption) => seriesItem.symbolSize === 0;

    return series.findIndex(
      (series) =>
        series.xAxisIndex === chartIndex && (series.name === 'health' || isNoDataSeries(series)),
    );
  }

  // We need this mostly for typing, because Object.values always returns any[].
  private getChartDataEntries(data: HealthChartsData): Array<HealthChartDataEntry | null> {
    return Object.values(data);
  }

  private getKpiKey(data: HealthChartsData, index: number): keyof HealthChartsData {
    return this.getChartDataObjects(data)[index][0];
  }

  private getChartTitle(data: HealthChartsData, index: number) {
    return i18n.t(`report.${this.getKpiKey(data, index)}`).toString();
  }

  private getDimensions(spec: any, titles: string[]): any {
    const totalTitleHeight = spec.titleHeight + spec.titleTopMargin + spec.titleBottomMargin;
    const allTitlesHeight = totalTitleHeight * spec.chartsCount;
    const allChartsHeight = 100 - spec.bottomPadding - allTitlesHeight;
    const chartHeight = allChartsHeight / spec.chartsCount;

    const grid = iterable(spec.chartsCount).map((_, i) => {
      const top = totalTitleHeight * (i + 1) - spec.titleTopMargin + chartHeight * i + 1;
      return {
        top: `${top}%`,
        height: `${chartHeight}%`,
        left: spec.chartsLeftPadding,
        right: spec.chartsRightPadding,
      };
    });

    const title = iterable(spec.chartsCount).map((_, i) => {
      const top =
        spec.titleTopMargin * i + (chartHeight + spec.titleHeight + spec.titleBottomMargin) * i;
      return {
        top: `${top}%`,
        text: titles[i],
        textStyle: {
          fontSize: '16px',
          fontWeight: 400,
          color: 'rgb(74, 74, 74)',
        },
      };
    });

    return {
      grid,
      title,
    };
  }

  // Generates axis options, so we can show a centered "No data" message through
  // a fake data point.
  private getNoDataAxisOptions(index: number) {
    return {
      gridIndex: index,
      axisLabel: { show: false },
      axisLine: { show: false },
      splitLine: { show: false },
      axisTick: { show: false },
      axisPointer: { show: false },
      min: -1,
      max: 1,
    };
  }

  private getSeriesColor(seriesName: keyof HealthTimelineData) {
    switch (seriesName) {
      case 'min':
        return '#ade8f4';
      case 'median':
        return '#48cae4';
      case 'average':
        return '#0096e7';
      case 'filteredMax':
        return '#023e8a';
      case 'max':
        return '#03045e';
      default:
        Logger.error('Invalid series name', seriesName);
        return '#03045e';
    }
  }
}

export function getNoDataSeriesOptions() {
  return {
    data: [[0, 0]],
    showSymbol: true,
    symbolSize: 0,
    label: {
      show: true,
      fontSize: 14,
      formatter: i18n.t('no_data').toString(),
    },
    cursor: 'default',
    emphasis: {
      disabled: true,
    },
  };
}
