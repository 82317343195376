// --------------------------------------------------------------------------------
// <copyright file="index.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Devices from '@/views/Devices.vue';
import Subsidiaries from '@/views/subsidiaries/Subsidiaries.vue';
import SubsidiaryDetail from '@/views/subsidiaries/subsidiary/SubsidiaryDetail.vue';
import Customers from '@/views/customers/Customers.vue';
import DeviceByTenant from '@/views/ControlList.vue';
import CustomerDetail from '@/views/customers/CustomerDetail.vue';
import Index from '@/views/Index.vue';
import WorkcenterOverview from '@/views/workcenterConsole/WorkCenterConsoleOverview.vue';
import Cut from '@/views/workcenterConsole/Cut.vue';
import Bend from '@/views/workcenterConsole/Bend.vue';
import GanttChart from '@/views/workcenterConsole/GanttChart.vue';
import TechnologyConsoleDevice from '@/views/technologyConsole/TechnologyConsoleDevice.vue';
import Manufacturing from '@/views/shopfloorConsole/Manufacturing.vue';
import BusinessConsoleOverview from '@/views/businessConsole/Overview.vue';
import Quotes from '@/views/businessConsole/Quotes.vue';
import Sales from '@/views/businessConsole/Sales.vue';
import Users from '@/views/Users.vue';
import TechnologyConsoleTenantDevices
  from '@/views/technologyConsole/TechnologyConsoleTenantDevices.vue';
import Login from '@/components/authentication/Login.vue';
import Logout from '@/components/authentication/Logout.vue';
import { UserType } from '@/models/userType';
import {
  canAccessBusinessConsole,
  canAccessCareConsoleAlerts,
  canAccessControlList,
  canAccessEServiceCenter,
  canAccessShopFloorConsole,
  canAccessTechnologyConsole,
  canAccessWorkCenterConsole,
  hasCurrentUserPermission,
} from '@/utils/permissionUtils';
import { ToastProgrammatic as Toast } from 'buefy';
import i18n from '@/i18n';
import Tubes from '@/views/workcenterConsole/Tubes.vue';
import { Routes } from './routes';
import CareConsoleDevice from '@/views/careConsole/CareConsoleDevice.vue';
import TechnologyConsole from '@/views/technologyConsole/Overview.vue';
import EServiceCenterCustomers from '@/views/careConsole/EServiceCenterCustomers.vue';
import CareConsoleAlerts from '@/views/careConsole/CareConsoleAlerts.vue';
import About from '@/components/About.vue';
import DataPlatforms from '@/views/dataPlatform/DataPlatforms.vue';
import EServiceCenterEquipment from '@/views/careConsole/EServiceCenterEquipment.vue';
import EServiceCenterInbox from '@/views/careConsole/EServiceCenterInbox.vue';
import { localeService } from '@/library-services/locale.service';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  // Back office
  {
    path: '/devices',
    name: Routes.Devices,
    component: Devices,
    meta: {
      canNavigate: createAccessLevelGuard(UserType.CustomerAdmin),
    },
  },
  {
    path: '/subsidiaries',
    name: Routes.Subsidiaries,
    component: Subsidiaries,
    meta: {
      canNavigate: createAccessLevelGuard(UserType.SubsidiaryAdmin),
    },
  },
  {
    path: '/subsidiaries/:id/detail',
    name: Routes.SubsidiaryDetail,
    component: SubsidiaryDetail,
    meta: {
      canNavigate: createAccessLevelGuard(UserType.SubsidiaryAdmin),
    },
  },
  {
    path: '/tenants',
    name: Routes.Tenants,
    component: Customers,
    meta: {
      canNavigate: createAccessLevelGuard(UserType.CustomerUser),
    },
  },
  {
    path: '/tenants/:id/detail',
    name: Routes.TenantDetail,
    component: CustomerDetail,
    meta: {
      canNavigate: createAccessLevelGuard(UserType.CustomerUser),
    },
  },
  {
    path: '/control-list',
    name: Routes.ControlList,
    component: DeviceByTenant,
    meta: {
      canNavigate: canAccessControlList,
    },
  },
  {
    path: '/users',
    name: Routes.Users,
    component: Users,
    meta: {
      canNavigate: createAccessLevelGuard(UserType.CustomerAdmin),
    },
  },
  {
    path: '/dataplatforms',
    name: Routes.DataPlatforms,
    component: DataPlatforms,
    meta: {
      canNavigate: createAccessLevelGuard(UserType.SubsidiaryAdmin),
    },
  },
  // Work Center Console
  {
    path: '/workcenter-console/overview',
    name: Routes.WorkcenterOverview,
    component: WorkcenterOverview,
    meta: {
      canNavigate: canAccessWorkCenterConsole,
    },
  },
  {
    path: '/workcenter-console/bend',
    name: Routes.Bend,
    component: Bend,
    props: true,
    meta: {
      canNavigate: canAccessWorkCenterConsole,
    },
  },
  {
    path: '/workcenter-console/cut',
    name: Routes.Cut,
    component: Cut,
    props: true,
    meta: {
      canNavigate: canAccessWorkCenterConsole,
    },
  },
  {
    path: '/workcenter-console/cut/gantt',
    name: Routes.CutGanttChart,
    component: GanttChart,
    meta: {
      canNavigate: canAccessWorkCenterConsole,
    },
  },
  {
    path: '/workcenter-console/tube',
    name: Routes.Tubes,
    component: Tubes,
    props: true,
    meta: {
      canNavigate: canAccessWorkCenterConsole,
    },
  },
  {
    path: '/workcenter-console/tube/gantt',
    name: Routes.TubeGanttChart,
    component: GanttChart,
    meta: {
      canNavigate: canAccessWorkCenterConsole,
    },
  },
  // Technology Console
  {
    path: '/technology-console/',
    name: Routes.TechnologyConsole,
    component: TechnologyConsole,
    meta: {
      canNavigate: canAccessTechnologyConsole,
    },
  },
  {
    path: '/technology-console/:tenantIdDh',
    name: Routes.TechnologyConsoleTenantDevices,
    component: TechnologyConsoleTenantDevices,
    meta: {
      canNavigate: canAccessTechnologyConsole,
    },
  },
  {
    path: '/technology-console/:tenantIdDh/device/:deviceId',
    name: Routes.TechnologyConsoleDevice,
    component: TechnologyConsoleDevice,
    meta: {
      canNavigate: canAccessTechnologyConsole,
    },
  },
  // Shop Floor Console
  {
    path: '/shopfloor-console/manufacturing',
    name: Routes.Manufacturing,
    component: Manufacturing,
    meta: {
      canNavigate: canAccessShopFloorConsole,
    },
  },
  // Business Console
  {
    path: '/business-console/overview',
    name: Routes.BusinessConsoleOverview,
    component: BusinessConsoleOverview,
    meta: {
      canNavigate: canAccessBusinessConsole,
    },
  },
  {
    path: '/business-console/quotes',
    name: Routes.Quotes,
    component: Quotes,
    meta: {
      canNavigate: canAccessBusinessConsole,
    },
  },
  {
    path: '/business-console/sales',
    name: Routes.Sales,
    component: Sales,
    meta: {
      canNavigate: canAccessBusinessConsole,
    },
  },
  // eService center (formerly Care Console)
  {
    path: '/e-service-center',
    name: Routes.EServiceCenter,
    redirect: {
      name: Routes.EServiceCenterInbox,
    },
    meta: {
      canNavigate: canAccessEServiceCenter,
    },
  },
  {
    path: '/e-service-center/inbox',
    name: Routes.EServiceCenterInbox,
    component: EServiceCenterInbox,
    meta: {
      canNavigate: canAccessEServiceCenter,
    },
  },
  {
    path: '/e-service-center/customers',
    name: Routes.EServiceCenterCustomers,
    component: EServiceCenterCustomers,
    meta: {
      canNavigate: canAccessEServiceCenter,
    },
  },
  {
    path: '/e-service-center/equipment',
    name: Routes.EServiceCenterEquipment,
    component: EServiceCenterEquipment,
    meta: {
      canNavigate: canAccessEServiceCenter,
    },
  },
  {
    path: '/e-service-center/customers/:customerIdDh',
    name: Routes.EServiceCenterCustomerEquipment,
    component: EServiceCenterEquipment,
    meta: {
      canNavigate: canAccessEServiceCenter,
    },
  },
  {
    path: '/e-service-center/customers/:customerIdDh/equipment/:equipmentId',
    name: Routes.EServiceCenterEquipmentView,
    component: CareConsoleDevice,
    meta: {
      canNavigate: canAccessEServiceCenter,
    },
  },
  {
    path: '/e-service-center/alerts',
    name: Routes.EServiceCenterAlerts,
    component: CareConsoleAlerts,
    meta: {
      canNavigate: canAccessCareConsoleAlerts,
    },
  },
  // Others
  {
    path: '/about',
    name: Routes.About,
    component: About,
  },
  {
    path: '/',
    name: Routes.Index,
    component: Index,
  },
  {
    path: '/authentication/login',
    name: Routes.Login,
    component: Login,
  },
  {
    path: '/authentication/login-failed',
    name: Routes.LoginFailed,
    component: Login,
  },
  {
    path: '/authentication/login-callback',
    name: Routes.LoginCallback,
    component: Login,
  },
  {
    path: '/authentication/logout',
    name: Routes.Logout,
    component: Logout,
  },
  {
    path: '/authentication/logged-out',
    name: Routes.LoggedOut,
    component: Logout,
  },
  {
    path: '/authentication/logout-callback',
    name: Routes.LogoutCallback,
    component: Logout,
  },
  // default route, when none of the above matches
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to: Route, from: Route, next) => {
  if (!hasGuard(to)) {
    next();
    return;
  }

  if (to.meta!.canNavigate()) {
    next();
  } else {
    const localeCode = localeService.store.currentLocale;

    Toast.open({
      duration: 5000,
      message: i18n.t('route.unauthorized_message', localeCode).toString(),
      position: 'is-top',
      type: 'is-danger',
    });

    next(false); // Abort navigation and remain in the previous route
  }
});

function hasGuard(to: Route): boolean {
  return to.matched.some((record) => record.meta?.canNavigate);
}

function createAccessLevelGuard(level: UserType) {
  return () => hasCurrentUserPermission(level);
}

export default router;
