
import { Component, Prop, Vue } from 'vue-property-decorator';
import { copyToClipboard } from '@/utils/clipboard';
import { toastService } from '@/library-services/toast.service';
import i18n from '@/i18n';

@Component({})
export default class GenericKeyCopyModal extends Vue {
  @Prop({ default: false })
  private loading!: boolean;
  @Prop({ required: true })
  private title!: string;
  @Prop({ required: true })
  private value!: string | null;

  private copyKey() {
    try {
      copyToClipboard(this.value!);
      toastService.success(i18n.t('action.clipboard').toString());
    } catch (e) {
      toastService.error(`${e}`);
    }
  }
}
