// --------------------------------------------------------------------------------
// <copyright file="clipboard.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

const copyToClipboardFallback = (text: string): boolean => {
  let success: boolean = false;
  const textArea: HTMLTextAreaElement = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    success = document.execCommand('copy');
  } finally {
    document.body.removeChild(textArea);
  }
  return success;
};

const copyToClipboard = async (text: string) => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  } else if (!copyToClipboardFallback(text)) {
    throw Error('Could not copy to clipboard');
  }
};

export { copyToClipboard, copyToClipboardFallback };
