// --------------------------------------------------------------------------------
// <copyright file="environment.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

class Environment {
  getEnvironment(): EnvironmentValue {
    return (
      (process.env.VUE_APP_ENV?.toLowerCase() as EnvironmentValue) ?? EnvironmentValue.DEVELOPMENT
    );
  }

  isLocalDevelopmentEnvironment(): boolean {
    return this.getEnvironment() === EnvironmentValue.LOCAL_DEVELOPMENT;
  }

  isProductionEnvironment(): boolean {
    return this.getEnvironment() === EnvironmentValue.PRODUCTION;
  }

  getDataStorageBlobName() {
    return `kiotdatastorage${
      !this.isLocalDevelopmentEnvironment() ? this.getEnvironment() : EnvironmentValue.DEVELOPMENT
    }`;
  }
}

export default new Environment();

export enum EnvironmentValue {
  LOCAL_DEVELOPMENT = 'local-dev',
  DEVELOPMENT = 'dev',
  QA = 'qa',
  PRODUCTION = 'pro',
}
