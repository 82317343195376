
import { Component, Prop, Vue } from 'vue-property-decorator';
import { isNil } from '@/utils/misc';

@Component({})
export default class OldTimeZoneIndicator extends Vue {
  @Prop({ required: true, default: null })
  private timeZone!: string | null;

  private now: Date = new Date();
  private timeIntervalHandle = -1;

  private mounted() {
    this.setUpTimeUpdater();
  }

  private get name() {
    return this.timeZone ?? '-';
  }

  private get time() {
    if (isNil(this.timeZone)) {
      return '-';
    }

    return Intl.DateTimeFormat(
      undefined, // browser default
      { hour: 'numeric', minute: 'numeric', timeZone: this.timeZone },
    ).format(this.now);
  }

  private setUpTimeUpdater() {
    if (this.timeIntervalHandle !== -1) {
      return;
    }

    this.timeIntervalHandle = window.setInterval(
      () => (this.now = new Date()),
      60 * 1000, // every minute
    );

    this.$once('hook:beforeDestroy', () => clearInterval(this.timeIntervalHandle));
  }
}
