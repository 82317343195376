// --------------------------------------------------------------------------------
// <copyright file="dictLocale.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

// Taken from
// https://raw.githubusercontent.com/umpirsky/language-list/master/data/{locale}/language.json
const dictLocale: Record<string, string> = {
  cs: 'Čeština',
  en: 'English',
  es: 'Español',
  de: 'Deutsch',
  da: 'Dansk',
  fi: 'Suomi',
  fr: 'Français',
  hu: 'Magyar',
  it: 'Italiano',
  ja: '日本語',
  ko: '한국어',
  nl: 'Nederlands',
  pl: 'Polski',
  'pt-BR': 'Português do Brasil',
  ru: 'Русский',
  sv: 'Svenska',
  tr: 'Türkçe',
  zh: '中文',
  'zh-HANT': '\u7e41\u9ad4\u4e2d\u6587',
} as const;

export { dictLocale };

export function getLocaleFullName(lang: string): string {
  return dictLocale[lang];
}
