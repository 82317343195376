// --------------------------------------------------------------------------------
// <copyright file="dummyChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator } from './abstract/chartGenerator';
import { EChartsOption } from 'echarts';
import { GeneratorParams } from './generatorParams';

interface Data {
  good: number;
  bad: number;
}

export default class DummyChartGenerator extends ChartGenerator<Data[]> {
  override getData() {
    const good = Math.round(Math.random() * 10);
    const bad = Math.round(Math.random() * 4);
    return Promise.resolve([
      {
        good,
        bad,
      },
    ]);
  }

  override updateOptions(
    data: Data[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        top: '5%',
        left: 'center',
      },
      series: [
        {
          name: 'Karma types',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: false,
          },
          data: [
            { value: data[0].bad, name: 'Bad' },
            { value: data[0].good, name: 'Good' },
          ],
        },
      ],
    };
  }
}
