// --------------------------------------------------------------------------------
// <copyright file="alertWorkflows.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { AlertWorkflow, AlertWorkflowDetail, AlertWorkflowUpdateDTO } from '@/models/alertWorkflow';
import { responseHandler } from '@/services/response.handler';

class AlertWorkflowsService {
  get(): Promise<AlertWorkflow[]> {
    return axios
      .get<AlertWorkflow[]>(`${BaseUrl}/alert-workflows`)
      .then((response) => responseHandler.handleDates(response.data));
  }

  getDetail(id: number): Promise<AlertWorkflowDetail> {
    return axios
      .get<AlertWorkflowDetail>(`${BaseUrl}/alert-workflows/${id}`)
      .then((response) => responseHandler.handleDates(response.data));
  }

  update(alertWorkflow: AlertWorkflowUpdateDTO) {
    return axios.put(`${BaseUrl}/alert-workflows/`, alertWorkflow).then((response: any) => {
      return response.data;
    });
  }
}

export const alertWorkflowsService = new AlertWorkflowsService();
