
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { translateFilterTimeAxisSpanEnum } from '@/utils/i18n';

@Component
export default class TimeAxisSpanButton extends Vue {
  @Prop({ default: 'is-right' })
  private position!: string;
  @Ref('bDropdown')
  private bDropdown: any;
  private timeAxisSpans: FilterTimeAxisSpanEnum[] = [];
  @Prop({ default: FilterTimeAxisSpanEnum.Day })
  private value!: FilterTimeAxisSpanEnum;

  public toggle() {
    this.bDropdown.toggle();
  }

  private getName(value: FilterTimeAxisSpanEnum) {
    return translateFilterTimeAxisSpanEnum(value);
  }

  private mounted() {
    this.initialize();
  }

  private initialize() {
    this.timeAxisSpans = Object.values(FilterTimeAxisSpanEnum)
      .filter(
        (x) =>
          !isNaN(Number(x)) &&
          x !== FilterTimeAxisSpanEnum.None &&
          x !== FilterTimeAxisSpanEnum.Hour,
      )
      .map((x) => Number(x));
  }
}
