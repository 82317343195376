// --------------------------------------------------------------------------------
// <copyright file="enums.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum RootMutations {
  ShowSpinner = 'showSpinner',
  RemoveSpinner = 'removeSpinner',
  SetTermsAcceptedSession = 'setTermsAcceptedSession',
  SetUserInfoLoaded = 'setUserInfoLoaded',
  SetCustomers = 'setCustomers',
}

export enum RootActions {
  ShowSpinner = 'showSpinner',
  RemoveSpinner = 'removeSpinner',
  SetTermsAcceptedSession = 'setTermsAcceptedSession',
  SetUserInfoLoaded = 'setUserInfoLoaded',
  SetCustomers = 'setCustomers',
}

export enum RootGetters {
  Spinners = 'spinners',
  TermsAcceptedSession = 'termsAcceptedSession',
  UserInfoLoaded = 'userInfoLoaded',
  Customers = 'customers',
}

export enum Mode {
  Create = 'create',
  Edit = 'edit',
  View = 'view',
}
