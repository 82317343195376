// --------------------------------------------------------------------------------
// <copyright file="ConsoleEnum.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum ConsoleEnum {
  // Work Center Console
  WCC = 1,
  // Business Console
  BC = 2,
  // Shop Floor Console
  SFC = 3,
  Technology = 4,
  Care = 5,
}
