
import { Component, Vue } from 'vue-property-decorator';
import { EquipmentFilterValues } from '@/views/careConsole/commons/model';
import { areArraysEqual } from '@/utils/array';
import CustomTableFilters from '@/components/tableWithActions/CustomTableFilters.vue';

@Component({
  components: {
    CustomTableFilters,
  },
})
export default class EquipmentFilters extends Vue {
  private static readonly defaultFilterValues: EquipmentFilterValues = Object.freeze({
    mySubscriptions: false,
    noSubscriptions: false,
    customerName: [],
    name: [],
    model: [],
    equipmentNumber: [],
    locationName: [],
    subsidiaryName: [],
    subscribers: [],
  });

  private filters = structuredClone(EquipmentFilters.defaultFilterValues);

  clearAllFilters() {
    this.filters = structuredClone(EquipmentFilters.defaultFilterValues);
    this.emitFilters();
  }

  private emitFilters() {
    this.$emit('filters-change', this.filters, this.areApplying);
  }

  private get areApplying(): boolean {
    return (
      this.areTextListFiltersApplying ||
      this.filters.mySubscriptions ||
      this.filters.noSubscriptions
    );
  }

  private get areTextListFiltersApplying(): boolean {
    return [
      'customerName',
      'name',
      'model',
      'equipmentNumber',
      'locationName',
      'subsidiaryName',
      'subscribers',
    ].some(
      (textListFilterKey) =>
        !areArraysEqual(
          this.filters[textListFilterKey as keyof EquipmentFilterValues] as string[],
          EquipmentFilters.defaultFilterValues[
            textListFilterKey as keyof EquipmentFilterValues
          ] as string[],
        ),
    );
  }
}
