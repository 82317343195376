
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SidebarEntry extends Vue {
  @Prop({ required: true })
  private label!: string;

  @Prop({ default: false })
  private active!: boolean;

  @Prop({ default: false })
  private expanded!: boolean;

  @Prop()
  private icon?: string;

  @Prop()
  private iconPack?: string;

  @InjectReactive('isSidebarExpanded')
  private isSidebarExpanded!: boolean;
}
