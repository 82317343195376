// --------------------------------------------------------------------------------
// <copyright file="laserPowerUtilizationHistogramGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { powerLevelColors } from '@/utils/color';
import { LaserPowerUtilizationTimesData } from './chartsData';
import { EChartsOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { iterable } from '@/utils/array';
import { abbreviateNumber } from '@/utils/number';
import { metricsService } from '@/services/metrics.service';

export const TOTAL_POWER_LEVELS = 30;

export class LaserPowerUtilizationHistogramGenerator extends ChartGenerator<
  LaserPowerUtilizationTimesData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const deviceId = selectedDevices[0];
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<LaserPowerUtilizationTimesData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom,
        dateTo,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserPowerUtilizationTimesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      grid: {
        top: 30,
        bottom: 10,
        left: 10,
        right: 38,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        name: 'kW',
        axisTick: {
          interval: 0,
        },
        axisLabel: {
          interval: 0,
          rotate: 90,
          hideOverlap: true,
        },
        data: this.powerLevels(),
      },
      yAxis: {
        type: 'value',
        name: 'h',
        axisLabel: {
          formatter: abbreviateNumber,
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: LaserPowerUtilizationHistogramGenerator.tooltipFormatter,
      },
      series: [
        {
          type: 'bar',
          data: this.generateSeriesData(data),
        },
      ],
    };
  }

  private generateSeriesData(data: LaserPowerUtilizationTimesData[]) {
    return iterable(TOTAL_POWER_LEVELS).map((index) => {
      const powerLevel = index + 1;
      const dataItem = data.find((item) => item.kPower === powerLevel);

      return {
        name: `k${powerLevel}_power`, // Used by the tooltip formatter
        value: dataItem?.hours ?? 0,
        itemStyle: {
          color: powerLevelColors[powerLevel],
        },
      };
    });
  }

  private powerLevels() {
    return iterable(TOTAL_POWER_LEVELS).map((index) => index + 1);
  }

  private static tooltipFormatter(params: any): string {
    return `
    <div class="tooltip-series-value-row">
      <div class="series-marker-name">
        ${params[0].marker}
        
        <span>
          ${LaserPowerUtilizationHistogramGenerator.translateCategoryName(params[0].name)}:
        </span>
      </div>
      <div class="series-value" data-testid="series-value">
        ${i18n.n(params[0].value)} h
      </div>
    </div>
    `;
  }

  private static translateCategoryName(name: string) {
    // k15 -> kx
    const normalizedName = name.replace(/\d+/, 'x');
    // k15 -> 15
    const seriesNumber = name.match(/\d+/)![0];
    return i18n.t(`report.${normalizedName}`, [seriesNumber]).toString();
  }
}
