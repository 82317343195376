import { render, staticRenderFns } from "./TableWithActions.vue?vue&type=template&id=27cb8346&scoped=true"
import script from "./TableWithActions.vue?vue&type=script&lang=ts"
export * from "./TableWithActions.vue?vue&type=script&lang=ts"
import style0 from "./TableWithActions.vue?vue&type=style&index=0&id=27cb8346&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27cb8346",
  null
  
)

export default component.exports