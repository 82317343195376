import { render, staticRenderFns } from "./HealthIndicators.vue?vue&type=template&id=b4c4a650&scoped=true"
import script from "./HealthIndicators.vue?vue&type=script&lang=ts"
export * from "./HealthIndicators.vue?vue&type=script&lang=ts"
import style0 from "./HealthIndicators.vue?vue&type=style&index=0&id=b4c4a650&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4c4a650",
  null
  
)

export default component.exports