// --------------------------------------------------------------------------------
// <copyright file="page-filters.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Shift } from '@/models/shift';
import { shiftsService } from '@/services/shifts.service';
import { Device } from '@/models/device';
import { isEmpty } from '@/utils/misc';

export function getActiveShifts(
  selectedShiftIds: number[],
  selectedDeviceIds: string[],
  devices: Device[],
) {
  const deviceIds = devices.map((device: Device) => device.deviceId);

  return isEmpty(selectedShiftIds)
    ? []
    : getShiftsFromActiveDevices(selectedDeviceIds, deviceIds).filter((shift) =>
        selectedShiftIds.includes(shift.id),
      );
}

function getShiftsFromActiveDevices(selectedDeviceIds: string[], deviceIds: string[]): Shift[] {
  return shiftsService.store.getAllByDeviceIds(getActiveDeviceIds(selectedDeviceIds, deviceIds));
}

function getActiveDeviceIds(selectedDeviceIds: string[], deviceIds: string[]): string[] {
  return selectedDeviceIds.length !== 0 ? selectedDeviceIds : deviceIds;
}
