// --------------------------------------------------------------------------------
// <copyright file="dialog.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { isNil } from '@/utils/misc';
import { DialogProgrammatic } from 'buefy';
import { BComponent, BDialogConfig } from 'buefy/types/components';

type DialogMethod = (params: BDialogConfig) => BComponent;

class DialogService {
  private openWithPromise(method: DialogMethod, params: BDialogConfig) {
    return method({
      hasIcon: true,
      ...params,
      closeOnConfirm: false,
      onConfirm: async (value, dialog) => {
        const { cancelLoading, close, startLoading } = dialog;
        if (isNil(params.onConfirm)) {
          close();
          return;
        }
        startLoading();
        try {
          await params.onConfirm(value, dialog);
          close();
        } finally {
          cancelLoading();
        }
      },
    });
  }

  confirmWithPromise(params: BDialogConfig): BComponent {
    return this.openWithPromise(DialogProgrammatic.confirm, params);
  }
}

export const dialogService = new DialogService();
