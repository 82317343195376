// --------------------------------------------------------------------------------
// <copyright file="MachineType.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum MachineType {
  Undefined = 0,
  Cut = 1,
  Bend = 2,
  FactoryOverview = 3,
  Tube = 4,
}
