
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserType } from '@/models/userType';
import { Location } from '@/models/location';
import LocationEditionModal from '@/components/locations/LocationEditionModal.vue';
import { locationsService } from '@/services/locations.service';
import { Tenant } from '@/models/tenant';
import ModalCreationModal from '@/components/locations/ModalCreationModal.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import ConnectionStringModal from '@/components/locations/ConnectionStringModal.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { canDownloadDataPlatform, hasCurrentUserPermission } from '@/utils/permissionUtils';
import { dataPlatformService } from '@/services/dataplatform.service';
import { downloadUrl } from '@/utils/misc';
import { modalService } from '@/library-services/modal.service';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  methods: { canDownloadDataPlatform },
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class CustomerLocations extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  private tenant!: Tenant;

  private locations: Location[] = [];
  private isPreparingDataPlatformDownload = false;
  private isLoading = false;

  private async created() {
    this.locations = await this.fetchLocations();
  }

  private async fetchLocations(): Promise<Location[]> {
    this.isLoading = true;
    return await locationsService.get(this.tenant.id).finally(() => (this.isLoading = false));
  }

  private createLocation() {
    modalService.open({
      parent: this,
      component: ModalCreationModal,
      props: {
        tenant: this.tenant,
      },
      events: {
        save: async () => (this.locations = await this.fetchLocations()),
      },
    });
  }

  private async downloadDataPlatform() {
    this.isPreparingDataPlatformDownload = true;
    const url = await dataPlatformService
      .getInstallerUrl()
      .finally(() => (this.isPreparingDataPlatformDownload = false));
    downloadUrl(url);
  }

  private startEdit(location: Location) {
    modalService.open({
      parent: this,
      component: LocationEditionModal,
      props: {
        location,
        tenant: this.tenant,
      },
      events: {
        save: async () => (this.locations = await this.fetchLocations()),
      },
    });
  }

  private confirmDelete(location: Location) {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: location.hasApiKey
        ? this.$i18n
            .t('location.dialog.delete_connection_string', { name: location.name })
            .toString()
        : this.$i18n
            .t('confirmation.message', {
              action: this.$i18n.t('action.delete').toString().toLowerCase(),
              entity: this.$i18n.t('location.name').toString().toLowerCase(),
              name: location.name,
            })
            .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t('dialog.confirm_cancel').toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteLocation(location.id),
    });
  }

  private async deleteLocation(id: number) {
    await locationsService
      .delete(id)
      .then(() => (this.locations = this.locations.filter((x) => x.id !== id)));
  }

  private openConnectionStringDialog(location: Location) {
    this.$buefy.dialog.confirm({
      message: this.$t('location.dialog.confirm_connection_string').toString(),
      onConfirm: () => {
        modalService.open({
          parent: this,
          component: ConnectionStringModal,
          canCancel: ['outside'],
          props: {
            location,
          },
        });
      },
    });
  }

  private get canManageLocations(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }
}
