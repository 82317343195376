
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ChangeToPostponedDate extends Vue {
  @Prop()
  private value?: Date;

  private async onSubmit() {
    this.$emit('confirmPostponed', this.value);
    this.$emit('close');
  }

  private get isEnabled() {
    return !!this.value;
  }
}
