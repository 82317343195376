// --------------------------------------------------------------------------------
// <copyright file="CutLayoutTab.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum CutLayoutTab {
  CutOverview,
  CutCurrentWorkload,
  CutIntradayStates,
  CutTrends,
}
