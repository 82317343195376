// --------------------------------------------------------------------------------
// <copyright file="bendPerformanceRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { metricsService } from '@/services/metrics.service';
import { BendPerformanceData } from '../chartsData';

export class BendPerformanceRetriever extends DataRetriever<BendPerformanceData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<BendPerformanceData[] | null> {
    const selectedTimeSpan = timeSpan as FilterTimeSpanEnum;
    return metricsService.getDevicesMetrics<BendPerformanceData[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: selectedTimeSpan,
      },
      this.controller,
    );
  }
}
