// --------------------------------------------------------------------------------
// <copyright file="quotesSizeGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import moment from 'moment';
import { currency } from '@/utils/currency';
import i18n from '@/i18n';
import { getColor } from '@/utils/color';
import { abbreviateNumber, numberWithCurrency } from '@/utils/number';
import { QuotesSizeData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockQuotesSizeData } from './mockWidgetSelectorData';
import { metricsService } from '@/services/metrics.service';

export class QuotesSizeGenerator extends ChartGenerator<QuotesSizeData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  private getLimits(
    params: Record<string, any> | undefined,
  ): [number, number] | [undefined, undefined] {
    if (!params?.amountFilter) {
      return [undefined, undefined];
    }
    const keys = [...Object.keys(params.amountFilter).map((x) => Number(x))];
    const startAmountIndex = Math.min(...keys);
    const endAmountIndex = Math.max(...keys);
    return [params.amountFilter[startAmountIndex], params.amountFilter[endAmountIndex]];
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    const groupNumber = params?.groupNumber || 5;
    const date = moment().format('YYYY-MM-DD');
    const [startAmount, endAmount] = this.getLimits(params);
    return metricsService.getSMBSMetricsBC<QuotesSizeData[]>(
      this.procedure,
      this.tenantIdDh,
      {
        startDate,
        endDate,
        groupNumber,
        date,
        startAmount,
        endAmount,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: QuotesSizeData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        extraCssText: 'z-index:1',
        confine: true,
        formatter: this.formatter(data),
      },
      legend: {
        bottom: 0,
        type: 'scroll',
      },
      grid: [
        {
          left: '9%',
          right: '5%',
          bottom: 42,
          top: 32,
        },
        {
          left: '9%',
          right: '5%',
          bottom: 42,
          top: 32,
        },
      ],
      xAxis: [
        {
          type: 'category',
          show: false,
        },
        {
          name: currency(),
          type: 'value',
          show: true,
          position: 'bottom',
          splitLine: {
            show: false,
          },
          splitNumber: 30,
          min: data[0].bin_start,
          max: data[29].bin_end,
          interval: (data[0].bin_end - data[0].bin_start) * 3,
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: '#f4f4f4',
              width: 1,
            },
          },
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        {
          gridIndex: 1,
          type: 'value',
          show: false,
        },
      ],
      series: this.series(data),
    };
  }

  override getMockData(): QuotesSizeData[] | null {
    return mockQuotesSizeData();
  }

  private formatter(data: QuotesSizeData[]) {
    return (params: any) => {
      const bin = data.filter((_, index: number) => index === params[0].dataIndex);
      let res = `
          <div>${numberWithCurrency(bin[0].bin_start)}
           - ${numberWithCurrency(bin[0].bin_end)}</div>`;
      res += params
        .map((item: any) => {
          return `<div style="display: flex; flex-direction: row;">
              <div style="flex-grow: 1; padding-right: 3em;">${item.marker} ${
            item.seriesName
          }:</div>
              <div><b>${i18n.n(item.data, { maximumFractionDigits: 2 })}</b></div>
            </div>`;
        })
        .join('');
      res += `<div style="display: flex; flex-direction: row;">
            <div style="flex-grow: 1; padding-right: 3em;">${i18n.t(
              'report.business_console.total_quotes',
            )}:</div>
            <div><b>${i18n.n(bin[0].not_open_quotes + bin[0].open_quotes, {
              maximumFractionDigits: 2,
            })}</b></div>
          </div>`;
      return res;
    };
  }

  private series(data: QuotesSizeData[]) {
    return [
      {
        name: i18n.t('report.business_console.not_open_quotes').toString(),
        type: 'bar' as const,
        stack: 'one',
        data: this.data(data, 'not_open_quotes'),
        barWidth: '100%',
        itemStyle: {
          color: getColor(0, 0),
        },
      },
      {
        name: i18n.t('report.business_console.open_quotes').toString(),
        type: 'bar' as const,
        stack: 'one',
        data: this.data(data, 'open_quotes'),
        barWidth: '100%',
        itemStyle: {
          color: getColor(0, 45),
        },
      },
      {
        type: 'line' as const,
        xAxisIndex: 1,
        markLine: {
          data: this.divisors(data, 'divisor'),
          symbol: 'none' as const,
          lineStyle: {
            width: 2,
          },
          label: {
            show: false,
          },
        },
        itemStyle: {
          color: getColor(1, 0),
        },
      },
    ];
  }

  private data(data: QuotesSizeData[], dataName: keyof QuotesSizeData) {
    return data.map((item) => item[dataName] as number);
  }

  private divisors(data: QuotesSizeData[], dataName: keyof QuotesSizeData) {
    return data
      .filter((item) => item[dataName] !== null)
      .map((item) => new Object({ xAxis: item[dataName] }));
  }
}
