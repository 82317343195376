
import { Component, Vue } from 'vue-property-decorator';
import { tenantsService } from '@/services/tenants.service';
import TableActionButton2 from '@/components/tableWithActions/TableActionButton2.vue';
import TableWithActions2 from '@/components/tableWithActions/TableWithActions2.vue';
import { router } from '@/library-services/e-service-center-router';
import CustomerFilters from './CustomerFilters.vue';
import {
  containsSubscribers,
  createTextListMatcher,
  FilterMatcher,
  hasSubscriptions,
  isCurrentUserSubscribed,
} from './commons/matchers';
import { CustomerEServiceData, CustomerFilterValues, SubscribedUser } from './commons/model';
import { matchIgnoringCase } from '@/utils/table';

const MAX_SUBSCRIBED_USERS_SHOWN = 5;

@Component({
  components: {
    CustomerFilters,
    TableWithActions2,
    TableActionButton2,
  },
  computed: {
    MAX_SUBSCRIBED_USERS_SHOWN: () => MAX_SUBSCRIBED_USERS_SHOWN,
  },
})
export default class EServiceCenterCustomers extends Vue {
  private customers: CustomerEServiceData[] = [];

  private isLoading = true;

  private filterMatchers: Record<keyof CustomerFilterValues, FilterMatcher> = {
    mySubscriptions: isCurrentUserSubscribed,
    noSubscriptions: hasSubscriptions,
    name: createTextListMatcher<CustomerFilterValues>('name'),
    subsidiaryName: createTextListMatcher<CustomerFilterValues>('subsidiaryName'),
    subscribers: containsSubscribers,
  };

  private async created() {
    this.customers = await this.fetchCustomers();
  }

  private async fetchCustomers() {
    return await tenantsService.getEServiceCenterData().finally(() => (this.isLoading = false));
  }

  private formatUserNames(userNames: SubscribedUser[]): string {
    return userNames
      .map((user) => user.name)
      .slice(0, MAX_SUBSCRIBED_USERS_SHOWN)
      .join(', ');
  }

  private async goToCustomerDevices(customer: CustomerEServiceData) {
    await router.goToCustomerEquipment(customer.idDh);
  }

  private async goToFirstEquipmentCareConsole(customer: CustomerEServiceData) {
    await router.goToEquipmentCareConsole(customer.idDh, customer.equipmentIds[0]);
  }

  private async goToFirstEquipmentWorkCenterConsole(customer: CustomerEServiceData) {
    await router.goToEquipmentWorkCenterConsole(customer.idDh, customer.equipmentIds[0]);
  }

  private subscribedUsersMatcher(customer: CustomerEServiceData, searchText: string): boolean {
    return matchIgnoringCase(customer.subscribedUsers.map((x) => x.name).join(''), searchText);
  }
}
