// --------------------------------------------------------------------------------
// <copyright file="salesPerSalespersonTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { Category, SalesPerSalespersonTimelineData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, SeriesOption } from 'echarts';
import { mockSalesPerSalespersonTimelineData } from './mockWidgetSelectorData';
import { abbreviateNumber } from '@/utils/number';
import { DEFAULT_TOP_NUMBER } from './salesTopBySalesGenerator';
import { getColor } from '@/utils/color';
import { metricsService } from '@/services/metrics.service';

export class SalesPerSalespersonTimelineGenerator extends ChartGenerator<
  SalesPerSalespersonTimelineData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    return metricsService.getSMBSMetricsBC<SalesPerSalespersonTimelineData[]>(
      this.procedure,
      this.tenantIdDh,
      {
        paramNumber: params?.topNumber || DEFAULT_TOP_NUMBER,
        startDate,
        endDate,
        axisTimespan: timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: SalesPerSalespersonTimelineData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      title: [
        {
          top: -5,
          left: 'center',
          text: Category.A,
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 18 : 14,
          },
        },
        {
          top: '30%',
          left: 'center',
          text: Category.B,
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 18 : 14,
          },
        },
        {
          top: '62%',
          left: 'center',
          text: Category.C,
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 18 : 14,
          },
        },
      ],
      tooltip: {
        show: true,
      },
      legend: {
        icon: 'roundRect',
        type: 'scroll',
        bottom: 0,
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      dataZoom: [
        {
          type: 'inside',
          filterMode: 'weakFilter',
          xAxisIndex: [0, 1, 2],
        },
      ],
      grid: [
        {
          top: 25,
          left: 50,
          right: 10,
          height: '22%',
        },
        {
          left: 50,
          right: 10,
          top: '36%',
          height: '22%',
        },
        {
          left: 50,
          right: 10,
          bottom: 50,
          height: '22%',
        },
      ],
      xAxis: Object.values(Category).map((category, i) => ({
        gridIndex: i,
        type: 'category',
        axisLabel: { show: i === 2 },
        axisTick: { show: i === 2 },
        data: this.getCategoryDates(data, category),
      })),
      yAxis: Object.keys(Category).map((_, i) => ({
        gridIndex: i,
        type: 'value',
        splitLine: {
          show: true,
        },
        axisLabel: {
          formatter: abbreviateNumber,
        },
      })),
      series: this.generateSeries(data),
    };
  }

  private getCategoryDates(data: SalesPerSalespersonTimelineData[], category: Category): string[] {
    return this.removeDuplicates(
      data.filter((dataItem) => dataItem.category === category).map((dataItem) => dataItem.date),
    );
  }

  private generateSeries(data: SalesPerSalespersonTimelineData[]): SeriesOption[] {
    // Sort salespeople to ensure generateSalespersonPerCategorySeries assigns
    // the same color as in SalesHorizontalBarChartGenerator charts.
    return this.getSalespeopleNames(data)
      .sort()
      .map((salesPersonName, salespersonIndex) =>
        this.generateSalespersonPerCategorySeries(data, salesPersonName, salespersonIndex),
      )
      .flat();
  }

  private getSalespeopleNames(data: SalesPerSalespersonTimelineData[]): string[] {
    return this.removeDuplicates(data.map((dataItem) => dataItem.salesperson));
  }

  private generateSalespersonPerCategorySeries(
    data: SalesPerSalespersonTimelineData[],
    salespersonName: string,
    salespersonIndex: number,
  ): SeriesOption[] {
    // Skip two first colors used in "Amount vs. Nº of quotes conversion by
    // salesperson" chart to make clear they aren't related.
    const salespersonColor = getColor(salespersonIndex + 2, 0);

    return Object.values(Category).map((category) => ({
      data: this.getSeriesData(data, salespersonName, category),
      type: 'bar' as const,
      name: salespersonName,
      emphasis: { focus: 'series' as const },
      color: salespersonColor,
      showSymbol: false,
      xAxisIndex: Object.values(Category).indexOf(category),
      yAxisIndex: Object.values(Category).indexOf(category),
    }));
  }

  private getDates(data: SalesPerSalespersonTimelineData[]): string[] {
    return this.removeDuplicates(data.map((dataItem) => dataItem.date));
  }

  private removeDuplicates(items: any[]) {
    return [...new Set(items)];
  }

  private getSeriesData(
    data: SalesPerSalespersonTimelineData[],
    salesPersonName: string,
    category: Category,
  ) {
    return this.getDates(data).map((date) => {
      const matchingDataItem = data.find(
        (dataItem) =>
          dataItem.date === date &&
          dataItem.category === category &&
          dataItem.salesperson === salesPersonName,
      );
      return matchingDataItem?.amount ?? 0;
    });
  }

  override getMockData(): SalesPerSalespersonTimelineData[] {
    return mockSalesPerSalespersonTimelineData();
  }
}
