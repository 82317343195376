// --------------------------------------------------------------------------------
// <copyright file="finishedPOEstimatedVsActualPOLGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { getColor } from '@/utils/color';
import { abbreviateNumber } from '@/utils/number';
import { FinishedPOData } from './chartsData';
import { EChartsOption, SeriesOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockFinishedPOEstimatedVsActualPOLData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { isCategoryXAxis } from '@/utils/charts';
import { TooltipFormatter } from '@/models/Charts/tooltipFormatter';
import { metricsService } from '@/services/metrics.service';

export class FinishedPOEstimatedVsActualPOLGenerator extends ChartGenerator<FinishedPOData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];

    return metricsService.getSMBSMetricsSFC<FinishedPOData[]>(
      this.procedure,
      this.tenantIdDh,
      { startDate, endDate, axisTimespan: timeAxisSpan },
      this.controller,
    );
  }

  override updateOptions(
    data: FinishedPOData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);
    return {
      dataZoom: {
        type: 'inside',
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: TooltipFormatter.build(parameters.timeAxisSpan)
          .withSeriesTranslationPrefix('')
          .get(),
      },
      legend: {
        bottom: 0,
        type: 'scroll',
      },
      grid: {
        containLabel: true,
        left: '3%',
        right: '4%',
        bottom: 40,
        top: 32,
      },
      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        ...(isCategoryAxis && {
          data: this.xAxisData(data),
        }),
      },
      yAxis: {
        type: 'value',
        name: 'h',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f4f4f4',
            width: 1,
          },
        },
        axisLabel: {
          formatter: (value: number) => abbreviateNumber(value),
        },
      },
      series: this.series(data),
    };
  }

  override getMockData(): FinishedPOData[] | null {
    return mockFinishedPOEstimatedVsActualPOLData();
  }

  private series(data: FinishedPOData[]): SeriesOption[] {
    const mainSeries: SeriesOption[] = this.getSeriesNames(data).map(
      (name: keyof FinishedPOData) => ({
        name: i18n.t(`report.business_console.${name}`).toString(),
        type: 'bar',
        data: data.map((item) => [item.date, item[name]]),
        itemStyle: {
          color: this.barToColor(name),
        },
        barGap: '0%',
      }),
    );

    const capacitySeries: SeriesOption = {
      name: i18n.t('report.capacity').toString(),
      type: 'line',
      data: data.map((dataItem) => [dataItem.date, dataItem.capacity]),
      lineStyle: {
        color: 'rgb(160, 197, 242)',
        type: 'dashed',
      },
      connectNulls: true,
    };

    return [...mainSeries, capacitySeries];
  }

  private getSeriesNames(data: FinishedPOData[]): Array<keyof FinishedPOData> {
    return Object.keys(data[0]).filter(this.isSeriesField) as Array<keyof FinishedPOData>;
  }

  private isSeriesField(fieldName: string): boolean {
    return fieldName !== 'date' && fieldName !== 'capacity';
  }

  private xAxisData(data: FinishedPOData[]) {
    const xAxisData: string[] = data.map((item) => item.date);
    return [...new Set(xAxisData)];
  }

  private barToColor(key: string) {
    let index: number;
    if (key === 'estimated_time_hours') {
      index = 1;
    } else {
      index = 3;
    }
    return getColor(index, 0);
  }
}
