
import { Component, Vue } from 'vue-property-decorator';
import { User } from '@/models/user';
import CustomDashboard from './CustomDashboard.vue';
import DashboardDefinition, { DashboardLayout } from '@/models/Charts/dashboardDefinition';
import { usersService } from '@/services/users.service';
import i18n from '@/i18n';
import AutoplayInterval from '@/components/inputs/AutoplayInterval.vue';
import { DashboardMode as Mode } from '@/views/dashboard/dashboardMode';

@Component({
  computed: {
    Mode: () => Mode,
  },
  components: {
    CustomDashboard,
  },
})
export default class DashboardsCarousel extends Vue {
  private mode = Mode.Normal;
  private carouselIndex = 0;
  private touchStartX = 0;
  private touchEndX = 0;

  private addCustomDashboard() {
    const dashboards: DashboardDefinition[] = [
      ...this.user.dashboards,
      new DashboardDefinition(
        `${i18n.t('view.name')} ${this.user.dashboards.length + 1}`,
        [],
        DashboardLayout.OneByOne,
      ),
    ];
    this.mode = Mode.Creation;
    this.updateDefinitions(dashboards, true);
    this.carouselIndex = this.user.dashboards.length - 1;
  }

  private saveDashboard(index: number, dashboardDefinition: DashboardDefinition) {
    const dashboards: DashboardDefinition[] = [...this.user.dashboards];
    dashboards[index].name = dashboardDefinition.name;
    dashboards[index].widgetDefinitions = dashboardDefinition.widgetDefinitions;
    dashboards[index].layout = dashboardDefinition.layout;
    this.updateDefinitions(dashboards);
    this.mode = Mode.Normal;
  }

  private deleteDashboard(index: number) {
    const dashboards: DashboardDefinition[] = [...this.user.dashboards];
    dashboards.splice(index, 1);
    this.updateDefinitions(dashboards, false);
    this.mode = Mode.Normal;
  }

  private async updateDefinitions(
    newDefinitions: DashboardDefinition[],
    isTemporal: boolean = false,
  ) {
    const user = User.GetCopy(this.user);
    user.dashboards = newDefinitions;
    await usersService.store.update(user);
    if (!isTemporal) {
      usersService.updateDashboards();
    }
  }

  private cancelDashboard(index: number) {
    if (this.mode === Mode.Creation) {
      this.deleteDashboard(index);
    }
  }

  private async toggleAutoplay() {
    const user = User.GetCopy(this.user);
    user.isAutoplay = !this.autoplay;
    await usersService.update(user);
  }

  private editAutoplayInterval() {
    this.$buefy.modal.open({
      parent: this,
      component: AutoplayInterval,
      hasModalCard: true,
      trapFocus: true,
      props: {
        autoplayInterval: this.user.autoplayPeriod,
      },
      events: {
        save: async (newInterval: number) => {
          const user = User.GetCopy(this.user);
          user.autoplayPeriod = newInterval;
          await usersService.update(user);
        },
      },
    });
  }

  private onIndicatorClick(event: Event) {
    if (this.isEditorEnabled) {
      event.stopPropagation();
    }
  }

  private onTouchStart(event: TouchEvent) {
    this.touchStartX = event.changedTouches[0].screenX;
  }

  private onTouchEnd(event: TouchEvent) {
    this.touchEndX = event.changedTouches[0].screenX;
    if (Math.abs(this.touchEndX - this.touchStartX) < 75 || this.isEditorEnabled) {
      return;
    }
    if (this.touchEndX > this.touchStartX) {
      if (this.carouselIndex !== 0) {
        this.carouselIndex -= 1;
      }
    } else {
      if (this.carouselIndex !== this.user.dashboards.length - 1) {
        this.carouselIndex += 1;
      } else if (
        this.carouselIndex === this.user.dashboards.length - 1 &&
        window.matchMedia('only screen and (hover: none)').matches &&
        !this.autoplay
      ) {
        this.addCustomDashboard();
      }
    }
  }

  // Called by Index
  async onBeforeRouteLeave(): Promise<boolean> {
    const dashboards = this.$refs.dashboard as CustomDashboard[] | undefined;
    const dashboard = dashboards?.[this.carouselIndex];
    return (await dashboard?.onBeforeRouteLeave()) ?? true;
  }

  private get isEditorEnabled() {
    return [Mode.Creation, Mode.Edition].includes(this.mode);
  }

  private get autoplay(): boolean {
    return this.user?.isAutoplay;
  }

  private get autoplayInterval(): number {
    return this.user.autoplayPeriod * 1000;
  }

  private get user() {
    return usersService.store.current();
  }
}
