
import { Component, Vue, Watch } from 'vue-property-decorator';
import { User } from '@/models/user';
import { PersistentGetters } from '@/store/persistent/enums';
import { Tenant } from '@/models/tenant';
import DashboardsCarousel from './dashboard/DashboardsCarousel.vue';
import { RootGetters } from '@/store/enums';
import { Route } from 'vue-router';
import { Routes } from '@/router/routes';

@Component({
  components: {
    DashboardsCarousel,
  },
})
export default class Index extends Vue {
  private mounted() {
    this.go();
  }

  @Watch('loadedInfoUser')
  @Watch('isTechnologyUser')
  @Watch('isSupportUser')
  private go() {
    if (!this.loadedInfoUser) {
      return;
    }

    if (this.isTechnologyUser) {
      this.$router.replace({
        name: Routes.TechnologyConsole,
      });
    } else if (this.isSupportUser) {
      this.$router.replace({
        name: Routes.EServiceCenter,
      });
    }
  }

  private get loadedInfoUser(): boolean {
    return this.$store.getters.userInfoLoaded;
  }

  private get termsAccepted(): boolean {
    return (
      this.user.isAcceptedTermsOfAgreement || this.$store.getters[RootGetters.TermsAcceptedSession]
    );
  }

  private get hasTenant(): boolean {
    return !!this.tenant;
  }

  private get isTechnologyUser(): boolean {
    return this.user.isTechnologyUser;
  }

  private get isSupportUser(): boolean {
    return this.user.isServiceUserType;
  }

  private get tenant(): Tenant {
    return this.$store.getters[PersistentGetters.GetTenant];
  }

  private get user(): User {
    return this.$store.getters[PersistentGetters.CurrentUser];
  }

  private async beforeRouteLeave(to: Route, from: Route, next: (r?: boolean) => void) {
    const dashboardsCarousel = this.$refs.dashboard as DashboardsCarousel | undefined;
    const continueRoute = (await dashboardsCarousel?.onBeforeRouteLeave()) ?? true;
    next(continueRoute);
  }
}
