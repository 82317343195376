
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OverviewDeviceCardKpi extends Vue {
  @Prop({ required: true })
  private width!: string;

  @Prop({ required: true })
  private label!: string;

  @Prop({ default: false, type: Boolean })
  private loading!: boolean;
}
