// --------------------------------------------------------------------------------
// <copyright file="driveAccumulatedCurrentDifferenceTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { DriveAccumulatedCurrentDifferenceData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, LineSeriesOption, SeriesOption } from 'echarts';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { XAXisOption } from 'echarts/types/dist/shared';
import { uniq } from '@/utils/array';
import { metricsService } from '@/services/metrics.service';
import {
  CUTTING_HEAD_VERSION_CHANGE_SYMBOL,
  cuttingHeadDataHasCategoryAxis,
  doesAllDataComeFromNewerCuttingHeadSoftware,
  getHeadVersionChangeSeriesData,
} from './cuttingHeadCommons';
import { tooltipFormatter } from './driveAccumulatedCurrentDifferenceTimelineTooltipFormatter';

export class DriveAccumulatedCurrentDifferenceTimelineGenerator extends ChartGenerator<
  DriveAccumulatedCurrentDifferenceData[]
> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ) {
    const deviceId = selectedDevices[0];
    return metricsService.getSSCMetrics<DriveAccumulatedCurrentDifferenceData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: timeSpan[0],
        dateTo: timeSpan[1],
        dateGrouping: timeAxisSpan,
        versionInformation: true,
        interruptIfV4: true,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: DriveAccumulatedCurrentDifferenceData[],
    parameters: GeneratorParams = {},
  ): EChartsOption {
    if (doesAllDataComeFromNewerCuttingHeadSoftware(data)) {
      return {
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: i18n.t('widget_info_text.no_data_for_ch').toString(),
              overflow: 'break',
              width: 300,
              fontSize: '18px',
              fontFamily: 'Inter',
              fontWeight: 'bold',
              textAlign: 'center',
            } as any,
          },
        ],
      };
    }

    // Only regular linear data must be taken into account to decide if the chart must
    // be treated as categorical or not, so version history data is omitted.
    const isCategoryAxis = cuttingHeadDataHasCategoryAxis(parameters.timeAxisSpan, data);

    return {
      legend: {
        icon: 'roundRect',
        bottom: 0,
        selectedMode: false,
        formatter: (name: string) => i18n.t(`report.${name}`).toString(),
      },
      dataZoom: [
        {
          type: 'inside',
          filterMode: 'weakFilter',
        },
      ],
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => tooltipFormatter(params, parameters, isCategoryAxis, data),
      },
      grid: {
        top: '4%',
        left: 7,
        right: 20,
        bottom: 30,
        containLabel: true,
      },
      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        data: isCategoryAxis ? uniq(data.map((dataItem) => dataItem.bucket)) : undefined,
        splitLine: {
          show: false,
        },
        axisLabel: {
          hideOverlap: true,
        },
      } as XAXisOption,
      yAxis: {
        type: 'value',
        scale: true,
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value: number) => i18n.n(value),
        },
      },
      series: this.generateSeries(data, isCategoryAxis),
    };
  }

  private generateSeries(
    data: DriveAccumulatedCurrentDifferenceData[],
    isCategoryAxis: boolean,
  ): SeriesOption[] {
    const lineChartSeries: LineSeriesOption = {
      name: 'accumulated_difference',
      data: this.getSeriesData(
        'accumulated_diff',
        data.filter((x) => !!x.accumulated_diff),
        isCategoryAxis,
      ),
      type: 'line',
    };
    const headVersionChangeSeriesData = getHeadVersionChangeSeriesData(data, isCategoryAxis);
    // This returns true if the array is empty or if all values are null
    if (headVersionChangeSeriesData.every((x) => x === null)) {
      return [lineChartSeries];
    }
    return [
      lineChartSeries,
      {
        data: headVersionChangeSeriesData,
        type: 'scatter',
        // FIXME: We should be able to load the image like below, but for some
        //   reason it looks blurry.
        // symbol: 'image:///images/cutting-head-version-change-mark.svg',
        symbol: CUTTING_HEAD_VERSION_CHANGE_SYMBOL,
        symbolSize: 15,
        symbolOffset: [0, '-50%'],
        color: '#000000',
        z: 4, // Make the symbol appear above the rest of the series
      },
    ];
  }

  private getSeriesData(
    fieldName: keyof DriveAccumulatedCurrentDifferenceData,
    data: DriveAccumulatedCurrentDifferenceData[],
    isCategoryAxis: boolean,
  ) {
    return isCategoryAxis
      ? data.map((dataItem) => dataItem[fieldName]!)
      : data.map((dataItem) => [dataItem.bucket, dataItem[fieldName]!]);
  }
}
