// --------------------------------------------------------------------------------
// <copyright file="rawMaterialUtilizationTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { EChartsOption, SeriesOption } from 'echarts';
import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { LaserOutputScrapHistoricalData } from './chartsData';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { XAXisOption } from 'echarts/types/dist/shared';
import { getUnitTransform, weightUnit } from '@/utils/measurement';
import { abbreviateNumber } from '@/utils/number';
import { GeneratorParams } from './generatorParams';
import { isCategoryXAxis } from '@/utils/charts';
import { uniq } from '@/utils/array';
import { groupByDate } from '@/utils/dates';
import { TooltipFormatter } from '@/models/Charts/tooltipFormatter';
import { metricsService } from '@/services/metrics.service';

export class RawMaterialUtilizationTimelineGenerator extends ChartGenerator<
  LaserOutputScrapHistoricalData[]
> {
  private readonly utilizationSeriesName = i18n.t('report.utilization_percentage').toString();

  constructor(procedure: ProcedureName, public customerIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
  ) {
    const startDate = (timeSpan as [string, string])[0];
    const endDate = (timeSpan as [string, string])[1];

    return metricsService.getDevicesMetrics<LaserOutputScrapHistoricalData[]>(
      this.procedure,
      {
        tenantIdDh: this.customerIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        startDate,
        endDate,
        timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserOutputScrapHistoricalData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);
    const disabledMaterials = this.getDisabledMaterials(parameters);
    const dates = this.getDates(data);

    return {
      grid: {
        top: 28,
        bottom: 40,
        left: 8,
        right: 12,
        containLabel: true,
      },
      legend: {
        show: true,
        bottom: 0,
        type: 'scroll',
        // Avoid overriding enabled legend items if updateOptions is called on legend change
        selected: parameters.legendParams?.selected ?? {},
      },
      xAxis: {
        type: isCategoryAxis ? 'category' : 'time',
        data: isCategoryAxis ? dates : undefined,
      } as XAXisOption, // type=category doesn't have data property,
      yAxis: [
        {
          name: weightUnit(),
          type: 'value',
          axisLabel: {
            formatter: abbreviateNumber,
          },
        },
        {
          name: '%',
          type: 'value',
          splitLine: {
            show: false,
          },
          axisLabel: {
            formatter: (value: number) => i18n.n(value),
          },
        },
      ],
      dataZoom: [
        {
          type: 'inside',
        },
      ],
      tooltip: {
        trigger: 'axis',
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: TooltipFormatter.build(parameters.timeAxisSpan)
          .withSeriesTranslationPrefix(undefined)
          .get(),
      },
      series: this.series(data, dates, isCategoryAxis, disabledMaterials, parameters.convertToLbs),
    };
  }

  private series(
    data: LaserOutputScrapHistoricalData[],
    dates: string[],
    isCategoryAxis: boolean,
    disabledMaterials: string[],
    convertToLbs: boolean = false,
  ): SeriesOption[] {
    const materialSeries = this.generateMaterialSeries(data, dates, isCategoryAxis, convertToLbs);
    const utilizationSeries = this.generateUtilizationSeries(data, dates, disabledMaterials);

    return [...materialSeries, utilizationSeries];
  }

  private generateMaterialSeries(
    data: LaserOutputScrapHistoricalData[],
    dates: string[],
    isCategoryAxis: boolean,
    convertToLbs: boolean,
  ) {
    const unitTransform = getUnitTransform(convertToLbs);
    const byDateData = groupByDate(data, dates, (sourceValuesObject, currentItem) => ({
      ...sourceValuesObject,
      [currentItem.group_name]: {
        output: unitTransform(currentItem.output),
        scrap: unitTransform(currentItem.scrap),
      },
    }));

    return this.getMaterialNames(data).map((materialName) => ({
      type: 'bar' as const,
      name: materialName,
      emphasis: {
        focus: 'series' as const,
      },
      stack: 'total',
      yAxisIndex: 0,
      data: this.getMaterialData(byDateData, materialName, isCategoryAxis),
    }));
  }

  private getDates(data: LaserOutputScrapHistoricalData[]): string[] {
    return uniq(data.map((item) => item.date));
  }

  private generateUtilizationSeries(
    data: LaserOutputScrapHistoricalData[],
    dates: string[],
    disabledMaterials: string[],
  ) {
    const seriesData = dates.map((date) => {
      const enabledMaterialsForDate = data
        .filter((dataItem) => dataItem.date === date)
        .filter((dataItem) => !disabledMaterials.includes(dataItem.group_name));
      const materialUtilizationSum = enabledMaterialsForDate.reduce(
        (previousValue, currentDataItem) =>
          previousValue + this.calculateMaterialUtilization(currentDataItem),
        0,
      );

      const percentage = enabledMaterialsForDate.length
        ? materialUtilizationSum / enabledMaterialsForDate.length
        : 0;
      return [date, percentage];
    });

    return {
      type: 'line' as const,
      name: this.utilizationSeriesName,
      yAxisIndex: 1,
      emphasis: {
        focus: 'series' as const,
      },
      data: seriesData,
    };
  }

  private calculateMaterialUtilization(dataItem: LaserOutputScrapHistoricalData): number {
    if (dataItem.output + dataItem.scrap === 0) {
      return 0;
    }
    return (dataItem.output / (dataItem.output + dataItem.scrap)) * 100;
  }

  private getMaterialNames(data: LaserOutputScrapHistoricalData[]): string[] {
    return uniq(data.map((item) => item.group_name));
  }

  private getMaterialData(byDateData: any[], materialName: string, isCategoryAxis: boolean) {
    if (isCategoryAxis) {
      return byDateData.map(
        (item) => (item[materialName]?.output ?? 0) + (item[materialName]?.scrap ?? 0),
      );
    } else {
      return byDateData.map((item) => [
        item.date,
        (item[materialName]?.output ?? 0) + (item[materialName]?.scrap ?? 0),
      ]);
    }
  }

  private getDisabledMaterials(parameters: GeneratorParams): string[] {
    if (!parameters.legendParams?.selected) {
      return [];
    }

    return Object.keys(parameters.legendParams?.selected)
      .filter((key) => key !== this.utilizationSeriesName)
      .filter((key) => !parameters.legendParams.selected[key]);
  }
}
