// --------------------------------------------------------------------------------
// <copyright file="user.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import DashboardDefinition, {
  availableDashboardLayouts,
  DashboardLayout,
} from '@/models/Charts/dashboardDefinition';
import { deserializeWidgetDefinition } from './Charts/widgetDefinition';
import { ConsoleEnum } from './enums/ConsoleEnum';
import { UserType } from './userType';
import { isNil } from '@/utils/misc';
import { BetaFeature } from '@/models/enums/BetaFeature';

export class User {
  constructor(
    public id: number,
    /** Name of the user.
     *
     * This is not a unique identifying username. It's just a person's name or
     * whatever the user wants to set it to. No restrictions.
     */
    public name: string,
    public customerId: number | null,
    public subsidiaryId: number | null,
    public email: string,
    public authenticationType: UserAuthenticationType,
    public password: string | undefined,
    public isAcceptedTermsOfAgreement: boolean,
    /** @deprecated It should be customDashboards instead, which is the field
     *  sent/received from the backend.
     */
    public dashboards: DashboardDefinition[],
    public timestampAcceptedTermsOfAgreement: Date | null,
    public isAutoplay: boolean,
    public autoplayPeriod: number,
    public wccActivationDate: Date | null = null,
    public wccEndDate: Date | null = null,
    public bcActivationDate: Date | null = null,
    public bcEndDate: Date | null = null,
    public sfcActivationDate: Date | null = null,
    public sfcEndDate: Date | null = null,
    public consoles: ConsoleEnum[] = [],
    public locations: number[] = [],
    public billable = true,
    /** @deprecated Used only on user forms, not really needed on the model. */
    public tenantName: string = '',
    /** @deprecated Used only on user forms, not really needed on the model. */
    public subsidiaryName: string = '',
    public userType: UserType = UserType.None,
    public otherMemberships: Membership[] = [],
    public hasAccessToBeta = false,
    public isBetaEnabled = false,
    /** List of features the user has access to when isBetaEnabled is true. */
    public betaFeatures: BetaFeature[] = [],
  ) {}

  static empty(): User {
    return new User(
      0,
      '',
      null,
      null,
      '',
      UserAuthenticationType.Local,
      '',
      false,
      [],
      null,
      false,
      0,
      null,
      null,
      null,
      null,
      null,
      null,
      [],
      [],
    );
  }

  static emptyGlobalAdmin(): User {
    const user = User.empty();
    user.userType = UserType.GlobalAdmin;
    return user;
  }

  static emptySubsidiaryAdmin(): User {
    const user = User.empty();
    user.userType = UserType.SubsidiaryAdmin;
    return user;
  }

  static emptyServiceGlobal(): User {
    const user = User.empty();
    user.userType = UserType.ServiceGlobal;
    user.authenticationType = UserAuthenticationType.Microsoft;
    return user;
  }

  static emptyServiceUser(): User {
    const user = User.empty();
    user.userType = UserType.ServiceUser;
    user.authenticationType = UserAuthenticationType.Microsoft;
    return user;
  }

  static emptyTechnology(): User {
    const user = User.empty();
    user.userType = UserType.TechnologyUser;
    user.authenticationType = UserAuthenticationType.Microsoft;
    return user;
  }

  static GetCopy(original: User): User {
    return new User(
      original.id,
      original.name,
      original.customerId,
      original.subsidiaryId,
      original.email,
      original.authenticationType,
      original.password,
      original.isAcceptedTermsOfAgreement,
      [...(original.dashboards ?? []).map((d) => DashboardDefinition.GetCopy(d))],
      original.timestampAcceptedTermsOfAgreement,
      original.isAutoplay,
      original.autoplayPeriod,
      original.wccActivationDate,
      original.wccEndDate,
      original.bcActivationDate,
      original.bcEndDate,
      original.sfcActivationDate,
      original.sfcEndDate,
      !!original.consoles ? [...original.consoles] : [],
      !!original.locations ? [...original.locations] : [],
      original.billable,
      original.tenantName,
      original.subsidiaryName,
      original.userType,
      isNil(original.otherMemberships) ? [] : [...original.otherMemberships],
      original.hasAccessToBeta,
      original.isBetaEnabled,
      original.betaFeatures,
    );
  }

  equals(otherUser: User): boolean {
    return this.id === otherUser.id;
  }

  get isGlobalAdmin() {
    return this.userType === UserType.GlobalAdmin;
  }

  get isCustomerAdmin() {
    return this.userType === UserType.CustomerAdmin;
  }

  get isCustomerUser() {
    return this.userType === UserType.CustomerUser;
  }

  get isCustomer() {
    return this.userType === UserType.CustomerAdmin || this.userType === UserType.CustomerUser;
  }

  get isSubsidiaryAdmin() {
    return this.userType === UserType.SubsidiaryAdmin;
  }

  get isServiceGlobal() {
    return this.userType === UserType.ServiceGlobal;
  }

  get isServiceUser() {
    return this.userType === UserType.ServiceUser;
  }

  get isServiceUserType() {
    return this.isServiceGlobal || this.isServiceUser;
  }

  get isTechnologyUser() {
    return this.userType === UserType.TechnologyUser;
  }

  get hasCustomer(): boolean {
    return this.customerId !== null;
  }

  get hasSubsidiary(): boolean {
    return this.subsidiaryId !== null;
  }

  serializeDashboards(): string {
    return JSON.stringify(
      this.dashboards.map((dashboard) => {
        const layout = availableDashboardLayouts[dashboard.layout];
        return {
          name: dashboard.name,
          widgetDefinitions: dashboard.widgetDefinitions
            .map((wd) => wd.serialize())
            .filter((wd) => wd.index! >= 0 && wd.index! < layout.length),
          layout: dashboard.layout,
        };
      }),
    );
  }

  static deserializeDashboards(
    customDashboardsConfiguration: string | null,
  ): DashboardDefinition[] {
    return !!customDashboardsConfiguration
      ? (JSON.parse(customDashboardsConfiguration) as DashboardDefinition[]).map(
          (dashboardDefinition) => this.deserializeDashboard(dashboardDefinition),
        )
      : [DashboardDefinition.defaultDashboard()];
  }

  private static deserializeDashboard(
    dashboardDefinition: DashboardDefinition,
  ): DashboardDefinition {
    const widgetDefinitions = dashboardDefinition.widgetDefinitions.map((widgetDefinition, index) =>
      deserializeWidgetDefinition(widgetDefinition, index),
    );
    const layout =
      dashboardDefinition.layout ?? this.getDefaultDashboardLayout(widgetDefinitions.length);
    return new DashboardDefinition(dashboardDefinition.name, widgetDefinitions, layout);
  }

  private static getDefaultDashboardLayout(size: number): DashboardLayout {
    if (size === 1) {
      return DashboardLayout.OneByOne;
    } else if (size === 2) {
      return DashboardLayout.OneByTwo;
    } else if (size === 3 || size === 4) {
      return DashboardLayout.TwoByTwo;
    } else {
      return DashboardLayout.TwoByThree;
    }
  }

  getConsoleActivationDate(console: ConsoleEnum): Date | null {
    switch (console) {
      case ConsoleEnum.WCC:
        return this.wccActivationDate;
      case ConsoleEnum.BC:
        return this.bcActivationDate;
      case ConsoleEnum.SFC:
        return this.sfcActivationDate;
      default:
        return null;
    }
  }

  getConsoleEndDate(console: ConsoleEnum): Date | null {
    switch (console) {
      case ConsoleEnum.WCC:
        return this.wccEndDate;
      case ConsoleEnum.BC:
        return this.bcEndDate;
      case ConsoleEnum.SFC:
        return this.sfcEndDate;
      default:
        return null;
    }
  }

  setConsoleActivationDate(console: ConsoleEnum, date: Date | null): void {
    if (console === ConsoleEnum.WCC) {
      this.wccActivationDate = date;
    } else if (console === ConsoleEnum.BC) {
      this.bcActivationDate = date;
    } else if (console === ConsoleEnum.SFC) {
      this.sfcActivationDate = date;
    }
  }

  setConsoleEndDate(console: ConsoleEnum, date: Date | null): void {
    if (console === ConsoleEnum.WCC) {
      this.wccEndDate = date;
    } else if (console === ConsoleEnum.BC) {
      this.bcEndDate = date;
    } else if (console === ConsoleEnum.SFC) {
      this.sfcEndDate = date;
    }
  }

  /**
   * Returns true if the user has beta and the specified feature enabled.
   *
   * @param feature Feature to check if is enabled for the user.
   */
  hasBetaFeature(feature: BetaFeature) {
    return this.isBetaEnabled && this.betaFeatures.includes(feature);
  }
}

export enum UserAuthenticationType {
  Local = 1,
  Microsoft = 2,
}

export interface Membership {
  type: MembershipType;
  id: number;
  name: string;
}

export enum MembershipType {
  Subsidiary = 1,
  Customer = 2,
}
