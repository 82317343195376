
import { Component, Prop, Vue } from 'vue-property-decorator';
import WidgetDefinition, { ParamValues } from '@/models/Charts/widgetDefinition';
import ControlledSidebar from '@/components/ControlledSidebar.vue';
import { EventData, ImportantMessageLevel } from '@/models/Charts/chartsData';
import moment, { Moment } from 'moment';
import { isEmpty, isNil } from '@/utils/misc';
import DateGroupingFilter from '../DateGroupingFilter.vue';
import EventTypeFilter from '../EventTypeFilter.vue';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { Logger } from '@/utils/logger';
import DateTimeRangePicker from '@/components/common/dateTimeRangePicker/DateTimeRangePicker.vue';
import { DateTimeGranularity } from '@/components/common/dateTimeRangePicker/DateTimeGranularity';
import EventModuleFilter from '../EventModuleFilter.vue';
import EventDetails from '@/components/careConsole/EventDetails.vue';
import { usersService } from '@/services/users.service';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import WidgetInfo from '@/components/Charts/WidgetInfo.vue';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import EventCodesFilter from '@/components/EventCodesFilter.vue';
import { UserType } from '@/models/userType';
import { defaultDateRange } from '@/views/careConsole/commons/utils';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import { SortOrder } from '@/models/enums/SortOrder';

@Component({
  computed: {
    WidgetEnum: () => WidgetEnum,
  },
  methods: { isNil },
  components: {
    WidgetContainer,
    WidgetInfo,
    EventDetails,
    DateTimeRangePicker,
    ControlledSidebar,
    EventModuleFilter,
    EventTypeFilter,
    EventCodesFilter,
    // Lazy load to avoid recursion issues (we open this component from EventsTable).
    'events-table': () => import('@/components/careConsole/EventsTable.vue'),
    DateGroupingFilter,
  },
})
export default class EventsTableExpandedView extends Vue {
  @Prop({ required: true })
  private deviceId!: string;

  @Prop({ required: true })
  private selectedTimeFilter!: [string, string];

  @Prop({ required: true })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  @Prop({ default: 'timestamp' })
  private defaultSortField!: string;

  @Prop({ default: SortOrder.Descending })
  private defaultSortOrder!: SortOrder;

  // Used when opened from the table's expand button
  @Prop({ default: () => ({}) })
  private paramValues!: ParamValues;

  // Used when opened by double-clicking a chart's data point
  @Prop()
  private widgetDefinition?: WidgetDefinition;

  // Used when opened by double-clicking a chart's data point
  @Prop()
  private module?: string;

  // Used when opened by double-clicking a chart's data point
  @Prop()
  private timestamp?: string;

  private page = 1;
  private selectedEvent: EventData | null = null;

  // We need these three initialized to null instead of to a safe value
  // ([] for example). Otherwise, EventsTable.onFilterChanged watcher is
  // triggered for each one when mounted. First for the initial value, then for
  // the final value set here on mounted.
  private dateRangeSelection: [Date, Date] | null = null;
  private selectedDateGrouping: FilterTimeAxisSpanEnum = FilterTimeAxisSpanEnum.None;
  private selectedEventModules: string[] | null = null;
  private selectedEventTypes: ImportantMessageLevel[] | null = null;
  private selectedEventCodes: string[] | null = null;
  private sortField = 'timestamp';
  private sortOrder: SortOrder = SortOrder.Descending;

  private mounted() {
    this.dateRangeSelection = this.getDateRangeSelection();
    this.selectedDateGrouping = this.dateGrouping;
    this.selectedEventModules = this.getSelectedEventModules();
    this.selectedEventTypes = this.getSelectedEventTypes();
    this.selectedEventCodes = this.getSelectedEventCodes();
    this.sortField = this.defaultSortField;
    this.sortOrder = this.defaultSortOrder;
  }

  private get eventsTableWidgetDefinition(): WidgetDefinition {
    const customerIdDh = this.$route.params.tenantIdDh
      ? +this.$route.params.tenantIdDh
      : +this.$route.params.customerIdDh;

    return new WidgetDefinition(
      WidgetEnum.EventsTableExpandedView,
      this.deviceId,
      [this.dateRangeString[0], this.dateRangeString[1]],
      [],
      undefined,
      this.selectedDateGrouping,
      undefined,
      {
        customerIdDh,
        modules: this.selectedEventModules,
        eventTypes: this.selectedEventTypes,
        eventCodes: this.selectedEventCodes,
        page: this.page,
        rowsPerPage: 25,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      }
    )
      .hideTitle()
      .hideInfo();
  }

  private getDateRangeSelection(): [Date, Date] {
    if (this.isOpenedFromChartDoubleClick()) {
      return this.parseTimestampProp();
    } else {
      return [
        moment(this.selectedTimeFilter[0]).toDate(),
        moment(this.selectedTimeFilter[1]).endOf('day').toDate(),
      ];
    }
  }

  private getSelectedEventModules(): string[] {
    if (this.module) {
      return [this.module];
    }
    if (!isEmpty(this.paramValues.eventModules)) {
      return this.paramValues.eventModules as string[];
    }
    if (!isEmpty(this.widgetDefinition?.paramValues.eventModules)) {
      return this.widgetDefinition?.paramValues.eventModules as string[];
    }
    return [];
  }

  private getSelectedEventTypes(): ImportantMessageLevel[] {
    if (!isEmpty(this.paramValues.eventTypes)) {
      return this.paramValues.eventTypes as ImportantMessageLevel[];
    }
    if (!isEmpty(this.widgetDefinition?.paramValues.eventTypes)) {
      return this.widgetDefinition?.paramValues.eventTypes as ImportantMessageLevel[];
    }
    return [];
  }

  private getSelectedEventCodes(): string[] {
    if (!isEmpty(this.paramValues.eventCodes)) {
      return this.paramValues.eventCodes as string[];
    }
    if (!isEmpty(this.widgetDefinition?.paramValues.eventCodes)) {
      return this.widgetDefinition?.paramValues.eventCodes as string[];
    }
    return [];
  }

  private onSort(sortField: string, sortOrder: SortOrder) {
    this.sortField = sortField;
    this.sortOrder = sortOrder;
  }

  private get dateRangeString(): [string, string] {
    if (isNil(this.dateRangeSelection)) {
      return [defaultDateRange()[0].toISOString(), defaultDateRange()[1].toISOString()];
    }

    return [this.dateRangeSelection[0].toISOString(), this.dateRangeSelection[1].toISOString()];
  }

  private parseTimestampProp(): [Date, Date] {
    const momentDate = this.timestampStringToMoment(this.timestamp!);

    if (!momentDate.isValid()) {
      // This should never happen
      Logger.error('Invalid data point timestamp format: ', this.timestamp);
      throw Error(`Invalid data point timestamp format: ${this.timestamp}`);
    }

    const f = (m: moment.Moment) => m.toDate();

    const unitOfTime = FilterTimeAxisSpanEnum[
      this.widgetDefinition!.axisSpan!
    ].toLowerCase() as moment.unitOfTime.StartOf;
    return [f(momentDate.startOf(unitOfTime)), f(momentDate.endOf(unitOfTime))];
  }

  private timestampStringToMoment(ts: string): Moment {
    return this.isQuarterDate(ts) ? moment(ts, 'YYYY-[Q]Q', true) : moment(ts, undefined, true);
  }

  private isQuarterDate(timestampString: string): boolean {
    return timestampString.toLowerCase().includes('q');
  }

  private onSidebarClose() {
    this.selectedEvent = null;
  }

  private isOpenedFromChartDoubleClick() {
    return !isNil(this.timestamp);
  }

  private get isSideBarEnabled(): boolean {
    return this.console === ConsoleEnum.Care;
  }

  private get console(): ConsoleEnum {
    const user = usersService.store.current();

    if (user.isServiceUserType) {
      return ConsoleEnum.Care;
    } else if (user.isTechnologyUser) {
      return ConsoleEnum.Technology;
    }

    throw new Error(
      `The user should be eService center or Technology console user: ${UserType[user!.userType]}`,
    );
  }

  private get dateTimeRangePickerGranularity(): DateTimeGranularity {
    switch (this.selectedDateGrouping) {
      case FilterTimeAxisSpanEnum.None:
        return DateTimeGranularity.SECONDS;
      case FilterTimeAxisSpanEnum.Hour:
        return DateTimeGranularity.HOURS;
      case FilterTimeAxisSpanEnum.Day:
      case FilterTimeAxisSpanEnum.Week:
        return DateTimeGranularity.DAYS;
      case FilterTimeAxisSpanEnum.Month:
      case FilterTimeAxisSpanEnum.Quarter:
      case FilterTimeAxisSpanEnum.Year:
        return DateTimeGranularity.MONTHS;
    }
  }
}
