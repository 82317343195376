// --------------------------------------------------------------------------------
// <copyright file="AggregateEnum.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum AggregateEnum {
  Min = 'min',
  Avg = 'avg',
  Q95 = 'q95',
  Max = 'max',
}
