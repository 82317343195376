// --------------------------------------------------------------------------------
// <copyright file="careOverviewModuleEventsIndicatorsRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { DataRetriever } from '../abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { ModuleEventsIndicatorData } from '@/models/Charts/chartsData';
import { metricsService } from '@/services/metrics.service';

export class CareOverviewModuleEventsIndicatorsRetriever extends DataRetriever<
  ModuleEventsIndicatorData[]
> {
  constructor(procedure: WidgetEnum, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum | undefined,
    params?: { [key: string]: any } | undefined,
    customerNo?: string | undefined,
  ): Promise<ModuleEventsIndicatorData[]> {
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<ModuleEventsIndicatorData[]>(
      this.procedure,
      this.tenantIdDh,
      selectedDevices[0],
      {
        dateFrom,
        dateTo,
      },
      this.controller,
    );
  }
}
