// --------------------------------------------------------------------------------
// <copyright file="bendPartCategory.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export class BendPartCategory {
  static GetEmpty(): BendPartCategory {
    return new BendPartCategory(0, 0, null, null);
  }
  constructor(
    public id: number,
    public tenantId: number,
    public simpleTo?: number | null,
    public mediumTo?: number | null,
  ) {}
}
