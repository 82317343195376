// --------------------------------------------------------------------------------
// <copyright file="barChartKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { iterable } from '@/utils/array';
import { DataWithTimeSpan } from '../chartsData';
import { AbstractKpiWidgetGenerator } from './abstractKpiWidgetGenerator';
import { GeneratorParams } from '../generatorParams';
import { EChartsOption } from 'echarts';

export abstract class BarChartKpiWidgetGenerator<
  T extends DataWithTimeSpan,
> extends AbstractKpiWidgetGenerator<T> {
  override updateOptions(
    data: T[],
    parameters: GeneratorParams,
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const primaryData = data.filter((x) => x.isprimary === 1);
    const seriesData = this.getSeriesData(primaryData).sort((a, b) => a.value - b.value);
    const palette = this.generatePalette(primaryData.length);

    return {
      tooltip: {
        show: true,
        formatter: (params: any) => {
          const { name, value } = params;
          return `${name}: <b>${this.formatWithUnit(value)}</b>`;
        },
      },
      grid: {
        top: 0,
        bottom: 20,
        left: 0,
        right: 0,
      },
      xAxis: {
        type: 'category',
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        data: iterable(seriesData.length)
          .map((x) => x + 1)
          .reverse(),
      },
      yAxis: {
        type: 'value',
        show: false,
      },
      series: {
        type: 'bar',
        itemStyle: {
          borderRadius: 48,
        },
        barWidth: 16,
        data: seriesData.map((series, index) => ({
          ...series,
          itemStyle: {
            color: palette[index],
          },
        })),
      },
    };
  }
}
