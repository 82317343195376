// --------------------------------------------------------------------------------
// <copyright file="persistent.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import state from '@/store/persistent/state';
import actions from '@/store/persistent/actions';
import mutations from '@/store/persistent/mutations';
import getters from '@/store/persistent/getters';

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
