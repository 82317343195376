
import { isEmpty, isVueComponent } from '@/utils/misc';
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import SlotComponent from './SlotComponent.vue';
import WizardStep from './WizardStep.vue';
import { ValidationObserver } from 'vee-validate';
import { VNode } from 'vue';
import NumberedBreadcrumbItem from './NumberedBreadcrumbItem.vue';

const isWizardStep = ($vnode: VNode | undefined): boolean => {
  return $vnode?.componentOptions?.tag === 'wizard-step';
};

function getDefaultSlotsRecursively(component: Vue | undefined): Vue[] {
  if (!component) {
    return [];
  }
  const slotsAndChildren: VNode[] = [
    ...(component.$slots.default ?? []),
    ...(component.$children as Vue[]).filter((x) => isVueComponent(x)).map((x) => x.$vnode),
  ].filter((x) => !!x.componentInstance);

  if (slotsAndChildren.length > 0) {
    const wizardSteps = slotsAndChildren.filter((x) => isWizardStep(x));
    const nonWizardSteps = slotsAndChildren.filter((x) => !isWizardStep(x));
    return [
      component,
      ...wizardSteps.map((x) => x.componentInstance!),
      ...nonWizardSteps.flatMap((x) => getDefaultSlotsRecursively(x.componentInstance)),
    ];
  }

  return [component];
}

@Component({
  components: {
    NumberedBreadcrumbItem,
    SlotComponent,
    ValidationObserver,
  },
})
export default class Wizard extends Vue {
  @Provide()
  private $wizard: Wizard = this;

  @Prop({ required: true })
  private title!: string;

  /* eslint-disable-next-line */
  @Prop({ default: () => async () => {} })
  private submit!: () => Promise<void>;

  private steps: WizardStep[] = [];
  private currentStep = 0;
  private isLeaving = false;
  private isSubmitting = false;

  private mounted() {
    this.refreshSteps();
  }

  private getWizardSteps(): WizardStep[] {
    const slots = (this.$slots.default ?? []).flatMap((x) =>
      getDefaultSlotsRecursively(x.componentInstance!),
    );
    const wizardSteps = slots.filter((x) => isWizardStep(x.$vnode));
    return wizardSteps.map((slot) => slot.$vnode.componentInstance! as WizardStep);
  }

  public refreshSteps(): void {
    this.steps = this.getWizardSteps();
    if (!isEmpty(this.steps)) {
      this.steps[0].enter(true);
    }
  }

  private async goToNextStep() {
    if (this.isLastStep) {
      return;
    }

    const onLeaveResult = this.nonHiddenSteps[this.currentStep].onLeave();
    let canLeave = false;
    if (onLeaveResult instanceof Promise) {
      this.isLeaving = true;
      canLeave = await onLeaveResult;
      this.isLeaving = false;
    } else {
      canLeave = onLeaveResult;
    }

    if (!canLeave) {
      return;
    }

    this.nonHiddenSteps[this.currentStep].leave();
    this.currentStep++;
    this.nonHiddenSteps[this.currentStep].enter(true);
  }

  private goToPreviousStep(): void {
    if (this.isFirstStep) {
      return;
    }
    this.nonHiddenSteps[this.currentStep].leave();
    this.currentStep--;
    this.nonHiddenSteps[this.currentStep].enter(false);
  }

  private getActualIndex(index: number): number {
    const nonHiddenStepsBeforeIndex = this.steps
      .slice(0, index)
      .filter((step) => step.hidden).length;
    return index - nonHiddenStepsBeforeIndex;
  }

  private isBreadcrumbItemActive(index: number): boolean {
    return this.getActualIndex(index) === this.currentStep;
  }

  private isBreadcrumbItemDisabled(index: number): boolean {
    return this.currentStep < this.getActualIndex(index);
  }

  private get nonHiddenSteps(): WizardStep[] {
    return this.steps.filter((slot) => slot.hidden === false);
  }

  private get isLastStep(): boolean {
    return this.currentStep === this.nonHiddenSteps.length - 1;
  }

  private get isNextButtonEnabled(): boolean {
    if (isEmpty(this.steps)) {
      return false;
    }

    return this.nonHiddenSteps[this.currentStep].allowNextIf;
  }

  private get isFirstStep(): boolean {
    return this.currentStep === 0;
  }

  private async onSubmit() {
    this.isSubmitting = true;
    await this.submit()
      .then(() => this.$emit('submit'))
      .finally(() => (this.isSubmitting = false));
  }
}
