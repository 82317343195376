// --------------------------------------------------------------------------------
// <copyright file="PlanState.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum PlanState {
  Inactive = 0,
  Started = 1,
  Completed = 2,
  PartiallyCompleted = 3,
  Failed = 4,
}
