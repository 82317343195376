
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TenantSourcesSelect extends Vue {
  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  private hasDatahub!: boolean;

  @Prop({ default: false, type: Boolean })
  private hasSmbs!: boolean;
}
