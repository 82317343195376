// --------------------------------------------------------------------------------
// <copyright file="businessFiguresRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

// tslint:disable: member-access
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DataRetriever } from '../abstract/chartGenerator';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import moment from 'moment';
import { mockBusinessOverviewFiguresData } from '../mockWidgetSelectorData';
import { BusinessOverviewFiguresData } from '../chartsData';
import { metricsService } from '@/services/metrics.service';

export class BusinessFiguresRetriever extends DataRetriever<BusinessOverviewFiguresData> {
  constructor(procedure: WidgetEnum, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string] | FilterTimeSpanEnum,
  ): Promise<BusinessOverviewFiguresData | null> {
    const [startDate, endDate] = timeSpan as [string, string];
    const date = moment().format('YYYY-MM-DD');
    return metricsService.getSMBSMetricsBC(
      this.procedure,
      this.tenantIdDh,
      {
        startDate,
        endDate,
        date,
      },
      this.controller,
    );
  }

  override getMockData(): BusinessOverviewFiguresData | null {
    return mockBusinessOverviewFiguresData();
  }
}
