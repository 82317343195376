var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"layout-grid",class:`tablet-rows-count-${_vm.editMode ? _vm.layout.length : _vm.tabletRowsCount}`},_vm._l((_vm.layout),function(slot,index){return _c('div',{key:`slot-${index}`,staticClass:"grid-element",class:[
      `col-from-${slot.column + 1}`,
      `col-to-${slot.column + slot.width + 1}`,
      `row-from-${slot.row + 1}`,
      `row-to-${slot.row + slot.height + 1}`,
      ..._vm.getSlotTabletRowClasses(index, slot.height),
    ]},[(_vm.isThereWidgetInSlot(index))?_c('div',{staticClass:"grid-element-content-wrapper"},[(_vm.editMode)?_c('div',{staticClass:"edition-overlay"},[_c('b-button',{staticClass:"action-button is-edit",attrs:{"icon-left":"pencil-outline","outlined":""},on:{"click":function($event){return _vm.$emit('edit', index)}}},[_vm._v(" "+_vm._s(_vm.$t('action.replace'))+" ")]),_c('b-button',{staticClass:"action-button",attrs:{"icon-left":"trash-can-outline","type":"is-danger","outlined":""},on:{"click":function($event){return _vm.$emit('delete', index)}}},[_vm._v(" "+_vm._s(_vm.$t('action.delete'))+" ")])],1):_vm._e(),_c('div',{staticClass:"grid-element-content"},[_vm._t(`widget-${index}`)],2)]):(_vm.editMode)?_c('div',{staticClass:"empty-slot"},[_c('div',{staticClass:"edition-overlay"},[_c('b-button',{staticClass:"action-button is-add",attrs:{"icon-left":"plus"},on:{"click":function($event){return _vm.$emit('add', index)}}},[_vm._v(" "+_vm._s(_vm.$t('widget.add_widget'))+" ")])],1)]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }