// --------------------------------------------------------------------------------
// <copyright file="dataplatform.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { BaseUrl } from '@/models/constants';
import {
  DataPlatformActionsDetailsTableData,
  DataPlatformCreateAction,
  DataPlatformDetailedTableData,
  DataPlatformTableEntry,
} from '@/models/dataPlatform';
import axios, { AxiosResponse } from 'axios';
import { responseHandler } from './response.handler';

class DataPlatformService {
  async getInstallerUrl(): Promise<string> {
    const response = await axios.get<string>(`${BaseUrl}/dataplatform/installerUrl`);
    return response.data;
  }

  private getFileName(response: AxiosResponse<BlobPart>): string {
    const disposition = response.headers?.['content-disposition'];
    const matches = /filename=(BystronicDataPlatform-(\d+\.\d+\.\d+)\.exe)/.exec(disposition);
    return matches?.[1] ?? 'BystronicDataPlatform.exe';
  }

  async get(): Promise<DataPlatformTableEntry[]> {
    return axios.get<DataPlatformTableEntry[]>(`${BaseUrl}/dataplatform`).then((response) => {
      responseHandler.handleDates(response.data);
      return response.data;
    });
  }

  async getDetail(dataPlatformId: number): Promise<DataPlatformDetailedTableData[]> {
    return axios
      .get<DataPlatformDetailedTableData[]>(`${BaseUrl}/dataplatform/${dataPlatformId}/details`)
      .then((response) => response.data);
  }

  async getActionsDetails(dataPlatformId: number): Promise<DataPlatformActionsDetailsTableData[]> {
    return axios
      .get<DataPlatformActionsDetailsTableData[]>(
        `${BaseUrl}/dataplatform/${dataPlatformId}/actions`,
      )
      .then((response) => response.data);
  }

  async createAction(dataplatformCreateAction: DataPlatformCreateAction) {
    return axios
      .post(`${BaseUrl}/dataplatform/actions`, dataplatformCreateAction)
      .then((response: any) => {
        return response.data;
      });
  }

  async delete(id: number): Promise<void> {
    return await axios.delete(`${BaseUrl}/dataplatform/${id}`);
  }
}

export const dataPlatformService = new DataPlatformService();
