
import { Component, Prop, Vue } from 'vue-property-decorator';

export type BubbleType = 'is-danger' | 'is-warning' | 'is-info';

@Component
export default class ActionBubble extends Vue {
  @Prop({ required: true })
  private label!: string;

  @Prop({ default: 'is-info' })
  private type!: BubbleType;

  @Prop({ default: true })
  private active!: boolean;
}
