// --------------------------------------------------------------------------------
// <copyright file="bendingProductionOutputKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { BEND_SUMMARY_BASE_COLOR } from '@/utils/color';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { ProcedureName } from './abstract/chartGenerator';
import { PieChartKpiWidgetGenerator } from './abstract/pieChartKpiWidgetGenerator';
import { DataWithTimeSpan } from './chartsData';
import { metricsService } from '@/services/metrics.service';
import { devicesService } from '@/services/devices.service';
import { isNil } from '@/utils/misc';

export interface BendingProductionOutputDataPerDevice extends DataWithTimeSpan {
  deviceId: string;
  productionOutput: number;
}

export class BendingProductionOutputKpiWidgetGenerator extends PieChartKpiWidgetGenerator<BendingProductionOutputDataPerDevice> {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    const mainColor = BEND_SUMMARY_BASE_COLOR;
    const unit = 'kg';
    super(procedure, tenantIdDh, mainColor, unit);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<BendingProductionOutputDataPerDevice[] | null> {
    return mockData.slice(0, selectedDevices.length)
      .map((deviceDataEntries, deviceIndex) =>
        deviceDataEntries.map((dataEntry) =>
          ({
            ...dataEntry,
            deviceId: selectedDevices[deviceIndex],
          })
        )
      ).flat();

    return await metricsService.getDevicesMetrics<BendingProductionOutputDataPerDevice[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  protected override getMaxDataEntry(primaryData: BendingProductionOutputDataPerDevice[]): number {
    return Math.max(...primaryData.map((x) => x.productionOutput));
  }

  protected override getSeriesData(primaryData: BendingProductionOutputDataPerDevice[]): any[] {
    return primaryData.map((x) => ({
      name: devicesService.store.findByStringId(x.deviceId)!.name,
      value: x.productionOutput,
    }));
  }
}

const mockData: Array<BendingProductionOutputDataPerDevice[]> = [
  [
    {
      'deviceId': 'to be replaced',
      'isprimary': 1,
      'productionOutput': 439.91,
    },
    {
      'deviceId': 'to be replaced',
      'isprimary': 0,
      'productionOutput': 439.91,
    },
  ],
  [
    {
      'deviceId': 'to be replaced',
      'isprimary': 1,
      'productionOutput': 214.49,
    },
    {
      'deviceId': 'to be replaced',
      'isprimary': 0,
      'productionOutput': 186.03,
    },
  ],
  [
    {
      'deviceId': 'to be replaced',
      'isprimary': 1,
      'productionOutput': 1658.69,
    },
    {
      'deviceId': 'to be replaced',
      'isprimary': 0,
      'productionOutput': 1490.05,
    },
  ],
  [
    {
      'deviceId': 'to be replaced',
      'isprimary': 1,
      'productionOutput': 424.02,
    },
    {
      'deviceId': 'to be replaced',
      'isprimary': 0,
      'productionOutput': 363.15,
    },
  ],
  [
    {
      'deviceId': 'to be replaced',
      'isprimary': 1,
      'productionOutput': 859.18,
    },
    {
      'deviceId': 'to be replaced',
      'isprimary': 0,
      'productionOutput': 802.24,
    },
  ],
];
