
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CareTab } from './commons/navigation';
import { Device } from '@/models/device';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { ImportantMessageLevel } from '@/models/Charts/chartsData';
import Alerts from '@/components/careConsole/Alerts.vue';
import CuttingHead from '@/components/careConsole/CuttingHead.vue';
import Health from '@/components/careConsole/Health.vue';
import LaserConsumption from '@/components/careConsole/LaserConsumption.vue';
import Events from '@/components/careConsole/Events.vue';
import Overview from '@/components/careConsole/Overview.vue';

@Component({
  computed: {
    Tab: () => CareTab,
  },
  components: {
    'overview-tab': Overview,
    'alerts-tab': Alerts,
    'health-tab': Health,
    'events-tab': Events,
    'cutting-head-tab': CuttingHead,
    'laser-consumption-tab': LaserConsumption,
  },
})
export default class CareConsoleMainView extends Vue {
  @Prop({ required: true })
  private activeTab!: CareTab;

  @Prop({ required: true })
  private selectedDeviceId!: string;

  @Prop({ required: true })
  private dateRangeSelection!: [Date, Date];

  @Prop({ required: true })
  private selectedTimeAxisSpan!: FilterTimeAxisSpanEnum;

  @Prop({ required: true })
  private selectedEventTypes!: ImportantMessageLevel[];

  @Prop({ required: true })
  private selectedEventModules!: string[];

  @Prop({ required: true })
  private selectedEventCodes!: string[];

  @Prop({ required: true })
  private devices!: Device[];

  private get selectedDevice(): Device {
    return this.devices.find((device) => device.deviceId === this.selectedDeviceId)!;
  }

  private get selectedDeviceName(): string {
    return this.selectedDevice.name;
  }
}
