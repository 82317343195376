
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import { tenantsService } from '@/services/tenants.service';

@Component({})
export default class TenantsListSelector extends Vue {
  @Prop({ default: () => [] })
  private value!: Tenant[];

  private tenants: Tenant[] = [];
  private selectedTenants: Tenant[] = [];

  private loading = false;

  private mounted() {
    this.selectedTenants = this.value;
    this.refreshTenants();
  }

  private async refreshTenants() {
    this.loading = true;
    this.tenants = await tenantsService.get();
    this.loading = false;
  }

  @Watch('selectedTenants')
  private onTenantsSelectionChanged() {
    this.$emit('input', this.selectedTenants);
  }

  private get orderedTenants(): Tenant[] {
    return this.tenants.sort((a, b) => {
      const aIsSelected = this.selectedTenants.includes(a);
      const bIsSelected = this.selectedTenants.includes(b);
      if (aIsSelected === bIsSelected) {
        return a.name.localeCompare(b.name);
      }
      return aIsSelected ? -1 : 1;
    });
  }
}
