
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class TableActionButton extends Vue {
  @Prop({ required: false })
  private icon?: string;

  @Prop({ default: 'mdi' })
  private pack!: string;

  @Prop({ required: false, default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  public hidden!: boolean;

  @Prop({ required: false, default: false, type: Boolean })
  public loading!: boolean;

  @Inject()
  private shouldUseMenu!: boolean;
}
