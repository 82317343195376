// --------------------------------------------------------------------------------
// <copyright file="quotesFiguresRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

// tslint:disable: member-access
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { DataRetriever } from '../abstract/chartGenerator';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { mockQuotesFiguresData } from '../mockWidgetSelectorData';
import { QuotesFiguresData } from '../chartsData';
import { metricsService } from '@/services/metrics.service';

export class QuotesFiguresRetriever extends DataRetriever<QuotesFiguresData> {
  constructor(procedure: WidgetEnum, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string] | FilterTimeSpanEnum,
    timeAxisSpan?: FilterTimeAxisSpanEnum | undefined,
    params?: { [key: string]: any } | undefined,
    customerNo?: string | undefined,
  ): Promise<QuotesFiguresData | null> {
    const [startDate, endDate] = timeSpan as [string, string];
    return metricsService.getSMBSMetricsBC(
      WidgetEnum.QuotesFigures,
      this.tenantIdDh,
      {
        startDate,
        endDate,
        customerNo,
      },
      this.controller,
    );
  }

  override getMockData(): QuotesFiguresData | null {
    return mockQuotesFiguresData();
  }
}
