// --------------------------------------------------------------------------------
// <copyright file="array.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { isNil } from '@/utils/misc';

/**
 * Function that determines if two arrays contain the exact same elements,
 * not taking order into account
 * @param array1
 * @param array2
 * @returns `true` if the arrays contain the same elements, even out of order. `false` otherwise
 */
export function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false;
  }
  if (array1.length !== array2.length) {
    return false;
  }
  return array1.every((x) => array2.includes(x)) && array2.every((x) => array1.includes(x));
}

export function uniq<T>(array: T[]): T[] {
  return [...new Set(array)];
}

export function last<T>(array: T[] | null | undefined): T | null {
  if (isNil(array)) {
    return null;
  }

  const lastIndex = array.length - 1;
  return array[lastIndex];
}

export function listToCommaSeparatedString(list: string[] | null | undefined): string {
  return (list ?? []).join(', ');
}

export function iterable(count: number): number[] {
  return Array(count)
    .fill(0)
    .map((_, i) => i);
}
