// tslint:disable:member-access
/*
 * --------------------------------------------------------------------------------
 * <copyright file="deviceStatusRetriever.ts" company="Bystronic Laser AG">
 *  Copyright (C) Bystronic Laser AG 2021-2023
 * </copyright>
 * --------------------------------------------------------------------------------
 */

import {
  BendStatusMetrics,
  CutStatusMetrics,
  TubeStatusMetrics,
} from '@/models/deviceStatusMetrics';
import { DataRetriever } from '../abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import {
  mockBendStatusData,
  mockCutStatusData,
  mockTubeStatusData,
} from '../mockWidgetSelectorData';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { metricsService } from '@/services/metrics.service';

type StatusMetricsTypes = CutStatusMetrics | BendStatusMetrics | TubeStatusMetrics;

export class DeviceStatusRetriever<T extends StatusMetricsTypes> extends DataRetriever<T> {
  constructor(procedure: WidgetEnum, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ): Promise<T | null> {
    return metricsService.getDevicesMetrics<T>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
      },
      this.controller,
    );
  }

  override getMockData(): T | null {
    switch (this.procedure) {
      case WidgetEnum.CutStatus:
        return mockCutStatusData() as T;
      case WidgetEnum.BendStatus:
        return mockBendStatusData() as T;
      case WidgetEnum.TubeStatus:
        return mockTubeStatusData() as T;
    }
    return null;
  }
}
