// --------------------------------------------------------------------------------
// <copyright file="LaserOutputAndScrapTimelineChartMode.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum LaserOutputAndScrapTimelineChartMode {
  NonStackedLines = 'NonStackedLines',
  StackedColumns = 'StackedColumns',
  RawMaterialUtilization = 'RawMaterialUtilization',
}
