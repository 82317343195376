// --------------------------------------------------------------------------------
// <copyright file="drawerOverviewCycleTimesGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { DrawerCycleTimesData } from './chartsData';
import { DefaultLabelFormatterCallbackParams, EChartsOption, SeriesOption } from 'echarts';
import { GeneratorParams } from './generatorParams';
import { WidgetEnum } from '../enums/WidgetEnum';
import { DrawerOverviewCycleTimesTooltipFormatter } from '@/models/Charts/drawerOverviewCycleTimesTooltipFormatter';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { metricsService } from '@/services/metrics.service';

export class DrawerOverviewCycleTimesGenerator extends ChartGenerator<DrawerCycleTimesData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    const deviceId = selectedDevices[0];
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<DrawerCycleTimesData[]>(
      WidgetEnum.TechnologyDrawerCycleTimes,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom,
        dateTo,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: DrawerCycleTimesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const chartDimensions = {
      width: '45%',
      height: '72%',
    };

    return {
      title: [
        {
          top: 0,
          left: '22%',
          textAlign: 'center',
          text: i18n.t('report.cutting_cycle_times').toString(),
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 13 : 10,
          },
        },
        {
          top: 0,
          left: '74%',
          textAlign: 'center',
          text: i18n.t('report.removed_cycle_times').toString(),
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 13 : 10,
          },
        },
      ],
      legend: {
        bottom: 0,
        formatter: (name: string) =>
          name === 'current'
            ? i18n.t(`report.${name}_last`).toString()
            : i18n.t(`report.${name}`).toString(),
      },
      grid: [
        {
          top: 30,
          left: 0,
          ...chartDimensions,
          containLabel: true,
        },
        {
          top: 30,
          right: 0,
          ...chartDimensions,
          containLabel: true,
        },
      ],
      tooltip: {
        confine: true,
        formatter: new DrawerOverviewCycleTimesTooltipFormatter(data[0]).get(),
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: ['cutting_lower_drawer', 'cutting_upper_drawer'],
          axisLabel: {
            formatter: DrawerOverviewCycleTimesGenerator.getXAxisLabelFormatter(parameters),
            fontSize: parameters.clientWidth! > 350 ? 13 : 10,
          },
          gridIndex: 0,
        },
        {
          type: 'category',
          axisTick: { show: false },
          data: ['removal_lower_drawer', 'removal_upper_drawer'],
          axisLabel: {
            formatter: DrawerOverviewCycleTimesGenerator.getXAxisLabelFormatter(parameters),
            fontSize: parameters.clientWidth! > 350 ? 13 : 10,
          },
          gridIndex: 1,
        },
      ],
      yAxis: Array(2)
        .fill(0)
        .map((_, i) => ({
          gridIndex: i,
          type: 'value',
          show: false,
          axisLabel: { show: false },
        })),
      series: this.generateSeries(data, parameters.clientWidth!),
    };
  }

  private generateSeries(data: DrawerCycleTimesData[], clientWidth: number): SeriesOption[] {
    return [
      ['cutting', 'hour_avg'],
      ['cutting', 'hours'],
      ['removed', 'second_avg'],
      ['removed', 'seconds'],
    ].map(([status, field]) => ({
      data: this.getSeriesData(status, field as keyof DrawerCycleTimesData, data[0]),
      type: 'bar',
      name: field === 'hour_avg' || field === 'second_avg' ? 'average' : 'current',
      label: {
        show: true,
        fontSize: clientWidth > 350 ? 13 : 10,
        position: 'insideBottom',
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        formatter: DrawerOverviewCycleTimesGenerator.labelFormatter.bind(null, status),
      },
      xAxisIndex: status === 'cutting' ? 0 : 1,
      yAxisIndex: status === 'cutting' ? 0 : 1,
    }));
  }

  private getSeriesData(
    status: string,
    field: keyof DrawerCycleTimesData,
    dataItem: DrawerCycleTimesData,
  ) {
    return ['lower', 'upper'].flatMap((drawer: string) => {
      const fieldName = `${drawer}_drawer_${status}_${field}` as keyof DrawerCycleTimesData;
      return dataItem[fieldName];
    });
  }

  private static labelFormatter(
    status: string,
    params: DefaultLabelFormatterCallbackParams,
  ): string {
    const unit = status === 'cutting' ? 'hours' : 'seconds';
    const formattedHours = i18n.n(params.data as number).toString();
    return i18n.t(`report.${unit}_value`).toString().replace('%1', formattedHours);
  }

  private static getXAxisLabelFormatter(parameters: GeneratorParams) {
    return (name: string) => {
      // Warning: Although we remove these prefixes here, they can't be omitted
      // from the name in xAxis.data, as they're used by the tooltip formatter
      // to detect whether it's the cutting or removal chart.
      const normalizedName = name.replace(/cutting_|removal_/, '');
      return parameters.clientWidth! > 350
        ? i18n.t(`report.${normalizedName}_label`).toString()
        : i18n.t(`report.${normalizedName}_label_abbr`).toString();
    };
  }
}
