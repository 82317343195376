// --------------------------------------------------------------------------------
// <copyright file="bendProductivityFiguresChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { EChartsOption } from 'echarts';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { GeneratorParams } from './generatorParams';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import Color from 'color';
import { metricsService } from '@/services/metrics.service';
import {
  BendProductivityFiguresChartData,
  BendProductivityFiguresData,
} from '@/models/Charts/chartsData';
import moment from 'moment';
import { abbreviateNumber } from '@/utils/number';
import { BEND_SUMMARY_BASE_COLOR } from '@/utils/color';
import { mockBendProductivityFiguresData } from '@/models/Charts/mockWidgetSelectorData';

const VALUE_INDEX = 2;
export const FIRST_ROW_INDEX = 0;

/**
 * Chart generator for 'Productivity figures' widget.
 *
 * This widget is placed in Work center console > Bend > Summary.
 *
 * Returns Echarts options to draw a one-dimensional heatmap chart where each
 * square represents the value for a certain date.
 *
 * The colors of the squares range from lighter (higher values) to darker (lower
 * values).
 */
export default class BendProductivityFiguresChartGenerator
  extends ChartGenerator<BendProductivityFiguresData[]>
{
  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ): Promise<BendProductivityFiguresData[] | null> {
    return mockBendProductivityFiguresData();

    /* Pending implementation in the backend.
    return metricsService.getDevicesMetrics<BendProductivityFiguresData[]>(
      this.procedure,
      {
        deviceIds: selectedDevices,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
    */
  }

  override updateOptions(
    data: BendProductivityFiguresData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const figureChartData = this.getFigureChartData(data, parameters.paramValues.figureName);
    const baseColor = new Color(BEND_SUMMARY_BASE_COLOR);

    return {
      grid: {
        top: 0,
        bottom: 55,
        left: 0,
        right: 0,
      },
      xAxis: {
        type: 'category',
        show: false,
        data: figureChartData.map((dataItem) => dataItem.bucket),
      },
      yAxis: {
        type: 'category',
        show: false,
      },
      visualMap: [
        {
          type: 'continuous',
          min: Math.min(...figureChartData.map((dataItem) => dataItem.value)),
          max: Math.max(...figureChartData.map((dataItem) => dataItem.value)),
          dimension: VALUE_INDEX,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: 0,
          inRange: {
            color: baseColor.hex(),
            colorLightness: [
              baseColor.lighten(0.5).lightness() / 100,
              baseColor.darken(0.5).lightness() / 100,
            ],
          },
        },
      ],
      tooltip: {
        formatter: (params) => this.tooltipFormatter(params, parameters.timeFilter),
      },
      series: {
        type: 'heatmap',
        coordinateSystem: 'cartesian2d',
        label: {
          show: false,
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: '#fff',
          color: baseColor.hex(),
        },
        data: figureChartData.map((dataItem, index) =>
          [index, FIRST_ROW_INDEX, dataItem.value],
        ),
      },
    };
  }

  private getFigureChartData(
    data: BendProductivityFiguresData[],
    figureName: string,
  ): BendProductivityFiguresChartData[] {
    return data.find((dataEntry) => dataEntry.name === figureName)!
      .chartData;
  }

  private tooltipFormatter(
    params: any,
    timeFilter: FilterTimeSpanEnum | undefined,
  ): string {
    const dateString: string = params.name;
    const formattedDate = timeFilter === FilterTimeSpanEnum.Day
      ? moment(dateString).format('YYYY-MM-DD, HH:mm')
      : dateString;

    // params.value contains [x, y, value]. Get the correct index from encode.
    const valueDimension = params.encode.value[0];

    return `
    <div class="tooltip-series-value-row">
      <div class="series-marker-name">
        ${params.marker}
        
        <span data-testid="series-name">
          ${formattedDate}
        </span>
      </div>
      <div class="series-value" data-testid="series-value">
        ${abbreviateNumber(params.value[valueDimension])}
      </div>
    </div>
    `;
  }
}
