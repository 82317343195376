// --------------------------------------------------------------------------------
// <copyright file="FilterTimeAxisSpanEnum.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum FilterTimeAxisSpanEnum {
  None = 0,
  Hour = 6,
  Day = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
  Year = 5,
}
