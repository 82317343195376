// --------------------------------------------------------------------------------
// <copyright file="laserInstantConsumptionTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { LaserConsumptionTimelineData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, SeriesOption } from 'echarts';
import { LaserConsumptionTimelineGenerator } from '@/models/Charts/laserConsumptionTimelineGenerator';
import { LaserInstantConsumptionKxSeriesGeneratorHelper } from '@/models/Charts/laserInstantConsumptionKxSeriesGeneratorHelper';

export class LaserInstantConsumptionTimelineGenerator extends LaserConsumptionTimelineGenerator {
  constructor(procedure: ProcedureName, tenantIdDh: number) {
    super(procedure, tenantIdDh);
  }

  override updateOptions(
    data: LaserConsumptionTimelineData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const options = super.updateOptions(data, parameters, prevOptions);
    delete options.dataZoom;
    options.grid = [
      {
        top: '7%',
        left: 40,
        right: 20,
        height: '38%',
      },
      {
        top: '52%',
        left: 40,
        right: 20,
        height: '38%',
      },
    ];

    return options;
  }

  protected generateKxSeries(
    data: LaserConsumptionTimelineData[],
    isCategoryAxis: boolean,
  ): SeriesOption[] {
    return new LaserInstantConsumptionKxSeriesGeneratorHelper(data, 1, isCategoryAxis).generate();
  }
}
