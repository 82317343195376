
import { Device } from '@/models/device';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DeviceCard from './overview/DeviceCard.vue';
import HealthIndicators from '@/components/careConsole/overview/HealthIndicators.vue';
import ProgressBar from '@/components/careConsole/ProgressBar.vue';
import ModuleEventsIndicators from '@/components/careConsole/overview/ModuleEventsIndicators.vue';
import { LayoutSlot } from '@/views/dashboard/layout-grid';
import { defaultDateRange } from '@/views/careConsole/commons/utils';

@Component({
  components: {
    ModuleEventsIndicators,
    ProgressBar,
    HealthIndicators,
    DeviceCard,
    LayoutGrid,
  },
})
export default class Overview extends Vue {
  @Prop({ required: true, default: () => defaultDateRange() })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private device!: Device;

  private layout: LayoutSlot[] = [
    { column: 0, row: 0, width: 4, height: 8 },
    { column: 4, row: 0, width: 4, height: 8 },
    { column: 8, row: 0, width: 4, height: 8 },
  ];
}
