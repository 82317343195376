
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { dateRangeToDateStrings, yearToDate } from '@/utils/dates';
import { translateFilterTimeAxisSpanEnum } from '@/utils/i18n';
import LayoutGrid from '../dashboard/LayoutGrid.vue';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import { indexedWidgetDefinitions } from '@/utils/misc';

@Component({
  methods: { translateFilterTimeAxisSpanEnum },
  components: {
    WidgetContainer,
    DateGroupingFilter,
    LayoutGrid,
  },
})
export default class Quotes extends Vue {
  private widgetDefinitions: WidgetDefinition[] = [];

  private selectedTimeAxisSpan = FilterTimeAxisSpanEnum.Month;
  private dateRangeSelection = yearToDate();
  private gridLayout = availableDashboardLayouts[DashboardLayout.TwoByThree];

  @Ref('bDatepicker')
  private bDatepicker: any;

  private created() {
    this.widgetDefinitions = this.createWidgetDefinitions();
  }

  private createWidgetDefinitions(): WidgetDefinition[] {
    return indexedWidgetDefinitions(
      [
        WidgetEnum.ManufacturingPOStatus,
        WidgetEnum.ManufacturingFinishedPOEstimatedVsActualPOL,
        WidgetEnum.ManufacturingReleasedPOStatus,
        WidgetEnum.ManufacturingPOLStatusDueDate,
        WidgetEnum.ManufacturingFigures,
        WidgetEnum.ManufacturingPOFinishedDueDate,
      ].map(
        (widgetEnumValue) =>
          new WidgetDefinition(
            widgetEnumValue,
            [],
            this.dateRangeString,
            [],
            undefined,
            this.selectedTimeAxisSpan,
            undefined,
          ),
      ),
    );
  }

  @Watch('dateRangeString')
  @Watch('selectedTimeAxisSpan')
  private updateFilterOnWidgetDefinitions() {
    this.widgetDefinitions = this.widgetDefinitions.map((widgetDefinition) =>
      widgetDefinition.getCopy({
        timeFilter: this.dateRangeString,
        axisSpan: this.selectedTimeAxisSpan,
      }),
    );
  }

  private updateWidgetDefinition(index: number, widgetDefinition: WidgetDefinition) {
    this.$set(this.widgetDefinitions, index, widgetDefinition);
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRangeSelection);
  }

  private toggleDatepicker() {
    this.bDatepicker.toggle();
  }
}
