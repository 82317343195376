// --------------------------------------------------------------------------------
// <copyright file="driveCurrentTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { DriveCurrentTimelineData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, SeriesOption } from 'echarts';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { XAXisOption } from 'echarts/types/dist/shared';
import { metricsService } from '@/services/metrics.service';
import { tooltipFormatter } from './driveCurrentTimelineGeneratorTooltipFormatter';
import {
  CUTTING_HEAD_VERSION_CHANGE_SYMBOL,
  cuttingHeadDataHasCategoryAxis,
  doesAllDataComeFromNewerCuttingHeadSoftware,
  getHeadVersionChangeSeriesData,
} from './cuttingHeadCommons';
import { isNil } from '@/utils/misc';
import { last } from '@/utils/array';

export class DriveCurrentTimelineGenerator extends ChartGenerator<DriveCurrentTimelineData[]> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const deviceId = selectedDevices[0];
    return metricsService.getSSCMetrics<DriveCurrentTimelineData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: timeSpan[0],
        dateTo: timeSpan[1],
        dateGrouping: timeAxisSpan,
        aggregate: params?.aggregate,
        versionInformation: true,
        interruptIfV4: true,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: DriveCurrentTimelineData[],
    parameters: GeneratorParams = {},
  ): EChartsOption {
    if (doesAllDataComeFromNewerCuttingHeadSoftware(data)) {
      return {
        title: {
          text: i18n.t('widget_info_text.no_data_for_ch').toString(),
          left: 'center',
          top: 'center',
        },
      };
    }

    // Only regular linear data must be taken into account to decide if the chart must
    // be treated as categorical or not, so version history data is omitted.
    const isCategoryAxis = cuttingHeadDataHasCategoryAxis(parameters.timeAxisSpan, data);

    const xAxisData = data.map((x) => x.bucket);
    return {
      legend: {
        icon: 'roundRect',
        type: 'scroll',
        bottom: 0,
        formatter: (name: string) => i18n.t(`report.${name}`).toString(),
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'weakFilter',
          xAxisIndex: [0, 1],
          bottom: 40,
          labelFormatter: '',
        },
        {
          type: 'inside',
          filterMode: 'weakFilter',
          xAxisIndex: [0, 1],
        },
      ],
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => tooltipFormatter(params, parameters, isCategoryAxis, data),
      },
      grid: [
        {
          top: '10%',
          left: 40,
          right: 40,
          height: '29%',
        },
        {
          top: '45%',
          left: 40,
          right: 40,
          height: '29%',
        },
      ],
      xAxis: [
        {
          gridIndex: 0,
          type: isCategoryAxis ? 'category' : 'time',
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: false,
          },
          data: isCategoryAxis ? xAxisData : undefined,
          min: xAxisData[0],
          max: last(xAxisData),
        },
        {
          gridIndex: 1,
          type: isCategoryAxis ? 'category' : 'time',
          axisLabel: {
            show: true,
            hideOverlap: true,
          },
          axisTick: { show: true },
          splitLine: {
            show: false,
          },
          data: isCategoryAxis ? xAxisData : undefined,
          min: xAxisData[0],
          max: last(xAxisData),
        },
      ] as [XAXisOption, XAXisOption],
      yAxis: [
        {
          gridIndex: 0,
          name: i18n.t('milliampere_abbreviation').toString(),
          type: 'value',
          scale: true,
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
          },
          axisLabel: {
            formatter: (value: number) => i18n.n(value),
          },
        },
        {
          gridIndex: 0,
          name: i18n.t('celsius_degrees_abbreviation').toString(),
          type: 'value',
          position: 'right',
          scale: true,
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
          },
          axisLabel: {
            formatter: (value: number) => i18n.n(value),
          },
        },
        // Cutting head version changes on absolute temperatures sub-chart
        {
          gridIndex: 0,
          type: 'value',
          show: false,
        },
        {
          gridIndex: 1,
          type: 'value',
          scale: true,
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
          },
          axisLabel: {
            formatter: (value: number) => i18n.n(value),
          },
        },
      ],
      series: this.generateSeries(data, isCategoryAxis),
    };
  }

  private generateSeries(
    data: DriveCurrentTimelineData[],
    isCategoryAxis: boolean,
  ): SeriesOption[] {
    const currentDifferenceData = this.getSeriesData('diff_DriveCurrent', data, isCategoryAxis);
    const headVersionChangeSeriesData = getHeadVersionChangeSeriesData(
      data,
      isCategoryAxis,
    ) as Array<[string, number]>;
    return [
      {
        name: `drive_current`,
        data: this.getSeriesData('DriveCurrent', data, isCategoryAxis),
        type: 'line',
        emphasis: { focus: 'series' },
        xAxisIndex: 0,
        yAxisIndex: 0,
      },
      {
        name: 'capacitive_temperature',
        data: this.getSeriesData('TempCapacitive', data, isCategoryAxis),
        type: 'line',
        emphasis: { focus: 'series' },
        xAxisIndex: 0,
        yAxisIndex: 1,
      },
      {
        data: getHeadVersionChangeSeriesData(data, isCategoryAxis),
        type: 'scatter',
        // FIXME: We should be able to load the image like below, but for some
        //   reason it looks blurry.
        // symbol: 'image:///images/cutting-head-version-change-mark.svg',
        symbol: CUTTING_HEAD_VERSION_CHANGE_SYMBOL,
        symbolSize: 15,
        symbolOffset: [0, '-50%'],
        color: '#000000',
        xAxisIndex: 0,
        yAxisIndex: 2,
        z: 4, // Make the symbol appear above the rest of the series
      },
      {
        name: 'current_difference',
        data:
          currentDifferenceData.length === 0
            ? [
                [headVersionChangeSeriesData[0][0], null],
                [last(headVersionChangeSeriesData)![0], null],
              ]
            : currentDifferenceData,
        type: 'line',
        emphasis: { focus: 'series' },
        xAxisIndex: 1,
        yAxisIndex: 3,
      },
    ];
  }

  private getSeriesData(
    fieldName: keyof DriveCurrentTimelineData,
    dataArray: DriveCurrentTimelineData[],
    isCategoryAxis: boolean,
  ): any[] {
    if (isCategoryAxis) {
      return dataArray
        .filter((dataItem) => !isNil(dataItem[fieldName]))
        .map((dataItem) => [dataItem.bucket, dataItem[fieldName]]);
    }
    return dataArray
      .filter((dataItem) => !isNil(dataItem[fieldName]))
      .map((dataItem) => [dataItem.bucket, dataItem[fieldName]]);
  }
}
