// --------------------------------------------------------------------------------
// <copyright file="TechnologyConsoleTab.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum TechnologyConsoleTab {
  Overview,
  Temperatures,
  Laser,
  DriveValues,
  DrawerInformation,
  ErrorsAndWarnings,
}
