
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Membership, MembershipType } from '@/models/user';
import { isEmpty } from '@/utils/misc';

@Component
export default class OtherMembershipsSummarySection extends Vue {
  @Prop({ required: true })
  private memberships!: Membership[];

  private get subsidiaryMemberships(): Membership[] {
    return this.getMembershipsOfType(MembershipType.Subsidiary);
  }

  private get customerMemberships(): Membership[] {
    return this.getMembershipsOfType(MembershipType.Customer);
  }

  private get hasSubsidiaryMemberships(): boolean {
    return !isEmpty(this.subsidiaryMemberships);
  }

  private get hasCustomerMemberships(): boolean {
    return !isEmpty(this.customerMemberships);
  }

  private getMembershipsOfType(type: MembershipType): Membership[] {
    return this.memberships
      .filter((membership) => membership.type === type);
  }
}
