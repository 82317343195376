// --------------------------------------------------------------------------------
// <copyright file="subsidiary.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export class Subsidiary {
  static GetEmpty(): Subsidiary {
    return new Subsidiary(0, '', 0);
  }
  static GetCopy(original: Subsidiary): Subsidiary {
    return new Subsidiary(original.id, original.name, original.code);
  }
  constructor(public id: number, public name: string, public code: number) {}
}
