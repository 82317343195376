
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NumberedBreadcrumbItem extends Vue {
  @Prop({ required: true, type: Boolean })
  private active!: boolean;

  @Prop({ required: true, type: Boolean })
  private disabled!: boolean;

  @Prop({ required: true, type: Number })
  private index!: number;

  @Prop({ default: false })
  private slim!: boolean;
}
