// --------------------------------------------------------------------------------
// <copyright file="permissionUtils.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { Customer } from '@/models/tenant';
import { User } from '@/models/user';
import { UserType } from '@/models/userType';
import { usersService } from '@/services/users.service';
import { customersService } from '@/services/tenants.service';

export function hasCurrentUserPermission(accessLevel: UserType): boolean {
  if (currentUser().isGlobalAdmin) {
    return true;
  }

  switch (accessLevel) {
    case UserType.SubsidiaryAdmin:
      return currentUser().isSubsidiaryAdmin;
    case UserType.CustomerAdmin:
      return currentUser().isSubsidiaryAdmin || currentUser().isCustomerAdmin;
    case UserType.CustomerUser:
      return (
        currentUser().isSubsidiaryAdmin ||
        currentUser().isCustomerAdmin ||
        currentUser().isCustomerUser
      );
    case UserType.ServiceGlobal:
      return currentUser().isServiceGlobal;
    case UserType.ServiceUser:
      return currentUser().isServiceUserType;
    case UserType.TechnologyUser:
      return currentUser().isTechnologyUser;
    default:
      return false;
  }
}

export function canAccessWorkCenterConsole(): boolean {
  const user = currentUser();
  return (
    user?.consoles.includes(ConsoleEnum.WCC) &&
    currentCustomer()?.hasDatahub === true &&
    !user.isTechnologyUser &&
    !user.isServiceUserType
  );
}

export function canAccessBusinessConsole(): boolean {
  const user = currentUser();
  return (
    user?.consoles.includes(ConsoleEnum.BC) &&
    currentCustomer()?.hasSmbs === true &&
    !user.isTechnologyUser &&
    !user.isServiceUserType
  );
}

export function canAccessShopFloorConsole(): boolean {
  // TODO: True if customer has SMBS or Plant Manager
  const user = currentUser();
  const consoles = user?.consoles ?? [];
  return (
    consoles.includes(ConsoleEnum.SFC) &&
    currentCustomer()?.hasSmbs === true &&
    !user.isTechnologyUser &&
    !user.isServiceUserType
  );
}

export function canAccessTechnologyConsole(): boolean {
  return currentUser().isTechnologyUser;
}

export function canAccessEServiceCenter(): boolean {
  return currentUser().isServiceUserType;
}

export function canAccessCareConsoleAlerts(): boolean {
  return hasCurrentUserPermission(UserType.ServiceUser);
}

export function canAccessControlList(): boolean {
  return currentUser().isSubsidiaryAdmin || currentUser().isGlobalAdmin;
}

export function canAccessDataPlatforms(): boolean {
  return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
}

export function canAccessCareConsoleSettingsMenu(): boolean {
  return hasCurrentUserPermission(UserType.ServiceUser);
}

export function canDownloadDataPlatform(): boolean {
  return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
}

export function currentUser(): User {
  return usersService.store.current();
}

export function currentCustomer(): Customer | null {
  return customersService.store.current();
}
