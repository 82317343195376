
import TenantsSelect from '@/components/inputs/TenantsSelect.vue';
import { WizardStep } from '@/components/wizard';
import { User } from '@/models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    TenantsSelect,
    WizardStep,
  },
})
export default class CustomerMembershipWizardStep extends Vue {
  @Prop()
  private value!: User;

  @Prop({ default: false })
  private hidden!: boolean;

  private onInput() {
    this.$emit('input', this.value);
  }
}
