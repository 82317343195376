
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { CompositeWidgetDefinition, WidgetEntry } from '@/models/Charts/compositeWidgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  computed: {
    WidgetEnum: () => WidgetEnum,
  },
  components: {
    'laser-availability': () => import('./widgetInfo/LaserAvailability.vue'),
    'laser-availability-hist': () => import('./widgetInfo/LaserAvailabilityHist.vue'),
    'laser-productivity': () => import('./widgetInfo/LaserProductivity.vue'),
    'laser-productivity-hist': () => import('./widgetInfo/LaserProductivityHist.vue'),
    'laser-starve-block': () => import('./widgetInfo/LaserStarveBlock.vue'),
    'laser-output-scrap': () => import('./widgetInfo/LaserOutputScrap.vue'),
    'laser-oee': () => import('./widgetInfo/LaserOee.vue'),
    'laser-cutting-time': () => import('./widgetInfo/LaserCuttingTime.vue'),
    'laser-oee-hist': () => import('./widgetInfo/LaserOeeHist.vue'),
    'laser-cutting-time-hist': () => import('./widgetInfo/LaserCuttingTimeHist.vue'),
    'laser-starve-block-hist': () => import('./widgetInfo/LaserStarveBlockHist.vue'),
    'laser-output-and-scrap-timeline': () => import('./widgetInfo/LaserOutputAndScrapTimeline.vue'),
    'laser-status': () => import('./widgetInfo/LaserStatus.vue'),
    'machine-states-gantt': () => import('./widgetInfo/MachineStatesGantt.vue'),
    'laser-gantt-chart-table': () => import('./widgetInfo/LaserGanttChartTable.vue'),
    'bend-gantt-chart-table': () => import('./widgetInfo/BendGanttChartTable.vue'),
    'cut-current-workload-table': () => import('./widgetInfo/CutCurrentWorkloadTable.vue'),

    'care-overview-health-indicators': () =>
      import('./widgetInfo/CareOverviewHealthIndicators.vue'),
    'care-overview-module-events-indicators': () =>
      import('./widgetInfo/CareOverviewModuleEventsIndicators.vue'),
    'technology-overview-cutting-hours-timeline': () =>
      import('./widgetInfo/TechnologyOverviewCuttingHoursTimeline.vue'),
    'technology-overview-laser-consumption-timeline': () =>
      import('./widgetInfo/TechnologyOverviewLaserConsumptionTimeline.vue'),
    'technology-overview-ssc-figures': () =>
      import('./widgetInfo/TechnologyOverviewSscFigures.vue'),
    'technology-temperatures': () => import('./widgetInfo/TechnologyTemperaturesInfo.vue'),
    'technology-drive-current-timeline': () =>
      import('./widgetInfo/TechnologyDriveCurrentTimeline.vue'),
    'technology-drive-current-difference-histogram': () =>
      import('./widgetInfo/TechnologyDriveCurrentDiffHistGenerator.vue'),
    'technology-drive-accumulated-current-difference-timeline': () =>
      import('./widgetInfo/TechnologyDriveAccCurrentDiffTimeline.vue'),
    'drawer-information-timeline': () => import('./widgetInfo/DrawerInformationTimeline.vue'),
    'technology-drawer-cycle-times': () => import('./widgetInfo/TechnologyDrawerCycleTimes.vue'),
    'care-alert-occurrences-table': () => import('./widgetInfo/CareAlertOccurrencesTable.vue'),
    'care-health-timeline': () => import('./widgetInfo/CareHealthTimeline.vue'),
    'care-health-indicators': () => import('./widgetInfo/CareHealthIndicators.vue'),
    'technology-laser-consumption-timeline': () =>
      import('./widgetInfo/TechnologyLaserConsumptionTimeline.vue'),
    'care-laser-consumption-timeline': () =>
      import('./widgetInfo/CareLaserConsumptionTimeline.vue'),
    'care-laser-available-vs-used-timeline': () =>
      import('./widgetInfo/CareLaserAvailableVsUsedTimeline.vue'),
    'care-laser-power-utilization-timeline': () =>
      import('./widgetInfo/CareLaserPowerUtilizationTimeline.vue'),
    'technology-laser-available-vs-used-times': () =>
      import('./widgetInfo/TechnologyLaserAvailableVsUsedTimes.vue'),
    'technology-laser-power-utilization-times': () =>
      import('./widgetInfo/TechnologyLaserPowerUtilizationTimes.vue'),
    'care-events-timeline': () => import('./widgetInfo/CareEventsTimeline.vue'),
    'care-cutting-head-diodes-and-drawer-cycles-timeline': () =>
      import('./widgetInfo/CareCuttingHeadDiodesAndDrawerCyclesTimeline.vue'),
    'care-cutting-head-events-timeline': () =>
      import('./widgetInfo/CareCuttingHeadEventsTimeline.vue'),
    'care-events-by-module': () => import('./widgetInfo/CareEventsByModule.vue'),
    'care-cutting-head-temperatures': () => import('./widgetInfo/CareCuttingHeadTemperatures.vue'),

    'events-table-expanded-view': () => import('./widgetInfo/EventsTableExpandedView.vue'),

    'bending-availability': () => import('./widgetInfo/BendingAvailability.vue'),
    'bending-time-between-bend': () => import('./widgetInfo/BendingTimeBetweenBend.vue'),
    'bending-top-parts': () => import('./widgetInfo/BendingTopParts.vue'),
    'bending-performance-part': () => import('./widgetInfo/BendingPerformancePart.vue'),
    'bending-availability-hist': () => import('./widgetInfo/BendingAvailabilityHist.vue'),
    'bending-time-between-bend-hist': () => import('./widgetInfo/BendingTimeBetweenBendHist.vue'),
    'bending-performance-part-hist': () => import('./widgetInfo/BendingPerformancePartHist.vue'),
    'bending-performance-hist': () => import('./widgetInfo/BendingPerformanceHist.vue'),
    'bending-performance': () => import('./widgetInfo/BendingPerformance.vue'),
    'bending-status': () => import('./widgetInfo/BendingStatus.vue'),
    'bending-number-of-bends-timeline': () =>
      import('./widgetInfo/BendingNumberOfBendsTimeline.vue'),

    'tube-availability': () => import('./widgetInfo/TubeAvailability.vue'),
    'tube-availability-hist': () => import('./widgetInfo/LaserAvailabilityHist.vue'),
    'tube-oee': () => import('./widgetInfo/TubeOee.vue'),
    'tube-oee-hist': () => import('./widgetInfo/TubeOeeHist.vue'),
    'tube-starve-block': () => import('./widgetInfo/TubeStarveBlock.vue'),
    'tube-starve-block-hist': () => import('./widgetInfo/TubeStarveBlockHist.vue'),
    'tube-output-scrap': () => import('./widgetInfo/TubeOutputScrap.vue'),
    'tube-output-scrap-hist': () => import('./widgetInfo/TubeOutputAndScrapTimeline.vue'),
    'tube-cutting-time': () => import('./widgetInfo/LaserCuttingTime.vue'),
    'tube-cutting-time-hist': () => import('./widgetInfo/TubeCuttingTimeHist.vue'),
    'tube-status': () => import('./widgetInfo/TubeStatus.vue'),

    'camera-view': () => import('./widgetInfo/CameraView.vue'),
    'factory-overview': () => import('./widgetInfo/FactoryOverview.vue'),

    'sales-map': () => import('./widgetInfo/SalesMap.vue'),
    'quotes-map': () => import('./widgetInfo/QuotesMap.vue'),
    'open-sales-funnel': () => import('./widgetInfo/OpenSalesFunnel.vue'),
    'business-overview-figures': () => import('./widgetInfo/BusinessOverviewFigures.vue'),

    'quotes-conversion-rates': () => import('./widgetInfo/QuotesConversionRates.vue'),
    'quotes-conversion-rates-detailed': () => import('./widgetInfo/QuotesConversionRatesGauge.vue'),
    'quotes-abc-customers': () => import('./widgetInfo/QuotesAbcCustomers.vue'),
    'quotes-figures': () => import('./widgetInfo/QuotesFigures.vue'),
    'quotes-figures-detailed': () => import('./widgetInfo/QuotesFiguresDetailed.vue'),
    'quotes-customer-evolution': () => import('./widgetInfo/QuotesCustomerEvolution.vue'),
    'quotes-top-quoted-materials-timeline': () =>
      import('./widgetInfo/QuotesTopQuotedMaterialsTimeline.vue'),
    'quotes-quoted-materials-list': () => import('./widgetInfo/QuotesQuotedMaterialsList.vue'),
    'quotes-converted-amount-timeline': () =>
      import('./widgetInfo/QuotesConvertedAmountTimeline.vue'),
    'quotes-size': () => import('./widgetInfo/QuotesSize.vue'),
    'quotes-converted-map': () => import('./widgetInfo/QuotesConvertedMap.vue'),

    'sales-funnel': () => import('./widgetInfo/SalesFunnel.vue'),
    'sales-customers-abc': () => import('./widgetInfo/SalesCustomersABC.vue'),
    'sales-figures': () => import('./widgetInfo/SalesFigures.vue'),
    'sales-timeline': () => import('./widgetInfo/SalesTimeline.vue'),
    'sales-top-salespeople': () => import('./widgetInfo/SalesTopSalespeople.vue'),
    'sales-top-salespeople-detailed': () => import('./widgetInfo/SalesTopSalespeopleDetailed.vue'),
    'sales-amount-vs-number-of-quotes-by-salesperson': () =>
      import('./widgetInfo/SalesAmountVsNumberOfQuotesBySalesperson.vue'),
    'sales-amount-by-salesperson': () => import('./widgetInfo/SalesAmountBySalesperson.vue'),
    'sales-item-changes-by-salesperson': () =>
      import('./widgetInfo/SalesItemChangesBySalesperson.vue'),
    'number-of-sales-by-salesperson': () => import('./widgetInfo/NumberOfSalesBySalesperson.vue'),
    'sales-per-salesperson-timeline': () => import('./widgetInfo/SalesPerSalespersonTimeline.vue'),
    'sales-unpaid-distribution': () => import('./widgetInfo/SalesUnpaidDistribution.vue'),

    'manufacturing-released-po-status-info': () =>
      import('./widgetInfo/ManufacturingReleasedPOStatus.vue'),
    'manufacturing-po-status-info': () => import('./widgetInfo/ManufacturingPOStatus.vue'),
    'pol-status-due-date': () => import('./widgetInfo/ManufacturingPOLStatusDueDate.vue'),
    'po-finished-due-date': () => import('./widgetInfo/ManufacturingPOFinishedDueDate.vue'),
    'finished-po-estim-actual': () =>
      import('./widgetInfo/ManufacturingFinishedPOEstimatedVsActualPOL.vue'),
    'manufacturing-figures': () => import('./widgetInfo/ManufacturingFigures.vue'),
  },
})
export default class WidgetInfo extends Vue {
  @Prop()
  private widget!: WidgetEntry | WidgetEnum;

  @Prop({ default: 'is-bottom-right' })
  private position!: string;

  private openCloseAll() {
    document.body
      .querySelectorAll('details')
      .forEach((e) =>
        e.hasAttribute('open') ? e.removeAttribute('open') : e.setAttribute('open', 'true'),
      );
  }

  private onDropdownTriggerClicked() {
    document.body
      .querySelectorAll('details')
      .forEach((e) => (e.hasAttribute('open') ? e.removeAttribute('open') : ''));
  }

  private hasManyWidgets(): boolean {
    return (
      typeof this.widget !== 'string' &&
      'isComposite' in (this.widget as any) &&
      (this.widget as any).isComposite()
    );
  }

  private get widgets(): WidgetEnum[] {
    if (this.hasManyWidgets()) {
      const compositeWidget = this.widget as CompositeWidgetDefinition;
      return [
        compositeWidget.left.widgetDefinition.widget,
        compositeWidget.right.widgetDefinition.widget,
      ];
    } else if (this.widget instanceof WidgetDefinition) {
      return [this.widget.widget];
    } else if (Object.values(WidgetEnum).includes(this.widget as WidgetEnum)) {
      return [this.widget as WidgetEnum];
    } else {
      return [];
    }
  }

  private getIcon(index: number): string {
    return index === 0
      ? (this.widget as CompositeWidgetDefinition).left.icon
      : (this.widget as CompositeWidgetDefinition).right.icon;
  }
}
