
import { Component, Prop, Vue } from 'vue-property-decorator';
import OverviewDeviceCardKpi from './OverviewDeviceCardKpi.vue';

@Component({
  components: {
    OverviewDeviceCardKpi,
  },
})
export default class OverviewDeviceCardKpiList extends Vue {
  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true })
  private width!: string;

  @Prop({ required: true })
  private leftLabel!: string;

  @Prop({ required: true })
  private rightLabel!: string;
}
