
import SubsidiariesSelect from '@/components/inputs/SubsidiariesSelect.vue';
import { WizardStep } from '@/components/wizard';
import { User } from '@/models/user';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    SubsidiariesSelect,
    WizardStep,
  },
})
export default class SubsidiaryMembershipWizardStep extends Vue {
  @Prop()
  private value!: User;

  @Prop({ default: false, type: Boolean })
  private hidden!: boolean;
}
