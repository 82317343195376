
import { mastersService } from '@/services/masters.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClickMenu } from './menu';

@Component({
  components: {
    ClickMenu,
  },
})
export default class EventModuleFilter extends Vue {
  @Prop({ default: () => [] })
  private value!: string[];

  @Prop({ default: 'is-bottom-left' })
  private position!: string;

  private get modules(): string[] {
    return mastersService.store.getEventModules();
  }
}
