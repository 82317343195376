// --------------------------------------------------------------------------------
// <copyright file="quotesMapGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { gradient } from '@/utils/color';
import MapGenerator, { MapData, NoDataException } from './mapGenerator';
import { numberWithCurrency } from '@/utils/number';
import { SMBSMapData } from './mapsData';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockSMBSMapData } from '@/models/Charts/mockWidgetSelectorData';
import { isEmpty } from '@/utils/misc';
import { metricsService } from '@/services/metrics.service';
import { ProcedureName } from '../Charts/abstract/chartGenerator';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';

export default class QuotesMapGenerator extends MapGenerator {
  constructor(
    procedure: ProcedureName,
    private tenantIdDh: number,
    private field: 'amount' | 'converted_amount' = 'amount',
  ) {
    super(procedure);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ): Promise<MapData | null> {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    const data = await metricsService.getSMBSMetricsBC<SMBSMapData[]>(
      this.procedure,
      this.tenantIdDh,
      { startDate, endDate },
      this.controller,
    );
    if (isEmpty(data)) {
      throw new NoDataException();
    }
    const customers = data!.filter((customer) => !!customer.latitude && !!customer.longitude);
    if (
      !!customerNo &&
      !customers.some((customer) => customer.bill_to_customer_no_ === customerNo)
    ) {
      throw new NoDataException();
    }
    return this.generateMapSettings(customers, customerNo);
  }

  override getMockData(): MapData {
    const data = mockSMBSMapData();
    return this.generateMapSettings(data);
  }

  private generateMapSettings(customers: SMBSMapData[], customerNo?: string): MapData {
    const minAmount: number = customers
      .map((customer) => customer[this.field])
      .reduce((a, b) => (a < b ? a : b), Number.MAX_SAFE_INTEGER);
    const maxAmount = customers
      .map((customer) => customer[this.field])
      .reduce((a, b) => (a > b ? a : b), Number.MIN_SAFE_INTEGER);
    const amountDiff = maxAmount - minAmount;
    const maxRadius = 10;

    const extrapolateRadius = (amount: number) => {
      return Math.floor(1 + ((maxRadius - 1) * (amount - minAmount)) / amountDiff);
    };

    return {
      markers: customers.map((customer) => {
        const radius = extrapolateRadius(customer[this.field]);
        return {
          coordinates: [customer.latitude, customer.longitude],
          radius: Math.ceil(radius * 0.7 + 3),
          color: this.getColor(customer, radius - 1, customerNo),
          zIndexOffset: customer.bill_to_customer_no_ !== customerNo ? -1000 : 1000,
          tooltip: () => `
          <p style="font-weight: bold; font-size: 14px; font-family: Inter">${customer.name}</p>
          <div style="display: flex; flex-direction: row;column-gap:1em">
            <div style="font-family: Inter; flex-grow: 1">${i18n.t(
              `report.business_console.${this.field}`,
            )}</div>
            <div style="font-family: Inter">${numberWithCurrency(customer[this.field])}</div>
          </div>
          `,
        };
      }),
    };
  }

  private getColor(customer: SMBSMapData, index: number, customerNo?: string): string {
    if (!!customerNo) {
      if (customer.bill_to_customer_no_ === customerNo!) {
        if (this.field === 'amount') {
          return '#5470c6';
        } else {
          return '#91cc75';
        }
      } else {
        return '#7f7f7f';
      }
    } else {
      return gradient[index];
    }
  }
}
