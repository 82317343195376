// --------------------------------------------------------------------------------
// <copyright file="metricsDownloadService.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { Logger } from '@/utils/logger';
import { downloadUrl, isEmpty } from '@/utils/misc';

export function downloadCsv(data: any[], fileName: string) {
  const csvData = jsonToCsv(data);
  const url = window.URL.createObjectURL(new Blob([csvData], { type: 'text/csv' }));
  downloadUrl(url, fileName);
}

function jsonToCsv(data: object[]) {
  if (isEmpty(data)) {
    Logger.warn('No data available to download');
    return '';
  }
  const keys: string[] = Object.keys(data[0]);
  return (
    keys.join(',') + `\n` + data.map((series: object) => Object.values(series).join(',')).join(`\n`)
  );
}
