
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateUserTarget, UserTarget } from '@/models/userTarget';
import TargetsMetricsSelect from '../inputs/TargetsMetricSelect.vue';
import { userTargetsService } from '@/services/userTargets.service';
import { Tenant } from '@/models/tenant';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { TargetsMetric } from '@/models/enums/TargetsMetric';
import { isEmpty } from '@/utils/misc';

@Component({
  components: {
    TargetsMetricsSelect,
  },
})
export default class UpdateTarget extends Vue {
  @Prop()
  public tenant!: Tenant;

  @Prop({ required: true })
  public console!: ConsoleEnum;

  public targetMetric: TargetsMetric = TargetsMetric.None;
  public targetValue1: number | null = null;
  public targetValue2: number | null = null;
  private userTargetId: number | null = null;
  private validInputs: boolean[] = [true, true];

  private TargetsMetric = TargetsMetric;

  private isSubmitting = false;

  public openTargetUpdateForm(userTarget: UserTarget) {
    this.targetMetric = userTarget.targetMetric;
    this.targetValue1 = userTarget.target1;
    this.targetValue2 = userTarget.target2;
    this.userTargetId = userTarget.id;
    this.$emit('update_started');
  }

  public cancelUpdateTarget() {
    this.$emit('cancel');
    this.cleanup();
  }

  private checkValidity(payload: string, index: number) {
    if (isEmpty(payload) || !payload.trim()) {
      this.validInputs[index] = false;
    } else {
      const targetValue: number = +payload;
      this.validInputs[index] =
        this.targetValueWithinBounds(targetValue, this.targetMetric) && !isNaN(targetValue);
    }
  }

  private targetValueWithinBounds(targetValue: number, target: TargetsMetric): boolean {
    if (target === TargetsMetric.LaserOee || target === TargetsMetric.TubeOee) {
      return !(targetValue < 0 || targetValue > 100);
    } else {
      return targetValue >= 0;
    }
  }

  private cleanup() {
    this.targetMetric = TargetsMetric.None;
    this.targetValue1 = null;
    this.targetValue2 = null;
    this.userTargetId = null;
  }

  private updateTarget() {
    this.isSubmitting = true;
    const createdTarget = new CreateUserTarget(
      this.tenant.id,
      this.targetMetric,
      this.targetValue1,
      this.targetValue2,
    );
    userTargetsService
      .update(this.userTargetId!, createdTarget)
      .then(() => {
        this.$emit('updated');
        this.cleanup();
      })
      .finally(() => (this.isSubmitting = false));
  }
}
