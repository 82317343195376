// --------------------------------------------------------------------------------
// <copyright file="BendLayoutTab.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum BendLayoutTab {
  BendOverview,
  BendSummary,
  BendIntradayStates,
  BendTrends,
}
