
import { Component, Prop, Vue } from 'vue-property-decorator';
import HealthIndicator from '@/components/careConsole/HealthIndicator.vue';
import { isEmpty, omit } from '@/utils/misc';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import HealthBar from '@/components/careConsole/HealthBar.vue';
import { CareOverviewHealthData } from '@/models/Charts/chartsData';
import { dateRangeToDateStrings } from '@/utils/dates';
import { defaultDateRange } from '@/views/careConsole/commons/utils';

@Component({
  methods: { isEmpty },
  components: {
    HealthBar,
    WidgetContainer,
    HealthIndicator,
  },
})
export default class HealthIndicators extends Vue {
  @Prop({ required: true, default: () => defaultDateRange() })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private deviceId!: string;

  private getLevelMaximums(healthEntry: CareOverviewHealthData[]): number[] {
    return Object.values(omit(healthEntry, ['deviceElement', 'value']));
  }

  private get widgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareOverviewHealthIndicators,
      this.deviceId,
      this.dateRangeString,
    );
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }
}
