
import { Component, Prop, Vue } from 'vue-property-decorator';
import { tenantsService } from '@/services/tenants.service';
import { Tenant } from '@/models/tenant';
import TimezonesSelect from '@/components/inputs/TimezonesSelect.vue';
import CurrencyCodesSelect from '@/components/inputs/CurrencyCodesSelect.vue';
import MeasurementsSelect from '@/components/inputs/MeasurementsSelect.vue';
import SubsidiariesSelect from '@/components/inputs/SubsidiariesSelect.vue';
import TenantSourcesSelect from '@/components/inputs/TenantSourcesSelect.vue';
import { UserType } from '@/models/userType';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { usersService } from '@/services/users.service';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import RequiredFieldsHint from '@/components/forms/RequiredFieldsHint.vue';

@Component({
  components: {
    RequiredFieldsHint,
    TimezonesSelect,
    CurrencyCodesSelect,
    MeasurementsSelect,
    SubsidiariesSelect,
    TenantSourcesSelect,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CustomerProperties extends Vue {
  @Prop({ required: true, default: Tenant.GetEmpty() })
  private tenant!: Tenant;

  private editTenant = Tenant.GetEmpty();

  private isSubmitting = false;

  private get validationRules(): any {
    return {
      name: {
        required: true,
        max: 256,
      },
      subsidiaryId: 'required',
      email: {
        email: true,
        max: 256,
      },
      foreName: {
        max: 256,
      },
      lastName: {
        max: 256,
      },
      sapNumber: {
        required: true,
        max: 256,
      },
      currencyCode: 'required',
      measurement: 'required',
      timeZone: 'required',
      bystronicOrderNumber: {
        required: this.editTenant.hasDatahub || this.editTenant.hasSmbs,
        max: 1024,
      },
      bystronicOrderPerson: {
        required: this.editTenant.hasDatahub || this.editTenant.hasSmbs,
        max: 1024,
      },
      customerOrderNumber: {
        max: 1024,
      },
      customerOrderPerson: {
        max: 1024,
      },
    };
  }

  private created() {
    this.editTenant = Tenant.GetCopy(this.tenant);
  }

  private async onSubmit() {
    this.isSubmitting = true;
    tenantsService
      .update(this.editTenant)
      .then(async () => {
        this.$emit('tenant-updated', this.editTenant);
        await usersService.me();
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  }

  private get canEditCustomer(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }
}
