
import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '@/router/routes';
import { usersService } from '@/services/users.service';
import { PersistentActions } from '@/store/persistent/enums';

@Component
export default class Login extends Vue {
  public async mounted() {
    switch (this.$route.name) {
      case Routes.Login:
        await this.login();
        break;
      case Routes.LoginCallback:
        await this.processLoginCallback();
        break;
      case Routes.LoginFailed:
        this.redirectToApiAuthorizationPath('/Identity/Account/AccessDenied');
        break;
      default:
        this.$auth.logout();
    }
  }

  private async login(): Promise<void> {
    await this.$auth.login();
  }

  private async processLoginCallback(): Promise<void> {
    const url = window.location.href;
    this.$auth
      .completeSignIn(url)
      .then((result) => {
        if (result) {
          usersService
            .me()
            .then((user) => {
              this.$store.dispatch(PersistentActions.SetCurrentUser, user);
            })
            .finally(() => this.navigateToReturnUrl(this.getReturnUrl(result.state)));
        }
      })
      .catch(() => {
        this.$auth.logout();
      });
  }

  private async navigateToReturnUrl(returnUrl: string) {
    window.location.replace(returnUrl);
  }

  private getReturnUrl(state?: any): string {
    const fromQuery = this.$route.params.returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (
      fromQuery &&
      !(fromQuery.startsWith(`${window.location.origin}/`) || /\/[^\/].*/.test(fromQuery))
    ) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.',
      );
    }
    return (state && state.returnUrl) || fromQuery || '/';
  }

  private redirectToApiAuthorizationPath(apiAuthorizationPath: string) {
    // It's important that we do a replace here so that when the user hits the back arrow on the
    // browser they get sent back to where it was on the app instead of to an endpoint on this
    // component.
    const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
    window.location.replace(redirectUrl);
  }
}
