// --------------------------------------------------------------------------------
// <copyright file="deviceStatus.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { DeviceStatusEnum } from './enums/DeviceStatusEnum';

export class DeviceStatus {
  static GetEmpty(): DeviceStatus {
    return new DeviceStatus('0', DeviceStatusEnum.None, '', '', undefined);
  }
  constructor(
    public deviceId: string,
    public status: DeviceStatusEnum,
    public lastSeenAgoText: string,
    public statusText: string,
    public lastSeen?: Date,
  ) {}
}
