// --------------------------------------------------------------------------------
// <copyright file="widgetMachineType.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { MachineType } from '@/models/enums/MachineType';
import { WidgetEnum } from '@/models/enums/WidgetEnum';

export function getWidgetMachineType(widget: WidgetEnum) {
  if ((widget.startsWith('laser') || widget.startsWith('cut')) && !widget.startsWith('laser_tab')) {
    // TODO: ñapa lo de laser_tab
    return MachineType.Cut;
  } else if (widget.startsWith('bending') || widget.startsWith('bend')) {
    return MachineType.Bend;
  } else if (widget.startsWith('factory')) {
    return MachineType.FactoryOverview;
  } else if (widget.startsWith('tube')) {
    return MachineType.Tube;
  } else {
    return MachineType.Undefined;
  }
}
