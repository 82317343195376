// --------------------------------------------------------------------------------
// <copyright file="deviceBaseModel.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum DeviceModelType {
  Bending = 'bending',
  Laser = 'laser',
  Tubes = 'tubes',
}

export class DeviceBaseModel {
  constructor(
    public deviceBaseModelId: string,
    public name: string,
    public type: DeviceModelType,
    public code1: string,
  ) {}
}
