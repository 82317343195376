
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import moment from 'moment';
import DateGroupingFilter from '@/components/DateGroupingFilter.vue';
import TimeSpanFilter from '@/components/TimeSpanFilter.vue';
import ShiftFilter from '@/components/ShiftFilter.vue';
import { MachineType } from '@/models/enums/MachineType';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import { PersistentGetters } from '@/store/persistent/enums';
import { Device } from '@/models/device';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import IconSwitch from '@/components/switch/IconSwitch.vue';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { Shift } from '@/models/shift';
import { dateRangeToDateStrings } from '@/utils/dates';
import { translateFilterTimeAxisSpanEnum, translateFilterTimeSpanEnum } from '@/utils/i18n';
import { devicesService } from '@/services/devices.service';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import LayoutGrid from '../dashboard/LayoutGrid.vue';
import { indexedWidgetDefinitions } from '@/utils/misc';
import TubeStatus from '@/components/tube/TubeStatus.vue';

@Component({
  computed: {
    MachineType: () => MachineType,
  },
  components: {
    WidgetContainer,
    ClickMenu,
    DateGroupingFilter,
    LayoutGrid,
    ShiftFilter,
    IconSwitch,
    TimeSpanFilter,
    TubeStatus,
  },
})
export default class Tubes extends Vue {
  @Prop({ default: '[]' })
  private selectedDevicesInput!: string;

  @Prop({ default: '[]' })
  private selectedShiftsInput!: string;

  private widgetDefinitions: WidgetDefinition[] = [];

  private dateRangeSelection: [Date, Date] = [moment().subtract(6, 'days').toDate(), new Date()];
  private selectedTimeAxisSpan = FilterTimeAxisSpanEnum.Day;
  private selectedTimespan = FilterTimeSpanEnum.Day;
  private selectedShifts: number[] = [];
  private selectedDevices: string[] = [];

  private isInLineChartMode = false;

  private gridLayout = availableDashboardLayouts[DashboardLayout.TwoByThree];

  @Ref('bDatepicker')
  private bDatepicker: any;

  private created() {
    this.widgetDefinitions = this.createWidgetDefinitions();
    this.selectedDevices =
      this.selectedDevicesInput === '' ? [] : JSON.parse(this.selectedDevicesInput);
    this.selectedShifts =
      this.selectedShiftsInput === '' ? [] : JSON.parse(this.selectedShiftsInput);
  }

  @Watch('activeDevices')
  @Watch('dateRangeString')
  @Watch('selectedTimespan')
  @Watch('selectedShifts')
  @Watch('selectedTimeAxisSpan')
  private updateFilterOnWidgetDefinitions() {
    this.widgetDefinitions = this.widgetDefinitions.map((widgetDefinition) =>
      widgetDefinition.getCopy({
        deviceId: this.activeDevices,
        timeFilter:
          this.isInLineChartMode &&
          (widgetDefinition as WidgetDefinition).widget !== WidgetEnum.BendingPerformance
            ? this.dateRangeString
            : this.selectedTimespan,
        shifts: this.selectedShifts,
        axisSpan: this.selectedTimeAxisSpan,
      }),
    );
  }

  private updateWidgetDefinition(index: number, widgetDefinition: WidgetDefinition) {
    this.$set(this.widgetDefinitions, index, widgetDefinition);
  }

  @Watch('isInLineChartMode')
  private onChartModeChanged() {
    this.widgetDefinitions = this.createWidgetDefinitions();
  }

  private updateAggregate(index: number, aggregates: number): void {
    this.$set(this.widgetDefinitions, index, this.widgetDefinitions[index].getCopy({ aggregates }));
  }

  private toggleChartMode() {
    this.isInLineChartMode = !this.isInLineChartMode;
  }

  private createWidgetDefinitions(): WidgetDefinition[] {
    const initialTimeFilter = this.isInLineChartMode ? this.dateRangeString : this.selectedTimespan;

    return indexedWidgetDefinitions([
      new WidgetDefinition(
        this.isInLineChartMode ? WidgetEnum.TubeAvailabilityHist : WidgetEnum.TubeAvailability,
        this.activeDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(WidgetEnum.TubeStatus, this.activeDevices, undefined, []),
      new WidgetDefinition(
        this.isInLineChartMode ? WidgetEnum.TubeOeeHist : WidgetEnum.TubeOee,
        this.activeDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        this.isInLineChartMode ? WidgetEnum.TubeStarveBlockHist : WidgetEnum.TubeStarveBlock,
        this.activeDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        this.isInLineChartMode ? WidgetEnum.TubeOutputScrapHist : WidgetEnum.TubeOutputScrap,
        this.activeDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
      new WidgetDefinition(
        this.isInLineChartMode ? WidgetEnum.TubeCuttingTimeHist : WidgetEnum.TubeCuttingTime,
        this.activeDevices,
        initialTimeFilter,
        this.selectedShifts,
        undefined,
        this.selectedTimeAxisSpan,
      ),
    ]);
  }

  private get shifts() {
    const allDevices = devicesService.store.get();
    return this.$store.getters[PersistentGetters.GetShifts].filter((shift: Shift) =>
      shift.relatedDevices?.some(
        (s) =>
          Device.GetMachineTypeById(Device.GetHubDeviceIdFromEntityDeviceId(s, allDevices)!) ===
            MachineType.Tube &&
          this.deviceIds.includes(Device.GetHubDeviceIdFromEntityDeviceId(s, allDevices)!),
      ),
    );
  }

  private get devices(): Device[] {
    return devicesService.store.tubeDevices();
  }

  private get deviceIds(): string[] {
    return this.devices.map((device: Device) => device.deviceId);
  }

  private get deviceNames(): string[] {
    return this.devices.map((device: Device) => device.name);
  }

  private get selectedTimespanTranslated() {
    return translateFilterTimeSpanEnum(this.selectedTimespan);
  }

  private get selectedGroupTimeAxisTranslated() {
    return translateFilterTimeAxisSpanEnum(this.selectedTimeAxisSpan);
  }

  private get dateRangeString(): [string, string] {
    return dateRangeToDateStrings(this.dateRangeSelection);
  }

  private toggleDatepicker() {
    this.bDatepicker.toggle();
  }

  private get activeDevices() {
    return this.selectedDevices.length !== 0 ? this.selectedDevices : this.deviceIds;
  }
}
