// --------------------------------------------------------------------------------
// <copyright file="shift.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ShiftOverflowEnum } from './enums/ShiftOverflowEnum';

export class Shift {
  static GetEmpty(): Shift {
    return new Shift(0, 0, '', 0, 0, 0, 0, null, []);
  }
  static GetCopy(shift: Shift): Shift {
    return new Shift(
      shift.id,
      shift.shiftModelId,
      shift.name,
      shift.timeFromHh,
      shift.timeFromMm,
      shift.timeToHh,
      shift.timeToMm,
      shift.overflow,
      shift.relatedDevices,
    );
  }
  constructor(
    public id: number,
    public shiftModelId: number,
    public name: string,
    public timeFromHh: number,
    public timeFromMm: number,
    public timeToHh: number,
    public timeToMm: number,
    public overflow: ShiftOverflowEnum | null,
    public relatedDevices: number[],
  ) {}
}
