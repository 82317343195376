// --------------------------------------------------------------------------------
// <copyright file="laserConsumptionTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import i18n from '@/i18n';
import { abbreviateNumber } from '@/utils/number';
import { LaserConsumptionTimelineData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, SeriesOption } from 'echarts';
import { getTooltipTitle, isCategoryAxisDateGrouping, isCategoryXAxis } from '@/utils/charts';
import { uniq } from '@/utils/array';
import { XAXisOption } from 'echarts/types/dist/shared';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { groupByDate } from '@/utils/dates';
import { metricsService } from '@/services/metrics.service';

export const TOTAL_CONSUMPTION_SERIES_NAME = 'totalConsumption';

export abstract class LaserConsumptionTimelineGenerator extends ChartGenerator<
  LaserConsumptionTimelineData[]
> {
  protected constructor(procedure: ProcedureName, protected tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ) {
    const deviceId = selectedDevices[0];
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<LaserConsumptionTimelineData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom,
        dateTo,
        dateGrouping: timeAxisSpan,
        last24h: true,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserConsumptionTimelineData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);
    const uniqueDates = uniq(data.map((item: LaserConsumptionTimelineData) => item.bucket));

    return {
      legend: {
        icon: 'roundRect',
        type: 'scroll',
        formatter: LaserConsumptionTimelineGenerator.getLegendFormatter(this.procedure),
        bottom: 0,
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'weakFilter',
          xAxisIndex: [0, 1],
          bottom: 40,
          labelFormatter: '',
        },
        {
          type: 'inside',
          filterMode: 'weakFilter',
          xAxisIndex: [0, 1],
        },
      ],
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: LaserConsumptionTimelineGenerator.getTooltipFormatter(
          this.procedure,
          parameters.timeAxisSpan,
          isCategoryAxis,
        ),
      },
      grid: [
        {
          top: '10%',
          left: 40,
          right: 20,
          height: '29%',
        },
        {
          top: '43%',
          left: 40,
          right: 20,
          height: '29%',
        },
      ],
      xAxis: [
        {
          gridIndex: 0,
          type: isCategoryAxis ? 'category' : 'time',
          data: isCategoryAxis ? uniqueDates : undefined,
          axisLabel: { show: false },
          axisTick: { show: false },
          splitLine: {
            show: false,
          },
        } as XAXisOption,
        {
          gridIndex: 1,
          type: isCategoryAxis ? 'category' : 'time',
          data: isCategoryAxis ? uniqueDates : undefined,
          axisLabel: {
            show: true,
            hideOverlap: true,
          },
          axisTick: { show: true },
          splitLine: {
            show: false,
          },
        } as XAXisOption,
      ],
      yAxis: [
        {
          gridIndex: 0,
          name: i18n.t('report.kwh').toString(),
          nameTextStyle: {
            align: 'left',
          },
          type: 'value',
          scale: true,
          axisLine: {
            show: true,
          },
          max(value) {
            return value.max * 1.2;
          },
          splitLine: {
            show: true,
          },
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
        },
        {
          gridIndex: 1,
          type: 'value',
          scale: true,
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
          },
          axisLabel: {
            formatter: (value: number) => abbreviateNumber(value),
          },
        },
      ],
      series: this.generateSeries(data, uniqueDates, isCategoryAxis),
    };
  }

  private generateSeries(
    data: LaserConsumptionTimelineData[],
    uniqueDates: string[],
    isCategoryAxis: boolean,
  ): SeriesOption[] {
    return [
      {
        data: this.getSeriesData(data, uniqueDates, isCategoryAxis),
        type: 'line',
        name: TOTAL_CONSUMPTION_SERIES_NAME,
        emphasis: { focus: 'series' },
        xAxisIndex: 0,
        yAxisIndex: 0,
      },
      ...this.generateKxSeries(data, isCategoryAxis),
    ];
  }

  protected getSeriesData(
    data: LaserConsumptionTimelineData[],
    uniqueDates: string[],
    isCategoryAxis: boolean,
  ) {
    return groupByDate(
      data,
      uniqueDates,
      (_, currentDataItem) => ({
        bucket: currentDataItem.bucket,
        totalConsumptionKwh: currentDataItem.totalConsumptionKwh,
      }),
      'bucket',
    ).map((dataItem: LaserConsumptionTimelineData) =>
      isCategoryAxis
        ? dataItem.totalConsumptionKwh
        : [dataItem.bucket, dataItem.totalConsumptionKwh],
    );
  }

  protected abstract generateKxSeries(
    data: LaserConsumptionTimelineData[],
    isCategoryAxis: boolean,
  ): SeriesOption[];

  private static getLegendFormatter(procedure: ProcedureName) {
    return (seriesName: string) =>
      LaserConsumptionTimelineGenerator.translateSeriesName(seriesName, procedure);
  }

  private static translateSeriesName(seriesName: string, procedure: ProcedureName): string {
    if (seriesName === TOTAL_CONSUMPTION_SERIES_NAME) {
      const translationKey =
        procedure === WidgetEnum.TechnologyLaserConsumptionTimeline
          ? 'accumulated_power'
          : 'power_consumption';
      return i18n.t(`report.${translationKey}`).toString();
    } else {
      // kx_consumption series, where x is the power
      const powerValue = seriesName.match(/\d+/)![0];
      return i18n.t(`report.kx_power`, [powerValue]).toString();
    }
  }

  private static getTooltipFormatter(
    procedure: ProcedureName,
    timeAxisSpan: FilterTimeAxisSpanEnum | undefined,
    isCategoryAxis: boolean,
  ) {
    return (params: any) =>
      LaserConsumptionTimelineGenerator.tooltipFormatter(
        params,
        procedure,
        timeAxisSpan ?? FilterTimeAxisSpanEnum.Hour,
        isCategoryAxis,
      );
  }

  private static tooltipFormatter(
    params: any,
    procedure: ProcedureName,
    timeAxisSpan: FilterTimeAxisSpanEnum,
    isCategoryAxis: boolean,
  ): string {
    let html = `
    <div data-testid="time-title" style="font-size: 15px">
      ${getTooltipTitle(params[0].axisValue, timeAxisSpan, isCategoryAxis)}
    </div>
    `;

    const accumulatedPowerParam = params.find(
      (param: any) => param.seriesName === TOTAL_CONSUMPTION_SERIES_NAME,
    );
    html += LaserConsumptionTimelineGenerator.getNumericValueTooltipHtml(
      accumulatedPowerParam,
      procedure,
      timeAxisSpan,
    );

    html += '<div style="height: 6px"></div>'; // chart values separator

    params
      .filter((paramItem: any) => paramItem.seriesName !== TOTAL_CONSUMPTION_SERIES_NAME)
      .forEach(
        (paramItem: any) =>
          (html += LaserConsumptionTimelineGenerator.getNumericValueTooltipHtml(
            paramItem,
            procedure,
            timeAxisSpan,
          )),
      );

    return html;
  }

  private static getNumericValueTooltipHtml(
    seriesParam: any,
    procedure: ProcedureName,
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ): string {
    const value = isCategoryAxisDateGrouping(timeAxisSpan)
      ? seriesParam.value
      : seriesParam.value[1]; // ['ISO date', value]
    const formattedValue = `${i18n.n(value, { maximumFractionDigits: 2 })} ${i18n.t('report.kwh')}`;
    return LaserConsumptionTimelineGenerator.getValueTooltipHtml(
      seriesParam,
      formattedValue,
      procedure,
    );
  }

  private static getValueTooltipHtml(
    seriesParam: any,
    value: string,
    procedure: ProcedureName,
  ): string {
    const translatedSeriesName = LaserConsumptionTimelineGenerator.translateSeriesName(
      seriesParam.seriesName,
      procedure,
    );

    return `
    <div
      data-testid="series-value-${seriesParam.axisIndex}-${seriesParam.seriesName}"
      style="display: flex; flex-direction: row"
    >
      <div style="flex-grow: 1">
        ${seriesParam.marker}
        ${translatedSeriesName}
      </div>
      <div style="font-weight: bold; padding-left: 3rem">${value}</div>
    </div>
    `;
  }
}
