
import { Component, Prop, Vue } from 'vue-property-decorator';
import { User, UserAuthenticationType } from '@/models/user';
import { usersService } from '@/services/users.service';
import { Subsidiary } from '@/models/subsidiary';
import { subsidiariesService } from '@/services/subsidiaries.service';
import PasswordResetModal from '@/components/users/PasswordResetModal.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import i18n from '@/i18n';
import { UserType } from '@/models/userType';
import UserCreationDropdown from '@/components/users/wizards/UserCreationDropdown.vue';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import {
  openServiceUserWizard,
  openSubsidiaryAdminWizard,
} from '@/components/users/wizards/modals';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  computed: {
    UserType: () => UserType,
  },
  components: {
    UserCreationDropdown,
    TableWithActions,
    TableActionButton,
  },
})
export default class SubsidiaryUsers extends Vue {
  @Prop({ required: true, default: Subsidiary.GetEmpty() })
  private subsidiary!: Subsidiary;

  private users: User[] = [];

  private isLoading = false;

  private async created() {
    await this.getUsers();
  }

  private async getUsers() {
    this.isLoading = true;
    this.users = await subsidiariesService
      .getUsers(this.subsidiary.id)
      .finally(() => (this.isLoading = false));
  }

  private async editUser(user: User) {
    if (user.isSubsidiaryAdmin) {
      await openSubsidiaryAdminWizard(this, user, this.subsidiary.id, this.onUserFormClosed);
    } else if (user.isServiceUser) {
      await openServiceUserWizard(this, user, this.subsidiary.id, this.onUserFormClosed);
    }
  }

  private confirmDelete(id: number, name: string) {
    dialogService.confirmWithPromise({
      title: i18n.t('dialog.confirm_delete_title').toString(),
      message: i18n
        .t('confirmation.message', {
          action: i18n.t('action.delete').toString().toLowerCase(),
          entity: i18n.t('user.name').toString().toLowerCase(),
          name,
        })
        .toString(),
      confirmText: i18n.t('dialog.confirm_delete').toString(),
      cancelText: i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteUser(id),
    });
  }

  private async deleteUser(id: number) {
    await usersService.delete(id).then(() => {
      this.users = this.users.filter((x) => x.id !== id);
    });
  }

  private resetPassword(user: User) {
    this.$buefy.modal.open({
      parent: this,
      component: PasswordResetModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        id: user.id,
        email: user.email,
      },
    });
  }

  private async onUserFormClosed() {
    await this.getUsers();
  }

  private canEdit(editingUser: User): boolean {
    return (
      hasCurrentUserPermission(UserType.GlobalAdmin) ||
      editingUser.isServiceUserType ||
      this.currentUser.equals(editingUser)
    );
  }

  private get canResetPasswords(): boolean {
    return hasCurrentUserPermission(UserType.GlobalAdmin);
  }

  private canResetPasswordOn(user: User): boolean {
    return (
      (this.canResetPasswords || user.id === this.currentUser.id) &&
      user.authenticationType === UserAuthenticationType.Local
    );
  }

  private get currentUser(): User {
    return usersService.store.current();
  }
}
