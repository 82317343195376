
import { Component, Prop, Vue } from 'vue-property-decorator';
import Targets from '@/components/targets/Targets.vue';
import { Tenant } from '@/models/tenant';
import { ConsoleEnum } from '@/models/enums/ConsoleEnum';
import { User } from '@/models/user';
import { usersService } from '@/services/users.service';

@Component({
  computed: {
    ConsoleEnum: () => ConsoleEnum,
  },
  components: {
    'sc-target': Targets,
  },
})
export default class CustomerTarget extends Vue {
  @Prop({ required: true })
  private tenant!: Tenant;

  private get hasWCC(): boolean {
    return (
      this.tenant.hasDatahub
      && (
        usersService.store.current().isGlobalAdmin
        || this.currentUser.consoles.includes(ConsoleEnum.WCC)
      )
    );
  }

  private get hasBC(): boolean {
    return (
      this.tenant.hasSmbs
      && (
        usersService.store.current().isGlobalAdmin
        || this.currentUser.consoles.includes(ConsoleEnum.BC)
      )
    );
  }

  private get currentUser(): User {
    return usersService.store.current();
  }
}
