// --------------------------------------------------------------------------------
// <copyright file="gaugeChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import i18n from '@/i18n';
import { EChartsOption } from 'echarts';
import { WidgetEnum } from '../enums/WidgetEnum';
import { mockLaserCuttingTimeData, mockLaserOeeData } from './mockWidgetSelectorData';
import { Logger } from '@/utils/logger';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';
import { DataWithTimeSpan } from './chartsData';

/*
  Extra options:
    formatter: function or string    |  formatter for the value display
    maxValue: number                 | maximum value for the gauge
    valueName: string                | metric's name
*/
export class GaugeChartGenerator<T extends DataWithTimeSpan> extends ChartGenerator<T[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    return metricsService.getDevicesMetrics<any[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: T[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const primaryPosition: number | undefined = this.findPrimaryPosition(data);
    const secondaryPosition: number | undefined = this.findSecondaryPosition(data, primaryPosition);
    const dpr: number = window.devicePixelRatio;
    const fontSizeInner = dpr > 1 ? 13 : 16;
    const fontSizeDetail = dpr > 1 ? 15 : 18;
    const fontSizeTitle = dpr > 1 ? 10 : 13;
    const gaugeWidth = dpr > 1 ? 25 : 35;
    const offsetCenterDprY1 = dpr > 1 ? '60%' : '55%';
    const offsetCenterDprY2 = dpr > 1 ? '95%' : '90%';

    const currentData =
      primaryPosition !== undefined ? data[primaryPosition][parameters.valueName! as keyof T] : 0.0;
    const previousData =
      secondaryPosition !== undefined
        ? data[secondaryPosition][parameters.valueName! as keyof T]
        : 0.0;

    const ratio: number | undefined =
      'clientWidth' in parameters ? parameters.clientWidth! / parameters.clientHeight! : undefined;
    const hideInIpad: boolean =
      /iPad/.test(navigator.userAgent) &&
      window.innerWidth === 1024 &&
      window.innerWidth > window.innerHeight;
    const radius: string = !!ratio && ratio < 1 && !hideInIpad ? '59%' : '76%';

    return {
      series: [
        {
          splitNumber: 5,
          type: 'gauge',
          radius,
          axisLabel: {
            formatter: (value: number) => i18n.n(value),
          },
          max: parameters.maxValue,
          anchor: {
            show: false,
          },
          pointer: {
            show: false,
          },
          progress: {
            show: true,
            overlap: false,
            roundCap: true,
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: gaugeWidth,
              dashOffset: 13,
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            distance: -55,
            length: 0,
          },
          data: [
            {
              value: currentData,
              name: i18n.t('report.current').toString(),
              title: { offsetCenter: ['0%', '40%'] },
              detail: { fontSize: fontSizeInner, offsetCenter: ['0%', offsetCenterDprY1] },
            },
            {
              value: previousData,
              name: i18n.t('report.previous').toString(),
              title: { offsetCenter: ['0%', '75%'] },
              detail: { fontSize: fontSizeInner, offsetCenter: ['0%', offsetCenterDprY2] },
            },
          ],
          detail: {
            width: 30,
            height: 10,
            fontSize: fontSizeDetail,
            color: 'inherit',
            borderColor: 'inherit',
            borderRadius: 0,
            borderWidth: 0,
            formatter: (value: number) =>
              i18n.n(value, { maximumFractionDigits: 2 }) + parameters.unit,
          },
          title: {
            fontSize: fontSizeTitle,
          },
        },
        (parameters.targets && {
          type: 'gauge',
          radius,
          pointer: { show: true, length: '100%' },
          max: parameters.maxValue,
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: { show: false },
          axisLine: { show: false },
          data: [{ value: parameters.targets[0], detail: { show: false } }],
        }) ||
          (undefined as any),
      ],
      title: {
        show: false,
      },
      grid: {
        left: '3%',
        right: '15%',
        bottom: '10%',
        containLabel: true,
      },
    };
  }

  override getMockData(): T[] | null {
    switch (this.procedure) {
      case WidgetEnum.LaserOee:
      case WidgetEnum.TubeOee:
        return mockLaserOeeData() as any;
      case WidgetEnum.LaserCuttingTime:
      case WidgetEnum.TubeCuttingTime:
        return mockLaserCuttingTimeData() as any;
      default:
        Logger.error(`Missing mock data for ${this.procedure}`);
        return null;
    }
  }

  private findPrimaryPosition(data: T[]): number | undefined {
    const positions = [0, 1].slice(0, data.length);
    return positions.find((pos: number) => data[pos].isprimary === 1);
  }

  private findSecondaryPosition(
    data: any,
    primaryPosition: number | undefined,
  ): number | undefined {
    if (primaryPosition !== undefined && data.length === 2) {
      return 1 - primaryPosition;
    } else if (primaryPosition === undefined && data.length === 1) {
      return 0;
    } else {
      return undefined;
    }
  }
}
