// --------------------------------------------------------------------------------
// <copyright file="diodesAndDrawerCyclesTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { DiodesAndDrawerCyclesTimelineData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption, SeriesOption } from 'echarts';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { isCategoryXAxis } from '@/utils/charts';
import { abbreviateNumber } from '@/utils/number';
import { iterable } from '@/utils/array';
import { dateWithGroupingTitleGenerator } from '@/models/Charts/tooltipFormatter';
import { metricsService } from '@/services/metrics.service';

export class DiodesAndDrawerCyclesTimelineGenerator extends ChartGenerator<
  DiodesAndDrawerCyclesTimelineData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string],
    timeAxisSpan: FilterTimeAxisSpanEnum,
    params: { [key: string]: any },
  ) {
    const deviceId = selectedDevices[0];
    return metricsService.getSSCMetrics<DiodesAndDrawerCyclesTimelineData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: timeSpan[0],
        dateTo: timeSpan[1],
        dateGrouping: timeAxisSpan,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: DiodesAndDrawerCyclesTimelineData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, data.length);
    const allDates = data.map((dataItem) => dataItem.date);

    return {
      title: [
        {
          top: 0,
          text: i18n.t('widget.ssc_diodes').toString(),
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 18 : 14,
            fontWeight: 'normal',
          },
        },
        {
          top: '48%',
          text: i18n.t('widget.care_health_drawer_cycles').toString(),
          textStyle: {
            fontSize: parameters.clientWidth! > 350 ? 18 : 14,
            fontWeight: 'normal',
          },
        },
      ],
      legend: {
        icon: 'roundRect',
        type: 'scroll',
        bottom: 0,
        selected: {
          // We don't want these series enabled by default
          mirrorPD: false,
        },
        formatter: (name) => i18n.t(`report.${name}`).toString(),
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      grid: [
        {
          top: 55,
          left: 40,
          right: 14,
          height: '35%',
        },
        {
          left: 40,
          right: 14,
          bottom: 50,
          height: '35%',
        },
      ],
      xAxis: iterable(2).map((index) => ({
        gridIndex: index,
        type: isCategoryAxis ? 'category' : 'time',
        axisLabel: {
          hideOverlap: true,
        },
        data: isCategoryAxis ? allDates : undefined,
      })),
      yAxis: iterable(2).map((index) => ({
        name: index === 0 ? 'PD' : 's',
        gridIndex: index,
        type: 'value',
        splitLine: {
          show: true,
        },
        axisLabel: {
          formatter: abbreviateNumber,
        },
      })),
      tooltip: {
        trigger: 'axis',
        confine: true,
        formatter: (params: any) => {
          const titleHtml = `
            <div class="tooltip-title" data-testid="title">
              ${dateWithGroupingTitleGenerator(params, parameters.timeAxisSpan)}
            </div>
          `;
          const valueHtml: string[] = params.map((seriesParam: any) => {
            const value = Array.isArray(seriesParam.value)
              ? seriesParam.value?.[1]
              : seriesParam.value;
            return `
            <div
              class="tooltip-series-value-row"
              data-testid="series-value-${seriesParam.seriesName}"
            >
              <div class="series-marker-name">
                ${seriesParam.marker}

                <span data-testid="series-name">
                  ${i18n.t(`report.${seriesParam.seriesName}`)}
                </span>
              </div>
              <div class="series-value" data-testid="series-value">
                ${i18n.n(value, { maximumFractionDigits: 2 })} ${this.getSuffix(seriesParam, data)}
              </div>
            </div>
          `;
          });
          return titleHtml + valueHtml.reduce((a, b) => a + b, '');
        },
      },
      series: this.generateSeries(data, parameters.timeAxisSpan!),
    };
  }

  private generateSeries(
    data: DiodesAndDrawerCyclesTimelineData[],
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ): SeriesOption[] {
    // Assign the same series names to these pairs, so they share legend
    // items and colors.
    const namesMap: any = {
      lowerProtectiveGlassPD: 'lowerDrawerRemovedSeconds',
      topProtectiveGlassPD: 'upperDrawerRemovedSeconds',
    };

    return this.getSeriesFieldNames(data).map((name) => ({
      name: namesMap?.[name] ?? name,
      data: this.getSeriesData(name as keyof DiodesAndDrawerCyclesTimelineData, data, timeAxisSpan),
      type: this.getChartType(name),
      emphasis: { focus: 'series' },
      xAxisIndex: this.getAxisIndex(name),
      yAxisIndex: this.getAxisIndex(name),
    }));
  }

  private getSeriesFieldNames(data: DiodesAndDrawerCyclesTimelineData[]): string[] {
    return Object.keys(data[0]).filter((key: string) => this.isSeriesField(key));
  }

  private isSeriesField(fieldName: string): boolean {
    return this.isDiodesChartField(fieldName) || this.isDrawerCyclesChartField(fieldName);
  }

  private getChartType(fieldName: string) {
    return this.isDiodesChartField(fieldName) ? 'line' : 'bar';
  }

  private getAxisIndex(fieldName: string): number {
    return this.isDiodesChartField(fieldName) ? 0 : 1;
  }

  private isDiodesChartField(fieldName: string): boolean {
    return fieldName.includes('PD');
  }

  private isDrawerCyclesChartField(fieldName: string): boolean {
    return fieldName.includes('Seconds');
  }

  private getSeriesData(
    fieldName: keyof DiodesAndDrawerCyclesTimelineData,
    data: DiodesAndDrawerCyclesTimelineData[],
    timeAxisSpan: FilterTimeAxisSpanEnum,
  ) {
    const isCategoryAxis = isCategoryXAxis(timeAxisSpan, data.length);

    return data.map((dataItem) =>
      isCategoryAxis ? dataItem[fieldName] ?? 0 : [dataItem.date, dataItem[fieldName] ?? 0],
    );
  }

  private getSuffix(params: any, data: DiodesAndDrawerCyclesTimelineData[]) {
    if (params.seriesType === 'line') {
      return 'PD';
    } else {
      // drawer cycles field
      // lowerDrawerRemovedSeconds -> lowerDrawerRemovedTimes
      const removedTimesFieldName = params.seriesName.replace(
        'Seconds',
        'Times',
      ) as keyof DiodesAndDrawerCyclesTimelineData;
      const removedTimes = data[params.dataIndex][removedTimesFieldName] ?? 0;
      return `s (${removedTimes} ${i18n.tc('report.cycles', removedTimes as number)})`;
    }
  }
}
