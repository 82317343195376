// --------------------------------------------------------------------------------
// <copyright file="cutCurrentWorkloadTableDataRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { metricsService } from '@/services/metrics.service';
import { isEmpty } from '@/utils/misc';
import { devicesService } from '@/services/devices.service';
import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { CutDeviceCurrentWorkloadData } from '../chartsData';
import { PlanState } from '@/models/enums/PlanState';

export class CutCurrentWorkloadTableDataRetriever extends DataRetriever<
  CutDeviceCurrentWorkloadData[]
> {
  constructor(procedure: ProcedureName, private tenantIdDh: number) {
    super(procedure);
  }

  override async getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: [string, string] | FilterTimeSpanEnum,
  ): Promise<CutDeviceCurrentWorkloadData[]> {
    const deviceIds = isEmpty(selectedDevices)
      ? devicesService.store.cutDevicesIds()
      : selectedDevices;

    return metricsService.getDevicesCurrentWorkload(
      deviceIds,
      this.tenantIdDh,
      [PlanState.Started, PlanState.Inactive],
      this.controller,
    );
  }
}
