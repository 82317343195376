// --------------------------------------------------------------------------------
// <copyright file="topQuotedMaterialsTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import i18n from '@/i18n';
import { getColor } from '@/utils/color';
import { abbreviateNumber } from '@/utils/number';
import { QuotesMaterialsData } from './chartsData';
import { EChartsOption, SeriesOption, XAXisComponentOption as XAXisOption } from 'echarts';
import { weightUnit } from '@/utils/measurement';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockQuotesMaterialsData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { isCategoryXAxis } from '@/utils/charts';
import { TooltipFormatter } from '@/models/Charts/tooltipFormatter';
import { metricsService } from '@/services/metrics.service';

export class TopQuotedMaterialsTimelineGenerator extends ChartGenerator<QuotesMaterialsData[]> {
  constructor(procedure: ProcedureName, public customerIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    return metricsService.getSMBSMetricsBC<QuotesMaterialsData[]>(
      this.procedure,
      this.customerIdDh,
      { startDate, endDate, axisTimespan: timeAxisSpan, customerNo },
      this.controller,
    );
  }

  override updateOptions(
    data: QuotesMaterialsData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const xAxisData = this.xAxisData(data);
    const isCategoryAxis = isCategoryXAxis(parameters.timeAxisSpan, xAxisData.length);
    const matKey: string = 'mat_group';
    let materials: string[] = data.map((x: any) => x[matKey]);
    materials = [...new Set(materials)];
    return {
      tooltip: {
        trigger: 'axis',
        confine: true,
        extraCssText: 'z-index: 1',
        formatter: TooltipFormatter.build(parameters.timeAxisSpan)
          .withSeriesTranslationPrefix(undefined)
          .get(),
      },
      legend: {
        bottom: 0,
        type: 'scroll',
      },
      axisPointer: {
        link: [{ xAxisIndex: 'all' }],
      },
      dataZoom: [
        {
          show: false,
          realtime: true,
          height: '2',
          top: '95%',
        },
        {
          show: true,
          type: 'inside',
          realtime: true,
        },
      ],
      grid: {
        containLabel: true,
        left: '3%',
        right: '4%',
        bottom: 40,
        top: 32,
      },
      xAxis: {
        type: isCategoryAxis ? ('category' as const) : ('time' as const),
        data: isCategoryAxis ? xAxisData : undefined,
      } as XAXisOption, // only type=category has data property
      yAxis: {
        type: 'value',
        name: weightUnit(),
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f4f4f4',
            width: 1,
          },
        },
        axisLabel: {
          formatter: (value: number) => abbreviateNumber(value),
        },
      },
      series: this.series(data, materials),
    };
  }

  override getMockData(): QuotesMaterialsData[] | null {
    return mockQuotesMaterialsData();
  }

  private series(data: QuotesMaterialsData[], materials: string[]) {
    const series: SeriesOption[] = [];
    materials.forEach((material: string, index: number) => {
      series.push({
        name:
          this.formatName(material) +
          ' ' +
          i18n.t('report.business_console.materials_converted').toString().toLowerCase(),
        type: 'bar',
        stack: material,
        data: this.convWeightData(data, material),
        itemStyle: {
          color: getColor(index, 0),
        },
      });
      series.push({
        name:
          this.formatName(material) +
          ' ' +
          i18n.t('report.business_console.materials_non_converted').toString().toLowerCase(),
        type: 'bar',
        stack: material,
        data: this.nonConvWeightData(data, material),
        itemStyle: {
          color: getColor(index, 45),
        },
      });
    });
    return series;
  }

  private convWeightData(data: QuotesMaterialsData[], material: string) {
    const seriesData: any[] = [];
    data.map((dataItem) => {
      if (dataItem.mat_group === material) {
        seriesData.push([dataItem.date.toString(), dataItem.converted_weight]);
      }
    });
    return seriesData;
  }

  private nonConvWeightData(data: QuotesMaterialsData[], material: string) {
    const seriesData: any[] = [];
    data.map((dataItem) => {
      if (dataItem.mat_group === material) {
        seriesData.push([dataItem.date.toString(), dataItem.weight - dataItem.converted_weight]);
      }
    });
    return seriesData;
  }

  private xAxisData(data: QuotesMaterialsData[]): string[] {
    const xAxisData: string[] = data.map((x) => x.date);
    return [...new Set(xAxisData)];
  }

  private formatName(name: string) {
    if (name === 'OTHERS') {
      return i18n.t('report.others').toString();
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }
  }
}
