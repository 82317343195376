
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class GenericParam<T> extends Vue {
  @Prop()
  public paramName!: string;
  @Prop()
  public paramInfo!: T;
  @Prop()
  public paramValue?: any;

  protected get label(): string {
    return this.paramName.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  }
}
