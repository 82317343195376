
import { Component, Vue } from 'vue-property-decorator';
import { Routes } from '@/router/routes';

@Component({
  computed: {
    Routes: () => Routes,
  },
})
export default class CareConsoleSettingsMenu extends Vue {}
