
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AlertKPI } from '@/models/enums/AlertsEnums';

@Component({})
export default class EventCodesSelectionForm extends Vue {
  @Prop({ required: true })
  private kpi!: AlertKPI;

  @Prop({ default: () => [] })
  private eventCodes!: string[];
}
