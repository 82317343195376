// --------------------------------------------------------------------------------
// <copyright file="abstractKpiWidgetGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { createPaletteByLightness } from '@/utils/color';
import { DataWithTimeSpan } from '../chartsData';
import { ChartGenerator, ProcedureName } from './chartGenerator';
import { isNil } from '@/utils/misc';

export abstract class AbstractKpiWidgetGenerator<T extends DataWithTimeSpan> extends ChartGenerator<
  T[]
> {
  constructor(
    procedure: ProcedureName,
    protected tenantIdDh: number,
    protected mainColor: string,
    private readonly _unit?: string,
  ) {
    super(procedure);
  }

  protected abstract getSeriesData(primaryData: T[]): any[];

  protected generatePalette(amount: number) {
    return createPaletteByLightness(this.mainColor, amount, -0.35, 0.35).reverse();
  }

  protected get unit(): string {
    return isNil(this._unit) ? '' : ` ${this._unit}`;
  }

  public formatWithUnit(content: string | number) {
    return `${content}${this.unit}`;
  }
}
