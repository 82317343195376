
import { Component, Vue } from 'vue-property-decorator';
import { Tenant } from '@/models/tenant';
import { tenantsService } from '@/services/tenants.service';
import { Routes } from '@/router/routes';
import TenantCreationModal from '../../components/tenants/TenantCreationModal.vue';
import { User } from '@/models/user';
import { UserType } from '@/models/userType';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { usersService } from '@/services/users.service';
import { formatDateWithTime } from '../../utils/table';
import { modalService } from '@/library-services/modal.service';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  methods: { formatDateWithTime },
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class Customers extends Vue {
  private tenants: Tenant[] = [];

  private isLoading = false;

  private async mounted() {
    await this.getTenants();
  }

  private confirmDelete(tenant: Tenant): void {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('tenant.name').toString().toLowerCase(),
          name: tenant.name,
        })
        .toString(),
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteTenant(tenant.id),
    });
  }

  private async editTenant(tenant: Tenant) {
    await this.$router.push({
      name: Routes.TenantDetail,
      params: { id: tenant.id.toString() },
    });
  }

  private async modalClose() {
    await this.getTenants();
  }

  private async getTenants() {
    this.isLoading = true;
    this.tenants = await tenantsService.get().finally(() => (this.isLoading = false));
  }

  private createTenant() {
    modalService.open({
      parent: this,
      component: TenantCreationModal,
      events: {
        save: () => this.getTenants(),
      },
    });
  }

  private async deleteTenant(id: number) {
    await tenantsService.delete(id).then(() => {
      this.tenants = this.tenants.filter((x) => x.id !== id);
    });
  }

  private get canEditTenants(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get currentUser(): User {
    return usersService.store.current();
  }

  private filterTenantIdDh(tenant: Tenant, filter: string) {
    return tenant.tenantIdDh.toString().includes(filter.toLowerCase());
  }

  private orderColumnEllipsisTitle(row: any, column: any) {
    if (column.rootClasses.indexOf('order-column') !== -1) {
      return {
        title: row[column.field],
      };
    }
  }
}
