
import WidgetDefinition, { ParamValues } from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { Component, Prop, Vue } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import EventsTable from '@/components/careConsole/EventsTable.vue';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { isEmpty } from '@/utils/misc';
import { ImportantMessageLevel } from '@/models/Charts/chartsData';
import { dateRangeToDateStrings } from '@/utils/dates';
import moment from 'moment';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';

import { LayoutSlot } from '@/views/dashboard/layout-grid';
import AlertOccurrencesTable from '@/components/careConsole/AlertOccurrencesTable.vue';
import { SortOrder } from '@/models/enums/SortOrder';

@Component({
  components: {
    AlertOccurrencesTable,
    WidgetContainer,
    LayoutGrid,
    EventsTable,
  },
})
export default class Events extends Vue {
  @Prop({ required: true })
  private dateRange!: [Date, Date];

  @Prop({ required: true, default: FilterTimeAxisSpanEnum.Month })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  @Prop({ required: true })
  private eventTypes!: ImportantMessageLevel[];

  @Prop({ required: true })
  private eventModules!: string[];

  @Prop({ required: true })
  private eventCodes!: string[];

  @Prop({ required: true })
  private deviceId!: string;

  private gridLayout: LayoutSlot[] = [
    { column: 0, row: 0, width: 6, height: 4 },
    { column: 6, row: 0, width: 6, height: 4 },
    { column: 0, row: 4, width: 12, height: 4 },
  ];
  private eventsTablePage = 1;
  private eventsTableSortField: string | null = null;
  private eventsTableSortOrder: string | null = null;

  private get timelineWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareEventsTimeline,
      this.deviceId,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      this.dateGrouping,
      undefined,
      this.paramValues,
    );
  }

  private get moduleTypeWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareEventsByModule,
      this.deviceId,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      // This isn't used by the chart, but by the modal opened on double-click
      this.dateGrouping,
      undefined,
      this.paramValues,
    );
  }

  private get eventsTableWidgetDefinition(): WidgetDefinition {
    const customerIdDh = this.$route.params.tenantIdDh
      ? +this.$route.params.tenantIdDh
      : +this.$route.params.customerIdDh;

    return new WidgetDefinition(
      WidgetEnum.EventsTable,
      this.deviceId,
      [this.dateRangeString[0], this.dateRangeString[1]],
      [],
      undefined,
      this.dateGrouping,
      undefined,
      {
        customerIdDh,
        modules: this.eventModules,
        eventTypes: this.eventTypes,
        eventCodes: this.eventCodes,
        page: this.eventsTablePage,
        sortField: this.eventsTableSortField,
        sortOrder: this.eventsTableSortOrder,
      },
    )
      .hideTitle()
      .hideInfo();
  }

  private onEventsTablePageChanged(page: number) {
    this.eventsTablePage = page;
  }

  private onEventsTableSortChanged(sortField: string, sortOrder: SortOrder) {
    this.eventsTableSortField = sortField;
    this.eventsTableSortOrder = sortOrder;
  }

  private get paramValues(): ParamValues | undefined {
    return isEmpty(this.eventTypes) && isEmpty(this.eventModules) && isEmpty(this.eventCodes)
      ? undefined
      : {
          eventTypes: this.eventTypes,
          eventModules: this.eventModules,
          eventCodes: this.eventCodes,
        };
  }

  private get dateRangeString(): [string, string] {
    return [
      moment(this.dateRange[0]).startOf('day').toISOString(),
      moment(this.dateRange[1]).endOf('day').toISOString(),
    ];
  }
}
