// --------------------------------------------------------------------------------
// <copyright file="string.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

const DIACRITICAL_MARKS_UNICODE_BLOCK_REGEX = /[\u0300-\u036f]/g;

export function normalizeString(s: string): string {
  // Taken from this SO answer: https://stackoverflow.com/a/37511463/5062666
  // Normalizing separates the accent (makes it a separate character) and transforms
  // other special characters (ligatures) into as many characters as needed.
  // Then replacing deletes the extra accent unicode characters, and the string is
  // trimmed for any potential trailing whitespace
  return s
    .toLowerCase()
    .normalize('NFKD')
    .replace(DIACRITICAL_MARKS_UNICODE_BLOCK_REGEX, '')
    .trim();
}
