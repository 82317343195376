
import { Component, Vue } from 'vue-property-decorator';
import { CustomerFilterValues } from './commons/model';
import { areArraysEqual } from '@/utils/array';
import CustomTableFilters from '@/components/tableWithActions/CustomTableFilters.vue';

@Component({
  components: {
    CustomTableFilters,
  },
})
export default class CustomerFilters extends Vue {
  private static readonly defaultFilterValues: CustomerFilterValues =
    Object.freeze<CustomerFilterValues>({
      mySubscriptions: false,
      noSubscriptions: false,
      name: [],
      subsidiaryName: [],
      subscribers: [],
    });

  private filters = structuredClone(CustomerFilters.defaultFilterValues);

  private clearAllFilters() {
    this.filters = structuredClone(CustomerFilters.defaultFilterValues);
    this.emitFilters();
  }

  private emitFilters() {
    this.$emit('filters-change', this.filters, this.areApplying);
  }

  private get areApplying(): boolean {
    return (
      this.areTextListFiltersApplying ||
      this.filters.mySubscriptions ||
      this.filters.noSubscriptions
    );
  }

  private get areTextListFiltersApplying(): boolean {
    return ['name', 'subsidiaryName', 'subscribers'].some(
      (textListFilterKey) =>
        !areArraysEqual(
          this.filters[textListFilterKey as keyof CustomerFilterValues] as string[],
          CustomerFilters.defaultFilterValues[
            textListFilterKey as keyof CustomerFilterValues
          ] as string[],
        ),
    );
  }
}
