// --------------------------------------------------------------------------------
// <copyright file="DateTimeGranularity.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

// We can't have it in DateTimeRangePicker.vue because unit tests fails to import it.

export enum DateTimeGranularity {
  SECONDS = 'seconds',
  HOURS = 'hours',
  DAYS = 'days',
  MONTHS = 'months',
}
