
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { indexedWidgetDefinitions } from '@/utils/misc';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { dateRangeToDateStrings } from '@/utils/dates';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class TechnologyOverview extends Vue {
  @Prop()
  public deviceId!: string;

  @Prop({ required: true })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  private widgetDefinitions: WidgetDefinition[] = [];

  private gridLayout = availableDashboardLayouts[DashboardLayout.TwoByThree];

  private created() {
    this.widgetDefinitions = this.createWidgetDefinitions();
  }

  private createWidgetDefinitions(): WidgetDefinition[] {
    return indexedWidgetDefinitions(
      [
        WidgetEnum.TechnologyOverviewCuttingHoursTimeline,
        WidgetEnum.TechnologyOverviewLaserConsumptionTimeline,
        WidgetEnum.TechnologyOverviewDrawerCycleTimes,
        WidgetEnum.TechnologyLaserPowerUtilizationTimes,
        WidgetEnum.TechnologyOverviewSscFigures,
        WidgetEnum.CareEventsByModule,
      ].map(
        (widget) =>
          new WidgetDefinition(
            widget,
            this.deviceId,
            this.stringDateRange,
            [],
            undefined,
            this.dateGrouping,
          ),
      ),
    );
  }

  @Watch('deviceId')
  @Watch('dateRange')
  @Watch('dateGrouping')
  private updateFilterOnWidgetDefinitions() {
    this.widgetDefinitions = this.widgetDefinitions.map((widgetDefinition) =>
      widgetDefinition.getCopy({
        deviceId: this.deviceId,
        timeFilter: this.stringDateRange,
        axisSpan: this.dateGrouping,
      }),
    );
  }

  private updateWidgetDefinition(index: number, widgetDefinition: WidgetDefinition) {
    this.$set(this.widgetDefinitions, index, widgetDefinition);
  }

  private get stringDateRange(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }
}
