// --------------------------------------------------------------------------------
// <copyright file="careDeviceOverviewRetriever.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { DeviceVersion, metricsService } from '@/services/metrics.service';
import { DataRetriever } from '../abstract/chartGenerator';

export class CareDeviceOverviewRetriever extends DataRetriever<DeviceVersion | null> {
  constructor(procedure: WidgetEnum, private tenantIdDh: number) {
    super(procedure);
  }

  override async getData(selectedDevices: string[]): Promise<DeviceVersion | null> {
    const response = await metricsService.getDevicesMetrics<[DeviceVersion] | null>(
      'device_versions',
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices.slice(0, 1),
      },
      this.controller,
    );
    return response === null ? response : response[0];
  }
}
