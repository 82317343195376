// --------------------------------------------------------------------------------
// <copyright file="widgetDefinition.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import {
  widgets,
  Widget,
  WidgetEnum,
  ModalConfiguration,
  Params,
  ParamValue,
} from '@/models/enums/WidgetEnum';
import moment from 'moment';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { WidgetType } from '../enums/WidgetType';
import { CompositeWidgetDefinition } from '@/models/Charts/compositeWidgetDefinition';
import { isNil } from '@/utils/misc';

export interface CopyParams {
  widget?: WidgetEnum;
  deviceId?: string | string[];
  timeFilter?: FilterTimeSpanEnum | [string, string];
  shifts?: number[];
  target?: number[];
  axisSpan?: FilterTimeAxisSpanEnum;
  aggregates?: number;
  paramValues?: ParamValues;
  index?: number;
}

export interface ParamValues {
  [key: string]: ParamValue | null | undefined;
}

export interface PersistableWidgetDefinition {
  widget: WidgetEnum;
  deviceId: string | string[];
  timeFilter?: FilterTimeSpanEnum | [string, string];
  shifts: number[];
  target: number[] | undefined;
  axisSpan: FilterTimeAxisSpanEnum | undefined;
  aggregates: number | undefined;
  paramValues?: ParamValues;
  index?: number;
}

export interface AbstractWidgetDefinition {
  isComposite: () => this is CompositeWidgetDefinition;
  withIndex: (index: number) => ThisType<this>;
}

export default class WidgetDefinition implements AbstractWidgetDefinition {
  private widgetInstance: Widget;
  private newTimeFilter?: FilterTimeSpanEnum | [string, string];

  showTitle = true;
  showInfo = true;

  constructor(
    public widget: WidgetEnum,
    public deviceId: string | string[],
    private initialTimeFilter: FilterTimeSpanEnum | [string, string] = FilterTimeSpanEnum.None,
    public shifts: number[] = [],
    public target?: number[] | undefined,
    public axisSpan?: FilterTimeAxisSpanEnum | undefined,
    public aggregates?: number | undefined,
    public paramValues: ParamValues = {},
    public index?: number,
    public beta?: boolean,
  ) {
    this.widgetInstance = widgets[widget];
    if (this.hasDatepicker && !Array.isArray(this.timeFilter)) {
      this.initialTimeFilter = this.widgetInstance.defaultDatepickerInterval;
    } else if (this.initialTimeFilter === FilterTimeSpanEnum.None) {
      if (this.hasDatepicker) {
        this.initialTimeFilter = [
          moment().subtract(6, 'days').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ];
      } else if (this.hasTimeMenu) {
        this.initialTimeFilter = FilterTimeSpanEnum.Day;
      }
    }
    if (this.hasAxisSpanFilter && !this.axisSpan) {
      this.axisSpan = FilterTimeAxisSpanEnum.Day;
    }
  }

  get timeFilter(): FilterTimeSpanEnum | [string, string] {
    return this.newTimeFilter ?? this.initialTimeFilter;
  }

  // Returns timeFilter value if it's a FilterTimeSpanEnum, undefined otherwise.
  get timeFilterEnumValue(): FilterTimeSpanEnum | undefined {
    return Array.isArray(this.timeFilter)
      ? undefined
      : this.timeFilter;
  }

  get params(): Params {
    return this.widgetInstance.params;
  }

  get quickSearchFunction(): (() => Promise<Array<{ id: any; name: string }>>) | undefined {
    if (!isNil(this.widgetInstance.quickSearchMethod)) {
      return async () => await this.widgetInstance.quickSearchMethod!(this);
    } else {
      return undefined;
    }
  }

  get deviceIds(): string[] {
    if (!this.deviceId) {
      return [];
    }

    if (Array.isArray(this.deviceId)) {
      return this.deviceId;
    }

    return [this.deviceId];
  }

  withParams(params: Params): this {
    this.widgetInstance.withParams(params);
    return this;
  }

  withIndex(index: number) {
    this.index = index;
    return this;
  }

  isBeta(value = true) {
    this.beta = value;
    return this;
  }

  updateParamValue(name: string, value: any): WidgetDefinition {
    return this.getCopy({
      paramValues: {
        ...this.paramValues,
        [name]: value,
      },
    });
  }

  hideTitle(): this {
    this.showTitle = false;
    return this;
  }

  hideInfo(): this {
    this.showInfo = false;
    return this;
  }

  initializeParamValues(definition: CompatibleWidgetDefinition) {
    const paramValues: ParamValues = {};
    for (const [paramName, paramConfig] of Object.entries(this.params)) {
      if (!isNil(definition.paramValues?.[paramName])) {
        paramValues[paramName] = definition.paramValues![paramName];
      } else {
        const retrocompatibilityMap = paramConfig.retrocompatibilityMap ?? (() => undefined);
        paramValues[paramName] = retrocompatibilityMap(definition);
      }
    }
    this.paramValues = paramValues;
  }

  canExpand(): boolean {
    return this.widgetInstance.isExpandableFn(this);
  }

  getModalConfiguration(): ModalConfiguration | undefined {
    return this.widgetInstance.modalConfiguration;
  }

  getDblClickModalConfiguration(): ModalConfiguration | undefined {
    return this.widgetInstance.dblClickModalConfiguration;
  }

  getQuickSearchModalConfiguration(): ModalConfiguration | undefined {
    return this.widgetInstance.quickSearchModalConfiguration;
  }

  canExpandOnDblClick() {
    return !!this.widgetInstance.dblClickModalConfiguration;
  }

  canExpandOnQuickSearch() {
    return (
      !!this.widgetInstance.quickSearchModalConfiguration && !!this.widgetInstance.quickSearchMethod
    );
  }

  serialize(): PersistableWidgetDefinition {
    const {
      widget,
      deviceId,
      timeFilter,
      shifts,
      target,
      axisSpan,
      aggregates,
      paramValues,
      index,
    } = this;
    return {
      widget,
      deviceId,
      timeFilter,
      shifts,
      target,
      axisSpan,
      aggregates,
      index,
      ...(Object.keys(paramValues).length > 0 && { paramValues }),
    };
  }

  get isEcharts(): boolean {
    return this.widgetInstance.isEcharts;
  }

  get isMap(): boolean {
    return this.widgetInstance.isMap;
  }

  get isSlot(): boolean {
    return this.widgetInstance.isSlot;
  }

  get widgetType(): WidgetType {
    return this.widgetInstance.widgetType;
  }

  get hasDatepicker(): boolean {
    return this.widgetInstance.hasDatepicker;
  }

  get hasTimeMenu(): boolean {
    return this.widgetInstance.hasTimeMenu;
  }

  get hasShiftMenu(): boolean {
    return this.widgetInstance.hasShiftMenu;
  }

  get hasAxisSpanFilter(): boolean {
    return this.widgetInstance.hasAxisSpanFilter;
  }

  get hasDownload(): boolean {
    return this.widgetInstance.hasDownload;
  }

  get hasAggregates(): boolean {
    return this.widgetInstance.hasAggregates;
  }

  /**
   * Returns the minimum height defined by the widget.
   *
   * The value can be any valid CSS min-height value with unit.
   */
  get minHeight(): string {
    return this.widgetInstance.minHeight;
  }

  get isDeviceDependent(): boolean {
    return this.widgetInstance.isDeviceDependent;
  }

  getCopy({
    widget,
    deviceId,
    timeFilter,
    shifts,
    target,
    axisSpan,
    aggregates,
    paramValues,
    index,
  }: CopyParams): WidgetDefinition {
    const wd = new WidgetDefinition(
      widget ?? this.widget,
      deviceId ?? this.deviceId,
      this.initialTimeFilter,
      shifts ?? this.shifts,
      target ?? this.target,
      axisSpan ?? this.axisSpan,
      aggregates ?? this.aggregates,
      paramValues ?? this.paramValues,
      index ?? this.index,
      this.beta,
    );

    wd.showTitle = this.showTitle;
    wd.showInfo = this.showInfo;

    if (!isNil(timeFilter)) {
      wd.newTimeFilter = timeFilter;
    } else if (!isNil(this.newTimeFilter)) {
      wd.newTimeFilter = this.newTimeFilter;
    }
    return wd;
  }

  isComposite(): this is CompositeWidgetDefinition {
    return false;
  }
}

// Old structure of WidgetDefinition. The database may still have widgets with this structure.
export interface CompatibleWidgetDefinition {
  widget: WidgetEnum;
  deviceId: string | string[];
  timeFilter: FilterTimeSpanEnum | [string, string];
  shifts: number[];
  target?: number[] | undefined;
  axisSpan?: FilterTimeAxisSpanEnum | undefined;
  aggregates?: number | undefined;
  stackedColumns?: boolean | undefined;
  paramValues?: ParamValues;
  index?: number;
}

export function deserializeWidgetDefinition(
  widgetDefinitionObject: CompatibleWidgetDefinition,
  index?: number,
): WidgetDefinition {
  const wd = new WidgetDefinition(
    widgetDefinitionObject.widget,
    widgetDefinitionObject.deviceId,
    widgetDefinitionObject.timeFilter,
    widgetDefinitionObject.shifts,
    widgetDefinitionObject.target,
    widgetDefinitionObject.axisSpan,
    widgetDefinitionObject.aggregates,
    widgetDefinitionObject.paramValues,
    widgetDefinitionObject.index ?? index,
  );
  wd.initializeParamValues(widgetDefinitionObject);
  return wd;
}
