// --------------------------------------------------------------------------------
// <copyright file="materials.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Material } from '@/models/material';

class MaterialsService {
  get(tenantId?: number): Promise<Material[]> {
    return axios
      .get<Material[]>(`${BaseUrl}/materials` + (tenantId ? '?tenantId=' + tenantId : ''))
      .then((response: any) => {
        return response.data;
      });
  }
  update(material: Material) {
    return axios.put(`${BaseUrl}/materials/`, material).then((response: any) => {
      return response.data;
    });
  }
}

export const materialsService = new MaterialsService();
