// --------------------------------------------------------------------------------
// <copyright file="userAlertStatus.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { AlertMetric, AlertOperator } from './enums/AlertsEnums';

export class UserAlertStatus {
  constructor(
    public id: number,
    public deviceName: string,
    public metric: AlertMetric,
    public operator: AlertOperator,
    public thresholdValue: number,
    public currentValue: number,
    public timestampNotification: string,
  ) {}
}
