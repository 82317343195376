
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EventData } from '@/models/Charts/chartsData';
import moment from 'moment';
import { isNil, loadScript } from '@/utils/misc';

const DECISION_TREE_MARKER = 'decision tree tool';

@Component({
  methods: { isNil },
})
export default class EventDetails extends Vue {
  @Prop({ required: true })
  private event!: EventData;

  private decisionTreeId: number | null = null;

  private mounted() {
    loadScript('https://zingtree.com/js/iframeResizerSmart.js');
  }

  @Watch('event', { immediate: true })
  private updateDecisionTreeId() {
    this.decisionTreeId = this.getDecisionTreeId();
  }

  private onManualDecisionTreeUpdate() {
    this.decisionTreeId = parseInt((this.$refs.decisionTreeId! as any).newValue, 10);
  }

  private get hasDecisionTree(): boolean {
    return this.event.solution.includes(DECISION_TREE_MARKER);
  }

  private getDecisionTreeId(): number | null {
    const matches = this.event.solution.match(/ID_(\d{9})/);
    if (matches === null) {
      return null;
    }
    return parseInt(matches[matches.length - 1], 10);
  }

  private formatDate(isoDate: string) {
    return moment(isoDate).format('YYYY-MM-DD, HH:mm:ss');
  }
}
