
import { Component, Vue } from 'vue-property-decorator';
import TableWithActions2 from '@/components/tableWithActions/TableWithActions2.vue';
import { AlertWorkflow, AlertWorkflowStatus, AlertWorkflowUpdateDTO } from '@/models/alertWorkflow';
import {
  alertMetricSorter,
  translateKpi,
  translateVariable,
  translateWorkflowStatus,
} from '@/utils/alerts';
import {
  addDataTestIdAttributeToTh,
  createDateRangeMatcher,
  formatDate,
  formatDateWithTime,
  matchesDate,
  matchIgnoringCase,
} from '@/utils/table';
import { isEmpty, isNil, valueOrDefault } from '@/utils/misc';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import { router } from '@/library-services/e-service-center-router';
import { modalService } from '@/library-services/modal.service';
import AlertWorkflowDetailsModal from '@/views/careConsole/AlertWorkflowDetailsModal.vue';
import { alertWorkflowsService } from '@/services/alertWorkflows.service';
import InboxFilters from '@/views/careConsole/InboxFilters.vue';
import { InboxFilterValues } from '@/views/careConsole/commons/model';
import {
  createTextListMatcher,
  createTextListMatcherForEnum,
  createTextListModelMatcher,
  FilterMatcher,
} from '@/views/careConsole/commons/matchers';
import TableActionButton2 from '@/components/tableWithActions/TableActionButton2.vue';
import AlertWorkflowStatusSelect from '@/components/inputs/AlertWorkflowStatusSelect.vue';
import { VueConstructor } from 'vue';
import ChangeToPostponedDate from '@/components/alertWorkflows/ChangeToPostponedDate.vue';
import ChangeToInProgress from '@/components/alertWorkflows/ChangeToInProgress.vue';
import ChangeToResolved from '@/components/alertWorkflows/ChangeToResolved.vue';
import ChangeToDiscarded from '@/components/alertWorkflows/ChangeToDiscarded.vue';
import { AlertOccurrence } from '@/models/alertOccurrence';
import i18n from '@/i18n';

/**
 * Page listing the alert workflows for the current user.
 *
 * Accessed from the eService center's Inbox menu entry in the sidebar.
 */
@Component({
  computed: {
    AlertWorkflowStatus: () => AlertWorkflowStatus,
  },
  methods: {
    isNil,
    isEmpty,
    formatDateWithTime,
    formatDate,
    valueOrDefault,
    addDataTestIdAttributeToTh,
    alertMetricSorter,
    translateKpi,
  },
  components: {
    TableActionButton2,
    InboxFilters,
    TableActionButton,
    TableWithActions2,
    AlertWorkflowStatusSelect,
  },
})
export default class EServiceCenterInbox extends Vue {
  private alertWorkflows: AlertWorkflow[] = [];
  private filterMatchers: Record<keyof InboxFilterValues, FilterMatcher> = {
    subsidiaryName: createTextListMatcher('subsidiary.name'),
    customerName: createTextListMatcher('customer.name'),
    equipmentNumber: createTextListMatcher('equipment.equipmentId'),
    equipmentName: createTextListMatcher('equipment.name'),
    equipmentModel: createTextListModelMatcher('equipment.deviceBaseModelName'),
    status: this.multipleStatusMatcher,
    assigneeName: createTextListMatcher('assigneeName'),
    alertMetric: createTextListMatcherForEnum('alertOccurrence.metric', translateKpi),
    crmCaseNumber: createTextListMatcher('crmCaseNumber'),
    createdDate: createDateRangeMatcher('createdDate'),
    processingDate: createDateRangeMatcher('processingDate'),
    postponedDate: createDateRangeMatcher('postponedDate'),
    resolutionDate: createDateRangeMatcher('resolutionDate'),
  };
  private isLoading = false;

  private async created() {
    this.alertWorkflows = await this.fetchWorkflows();
  }

  private async fetchWorkflows() {
    this.isLoading = true;
    return await alertWorkflowsService.get().finally(() => (this.isLoading = false));
  }

  private statusMatcher(alertWorkflow: AlertWorkflow, searchText: string): boolean {
    return matchIgnoringCase(translateWorkflowStatus(alertWorkflow.status), searchText);
  }

  private multipleStatusMatcher(
    alertWorkflow: AlertWorkflow,
    filterValue: AlertWorkflowStatus[],
  ): boolean {
    if (isEmpty(filterValue)) {
      return true;
    }

    return filterValue.some((status) =>
      matchIgnoringCase(
        translateWorkflowStatus(alertWorkflow.status),
        translateWorkflowStatus(status),
      ),
    );
  }

  private createdDateMatcher(alertWorkflow: AlertWorkflow, searchText: string): boolean {
    return matchesDate(alertWorkflow.createdDate, searchText, formatDateWithTime);
  }

  private processingDateMatcher(alertWorkflow: AlertWorkflow, searchText: string): boolean {
    return matchesDate(alertWorkflow.processingDate, searchText);
  }

  private openDetails(alertWorkflowId: number) {
    modalService.open({
      parent: this,
      component: AlertWorkflowDetailsModal,
      // This max-width value because it is the maximum so that the width of modal is not affected when you open/close all the blinds.
      width: '806px',
      props: {
        alertWorkflowId,
      },
    });
  }

  private async goToEquipmentCareConsole(alertWorkflow: AlertWorkflow) {
    await router.goToEquipmentAlertTabOnCareConsole(
      alertWorkflow.customer.tenantIdDh,
      alertWorkflow.equipment.deviceId,
    );
  }

  private matchesEquipmentColumn(alertWorkflow: AlertWorkflow, searchText: string) {
    return matchIgnoringCase(this.getEquipmentColumnValue(alertWorkflow), searchText);
  }

  private getEquipmentColumnValue(alertWorkflow: AlertWorkflow): string {
    return `
      ${valueOrDefault(alertWorkflow.equipment.equipmentId, '-')},
      ${alertWorkflow.equipment.name}
    `;
  }

  private getAlertMetricTooltip(alertOccurrence: AlertOccurrence): string | null {
    if (!isNil(alertOccurrence.variable)) {
      const translatedVariable = translateVariable(
        alertOccurrence.variable,
        alertOccurrence.metric,
      );
      return i18n
        .t('e_service_center.inbox.tooltip.variable', { variable: translatedVariable })
        .toString();
    }

    if (!isEmpty(alertOccurrence.eventSources)) {
      return i18n
        .t('e_service_center.inbox.tooltip.event-sources', {
          eventSources: alertOccurrence.eventSources!.join(', '),
        })
        .toString();
    }

    if (!isEmpty(alertOccurrence.eventCodes)) {
      return i18n
        .t('e_service_center.inbox.tooltip.event-codes', {
          eventCodes: alertOccurrence.eventCodes!.join(', '),
        })
        .toString();
    }

    return null;
  }

  private matchesMetric(alertWorkflow: AlertWorkflow, searchText: string) {
    return matchIgnoringCase(translateKpi(alertWorkflow.alertOccurrence.metric), searchText);
  }

  private handleStatusChange(alertWorkflow: AlertWorkflow, newStatus: AlertWorkflowStatus) {
    switch (newStatus) {
      case AlertWorkflowStatus.New:
        this.confirmChangeStatusToNew(alertWorkflow);
        break;
      case AlertWorkflowStatus.Postponed:
        this.openModalToChangeStatus(ChangeToPostponedDate, alertWorkflow);
        break;
      case AlertWorkflowStatus.InProgress:
        this.openModalToChangeStatus(ChangeToInProgress, alertWorkflow);
        break;
      case AlertWorkflowStatus.Resolved:
        this.openModalToChangeStatus(ChangeToResolved, alertWorkflow);
        break;
      case AlertWorkflowStatus.Discarded:
        this.openModalToChangeStatus(ChangeToDiscarded, alertWorkflow);
        break;
      default:
        break;
    }
  }

  private confirmChangeStatusToNew(alertWorkflow: AlertWorkflow) {
    this.$buefy.dialog.confirm({
      title: `${this.$i18n.t(`e_service_center.alert_workflow.change_status_to`, {
        status: this.$i18n.t(`alert.workflow_status.1`),
      })}`,
      message: this.$i18n.t('e_service_center.alert_workflow.confirm_change').toString(),
      confirmText: this.$i18n.t('dialog.confirm_save').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-primary',
      hasIcon: true,
      onConfirm: () =>
        this.changeStatus({
          id: alertWorkflow.id,
          status: AlertWorkflowStatus.New,
          postponedDate: null,
          crmCaseNumber: null,
          closureInformation: null,
        }),
    });
  }

  openModalToChangeStatus(component: VueConstructor, alertWorkflow: AlertWorkflow) {
    modalService.open({
      component,
      hasModalCard: true,
      trapFocus: true,
      parent: this,
      props: {
        alertWorkflow,
      },
      events: {
        confirmPostponed: async (postponedDate: Date) =>
          this.changeStatus({
            id: alertWorkflow.id,
            postponedDate,
            status: AlertWorkflowStatus.Postponed,
            crmCaseNumber: null,
            closureInformation: null,
          }),
        confirmInProgress: async (crmCaseNumber: string) =>
          this.changeStatus({
            id: alertWorkflow.id,
            crmCaseNumber,
            status: AlertWorkflowStatus.InProgress,
            postponedDate: null,
            closureInformation: null,
          }),
        confirmResolved: async (resolvedInformation: string) =>
          this.changeStatus({
            id: alertWorkflow.id,
            closureInformation: resolvedInformation,
            status: AlertWorkflowStatus.Resolved,
            crmCaseNumber: null,
            postponedDate: null,
          }),
        confirmDiscarded: async (discardedInformation: string) =>
          this.changeStatus({
            id: alertWorkflow.id,
            closureInformation: discardedInformation,
            status: AlertWorkflowStatus.Discarded,
            crmCaseNumber: null,
            postponedDate: null,
          }),
      },
    });
  }

  async changeStatus(alertWorkflowUpdateDTO: AlertWorkflowUpdateDTO) {
    this.isLoading = true;
    alertWorkflowsService
      .update(alertWorkflowUpdateDTO)
      .then(async () => {
        this.alertWorkflows = await this.fetchWorkflows();
      })
      .finally(() => (this.isLoading = false));
  }

  private getDisabledStatusesByStatus(status: AlertWorkflowStatus): AlertWorkflowStatus[] {
    const disabledStatuses: AlertWorkflowStatus[] = [status];
    switch (status) {
      case AlertWorkflowStatus.New:
        disabledStatuses.push(AlertWorkflowStatus.Resolved);
        break;
      case AlertWorkflowStatus.Postponed:
      case AlertWorkflowStatus.InProgress:
        disabledStatuses.push(AlertWorkflowStatus.Postponed, AlertWorkflowStatus.InProgress);
        break;
      case AlertWorkflowStatus.Resolved:
      case AlertWorkflowStatus.Discarded:
      default:
        disabledStatuses.push(
          AlertWorkflowStatus.New,
          AlertWorkflowStatus.Postponed,
          AlertWorkflowStatus.InProgress,
          AlertWorkflowStatus.Resolved,
          AlertWorkflowStatus.Discarded,
        );
        break;
    }
    return disabledStatuses;
  }
}
