
import { DataPlatformDetailedTableData, DataPlatformTableEntry } from '@/models/dataPlatform';
import { dataPlatformService } from '@/services/dataplatform.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DataPlatformDetailModal extends Vue {
  @Prop({ required: true })
  private dataPlatform!: DataPlatformTableEntry;

  private data: DataPlatformDetailedTableData[] = [];

  private mounted() {
    this.loadData();
  }

  private async loadData() {
    this.data = await dataPlatformService.getDetail(this.dataPlatform?.id ?? 0);
  }

  private getFailedReadingJobAmount(row: DataPlatformDetailedTableData): number {
    // isDataSourceOk and isDataSinkOk can both be null. We want to leave nulls out,
    // so that's why these booleans are checked with === false explicitly
    return row.jobs.filter((job) => job.isDataSourceOk === false && !job.disabled).length;
  }

  private getFailedWritingJobAmount(row: DataPlatformDetailedTableData): number {
    // isDataSourceOk and isDataSinkOk can both be null. We want to leave nulls out,
    // so that's why these booleans are checked with === false explicitly
    return row.jobs.filter((job) => job.isDataSinkOk === false && !job.disabled).length;
  }

  private sortByJobsNumber(
    a: DataPlatformDetailedTableData,
    b: DataPlatformDetailedTableData,
    isAsc: boolean,
  ) {
    const jobsA = a.jobs ? a.jobs.length : 0;
    const jobsB = b.jobs ? b.jobs.length : 0;
    if (jobsA < jobsB) {
      return isAsc ? -1 : 1;
    } else if (jobsA > jobsB) {
      return isAsc ? 1 : -1;
    }
    return 0;
  }
}
