
import { Component, Prop, Vue } from 'vue-property-decorator';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Subsidiary } from '@/models/subsidiary';
import i18n from '@/i18n';
import { toastService } from '@/library-services/toast.service';

@Component({})
export default class SubsidiaryProperties extends Vue {
  @Prop({ required: true, default: Subsidiary.GetEmpty() })
  public subsidiary!: Subsidiary;

  public editSubsidiary: Subsidiary = Subsidiary.GetEmpty();

  private isSubmitting = false;

  public created() {
    this.editSubsidiary = Subsidiary.GetCopy(this.subsidiary);
  }

  public handleChange(): void {
    this.editSubsidiary = Subsidiary.GetCopy(this.editSubsidiary);
  }

  public get isEnabled() {
    if (this.editSubsidiary && this.$refs.form) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  public async onSubmit() {
    this.isSubmitting = true;
    subsidiariesService
      .update(this.editSubsidiary)
      .then(() => toastService.success('changes_saved'))
      .finally(() => (this.isSubmitting = false));
  }
}
