
import { Component, Ref, Vue } from 'vue-property-decorator';
import { BillingInfo } from '@/models/billingInfo';
import { tenantsService } from '@/services/tenants.service';
import { isNil } from '@/utils/misc';
import { generateLocalizedCustomSearch, matchIgnoringCase } from '@/utils/table';
import moment from 'moment';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';

@Component({
  components: {
    TableWithActions,
    TableActionButton,
  },
  methods: {
    generateLocalizedCustomSearch,
    isNil,
  },
})
export default class ControlList extends Vue {
  private billingInfo: BillingInfo[] = [];
  private viewOnDate = new Date();

  @Ref('bDatepicker')
  private bDatepicker!: any;

  private isLoading = false;
  private isPreparingDownload = false;

  private async created() {
    await this.getBillingInfo();
  }

  private get dateString(): string {
    return moment(this.viewOnDate)
      .subtract(this.viewOnDate.getTimezoneOffset(), 'minutes')
      .format('YYYY-MM-DD');
  }

  private getYears() {
    const year = new Date().getFullYear();
    const years = Array.from({ length: year + 1 - 2017 }, (value, index) => index + 2017);
    return years.reverse();
  }

  private async confirmDownload() {
    this.isPreparingDownload = true;
    await tenantsService
      .getBillingInfo(true, this.dateString)
      .finally(() => (this.isPreparingDownload = false));
  }

  private formatDate(date: Date) {
    if (date instanceof Date) {
      return moment(date).format('DD.MM.YYYY');
    }
  }

  private async getBillingInfo() {
    this.isLoading = true;
    try {
      this.billingInfo = await tenantsService.getBillingInfo(false, this.dateString);
    } finally {
      this.isLoading = false;
    }
  }

  private toggleDatepicker() {
    this.bDatepicker.toggle();
  }

  private searchByCustomerEmail(billingInfo: BillingInfo, filter: string) {
    return matchIgnoringCase(billingInfo.customerEmail, filter);
  }

  private sortByCustomerEmail(a: BillingInfo, b: BillingInfo, isAsc: boolean) {
    const translatedTypeA: string = a.customerEmail ?? '';
    const translatedTypeB: string = b.customerEmail ?? '';
    return isAsc
      ? translatedTypeA.localeCompare(translatedTypeB)
      : translatedTypeB.localeCompare(translatedTypeA);
  }
}
