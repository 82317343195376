
import { DeviceStateData } from '@/models/deviceStateData';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OverviewDeviceCardKpi from './OverviewDeviceCardKpi.vue';
import { isNil } from '@/utils/misc';

@Component({
  components: {
    OverviewDeviceCardKpi,
  },
})
export default class OverviewDeviceCardStateKpi extends Vue {
  @Prop({ required: true })
  private width!: string;

  @Prop({ required: true })
  private label!: string;

  @Prop()
  private deviceState?: DeviceStateData;

  @Prop({ required: true })
  private metric!: keyof DeviceStateData;

  private get deviceStateIsLoading(): boolean {
    return isNil(this.deviceState);
  }

  private get metricValue(): number | null {
    return this.deviceState?.[this.metric] as number | null;
  }

  private get isMetricNil(): boolean {
    return isNil(this.metricValue);
  }
}
