// --------------------------------------------------------------------------------
// <copyright file="axios-interceptor-auth.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import AuthService from '@/services/auth.service';

export function AuthInterceptor(authService: AuthService) {
  axios.interceptors.request.use(
    async (config) => {
      const tokenResponse = await authService.getAccessToken();
      config.headers = config.headers ?? {};
      config.headers.Authorization = `Bearer ${tokenResponse}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}
