// --------------------------------------------------------------------------------
// <copyright file="healthTimelineTooltipFormatter.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { HealthChartsData } from '@/models/Charts/chartsData';
import { dateWithGroupingTitleGenerator } from '@/models/Charts/tooltipFormatter';
import i18n from '@/i18n';
import { isEmpty, isNil, omit } from '@/utils/misc';
import { Logger } from '@/utils/logger';
import { uniq } from '@/utils/array';
import { HealthTimelineGenerator } from '@/models/Charts/healthTimelineGenerator';

export function tooltipFormatter(
  params: any,
  data: HealthChartsData,
  dateGrouping: FilterTimeAxisSpanEnum,
): string {
  const orderedByChartParams = [...params].sort(
    (paramsItemA: any, paramItemB: any) => paramsItemA.axisIndex - paramItemB.axisIndex,
  );

  return (
    getTitleHtml(orderedByChartParams, dateGrouping) +
    getSeriesValuesHtml(orderedByChartParams, data)
  );
}

function getTitleHtml(params: any, dateGrouping: FilterTimeAxisSpanEnum) {
  return `
    <div class="tooltip-title" data-testid="title">
      ${dateWithGroupingTitleGenerator(params, dateGrouping)}
    </div>
  `;
}

function getSeriesValuesHtml(params: any, data: HealthChartsData) {
  return `
    <table class="tooltip-table">
      <thead>
        <th></th>
        ${getReorderedColumnHeaders(params)}
      </thead>
      <tbody>
        ${getRowEntries(params, data)}
      </tbody>
    </table>
  `;
}

function getReorderedColumnHeaders(params: any): string {
  const seriesNames = uniq(params.map((paramItem: any) => paramItem.seriesName));

  return [
    // Move Health to the start
    ...seriesNames.filter((name) => name === 'health'),
    ...seriesNames.filter((name) => name !== 'health'),
  ]
    .map(
      (seriesName) =>
        `
    <th ${seriesName === 'health' ? 'class="highlighted"' : ''}>
      ${i18n.t(`health_timeline_legend.${seriesName}`)}
    </th>
    `,
    )
    .join('\n');
}

function getRowEntries(params: any, data: HealthChartsData) {
  return getChartIndices(params)
    .map((chartIndex) => {
      let chartParamItems = params.filter((paramItem: any) => paramItem.axisIndex === chartIndex);

      if (isDrawerCycleTimesChart(chartParamItems)) {
        chartParamItems = addFakeNoValueParamsItemIfFilteredMaxIsEnabled(chartParamItems, params);
      }

      return getChartEntry(chartParamItems, data);
    })
    .join('\n');
}

function getChartIndices(params: any): number[] {
  return uniq<number>(params.map((paramItem: any) => paramItem.axisIndex));
}

function isDrawerCycleTimesChart(chartParamItems: any[]) {
  return chartParamItems[0].axisIndex === DRAWER_CYCLES_TIME_AXIS_INDEX;
}

// Inserts a fake params item so getChartEntry generates a cell for the
// "Filtered Max." series with a "-" value.
function addFakeNoValueParamsItemIfFilteredMaxIsEnabled(chartParamItems: any[], params: any) {
  if (!isFilteredMaxSeriesEnabled(params)) {
    return chartParamItems;
  }

  const fakeNoValueParamItem = {
    ...chartParamItems[0],
    value: null,
  };
  const filteredMaxIndex = getFilteredMaxIndex(params);

  return [
    ...chartParamItems.slice(0, filteredMaxIndex),
    fakeNoValueParamItem,
    ...chartParamItems.slice(filteredMaxIndex),
  ];
}

function isFilteredMaxSeriesEnabled(params: any) {
  return params.some((paramItem: any) => paramItem.seriesName === 'filteredMax');
}

function getFilteredMaxIndex(params: any) {
  const indexWithFilteredMax = params.findIndex(
    (paramsItem: any) => paramsItem.seriesName === 'filteredMax',
  );
  const axisIndexWithFilteredMax = params[indexWithFilteredMax].axisIndex;
  return params
    .filter((paramsItem: any) => paramsItem.axisIndex === axisIndexWithFilteredMax)
    .findIndex((paramsItem: any) => paramsItem.seriesName === 'filteredMax');
}

function getChartEntry(chartParamItems: any[], data: HealthChartsData): string {
  return `
    <tr class="tooltip-entry">
      <td class="entry-marker-and-name">
        ${getMarker(chartParamItems)}
        
        <span data-testid="entry-name">
          ${i18n.t(`report.${getChartName(chartParamItems[0])}`)}
        </span>
      </td>
      
      ${getValueEntries(chartParamItems, data)}
    </tr>
  `;
}

function getMarker(chartParamItems: any[]): string {
  const healthSeriesIndex = chartParamItems.findIndex(
    (paramsItem) => paramsItem.seriesName === 'health',
  );

  return healthSeriesIndex !== -1
    ? chartParamItems[healthSeriesIndex].marker
    : chartParamItems[0].marker;
}

function getValueEntries(chartParamItems: any[], data: HealthChartsData): string {
  return moveHealthParamItemToTheStart(chartParamItems)
    .map((paramItem, index) => {
      const value = Array.isArray(paramItem.value) ? paramItem.value?.[1] : paramItem.value;
      const highlightedClass = paramItem.seriesName === 'health' ? 'highlighted' : '';

      return `
      <td class="entry-value ${highlightedClass}" data-testid="entry-value">
        ${isNil(value) ? '-' : i18n.n(value, { maximumFractionDigits: 2 })}
        ${index === 0 ? getUnitAndLevel(paramItem, data) : ''}
      </td>
    `;
    })
    .join('\n');
}

function getUnitAndLevel(paramItem: any, data: HealthChartsData) {
  const value = Array.isArray(paramItem.value) ? paramItem.value?.[1] : paramItem.value;
  const seriesName = getChartName(paramItem);
  const levelMaximums = Object.values(omit(data[seriesName], ['timelineData'])) as number[];
  const level = getLevel(value, levelMaximums);
  const unit = HealthTimelineGenerator.getUnit(seriesName, value);

  if (!isEmpty(unit)) {
    return `${unit} (${level})`;
  }
  return `(${level})`;
}

function getLevel(value: number | null, levelMaximums: number[]) {
  if (isNil(value)) {
    return '-';
  }

  const eLevelMaximum = levelMaximums[levelMaximums.length - 1];
  const adjustedValue = value <= eLevelMaximum ? value : eLevelMaximum;
  const levelIndex = levelMaximums.findIndex((levelMaximum) => adjustedValue <= levelMaximum);

  return ['A', 'B', 'C', 'D', 'E'][levelIndex];
}

function moveHealthParamItemToTheStart(chartParamItems: any[]) {
  return [
    ...chartParamItems.filter((paramItem) => paramItem.seriesName === 'health'),
    ...chartParamItems.filter((paramItem) => paramItem.seriesName !== 'health'),
  ];
}

export const LOWER_PROTECTIVE_GLASS_AXIS_INDEX = 0;
export const UPPER_PROTECTIVE_GLASS_AXIS_INDEX = 1;
export const DRAWER_CYCLES_TIME_AXIS_INDEX = 2;
export const LENS_DRIVE_AXIS_INDEX = 3;
export const TEMPERATURES_AXIS_INDEX = 4;

function getChartName(seriesParam: any): keyof HealthChartsData {
  switch (seriesParam.axisIndex) {
    case LOWER_PROTECTIVE_GLASS_AXIS_INDEX:
      return 'lowerProtectiveGlass';
    case UPPER_PROTECTIVE_GLASS_AXIS_INDEX:
      return 'upperProtectiveGlass';
    case DRAWER_CYCLES_TIME_AXIS_INDEX:
      return 'drawerCyclesTime';
    case LENS_DRIVE_AXIS_INDEX:
      return 'lensDrive';
    case TEMPERATURES_AXIS_INDEX:
      return 'temperatures';
    default:
      Logger.warn('Invalid axisIndex', seriesParam.axisIndex);
      return 'temperatures';
  }
}
