// --------------------------------------------------------------------------------
// <copyright file="i18n.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const languages = [
  'en',
  'es',
  'de',
  'cs',
  'da',
  'fi',
  'fr',
  'hu',
  'it',
  'ja',
  'ko',
  'nl',
  'pl',
  'pt-BR',
  'ru',
  'sv',
  'tr',
  'zh',
  'zh-HANT',
];

export const messages = Object.fromEntries(
  languages.map((language) => {
    const underscoreLang = language.replace('-', '_');
    const langMessages = require(`./locale/${underscoreLang}/${underscoreLang}.json`);
    return [
      language,
      Object.assign(
        {},
        langMessages,
        require(`./locale/${underscoreLang}/timezones.${underscoreLang}.json`),
        require('./locale/currencies.json'),
        {
          validation: {
            ...require(`vee-validate/dist/locale/${
              ['zh', 'zh-HANT'].includes(language) ? 'zh_CN' : underscoreLang
            }.json`).messages,
            ...(langMessages.validation ?? []),
          },
        },
      ),
    ];
  }),
);

const navigatorLanguage = window.navigator.language || 'en';
const navigatorCode = navigatorLanguage.split('-');

export default new VueI18n({
  locale: navigatorCode[0] || process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
});
