// --------------------------------------------------------------------------------
// <copyright file="poTreeMapGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { poStatusToColor } from '@/utils/color';
import { WidgetEnum } from '../enums/WidgetEnum';
import { POTreemapData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import {
  mockManufacturingReleasedPOStatus,
  mockManufacturingPOStatusData,
} from './mockWidgetSelectorData';
import { Logger } from '@/utils/logger';
import { metricsService } from '@/services/metrics.service';

const levels = ['status', 'routing'];
const STATUS_LEVEL = 1;

export class POTreemapGenerator extends ChartGenerator<POTreemapData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];

    return metricsService.getSMBSMetricsSFC<POTreemapData[]>(
      this.procedure,
      this.tenantIdDh,
      { startDate, endDate },
      this.controller,
    );
  }

  override updateOptions(
    data: POTreemapData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const dimensionSpecifier = (info: any, index: number) => {
      const levelText: string = i18n.t(`report.shop_floor_console.${levels[index]}`).toString();
      return `
          <p>${levelText}
          <b style="margin-left:32px;float:right">${info.name}</b></p>`;
    };

    return {
      tooltip: {
        show: true,
        confine: true,
        formatter: (params: any) => {
          return (
            params.treeAncestors.slice(1).map(dimensionSpecifier).join('\n') +
            `
            <p>${i18n.t('report.shop_floor_console.estimated_time')}
            <b style="margin-left:32px;float:right">${i18n.n(params.value, {
              maximumFractionDigits: 2,
            })}h</b></p>`
          );
        },
      },
      series: [
        {
          type: 'treemap',
          breadcrumb: {
            show: true,
            top: 0,
            bottom: 'auto',
          },
          leafDepth: 1,
          data: this.recursion(0, '', data).children ?? [],
          levels: [
            {
              colorSaturation: [0.3, 0.6],
              itemStyle: {
                borderColorSaturation: 0.7,
                gapWidth: 2,
                borderWidth: 2,
              },
            },
            {
              colorSaturation: [0.3, 0.5],
              itemStyle: {
                borderColorSaturation: 0.6,
                gapWidth: 1,
              },
            },
            {
              colorSaturation: [0.3, 0.5],
            },
          ],
        },
      ],
    };
  }

  override getMockData(): POTreemapData[] | null {
    switch (this.procedure) {
      case WidgetEnum.ManufacturingPOStatus:
        return mockManufacturingPOStatusData();
      case WidgetEnum.ManufacturingReleasedPOStatus:
        return mockManufacturingReleasedPOStatus();
      default:
        Logger.error(`Missing mock data for ${this.procedure}`);
        return null;
    }
  }

  private recursion(level: number, distinctValue: string, data: POTreemapData[]) {
    if (level === levels.length) {
      return {
        name: (data[0] as any)[levels[levels.length - 1]],
        value: data[0].estimated_time,
        nPOs: data[0].nPOs,
        nPOLs: data[0].nPOLs,
      };
    }
    const dimension: string = levels[level];
    interface Child {
      name: string;
      value: number;
      nPOs: number;
      nPOLs: number;
      children?: Child[];
      label?: any;
    }
    const children: Child[] = [];
    const distinctValues: string[] = [...new Set<string>(data.map((x: any) => x[dimension]))];
    distinctValues.forEach((distinctValueEntry: string) => {
      const obj = this.recursion(
        level + 1,
        distinctValueEntry,
        data.filter((dataItem: any) => dataItem[dimension] === distinctValueEntry),
      );
      children.push(obj);
    });
    let value: number = 0;
    let nPOs: number = 0;
    let nPOLs: number = 0;
    children.forEach((c: Child) => {
      value += c.value;
      nPOs += c.nPOs;
      nPOLs += c.nPOLs;
    });
    return {
      name:
        level === STATUS_LEVEL
          ? i18n
              .t(`report.shop_floor_console.${distinctValue.toLowerCase().replaceAll(' ', '_')}`)
              .toString()
          : distinctValue,
      value,
      nPOs,
      nPOLs,
      children,
      itemStyle: {
        color: level === STATUS_LEVEL ? poStatusToColor(distinctValue) : undefined,
      },
      label: {
        show: true,
        position: 'insideTopLeft',
        formatter: (params: any) =>
          this.procedure === WidgetEnum.ManufacturingReleasedPOStatus
            ? `${params.name}\n{hr|}\n${i18n.n(params.data.nPOLs)}{label|${i18n.t(
                'report.manufacturing_console.orders',
              )}}`
            : [
                `${params.name}\n{hr|}\n${i18n.n(params.data.nPOs)}{label|${i18n.t(
                  'report.manufacturing_console.orders',
                )}}`,
                `${i18n.n(params.data.nPOLs)}{label|${i18n.t(
                  'report.manufacturing_console.lines',
                )}}`,
              ].join('\n'),
        rich: {
          budget: {
            fontSize: 22,
            lineHeight: 30,
            color: 'yellow',
          },
          household: {
            fontSize: 14,
            color: '#fff',
          },
          label: {
            fontSize: 9,
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: '#fff',
            borderRadius: 2,
            padding: [2, 4],
            lineHeight: 25,
            align: 'right',
          },
          name: {
            fontSize: 12,
            color: '#fff',
          },
          hr: {
            width: '100%',
            borderColor: 'rgba(255,255,255,0.2)',
            borderWidth: 0.5,
            height: 0,
            lineHeight: 10,
          },
        },
      },
    };
  }
}
