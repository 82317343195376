// --------------------------------------------------------------------------------
// <copyright file="commons.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { keys } from '@/utils/enum';

export enum CareTab {
  Overview,
  Alerts,
  Health,
  Events,
  CuttingHead,
  LaserConsumption,
}

export enum DeviceView {
  Care = 'care',
  WorkCenter = 'work-center',
}

export function isValidCareTab(value: string): value is keyof typeof CareTab {
  return keys(CareTab).includes(value);
}
