// --------------------------------------------------------------------------------
// <copyright file="pieChartGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { Color } from '@/utils/color';
import i18n from '@/i18n';
import { decimalFormatter } from '@/utils/number';
import { LaserProductivityData } from './chartsData';
import { DefaultLabelFormatterCallbackParams, EChartsOption } from 'echarts';
import { mockLaserProductivityData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export class PieChartGenerator extends ChartGenerator<LaserProductivityData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    return metricsService.getDevicesMetrics<LaserProductivityData[]>(
      this.procedure,
      {
        tenantIdDh: this.tenantIdDh,
        deviceIds: selectedDevices,
        shifts: selectedShifts,
        timeSpan: timeSpan as FilterTimeSpanEnum,
      },
      this.controller,
    );
  }

  setOpacity(hex: string, alpha: number): string {
    return `${hex}${Math.floor(alpha * 255).toString(16)}`;
  }

  override updateOptions(
    data: any[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const primaryPosition: number | undefined = this.findPrimaryPosition(data);
    const secondaryPosition: number | undefined = this.findSecondaryPosition(data, primaryPosition);
    const greenTransparent = this.setOpacity(Color.Green, 0.5);
    const redTransparent = this.setOpacity(Color.PinkRed, 0.5);
    const currentProd =
      primaryPosition !== undefined
        ? data[primaryPosition].work / (data[primaryPosition].rest + data[primaryPosition].work)
        : 0.0;
    const previousProd =
      secondaryPosition !== undefined
        ? data[secondaryPosition].work /
          (data[secondaryPosition].rest + data[secondaryPosition].work)
        : 0.0;
    const dpr: number = window.devicePixelRatio;
    const fontSizePerc = dpr > 1 ? '13' : '15';
    const currentSeriesOuterRadius = dpr > 1 ? '49%' : '54%';
    const previousSeriesOuterRadius = dpr > 1 ? '25%' : '27%';

    return {
      tooltip: {
        trigger: 'item',
        extraCssText: 'z-index: 1',
        confine: true,
        valueFormatter: decimalFormatter,
      },
      legend: {
        top: '88%',
        left: 'center',
      },
      series: [
        {
          name: i18n.t('report.current').toString(),
          type: 'pie' as const,
          radius: ['32%', currentSeriesOuterRadius],
          markPoint: {
            tooltip: { show: false },
            label: {
              formatter: (params: any) =>
                isNaN(currentProd)
                  ? '━'
                  : i18n.n(params.value * 100, { maximumFractionDigits: 2 }) + ' %',
              show: true,
              color: Color.Black,
              fontSize: fontSizePerc,
              fontWeight: 'bold',
            },
            data: [
              {
                name: '', // defined as required
                value: isNaN(currentProd) ? 0.0 : currentProd,
                itemStyle: { color: 'transparent' },
                x: '20%',
                y: '25%',
              },
            ],
          },
          avoidLabelOverlap: true,

          labelLayout: {
            align: 'center' as const,
            hideOverlap: true,
            moveOverlap: 'shiftX' as const,
          },
          labelLine: {
            show: true,
            length2: 5,
            lineStyle: {
              color: '#bbb',
            },
          },
          data: [
            {
              value: primaryPosition !== undefined ? data[primaryPosition].work : 0.0,
              name: i18n.t('report.work_time').toString(),
              label: {
                show: true,
                formatter: (params: any) =>
                  i18n.n(params.data.value, { maximumFractionDigits: 2 }) + parameters.unit,
                minMargin: 10,
                position: 'top' as const,
              },
              itemStyle: {
                color: Color.Green,
              },
            },
            {
              value: primaryPosition !== undefined ? data[primaryPosition].rest : 0.0,
              name: i18n.t('report.unproductive').toString(),
              label: {
                show: true,
                formatter: (params: any) =>
                  i18n.n(params.data.value, { maximumFractionDigits: 2 }) + parameters.unit,
                minMargin: 10,
                position: 'top' as const,
              },
              itemStyle: {
                color: Color.PinkRed,
              },
            },
          ],
        },
        {
          name: i18n.t('report.previous').toString(),
          type: 'pie' as const,
          radius: ['15%', previousSeriesOuterRadius],
          markPoint: {
            tooltip: { show: false },
            label: {
              formatter: (params: DefaultLabelFormatterCallbackParams) => {
                const arrowSymbol = currentProd > previousProd ? '▲' : '▼';
                return isNaN(currentProd - previousProd)
                  ? '━'
                  : i18n.n(Math.abs(Number(params.value)) * 100, { maximumFractionDigits: 2 }) +
                      ' % ' +
                      arrowSymbol;
              },
              show: true,
              color: isNaN(currentProd - previousProd)
                ? Color.Black
                : currentProd > previousProd
                ? Color.Green
                : Color.PinkRed,
              fontSize: fontSizePerc,
              fontWeight: 'bold',
            },
            data: [
              {
                name: '', // defined as required
                value: currentProd - previousProd,
                itemStyle: { color: 'transparent' },
                x: '80%',
                y: '25%',
              },
            ],
          },
          avoidLabelOverlap: false,

          labelLayout: {
            align: 'center' as const,
            hideOverlap: true,
            moveOverlap: 'shiftX' as const,
          },
          labelLine: {
            show: true,
            length2: 5,
            lineStyle: {
              color: '#bbb',
            },
          },
          data: [
            {
              value: secondaryPosition !== undefined ? data[secondaryPosition].work : 0.0,
              name: i18n.t('report.work_time').toString(),
              label: {
                show: true,
                formatter: (params: any) =>
                  i18n.n(params.data.value, { maximumFractionDigits: 2 }) + parameters.unit,
                minMargin: 10,
                position: 'top' as const,
              },
              itemStyle: {
                color: greenTransparent,
              },
            },
            {
              value: secondaryPosition !== undefined ? data[secondaryPosition].rest : 0.0,
              name: i18n.t('report.unproductive').toString(),
              label: {
                formatter: (params: any) =>
                  i18n.n(params.data.value, { maximumFractionDigits: 2 }) + parameters.unit,
                minMargin: 10,
                position: 'top' as const,
              },
              itemStyle: {
                color: redTransparent,
              },
            },
          ],
        },
      ],
    };
  }

  override getMockData(): LaserProductivityData[] | null {
    return mockLaserProductivityData();
  }

  private findPrimaryPosition(data: LaserProductivityData[]): number | undefined {
    const positions = [0, 1].slice(0, data.length);
    return positions.find((pos: number) => data[pos].isprimary);
  }

  private findSecondaryPosition(
    data: LaserProductivityData[],
    primaryPosition: number | undefined,
  ): number | undefined {
    if (primaryPosition !== undefined && data.length === 2) {
      return 1 - primaryPosition;
    } else if (primaryPosition === undefined && data.length === 1) {
      return 0;
    } else {
      return undefined;
    }
  }
}
