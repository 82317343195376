
import WidgetDefinition, { ParamValues } from '@/models/Charts/widgetDefinition';
import { Component, InjectReactive, Prop, Provide, Ref, Vue, Watch } from 'vue-property-decorator';
import { findWidgetType, WidgetEnum } from '@/models/enums/WidgetEnum';
import { WidgetType } from '@/models/enums/WidgetType';
import { DataRetriever } from '@/models/Charts/abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { RequestCanceledError } from '@/services/requestCanceledError';
import { Logger } from '@/utils/logger';
import WidgetFactory from '@/models/Charts/widgetFactory';
import { filterChanged } from '@/models/metrics/widgetFilters';
import i18n from '@/i18n';
import { usersService } from '@/services/users.service';
import { User } from '@/models/user';
import { customersService } from '@/services/tenants.service';
import WidgetInfo from '../WidgetInfo.vue';
import { DynamicModal } from './common';
import QuickSearchItem from '@/models/metrics/quickSearchItem';
import { downloadCsv } from '@/services/metricsDownloadService';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import WidgetCard from './WidgetCard.vue';
import { listenersWithPrefix } from '@/utils/components';
import MapWidget from '@/components/Charts/MapWidget.vue';

@Component({
  components: {
    WidgetCard,
    WidgetInfo,
    Chart: () => import('@/components/Charts/Chart.vue'),
    CameraView: () => import('@/components/CameraView.vue'),
    // MapWidget is not dynamically imported to avoid issues with the ref,
    // which wasn't being loaded in time for the first update() call.
    MapWidget,

    // Slot components
    'bend-status': () => import('@/components/bend/BendStatus.vue'),
    'bend-performance': () => import('@/components/bend/BendPerformance.vue'),
    'figures-widget': () => import('@/components/FiguresWidget.vue'),
    'cut-status': () => import('@/components/cut/CutStatus.vue'),
    'tube-status': () => import('@/components/tube/TubeStatus.vue'),
    'bend-productivity-figures': () => import('@/components/bend/BendProductivityFigures.vue'),
    'bend-states-overview': () => import('@/components/bend/BendStatesOverview.vue'),

    // Table components
    'laser-gantt-chart-table': () =>
      import('@/views/workcenterConsole/LaserGanttChartTable/LaserGanttChartTable.vue'),
    'bend-gantt-chart-table': () =>
      import('@/views/workcenterConsole/BendGanttChartTable/BendGanttChartTable.vue'),
    'cut-current-workload-table': () => import('@/components/cut/CutCurrentWorkloadTable.vue'),

    // KPI widget components
    'number-of-bends-kpi-widget': () => import('@/components/bend/NumberOfBendsKpiWidget.vue'),
    'bends-per-part-kpi-widget': () => import('@/components/bend/BendsPerPartKpiWidget.vue'),
    'bending-tool-changes-kpi-widget': () =>
      import('@/components/bend/BendingToolChangesKpiWidget.vue'),
    'bending-production-output-kpi-widget': () =>
      import('@/components/bend/BendingProductionOutputKpiWidget.vue'),

    // Dynamic modals
    'cut-intraday': () => import('@/components/cut/CutIntraday.vue'),
    'detailed-customer-groups': () => import('@/components/sales/DetailedCustomerGroups.vue'),
    'detailed-top-sales-people': () => import('@/components/sales/DetailedTopSalespeople.vue'),
    'detailed-customer': () => import('@/components/quotes/DetailedCustomerView.vue'),
    'cut-states-intraday': () => import('@/components/cut/CutStatesIntraday.vue'),
    'oee-intraday': () => import('@/components/cut/OEEIntraday.vue'),
    'quotes-customers-expanded-view': () =>
      import('@/components/quotes/QuotesCustomersExpandedView.vue'),
    'events-table-expanded-view': () =>
      import('@/components/careConsole/EventsTableExpandedView.vue'),
    'modal-wrapped-events-table-expanded-view': () =>
      import('@/components/careConsole/ModalWrappedEventsTableExpandedView.vue'),
  },
  computed: {
    WidgetType: () => WidgetType,
  },
})
export default class SingleWidgetContainer extends Vue {
  @Prop({ required: true })
  @Provide('widgetDefinition')
  private widgetDefinition!: WidgetDefinition;

  @Prop({ default: false, type: Boolean })
  private selfManagedFilters!: boolean;

  @Prop({ default: 'is-bottom-right' })
  private widgetInfoPosition!: string;

  @Prop({ default: true, type: Boolean })
  private autorefresh!: boolean;

  @Prop({ default: false, type: Boolean })
  private isWidgetSelector!: boolean;

  @Prop()
  private customerNo?: string;

  /** Provided by Technology console where the user selects a customer. */
  @InjectReactive({ from: 'technologyTenantIdDh', default: null })
  private technologyTenantIdDh!: number | null;

  /** Provided by Care console where the user selects a customer. */
  @InjectReactive({ from: 'supportTenantIdDh', default: null })
  private supportTenantIdDh!: number | null;

  @Ref('map-widget')
  private mapWidget?: MapWidget;

  private dynamicModalSettings: DynamicModal | null = null;

  private dataRetriever: DataRetriever<any> | null = null;
  private data: any = null;

  private handle: number = -1;
  private isFetchingForTheFirstTime = true;
  private loading = true;
  private paramValues: Record<string, any> = {};

  private async mounted() {
    this.initializeParamValuesIfDefined();

    if (this.handle === -1 && this.autorefresh) {
      this.handle = window.setInterval(this.update, 40 * 1000);
    }

    this.dataRetriever = WidgetFactory.createDataRetriever(
      this.widgetDefinition.widget,
      this.customerIdDh,
    );
    await this.update();
  }

  private beforeDestroy() {
    clearInterval(this.handle);
    this.dataRetriever?.abort();
  }

  @Watch('widgetDefinition')
  private onWidgetDefinitionChanged(newValue: WidgetDefinition, oldValue: WidgetDefinition) {
    this.initializeParamValuesIfDefined();

    if (newValue.widget !== oldValue.widget) {
      this.data = null;
      this.isFetchingForTheFirstTime = true;
      this.dataRetriever?.abort();
      this.dataRetriever = WidgetFactory.createDataRetriever(
        this.widgetDefinition.widget,
        this.customerIdDh,
      );
      this.update();
    } else if (filterChanged(newValue, oldValue)) {
      this.update();
    }
  }

  private async getData() {
    if (this.isWidgetSelector) {
      return this.dataRetriever?.getMockData();
    } else {
      this.dataRetriever?.abort();
      return this.dataRetriever?.getData(
        this.deviceIds,
        this.widgetDefinition.shifts,
        this.widgetDefinition.timeFilter ?? FilterTimeSpanEnum.None,
        this.widgetDefinition.axisSpan,
        this.paramValues,
        this.customerNo,
      );
    }
  }

  @Watch('technologyTenantIdDh')
  @Watch('supportTenantIdDh')
  private async update() {
    if (this.widgetType === WidgetType.CameraView) {
      return;
    } else if (this.widgetType === WidgetType.Map) {
      // Waiting for the next tick when the map is updated helps to let the component fully load.
      // This in turn means that Leaflet will correctly run fitBounds always, and that when switching
      // between composites the map is also fully mounted.
      this.$nextTick(() => {
        this.mapWidget?.update();
      });
      return;
    }
    const widgetDefinition = this.widgetDefinition;
    this.loading = true;
    let data: any = {};
    try {
      data = await this.getData();
    } catch (e) {
      // Avoid the chart being hidden while the new response arrives
      // (We throw an exception when a request is aborted).
      if (!(e instanceof RequestCanceledError)) {
        this.data = {}; // To show 'no data'
        Logger.error(`Error loading data for '${this.widgetTitle}' chart.`, e);
      }
    } finally {
      if (!this.hasWidgetDefinitionChanged(widgetDefinition)) {
        // This is checked to avoid a race condition.
        // This race condition could happen when a widget definition is changed before the
        // request has been completed. This function would be called again before the previous
        // call has ended, and the this.loading assignment in line 461 and the one below would
        // conflict. For example, when quickly clicking the switch in WCC Cut.
        this.data = data ?? {}; // {} to show 'no data'
        this.loading = false;
        this.isFetchingForTheFirstTime = false;
      }
    }
  }

  private initializeParamValuesIfDefined() {
    this.paramValues = {
      ...this.generateParamValuesWithDefaults(),
      ...this.widgetDefinition.paramValues,
    };
  }

  private generateParamValuesWithDefaults() {
    return Object.entries(this.widgetDefinition.params ?? {})
      .map(([paramName, paramInfo]) => ({ [paramName]: paramInfo.defaultValue }))
      .reduce((a, b) => ({ ...a, ...b }), {});
  }

  private hasWidgetDefinitionChanged(widgetDefinition: WidgetDefinition): boolean {
    return (
      this.widgetDefinition.widget !== widgetDefinition.widget ||
      filterChanged(this.widgetDefinition, widgetDefinition)
    );
  }

  private onParamUpdated(paramValues: ParamValues): void {
    let widgetDefinition = this.widgetDefinition;

    Object.entries(paramValues).forEach(([paramName, paramValue]) => {
      this.$set(this.paramValues, paramName, paramValue);
      widgetDefinition = widgetDefinition.updateParamValue(paramName, paramValue);
    });
    // Important: We don't want to call update() here. It'll be triggered by the
    // 'change' event through watchChanges.
    this.$emit('param-update');
    this.$emit('change', widgetDefinition);
  }

  private openDynamicModal(dynamicModalSettings: DynamicModal): void {
    this.dynamicModalSettings = {
      type: dynamicModalSettings.type,
      propsData: {
        ...dynamicModalSettings.propsData,
        onClose: () => {
          this.dynamicModalSettings = null;
        },
      },
    };
  }

  private onExpandOnDblClick(params: any) {
    const config = this.widgetDefinition.getDblClickModalConfiguration()!;
    this.openDynamicModal({
      type: config.type,
      propsData: {
        ...Object.fromEntries(config.propsData.map((prop) => [prop, (this as any)[prop]])),
        ...(config.mapParamsToProps?.(params) ?? {}),
      },
    });
  }

  private onQuickSearchSelect(selection: QuickSearchItem) {
    if (this.canExpandOnQuickSearch) {
      const config = this.widgetDefinition.getQuickSearchModalConfiguration()!;
      this.openDynamicModal({
        type: config.type,
        propsData: {
          ...Object.fromEntries(config.propsData.map((prop) => [prop, (this as any)[prop]])),
          selection,
        },
      });
    }
  }

  private downloadData() {
    const fileName = i18n.t(`widget.${this.widgetDefinition.widget}`) ?? 'data';
    downloadCsv(this.data, `${fileName}.csv`);
  }

  private onExpand() {
    const config = this.widgetDefinition.getModalConfiguration()!;
    this.openDynamicModal({
      type: config.type,
      propsData: {
        ...Object.fromEntries(config.propsData.map((prop) => [prop, (this as any)[prop]])),
      },
    });
  }

  private async refreshTableData() {
    await this.update();
  }

  private get slotWidgetComponent(): string | null {
    switch (this.widgetDefinition.widget) {
      case WidgetEnum.CutStatus:
        return 'cut-status';
      case WidgetEnum.BendStatus:
        return 'bend-status';
      case WidgetEnum.TubeStatus:
        return 'tube-status';
      case WidgetEnum.BendingPerformance:
        return 'bend-performance';
      case WidgetEnum.BendProductivityFigures:
        return 'bend-productivity-figures';
      case WidgetEnum.BendStatusOverview:
        return 'bend-states-overview';
      case WidgetEnum.BusinessOverviewFigures:
      case WidgetEnum.QuotesFiguresDetailed:
      case WidgetEnum.QuotesFigures:
      case WidgetEnum.SalesFigures:
      case WidgetEnum.ManufacturingFigures:
      case WidgetEnum.TechnologyOverviewSscFigures:
        return 'figures-widget';
      default:
        return null;
    }
  }

  private get tableWidgetComponent(): string | null {
    switch (this.widgetDefinition.widget) {
      case WidgetEnum.LaserStatesGanttTable:
        return 'laser-gantt-chart-table';
      case WidgetEnum.BendStatesGanttTable:
        return 'bend-gantt-chart-table';
      case WidgetEnum.LaserCurrentWorkload:
        return 'cut-current-workload-table';
      default:
        return null;
    }
  }

  private get kpiWidgetComponent(): string | null {
    switch (this.widgetDefinition.widget) {
      case WidgetEnum.NumberOfBendsKpiWidget:
        return 'number-of-bends-kpi-widget';
      case WidgetEnum.BendsPerPartKpiWidget:
        return 'bends-per-part-kpi-widget';
      case WidgetEnum.BendingToolChangesKpiWidget:
        return 'bending-tool-changes-kpi-widget';
      case WidgetEnum.BendingProductionOutputKpiWidget:
        return 'bending-production-output-kpi-widget';
      default:
        return null;
    }
  }

  private get user(): User {
    return usersService.store.current();
  }

  private get customerIdDh(): number {
    if (this.user.isTechnologyUser) {
      return this.technologyTenantIdDh!;
    } else if (this.user.isServiceUserType) {
      return this.supportTenantIdDh!;
    } else {
      return customersService.store.currentIdDh()!;
    }
  }

  private get widgetTitle(): string {
    return i18n.t(`widget.${this.widgetDefinition.widget}`).toString();
  }

  private get canExpandOnQuickSearch(): boolean {
    return this.widgetDefinition.canExpandOnQuickSearch();
  }

  private get widgetType(): WidgetType {
    return findWidgetType(this.widgetDefinition.widget);
  }

  private get hasWidgetCard(): boolean {
    return ![WidgetType.Table, WidgetType.KPI].includes(this.widgetType);
  }

  private get deviceIds(): string[] {
    return this.widgetDefinition.deviceIds;
  }

  /* These getters are used to be passed as props to the dynamic modals. */
  private get selectedShifts() {
    return this.widgetDefinition.shifts;
  }

  private get selectedTimeFilter() {
    return this.widgetDefinition.timeFilter;
  }

  private get selectedDeviceIds(): string[] {
    return this.deviceIds;
  }

  private get selectedDateGrouping() {
    return this.widgetDefinition.axisSpan ?? FilterTimeAxisSpanEnum.Day;
  }

  private get tableListeners() {
    return listenersWithPrefix('table', this);
  }
}
