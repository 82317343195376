
import { Component, Vue } from 'vue-property-decorator';
import { usersService } from '@/services/users.service';
import { User } from '@/models/user';
import { downloadTermsAndConditionsPdf } from '@/utils/misc';
import { currentLocale } from '@/utils/i18n';

/**
 * Shows the terms and conditions for the user to accept them.
 */
@Component
export default class TermsAgreementModal extends Vue {
  private rememberDecision = false;
  private termsHtmlContent = '';

  private isLoading = false;
  private isSubmitting = false;

  private mounted() {
    this.loadTerms();
  }

  private loadTerms() {
    this.isLoading = true;
    usersService
      .getTermsAndConditionsHtmlContent(currentLocale())
      .then((html) => (this.termsHtmlContent = html))
      .finally(() => (this.isLoading = false));
  }

  private async acceptTermAgreement() {
    this.isSubmitting = true;

    try {
      await usersService.acceptTermsAndConditions(this.rememberDecision);
    } catch (e: any) {
      this.$buefy.dialog.alert({
        message: `<p>${this.$i18n.t('terms.notify_failed')}</p><p><b>${e.message}</b></p>`,
        onConfirm: () => downloadTermsAndConditionsPdf(),
      });
    } finally {
      this.isSubmitting = false;
    }
    this.$emit('close');
  }

  private async logout() {
    this.$spinner.showSpinner();
    await usersService.store.update({} as User);
    await this.$auth.logout().finally(() => this.$spinner.removeSpinner());
  }
}
