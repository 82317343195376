// --------------------------------------------------------------------------------
// <copyright file="AlertWorkflowNotification.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

/**
 * Notification sent by the backend when a new alert workflow has been created.
 *
 * When an equipment's alert is triggered a new alert workflow is created, if it
 * hasn't been done already. It's only when the alert workflow is created that
 * this type of notifications is sent.
 */
export class AlertWorkflowNotification {
  constructor(
    public deviceId: number,
    public deviceName: string,
    public customerName: string,
    public timestampNotification: string,
  ) {}
}
