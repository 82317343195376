
import { Component, Prop, Vue } from 'vue-property-decorator';
import TableActionsList from './TableActionsList.vue';

@Component({
  components: {
    TableActionsList,
  },
})
export default class TableColumnActionsWrapper extends Vue {
  /**
   * Defines how the row actions are displayed (only on hover/ always).
   *
   * See `TableWithActions2.rowActionsDisplayMode` for more details.
   */
  @Prop({ default: 'on-hover' })
  private displayMode!: 'on-hover' | 'always';
}
