
import { AlertAction } from '@/models/enums/AlertsEnums';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AlertsActionsSelect extends Vue {
  @Prop({ default: '' })
  public value!: string;

  @Prop({ default: false })
  public required!: boolean;

  @Prop({ default: false })
  public disabled!: boolean;

  public items: object[] = [];

  public mounted(): void {
    this.getItems();
  }

  public getItems() {
    for (const [propertyKey, propertyValue] of Object.entries(AlertAction)) {
      if (Number.isNaN(Number(propertyKey)) || Number(propertyKey) === 0) {
        continue;
      }
      this.items.push({ value: propertyValue, key: propertyKey });
    }
  }

  public handleSelect(value: string) {
    this.$emit('input', value);
    this.$emit('change', value);
  }
}
