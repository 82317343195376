// --------------------------------------------------------------------------------
// <copyright file="masters.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Measurement } from '@/models/measurement';
import { CurrencyCode } from '@/models/currencyCode';
import { TimeZone } from '@/models/timeZone';
import { PersistentActions, PersistentGetters } from '@/store/persistent/enums';
import store from '@/store';

class MastersService {
  store = {
    getCurrencyCodes(): CurrencyCode[] {
      return store.getters[PersistentGetters.GetCurrencyCodes];
    },
    getMeasurementUnits(): Measurement[] {
      return store.getters[PersistentGetters.GetMeasurements];
    },
    getTimezones(): TimeZone[] {
      return store.getters[PersistentGetters.GetTimezones];
    },
    getEventModules(): string[] {
      return store.getters[PersistentGetters.GetEventModules];
    },
    getEventCodes(): string[] {
      return store.getters[PersistentGetters.GetEventCodes];
    },
  };

  async getCurrencyCodes(): Promise<CurrencyCode[]> {
    const response = await axios.get<CurrencyCode[]>(`${BaseUrl}/masters/currency-codes`);
    await store.dispatch(PersistentActions.SetCurrencyCodes, response.data);
    return response.data;
  }

  async getMeasurementUnits(): Promise<Measurement[]> {
    const response = await axios.get<Measurement[]>(`${BaseUrl}/masters/measurement-units`);
    await store.dispatch(PersistentActions.SetMeasurements, response.data);
    return response.data;
  }

  async getTimezones(): Promise<TimeZone[]> {
    const response = await axios.get<TimeZone[]>(`${BaseUrl}/masters/timezones`);
    await store.dispatch(PersistentActions.SetTimezones, response.data);
    return response.data;
  }

  async getEventSources(): Promise<string[]> {
    return axios
      .get<string[]>(`${BaseUrl}/masters/event-sources`)
      .then((response) => response.data);
  }

  async getEventModules(): Promise<string[]> {
    const modules = await axios
      .get<string[]>(`${BaseUrl}/masters/event-modules`)
      .then((response) => response.data.sort());
    await store.dispatch(PersistentActions.SetEventModules, modules);
    return modules;
  }

  async getEventCodes(): Promise<string[]> {
    const response = await axios.get<string[]>(`${BaseUrl}/masters/event-codes`);
    await store.dispatch(PersistentActions.SetEventCodes, response.data);
    return response.data;
  }
}

export const mastersService = new MastersService();
