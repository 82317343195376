// --------------------------------------------------------------------------------
// <copyright file="quotesAbcCustomersGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import moment from 'moment';
import { EChartsOption } from 'echarts';
import { getColor } from '@/utils/color';
import { QuotesAbcCustomersData } from './chartsData';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockQuotesAbcCustomersData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export enum CustomerFilter {
  All = 'all',
  New = 'new',
  BestRates = 'best_rates',
  WorstRates = 'worst_rates',
  Overdue = 'overdue',
}

export default abstract class QuotesAbcCustomersGenerator extends ChartGenerator<
  QuotesAbcCustomersData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    return metricsService.getSMBSMetricsBC<QuotesAbcCustomersData[]>(
      this.procedure,
      this.tenantIdDh,
      { date: moment().format('YYYY-MM-DD'), startDate, endDate },
      this.controller,
    );
  }

  override updateOptions(
    data: QuotesAbcCustomersData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const filter = this.getCustomerFilter(parameters.customerFilter ?? CustomerFilter.All);
    const mappedData = ['A', 'B', 'C'].map((category, i) => {
      const entries = data.filter((entry) => entry.category === category && filter(entry));
      return {
        value: entries.map((entry) => entry.converted_amount).reduce((a, b) => a + b, 0),
        name: category,
        path: category,
        itemStyle: {
          color: getColor(i, 0),
        },
        children: entries.map((entry) => ({
          value: entry.converted_amount,
          name: entry.name,
          selection: {
            id: entry.no_,
            name: entry.name,
          },
          path: `${category}/${entry.name}`,
        })),
      };
    });
    return {
      title: {
        show: false,
      },
      series: [
        {
          name: '/',
          type: 'treemap',
          leafDepth: 3,
          breadcrumb: {
            show: true,
            top: 0,
            bottom: 'auto',
          },
          itemStyle: {
            borderColor: '#fff',
          },
          levels: [
            {
              itemStyle: {
                borderWidth: 0,
                gapWidth: 5,
              },
            },
            {
              itemStyle: {
                gapWidth: 1,
              },
            },
            {
              colorSaturation: [0.35, 0.5],
              itemStyle: {
                gapWidth: 1,
                borderColorSaturation: 0.6,
              },
            },
          ],
          data: mappedData,
        },
      ],
    };
  }

  override getMockData(): QuotesAbcCustomersData[] | null {
    return mockQuotesAbcCustomersData();
  }

  private getCustomerFilter(filter: CustomerFilter): (d: QuotesAbcCustomersData) => boolean {
    switch (filter) {
      case CustomerFilter.New:
        return (d) => d.new_customer;
      case CustomerFilter.BestRates:
        return (d) => d.conversion_rate > 0.75;
      case CustomerFilter.WorstRates:
        return (d) => d.conversion_rate < 0.25;
      case CustomerFilter.Overdue:
        return (d) => d.overdue;
      case CustomerFilter.All:
      default:
        return () => true;
    }
  }
}
