
import { Component, Prop, Ref, Vue } from 'vue-property-decorator';

@Component
export default class AggregateButton extends Vue {
  @Prop({ default: 'is-right' })
  private position!: string;
  @Prop({ default: [] })
  private chosen!: string[];
  @Ref('bDropdown')
  private bDropdown: any;

  private data: string[] = ['Max', 'Min', 'Avg'];

  public toggle() {
    this.bDropdown.toggle();
  }

  private changeToggle(agg: string, payload: boolean) {
    if (payload) {
      this.$emit('input', [...this.chosen, agg]);
    } else {
      this.$emit(
        'input',
        [...this.chosen].filter((other: string) => other !== agg),
      );
    }
  }
}
