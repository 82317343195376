
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { isNil } from '@/utils/misc';
import ActionBubble, { BubbleType } from '../ActionBubble.vue';

@Component({
  components: {
    ActionBubble,
  },
  methods: {
    isNil,
  },
})
export default class TableActionButton2 extends Vue {
  @Prop({ required: true })
  private icon!: string;

  @Prop({ required: true })
  private title!: string;

  @Prop({ default: 'mdi' })
  private iconPack!: string;

  @Prop({ default: false, type: Boolean })
  private disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public hidden!: boolean;

  @Prop()
  private bubble?: string;

  @Prop({ default: 'is-info' })
  private bubbleType!: BubbleType;

  @InjectReactive()
  private shouldUseMenu!: boolean;
}
