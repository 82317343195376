// --------------------------------------------------------------------------------
// <copyright file="aggregates.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

/*
  Saving the array of strings in the widget definitions led
  to reactivity and memory issues, hence it is easier to simply
  encode the selection of the aggregates as a number with this
  bit of bitwise logic
*/
export function arrayToNumber(arr: string[]): number {
  let result: number = 0;
  if (arr.indexOf('Max') !== -1) {
    result += 4;
  }
  if (arr.indexOf('Min') !== -1) {
    result += 2;
  }
  if (arr.indexOf('Avg') !== -1) {
    result += 1;
  }
  return result;
}

export function numberToArray(n: number | undefined) {
  const aggStrings: string[] = [];
  /* eslint-disable no-bitwise */
  if (((n ?? 0) & 4) !== 0) {
    aggStrings.push('Max');
  }
  if (((n ?? 0) & 2) !== 0) {
    aggStrings.push('Min');
  }
  if (((n ?? 0) & 1) !== 0) {
    aggStrings.push('Avg');
  }
  /* eslint-enable no-bitwise */
  return aggStrings;
}
