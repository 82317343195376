// --------------------------------------------------------------------------------
// <copyright file="locations.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Location } from '@/models/location';

class LocationsService {
  create(location: Location) {
    return axios.post(`${BaseUrl}/locations`, location).then((response: any) => {
      return response.data;
    });
  }

  get(tenantId?: number | null): Promise<Location[]> {
    return axios
      .get<Location[]>(`${BaseUrl}/locations/` + (tenantId ? tenantId : ''))
      .then((response: any) => {
        return response.data;
      });
  }

  update(location: Location) {
    return axios.put(`${BaseUrl}/locations`, location).then((response: any) => {
      return response.data;
    });
  }

  delete(id: number) {
    return axios.delete(`${BaseUrl}/locations/${id}`).then((response: any) => {
      return response.data;
    });
  }

  async getApiKey(locationId: number): Promise<string> {
    const response = await axios.get<{ apiKey: string }>(
      `${BaseUrl}/locations/api-key/${locationId}`,
    );
    return response.data.apiKey;
  }
}

export const locationsService = new LocationsService();
