// --------------------------------------------------------------------------------
// <copyright file="laserConsumptionKxSeriesGeneratorHelper.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { powerLevelColors } from '@/utils/color';
import { SeriesOption } from 'echarts';
import { LaserConsumptionTimelineData } from './chartsData';
import { uniq } from '@/utils/array';

export abstract class LaserConsumptionKxSeriesGeneratorHelper {
  protected constructor(
    protected data: LaserConsumptionTimelineData[],
    protected axesIndex: number,
    protected isCategoryAxis: boolean,
  ) {}

  generate(): SeriesOption[] {
    const uniqueDates = uniq(this.data.map((dataItem) => dataItem.bucket));

    return this.getPowerLevels().map((powerLevel: number) => ({
      data: this.getSeriesData(powerLevel, uniqueDates),
      type: 'line',
      name: `k${powerLevel}_consumption`,
      emphasis: { focus: 'series' },
      xAxisIndex: this.axesIndex,
      yAxisIndex: this.axesIndex,
      itemStyle: {
        color: powerLevelColors[powerLevel],
      },
    }));
  }

  private getPowerLevels(): number[] {
    // It sorts as strings by default, use custom comparator
    return uniq(this.data.map((item) => item.kPower)).sort((a, b) => a - b);
  }

  protected abstract getSeriesData(powerLevel: number, uniqueDates: string[]): any[];
}
