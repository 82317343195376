// --------------------------------------------------------------------------------
// <copyright file="salesTopBySalesGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import i18n from '@/i18n';
import { abbreviateNumber } from '@/utils/number';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { SalesTopBySalesData } from './chartsData';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockTopSalespeopleData } from './mockWidgetSelectorData';
import { GeneratorParams } from './generatorParams';
import { metricsService } from '@/services/metrics.service';

export const DEFAULT_TOP_NUMBER = 3;

export default class SalesTopBySalesGenerator extends ChartGenerator<SalesTopBySalesData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
    customerNo?: string,
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];
    const topNumber = params?.topNumber || DEFAULT_TOP_NUMBER;
    const kpiName = this.procedure.replace('_detailed', '');
    return metricsService.getSMBSMetricsBC<SalesTopBySalesData[]>(
      kpiName,
      this.tenantIdDh,
      {
        startDate,
        endDate,
        customerNo,
        paramNumber: topNumber,
      },
      this.controller,
    );
  }

  override updateOptions(
    data: SalesTopBySalesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const customerNo = parameters.customerNo;
    const max = Math.max(
      data
        .map((entry) => entry.total_converted)
        .reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE),
      data.map((entry) => entry.total_sales).reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE),
      !customerNo
        ? data
            .map((entry) => entry.total_invoice)
            .reduce((a, b) => (a > b ? a : b), Number.MIN_VALUE)
        : Number.MIN_VALUE,
    );
    const { length } = data;
    const gridHeight = (2 * 83) / (length + (length % 2));
    return {
      legend: {
        show: true,
        type: 'scroll',
        bottom: 0,
      },
      tooltip: {
        confine: true,
        formatter: (tooltipData: any) => {
          const entry =
            tooltipData.name !== i18n.t('report.others')
              ? data.find((e) => e.Salesperson === tooltipData.name)!
              : data[data.length - 1];
          let tooltip = `
          <div>
            <p>${tooltipData.name}</p>
            <div style="display: flex; flex-direction: row; column-gap: 1em; font-size: 0.9em">
              <div style="flex-grow: 1">
                ${
                  tooltipData.seriesName === i18n.t('report.business_console.converted_amount')
                    ? '<b>'
                    : ''
                }
                ${i18n.t('report.business_console.converted_amount')}
                ${
                  tooltipData.seriesName === i18n.t('report.business_console.converted_amount')
                    ? '</b>'
                    : ''
                }
              </div>
              <div>
                ${i18n.n(entry.total_converted)}
              </div>
            </div>
            <div style="display: flex; flex-direction: row; column-gap: 1em; font-size: 0.9em">
              <div style="flex-grow: 1">
                ${
                  tooltipData.seriesName === i18n.t('report.business_console.sales_amount')
                    ? '<b>'
                    : ''
                }
                ${i18n.t('report.business_console.sales_amount')}
                ${
                  tooltipData.seriesName === i18n.t('report.business_console.sales_amount')
                    ? '</b>'
                    : ''
                }
              </div>
              <div>
                ${i18n.n(entry.total_sales)}
              </div>
            </div>
          `;
          if (!customerNo) {
            tooltip =
              tooltip.concat(`<div style="display: flex; flex-direction: row; column-gap: 1em; font-size: 0.9em">
            <div style="flex-grow: 1">
              ${
                tooltipData.seriesName === i18n.t('report.business_console.invoiced_amount')
                  ? '<b>'
                  : ''
              }
              ${i18n.t('report.business_console.invoiced_amount')}
              ${
                tooltipData.seriesName === i18n.t('report.business_console.invoiced_amount')
                  ? '</b>'
                  : ''
              }
            </div>
            <div>
              ${i18n.n(entry.total_invoice)}
            </div>`);
          }
          return tooltip.concat(`
            </div>
          </div>
          `);
        },
      },
      xAxis: [...new Array(length).keys()].map((n) => ({
        splitNumber: 3,
        interval: (Math.ceil(max / 100) * 100) / 3,
        type: 'value' as const,
        gridIndex: n,
        max,
        axisLabel: {
          show: this.showXAxisLabels(length, n),
          margin: 4,
          formatter: (value: number) => abbreviateNumber(value),
          fontSize: parameters.clientWidth! < 357 ? 10 : 12,
        },
      })),
      yAxis: [...new Array(length).keys()].map((n) => ({
        type: 'category' as const,
        inverse: true,
        data: [n !== length - 1 ? data[n].Salesperson : i18n.t('report.others')],
        gridIndex: n,
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      })),
      grid: [...new Array(length).keys()].map((n) => ({
        top: `${gridHeight * (n % ((length + (length % 2)) / 2))}%`,
        height: `${gridHeight}%`,
        right: n >= length / 2 ? '5%' : '54%',
        left: n < length / 2 ? '5%' : '54%',
      })),
      series: [...new Array(length).keys()].flatMap((n) =>
        [
          {
            name: i18n.t('report.business_console.converted_amount').toString(),
            data: [data[n].total_converted],
            label: {
              position: [5, '50%'],
              verticalAlign: 'middle' as const,
              textBorderWidth: -1,
              formatter: '{b}',
              show: true,
            },
          },
          {
            name: i18n.t('report.business_console.sales_amount').toString(),
            data: [data[n].total_sales],
          },
          !customerNo && {
            name: i18n.t('report.business_console.invoiced_amount').toString(),
            data: [data[n].total_invoice],
          },
        ]
          .filter((d) => !!d)
          .map((d) => ({
            ...d,
            type: 'bar' as const,
            xAxisIndex: n,
            yAxisIndex: n,
            z: 0,
          })),
      ),
    };
  }

  override getMockData(): SalesTopBySalesData[] | null {
    return mockTopSalespeopleData();
  }

  private showXAxisLabels(length: number, index: number): boolean {
    if (length % 2 === 0) {
      return (index + 1) % (length / 2) === 0;
    } else {
      return (index + 1) % Math.ceil(length / 2) === 0 || index + 1 === length;
    }
  }
}
