// --------------------------------------------------------------------------------
// <copyright file="polStatusDueDateGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from './abstract/chartGenerator';
import { FilterTimeAxisSpanEnum } from '../enums/FilterTimeAxisSpanEnum';
import i18n from '@/i18n';
import { poStatusToColor } from '@/utils/color';
import { abbreviateNumber, decimalFormatter } from '@/utils/number';
import { GeneratorParams } from './generatorParams';
import { ManufacturingPOLStatusDueDateData, POLStatus } from './chartsData';
import { EChartsOption, SeriesOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { mockPOLStatusDueDateData } from './mockWidgetSelectorData';
import { metricsService } from '@/services/metrics.service';

export const DEFAULT_DAYS_PER_INTERVAL = 5;

export class POLStatusDueDateGenerator extends ChartGenerator<ManufacturingPOLStatusDueDateData[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const startDate = (timeSpan as [string, string])?.[0];
    const endDate = (timeSpan as [string, string])?.[1];

    return metricsService.getSMBSMetricsSFC<ManufacturingPOLStatusDueDateData[]>(
      this.procedure,
      this.tenantIdDh,
      { paramNumber: params?.daysPerInterval ?? DEFAULT_DAYS_PER_INTERVAL, startDate, endDate },
      this.controller,
    );
  }

  override getMockData(): ManufacturingPOLStatusDueDateData[] | null {
    return mockPOLStatusDueDateData();
  }

  override updateOptions(
    data: ManufacturingPOLStatusDueDateData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        confine: true,
        extraCssText: 'z-index: 1',
        valueFormatter: decimalFormatter,
      },
      legend: {
        bottom: 0,
        type: 'scroll',
      },
      grid: {
        top: 28,
        bottom: 40,
        left: 15,
        right: 8,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        axisLabel: { rotate: 60 },
        // If we don't do this, ECharts shows the labels in the order the ranges
        // appear in the series. It shows the labels from the 1st series, then
        // the 2nd... So the may get unordered.
        data: this.getXAxisRangeLabels(data),
      },
      yAxis: {
        type: 'value',
        name: i18n.t('hours_abbreviation').toString(),
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f4f4f4',
            width: 1,
          },
        },
        axisLabel: {
          formatter: (value: number) => abbreviateNumber(value),
        },
      },
      series: this.series(data),
    };
  }

  private getXAxisRangeLabels(data: ManufacturingPOLStatusDueDateData[]) {
    return this.removeDuplicates(
      data.map((dataItem) => this.generateRangeLabel(dataItem.range_start, dataItem.range_end)),
    );
  }

  private getStatusSeriesData(data: ManufacturingPOLStatusDueDateData[], status: string) {
    return data
      .filter((dataItem) => dataItem.status === status)
      .map((dataItem) => [
        this.generateRangeLabel(dataItem.range_start, dataItem.range_end),
        dataItem.estimated_time_hours,
      ]);
  }

  private generateRangeLabel(start: number, end: number) {
    return start === end ? start.toString() : `[${start}, ${end}]`;
  }

  private series(data: ManufacturingPOLStatusDueDateData[]): SeriesOption[] {
    return this.getStatuses(data).map((status: string) => ({
      name: i18n.t(this.getStatusTranslationKey(status)).toString(),
      type: 'bar',
      stack: status,
      data: this.getStatusSeriesData(data, status),
      itemStyle: {
        color: poStatusToColor(status),
      },
    }));
  }

  private getStatuses(data: ManufacturingPOLStatusDueDateData[]): string[] {
    const statusesFromData = this.removeDuplicates(data.map((dataItem) => dataItem.status));

    return this.getAllStatusesSorted().filter((status) => statusesFromData.indexOf(status) !== -1);
  }

  private getAllStatusesSorted(): string[] {
    return Object.values(POLStatus);
  }

  private getStatusTranslationKey(status: string): string {
    const normalizeStatus = status.toLowerCase().replaceAll(' ', '_').replaceAll(/[()]/g, '');
    return `report.shop_floor_console.${normalizeStatus}`;
  }

  private removeDuplicates(names: string[]) {
    return [...new Set(names)];
  }
}
