// --------------------------------------------------------------------------------
// <copyright file="userType.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export enum UserType {
  None = 0,

  GlobalAdmin = 10,

  SubsidiaryAdmin = 20,
  CustomerAdmin = 25,
  CustomerUser = 26,

  TechnologyUser = 31,

  ServiceGlobal = 40,
  ServiceUser = 41,
}
