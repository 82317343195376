// --------------------------------------------------------------------------------
// <copyright file="subsidiaries.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { Subsidiary } from '@/models/subsidiary';
import { User } from '@/models/user';

export type RestrictedSubsidiary = {
  id: number;
  name: string;
};

class SubsidiariesService {
  async get(): Promise<Subsidiary[]> {
    const response: any = await axios.get<Subsidiary[]>(`${BaseUrl}/subsidiaries`);
    return response.data;
  }

  getById(id: number | null): Promise<Subsidiary> {
    return axios.get<Subsidiary>(`${BaseUrl}/subsidiaries/${id}`).then((response: any) => {
      return response.data;
    });
  }

  getNameById(id: number): Promise<string> {
    return this.getById(id).then((subsidiary) => subsidiary.name);
  }

  async getRestricted(): Promise<RestrictedSubsidiary[]> {
    return await axios
      .get<RestrictedSubsidiary[]>(`${BaseUrl}/subsidiaries/restricted`)
      .then((response) => response.data);
  }

  delete(id: number): Promise<Subsidiary[]> {
    return axios.delete(`${BaseUrl}/subsidiaries/${id}`).then((response: any) => {
      return response.data;
    });
  }

  update(subsidiary: Subsidiary) {
    return axios.put(`${BaseUrl}/subsidiaries/`, subsidiary).then((response: any) => {
      return response.data;
    });
  }

  create(subsidiary: Subsidiary) {
    return axios.post(`${BaseUrl}/subsidiaries/`, subsidiary).then((response: any) => {
      return response.data;
    });
  }

  getUsers(id: number): Promise<User[]> {
    return axios.get<User[]>(`${BaseUrl}/subsidiaries/${id}/users`).then((response) => {
      return response.data.map((user) => User.GetCopy(user));
    });
  }
}

export const subsidiariesService = new SubsidiariesService();
