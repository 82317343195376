// --------------------------------------------------------------------------------
// <copyright file="actions.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ActionTree, ActionContext } from 'vuex';
import { RootMutations } from '@/store/enums';
import RootState from '@/store/rootState';

const actions: ActionTree<RootState, any> = {
  showSpinner: (store: ActionContext<RootState, any>) => {
    store.commit(RootMutations.ShowSpinner);
  },
  removeSpinner: (store: ActionContext<RootState, any>) => {
    store.commit(RootMutations.RemoveSpinner);
  },
  setTermsAcceptedSession: (
    store: ActionContext<RootState, any>,
    termsAcceptedSessionValue: boolean,
  ) => {
    store.commit(RootMutations.SetTermsAcceptedSession, termsAcceptedSessionValue);
  },
  setUserInfoLoaded: (store: ActionContext<RootState, any>, userInfoLoadedValue: boolean) => {
    store.commit(RootMutations.SetUserInfoLoaded, userInfoLoadedValue);
  },
  setCustomers: (store: ActionContext<RootState, any>, customersValue: string) => {
    store.commit(RootMutations.SetCustomers, customersValue);
  },
};

export default actions;
