// --------------------------------------------------------------------------------
// <copyright file="laserUsedVSNotUsedTimesGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import i18n from '@/i18n';
import { decimalFormatter, formatPercentage } from '@/utils/number';
import { LaserUsedVsNotUsedTimesData } from './chartsData';
import { GeneratorParams } from './generatorParams';
import { EChartsOption } from 'echarts';
import { FilterTimeSpanEnum } from '../enums/FilterTimeSpanEnum';
import { metricsService } from '@/services/metrics.service';

export class LaserUsedVSNotUsedTimesGenerator extends ChartGenerator<
  LaserUsedVsNotUsedTimesData[]
> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
  ) {
    const deviceId = selectedDevices[0];
    const dateRange = timeSpan as [string, string];
    return metricsService.getSSCMetrics<LaserUsedVsNotUsedTimesData[]>(
      this.procedure,
      this.tenantIdDh,
      deviceId,
      {
        dateFrom: dateRange[0],
        dateTo: dateRange[1],
      },
      this.controller,
    );
  }

  override updateOptions(
    data: LaserUsedVsNotUsedTimesData[],
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const totalHours = data[0].totalHours;

    return {
      title: {
        text: `${i18n.t('report.total_time', { totalTimeHours: decimalFormatter(totalHours) })}`,
        right: 0,
        textStyle: {
          fontSize: parameters.clientWidth! < 332 ? 12 : 16,
          fontWeight: 'normal',
        },
      },
      legend: {
        bottom: 0,
      },
      tooltip: {
        valueFormatter: (value: any) => formatPercentage((value / totalHours) * 100),
      },
      series: [
        {
          type: 'pie',
          radius: ['18%', '68%'],
          top: '-15%',
          emphasis: {
            focus: 'self',
          },
          label: {
            formatter: (params: any) => `${decimalFormatter(params.data.value)} h`,
            position: 'inside',
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              name: i18n.t('report.used').toString(),
              value: data[0].cuttingHours,
            },
            {
              name: i18n.t(`report.not_used`).toString(),
              value: data[0].totalHours - data[0].cuttingHours,
            },
          ],
        },
      ],
    };
  }
}
