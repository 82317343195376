// --------------------------------------------------------------------------------
// <copyright file="device.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { DeviceStatus } from './deviceStatus';
import { MachineType } from './enums/MachineType';
import { DeviceStatusEnum } from '@/models/enums/DeviceStatusEnum';

export class Device {
  constructor(
    public id: number,
    public deviceId: string,
    public name: string,
    public tenantId: number,
    public tenantName: string,
    public deviceBaseModelId: string,
    public deviceBaseModelName: string,
    public ip: string,
    public equipmentId: string,
    public hasWCC: boolean,
    public locationId: number,
    public hasCare: boolean,
    public hasTechnology: boolean,
    public billable = true,
    public cameraIp?: string,
    public sscIp?: string,
    public startDate?: Date,
    public endDate?: Date | null,
    public technologyStartDate?: Date,
    public technologyEndDate?: Date | null,
    public careStartDate?: Date,
    public careEndDate?: Date | null,
    public status?: DeviceStatus,
    public shiftModelId?: number | null,
  ) {
    this.fixInconsistencies();
  }

  static GetEmpty(): Device {
    return new Device(0, '', '', 0, '', '', '', '', '', false, 0, false, false, true, '', '');
  }

  static GetCopy(original: Device): Device {
    return new Device(
      original.id,
      original.deviceId,
      original.name,
      original.tenantId,
      original.tenantName,
      original.deviceBaseModelId,
      original.deviceBaseModelName,
      original.ip,
      original.equipmentId,
      original.hasWCC,
      original.locationId,
      original.hasCare,
      original.hasTechnology,
      original.billable,
      original.cameraIp,
      original.sscIp,
      original.startDate,
      original.endDate,
      original.technologyStartDate,
      original.technologyEndDate,
      original.careStartDate,
      original.careEndDate,
      original.status,
      original.shiftModelId,
    );
  }

  static GetMachineType(device: Device): MachineType {
    return this.GetMachineTypeById(device.deviceId);
  }

  static GetMachineTypeById(deviceId: string) {
    switch (deviceId.substring(0, 2)) {
      case 'L-':
        return MachineType.Cut;
      case 'B-':
        return MachineType.Bend;
      case 'F-':
        return MachineType.FactoryOverview;
      case 'T-':
        return MachineType.Tube;
      default:
        return MachineType.Undefined;
    }
  }

  static GetHubDeviceIdFromEntityDeviceId(id: number, allDevices: Device[]) {
    return allDevices.find((d) => d.id === id)?.deviceId;
  }

  private fixInconsistencies() {
    if (!this.hasWCC) {
      this.cameraIp = undefined;
      this.startDate = undefined;
      this.endDate = undefined;
    }

    if (!this.hasTechnology) {
      this.technologyStartDate = undefined;
      this.technologyEndDate = undefined;
    }

    if (!this.hasCare) {
      this.careStartDate = undefined;
      this.careEndDate = undefined;
    }
  }

  getType(): MachineType {
    return Device.GetMachineTypeById(this.deviceId);
  }

  isCut(): boolean {
    return this.getType() === MachineType.Cut;
  }

  isBend(): boolean {
    return this.getType() === MachineType.Bend;
  }

  isTube(): boolean {
    return this.getType() === MachineType.Tube;
  }

  setStatusToNotAvailable() {
    this.status = new DeviceStatus(this.deviceId, DeviceStatusEnum.None, 'n/a', 'n/a');
  }
}
