
import { Component, Prop, Vue } from 'vue-property-decorator';
import { emptyPaged, Paged } from '@/services/pagination';
import moment from 'moment';
import { EventData, ImportantMessageLevel } from '@/models/Charts/chartsData';
import EventsTableExpandedView from '@/components/careConsole/EventsTableExpandedView.vue';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { addDataTestIdAttributeToTh } from '@/utils/table';
import { SortOrder } from '@/models/enums/SortOrder';

@Component({
  methods: { addDataTestIdAttributeToTh },
})
export default class EventsTable extends Vue {
  @Prop({ required: true, default: () => emptyPaged<EventData>() })
  private pagedEvents!: Paged<EventData>;

  @Prop({ required: true })
  private deviceId!: string;

  @Prop({ required: true })
  private dateRange!: [string, string];

  @Prop({ default: FilterTimeAxisSpanEnum.Day })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  @Prop({ default: () => [] })
  private modules!: string[];

  @Prop({ default: () => [] })
  private eventTypes!: ImportantMessageLevel[];

  @Prop({ default: () => [] })
  private eventCodes!: string[];

  @Prop({ default: 10 })
  private rowsPerPage!: number;

  /** Shows the expand button to open the table in a modal, if true. */
  @Prop({ default: false, type: Boolean })
  private expandable!: boolean;

  @Prop({ default: 'timestamp' })
  private defaultSortField!: string;

  @Prop({ default: SortOrder.Descending })
  private defaultSortOrder!: SortOrder;

  @Prop({ default: false, type: Boolean })
  private loading!: boolean;

  private sortField = 'timestamp';
  private sortOrder: SortOrder = SortOrder.Descending;

  private page = 1;

  private mounted() {
    this.sortField = this.defaultSortField;
    this.sortOrder = this.defaultSortOrder;
  }

  private async onPageChanged(page: number) {
    this.page = page;
    this.$emit('page-change', page);
  }

  private onExpandClicked() {
    this.$buefy.modal.open({
      parent: this,
      component: EventsTableExpandedView,
      hasModalCard: true,
      trapFocus: true,
      width: '98vw',
      canCancel: ['escape', 'outside'],
      props: {
        deviceId: this.deviceId,
        selectedTimeFilter: this.dateRange,
        dateGrouping: this.dateGrouping,
        defaultSortField: this.sortField,
        defaultSortOrder: this.sortOrder,
        paramValues: {
          eventModules: this.modules,
          eventTypes: this.eventTypes,
          eventCodes: this.eventCodes,
        },
      },
    });
  }

  private get isGroupingByDate(): boolean {
    return this.dateGrouping !== FilterTimeAxisSpanEnum.None;
  }

  private formatTimestamp(isoDate: string) {
    switch (this.dateGrouping) {
      case FilterTimeAxisSpanEnum.None:
        return moment(isoDate).format('YYYY-MM-DD, HH:mm:ss');
      case FilterTimeAxisSpanEnum.Hour:
        return moment(isoDate).format('YYYY-MM-DD, HH:mm');
      default:
        // The format is already ok for the rest of groupings and no time zone
        // change is needed either.
        return isoDate;
    }
  }

  private formatFirstLastTimestamp(isoDate: string) {
    return moment(isoDate).format('YYYY-MM-DD, HH:mm:ss');
  }

  private onSort(sortField: string, sortOrder: SortOrder) {
    this.sortField = sortField;
    this.sortOrder = sortOrder;
    this.$emit('sort', sortField, sortOrder);
  }
}
