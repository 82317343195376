// --------------------------------------------------------------------------------
// <copyright file="modal.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2023
// </copyright>
// --------------------------------------------------------------------------------

import { ModalProgrammatic as Modal } from 'buefy';
import { BModalComponent, BModalConfig } from 'buefy/types/components';

class ModalService {
  open(params: BModalConfig): BModalComponent {
    return Modal.open({
      hasModalCard: true,
      trapFocus: true,
      ...params,
    });
  }
}

export const modalService = new ModalService();
