// --------------------------------------------------------------------------------
// <copyright file="shiftModels.service.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import axios from 'axios';
import { BaseUrl } from '@/models/constants';
import { ShiftModel } from '@/models/shiftModel';
import { shiftsService } from '@/services/shifts.service';

class ShiftModelsService {
  get(tenantId?: number | null): Promise<ShiftModel[]> {
    return axios
      .get<ShiftModel[]>(`${BaseUrl}/shift-models/` + (tenantId ? tenantId : ''))
      .then((response) => response.data);
  }

  create(shiftModel: ShiftModel): Promise<void> {
    return axios.post(`${BaseUrl}/shift-models/`, shiftModel);
  }

  update(shiftModel: ShiftModel): Promise<void> {
    return axios.put(`${BaseUrl}/shift-models/`, shiftModel);
  }

  delete(id: number): Promise<void> {
    return axios.delete(`${BaseUrl}/shift-models/${id}`);
  }

  // Temporary. To be removed once we have Device.shiftModelId
  getIdByDeviceId(deviceId: string): number {
    return shiftsService.store.getAllByDeviceIds([deviceId])[0]?.shiftModelId;
  }
}

export const shiftModelsService = new ShiftModelsService();
