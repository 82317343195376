/*
 * --------------------------------------------------------------------------------
 * <copyright file="eventsTableRetriever.ts" company="Bystronic Laser AG">
 *  Copyright (C) Bystronic Laser AG 2021-2024
 * </copyright>
 * --------------------------------------------------------------------------------
 */

import { DataRetriever, ProcedureName } from '../abstract/chartGenerator';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { metricsService } from '@/services/metrics.service';
import { Paged } from '@/services/pagination';
import { EventData } from '@/models/Charts/chartsData';
import moment from 'moment/moment';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { SortOrder } from '@/models/enums/SortOrder';

export class EventsTableRetriever extends DataRetriever<Paged<EventData>> {
  constructor(procedure: ProcedureName) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum | undefined,
    params?: { [key: string]: any } | undefined,
    customerNo?: string | undefined,
  ): Promise<Paged<EventData> | null> {
    const dateFrom = (timeSpan as [string, string])?.[0];
    const dateTo = (timeSpan as [string, string])?.[1];

    return metricsService.getSSCMetrics<Paged<EventData>>(
      this.procedure,
      params?.customerIdDh,
      selectedDevices[0],
      {
        dateFrom: moment(dateFrom).local().format('YYYY-MM-DDTHH:mm:ss'),
        dateTo: moment(dateTo).local().format('YYYY-MM-DDTHH:mm:ss'),
        modules: params?.modules,
        dateGrouping: timeAxisSpan,
        messageLevel: metricsService.getEventTypeParameter(params?.eventTypes),
        eventCodes: params?.eventCodes,
        pageNumber: params?.page ?? 1,
        rowsPerPage: params?.rowsPerPage ?? 10,
        sortField: params?.sortField ?? 'timestamp',
        sortOrder: params?.sortOrder ?? SortOrder.Descending,
      },
      this.controller,
    );
  }
}
