
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ClickMenu from '@/components/menu/ClickMenu.vue';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { translateFilterTimeAxisSpanEnum } from '@/utils/i18n';

@Component({
  methods: {
    translateFilterTimeAxisSpanEnum,
  },
  components: {
    'click-menu': ClickMenu,
  },
})
export default class DateGroupingFilter extends Vue {
  @Prop({ default: FilterTimeAxisSpanEnum.Day })
  private value!: FilterTimeAxisSpanEnum;

  @Prop({ default: 'is-bottom-left' })
  private position!: string;

  @Prop({ default: false, type: Boolean })
  private hasNoneOption!: boolean;

  @Prop({ default: false, type: Boolean })
  private hasHourOption!: boolean;

  private mounted() {
    if (
      (!this.hasNoneOption && this.value === FilterTimeAxisSpanEnum.None) ||
      (!this.hasHourOption && this.value === FilterTimeAxisSpanEnum.Hour)
    ) {
      this.$emit('input', FilterTimeAxisSpanEnum.Day);
    }
  }

  @Watch('hasNoneOption')
  private watchHasNoneOption() {
    if (!this.hasNoneOption && this.value === FilterTimeAxisSpanEnum.None) {
      this.$emit('input', FilterTimeAxisSpanEnum.Day);
    }
  }

  @Watch('hasHourOption')
  private watchHasHourOption() {
    if (!this.hasHourOption && this.value === FilterTimeAxisSpanEnum.Hour) {
      this.$emit('input', FilterTimeAxisSpanEnum.Day);
    }
  }

  private get ids(): FilterTimeAxisSpanEnum[] {
    return Object.values(FilterTimeAxisSpanEnum)
      .filter((value) => !isNaN(Number(value)))
      .filter(
        (value) =>
          value !== FilterTimeAxisSpanEnum.None ||
          (value === FilterTimeAxisSpanEnum.None && this.hasNoneOption),
      )
      .filter(
        (value) =>
          value !== FilterTimeAxisSpanEnum.Hour ||
          (value === FilterTimeAxisSpanEnum.Hour && this.hasHourOption),
      )
      .map(Number);
  }

  private get names(): string[] {
    return this.ids.map((id) => translateFilterTimeAxisSpanEnum(id));
  }
}
