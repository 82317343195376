
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import WidgetContainer from '@/components/Charts/widgetContainer/WidgetContainer.vue';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { availableDashboardLayouts, DashboardLayout } from '@/models/Charts/dashboardDefinition';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { dateRangeToDateStrings } from '@/utils/dates';

@Component({
  components: {
    WidgetContainer,
    LayoutGrid,
  },
})
export default class Temperatures extends Vue {
  @Prop({ required: true })
  private deviceId!: string;

  @Prop({ required: true })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private dateGrouping!: FilterTimeAxisSpanEnum;

  private gridLayout = availableDashboardLayouts[DashboardLayout.OneByOne];
  private widgetDefinition: WidgetDefinition | null = null;

  private mounted() {
    this.createWidgetDefinition();
  }

  @Watch('deviceId')
  @Watch('dateRange')
  @Watch('dateGrouping')
  private createWidgetDefinition() {
    if (this.widgetDefinition === null) {
      this.widgetDefinition = new WidgetDefinition(
        WidgetEnum.TechnologyTemperatures,
        this.deviceId!,
        this.stringDateRange,
        [],
        undefined,
        this.dateGrouping,
      );
    } else {
      this.widgetDefinition = this.widgetDefinition.getCopy({
        deviceId: this.deviceId!,
        timeFilter: this.stringDateRange,
        axisSpan: this.dateGrouping,
      });
    }
  }

  private get stringDateRange(): [string, string] {
    return dateRangeToDateStrings(this.dateRange);
  }

  private onWidgetDefinitionChange(widgetDefinition: WidgetDefinition) {
    this.widgetDefinition = widgetDefinition;
  }
}
