
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';

@Component({})
export default class DevicesListSelector extends Vue {
  @Prop({ default: () => [] })
  private value!: Device[];

  @Prop({ default: () => [] })
  private selectedTenantIds!: number[];

  private devices: Device[] = [];
  private selectedDevices: Device[] = [];

  private loading = false;

  private mounted() {
    this.selectedDevices = this.value;
  }

  async refreshDevices() {
    this.loading = true;
    this.devices = await devicesService.getDevicesFromTenants(this.selectedTenantIds);
    this.loading = false;
  }

  @Watch('selectedDevices')
  private onDevicesSelectionChanged() {
    this.$emit('input', this.selectedDevices);
  }

  private getDeviceBaseModelName(deviceBaseModelId: string): string {
    return devicesService.store.getDeviceBaseModelName(deviceBaseModelId);
  }
}
