// --------------------------------------------------------------------------------
// <copyright file="location.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export class Location {
  static GetEmpty(): Location {
    return new Location(0, '', 0, '', '', '', false);
  }

  static GetCopy(original: Location): Location {
    return new Location(
      original.id,
      original.name,
      original.tenantId,
      original.address,
      original.zipCode,
      original.country,
      original.hasApiKey,
    );
  }

  constructor(
    public id: number,
    public name: string,
    public tenantId: number,
    public address: string,
    public zipCode: string,
    public country: string,
    public hasApiKey: boolean,
  ) {}
}
