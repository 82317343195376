
import { ChildUpdater, ParamConfiguration, Params, ParamValue } from '@/models/enums/WidgetEnum';
import NumberParam from './threeDotParams/NumberParam.vue';
import RadioParam from './threeDotParams/RadioParam.vue';
import ListParam from './threeDotParams/ListParam.vue';
import SliderParam from './threeDotParams/SliderParam.vue';
import { Component, Inject, Prop, Ref, Vue } from 'vue-property-decorator';
import WidgetDefinition from '@/models/Charts/widgetDefinition';

@Component({
  components: {
    NumberParam,
    RadioParam,
    ListParam,
    SliderParam,
    ThreeDotParamEntry: () => import('./ThreeDotParamEntry.vue'),
  },
})
export default class ThreeDotParamEntry extends Vue {
  @Prop({ required: true })
  private params!: Params;

  @Prop({ required: true })
  private paramValues!: { [key: string]: any };

  @Prop({ default: 'is-right' })
  private position!: string;

  @Inject('widgetDefinition')
  public widgetDefinition!: WidgetDefinition;

  @Ref('paramDropdown')
  private paramDropdown: any;

  private children: Record<string, Record<string, ParamConfiguration>> = {};

  private async mounted() {
    const children: Record<string, Params> = {};
    for (const [paramName, paramConfig] of this.parameters) {
      if (!paramConfig.children) {
        continue;
      }
      await this.getChildrenParamConfigurations(
        paramConfig.children,
        this.widgetDefinition.paramValues[paramName] ?? paramConfig.defaultValue!,
      ).then((childrenParamConfigs) => {
        children[paramName] = childrenParamConfigs;
      });
    }
    this.children = children;
  }

  private async onUpdate(
    paramName: string,
    paramConfig: ParamConfiguration,
    param: { [key: string]: any },
  ) {
    if (!!paramConfig.children) {
      Object.entries(param).forEach(([name, value]) => this.$set(this.paramValues, name, value));
      await this.getChildrenParamConfigurations(paramConfig.children, param[paramName]).then(
        (childrenParamConfigs) => {
          this.$set(this.children, paramName, childrenParamConfigs);
        },
      );
    } else {
      this.$emit('update', param);
    }
  }

  private async getChildrenParamConfigurations(
    children: Record<string, ChildUpdater<any>>,
    parentParamValue: ParamValue,
  ): Promise<Params> {
    const childrenParamConfigs: Record<string, ParamConfiguration> = {};
    for (const [childName, childParamConfig] of Object.entries(children)) {
      childrenParamConfigs[childName] = await childParamConfig(
        parentParamValue,
        this.widgetDefinition,
      );
    }
    return childrenParamConfigs;
  }

  private get parameters() {
    return Object.entries(this.params ?? {});
  }

  public toggleParams(): void {
    this.paramDropdown.toggle();
  }
}
