
import { Component, Prop, Vue } from 'vue-property-decorator';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import WidgetContainer from '../Charts/widgetContainer/WidgetContainer.vue';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { dateRangeToDateStrings } from '@/utils/dates';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import HealthIndicatorsContainer from '@/components/careConsole/HealthIndicatorsContainer.vue';
import LayoutGrid from '@/views/dashboard/LayoutGrid.vue';
import { LayoutSlot } from '@/views/dashboard/layout-grid';

@Component({
  components: {
    HealthIndicatorsContainer,
    WidgetContainer,
    LayoutGrid,
  },
})
export default class Health extends Vue {
  @Prop({ required: true })
  private dateRange!: [Date, Date];

  @Prop({ required: true })
  private timeAxisSpan!: FilterTimeAxisSpanEnum;

  @Prop({ required: true })
  private deviceId!: string;

  private gridLayout: LayoutSlot[] = [
    { column: 0, row: 0, width: 8, height: 8 },
    { column: 8, row: 0, width: 4, height: 8 },
  ];

  private get healthTimelineWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareHealthTimeline,
      this.deviceId!,
      dateRangeToDateStrings(this.dateRange),
      [],
      undefined,
      this.timeAxisSpan,
    ).hideTitle();
  }

  private get healthIndicatorsWidgetDefinition(): WidgetDefinition {
    return new WidgetDefinition(
      WidgetEnum.CareHealthIndicators,
      this.deviceId!,
      dateRangeToDateStrings(this.dateRange),
    ).hideTitle();
  }
}
