
import {
  DataPlatformActionsDetailsStatusInfo,
  DataPlatformActionsDetailsTableData,
  DataPlatformTableEntry,
} from '@/models/dataPlatform';
import { DataPlatformActionEnum } from '@/models/enums/DataPlatformEnum';
import { dataPlatformService } from '@/services/dataplatform.service';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DataPlatformActionsDetailsModal extends Vue {
  @Prop({ required: true })
  private dataPlatform!: DataPlatformTableEntry;

  private data: DataPlatformActionsDetailsTableData[] = [];

  private mounted() {
    this.loadData();
  }

  private async loadData() {
    this.data = await dataPlatformService.getActionsDetails(this.dataPlatform?.id ?? 0);
  }

  private formatTime(date: Date | null): string {
    if (date === null) {
      return '';
    }
    return moment(date).local().format('YYYY-MM-DD, HH:mm:ss');
  }

  sortByStatus(
    a: DataPlatformActionsDetailsTableData,
    b: DataPlatformActionsDetailsTableData,
    isAsc: boolean,
  ) {
    const a_text = this.getActionStatus(a).text;
    const b_text = this.getActionStatus(b).text;
    return isAsc ? a_text.localeCompare(b_text) : b_text.localeCompare(a_text);
  }

  getActionStatus(
    actionDetail: DataPlatformActionsDetailsTableData,
  ): DataPlatformActionsDetailsStatusInfo {
    if (actionDetail.executed) {
      if (actionDetail.errorMessage) {
        return {
          text: this.$t('dataplatforms.modal_actions.result_ko').toString(),
          class: 'is-danger',
          errorMessage: actionDetail.errorMessage,
        };
      }
      return {
        text: this.$t('dataplatforms.modal_actions.result_ok').toString(),
        class: 'is-success',
        errorMessage: '-',
      };
    }
    return {
      text: this.$t('dataplatforms.modal_actions.result_pending').toString(),
      class: 'is-warning',
      errorMessage: '',
    };
  }

  getActionName(action: DataPlatformActionEnum): string {
    switch (action) {
      case DataPlatformActionEnum.Restart:
        return this.$t('dataplatforms.actions.restart').toString();
      default:
        return this.$t('dataplatforms.modal.unknown').toString();
    }
  }
}
