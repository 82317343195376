var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-dropdown',{ref:"dropdown",staticClass:"date-time-range-picker",attrs:{"position":"is-bottom-left","can-close":_vm.canCloseRangeDropdown,"aria-role":"dialog","trigger-tabindex":-1},on:{"active-change":function($event){return _vm.onDropdownOpenStatusChanged($event)}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-input',{staticClass:"trigger",attrs:{"autocomplete":"off","value":_vm.formattedValue,"placeholder":_vm.$t('date_time_range_picker.placeholder'),"size":"is-small","icon":"calendar-range","readonly":"","use-html5-validation":false}})]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"focusable":false,"custom":"","paddingless":""}},[_c('validation-observer',{ref:"validationObserver",staticClass:"dropdown-content-wrapper",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, invalid }){return [_c('validation-provider',{ref:"startDateTimeValidationProvider",attrs:{"vid":"startDateTime","rules":_vm.validationRules.startDateTime,"name":"date_time_range_picker.start_label"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, touched }){return [_c('b-field',{attrs:{"label":_vm.$t('date_time_range_picker.start_label'),"label-for":`start-date-time-${_vm.componentId}`,"type":{ 'is-danger': errors[0] && touched },"horizontal":""}},[_c('b-datetimepicker',{ref:"startDateTimePicker",staticClass:"start-date-time date-time-picker",class:_vm.granularityCSSClasses,attrs:{"placeholder":_vm.$t('date_time_range_picker.start_placeholder'),"icon":"calendar-today","datepicker":{
              id: `start-date-time-${_vm.componentId}`,
              'nearby-selectable-month-days': true,
            },"timepicker":{ enableSeconds: _vm.hasSeconds },"size":"is-small","mobile-native":false},on:{"input":function($event){return _vm.resetValidation()},"active-change":function($event){return _vm.onDateTimePickerDropdownOpenStatusChanged($event)}},model:{value:(_vm.startDateTime),callback:function ($$v) {_vm.startDateTime=$$v},expression:"startDateTime"}})],1)]}}],null,true)}),_c('validation-provider',{ref:"endDateTimeValidationProvider",attrs:{"vid":"endDateTime","rules":_vm.validationRules.endDateTime,"name":"date_time_range_picker.end_label"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, touched }){return [_c('b-field',{attrs:{"label":_vm.$t('date_time_range_picker.end_label'),"label-for":`end-date-time-${_vm.componentId}`,"type":{ 'is-danger': errors[0] && touched },"horizontal":""}},[_c('b-datetimepicker',{ref:"endDateTimePicker",staticClass:"end-date-time date-time-picker",class:_vm.granularityCSSClasses,attrs:{"placeholder":_vm.$t('date_time_range_picker.end_placeholder'),"icon":"calendar-today","datepicker":{
              id: `end-date-time-${_vm.componentId}`,
              'nearby-selectable-month-days': true,
            },"timepicker":{ enableSeconds: _vm.hasSeconds },"size":"is-small","mobile-native":false},on:{"input":function($event){return _vm.resetValidation()},"active-change":function($event){return _vm.onDateTimePickerDropdownOpenStatusChanged($event)}},model:{value:(_vm.endDateTime),callback:function ($$v) {_vm.endDateTime=$$v},expression:"endDateTime"}})],1)]}}],null,true)}),(invalid)?_c('div',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.$t('date_time_range_picker.start_before_end_validation_error'))+" ")]):_vm._e(),_c('div',{staticClass:"actions"},[_c('b-button',{attrs:{"type":"is-success","disabled":invalid,"size":"is-small"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.onAcceptClicked)}}},[_vm._v(" "+_vm._s(_vm.$t('action.accept'))+" ")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }