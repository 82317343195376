// --------------------------------------------------------------------------------
// <copyright file="laserOutputAndScrapTimelineGenerator.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { ChartGenerator, ProcedureName } from '@/models/Charts/abstract/chartGenerator';
import { EChartsOption } from 'echarts';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { LaserOutputAndScrapTimelineChartMode } from '@/models/enums/LaserOutputAndScrapTimelineChartMode';
import { MultiLineChartGenerator } from './multiLineChartGenerator';
import { RawMaterialUtilizationTimelineGenerator } from './rawMaterialUtilizationTimelineGenerator';
import { WidgetEnum } from '../enums/WidgetEnum';
import { GeneratorParams } from './generatorParams';
import { mockLaserOutputScrapHistData } from './mockWidgetSelectorData';
import { LaserOutputScrapHistoricalData } from './chartsData';

export class LaserOutputAndScrapTimelineGenerator extends ChartGenerator<any[]> {
  constructor(procedure: ProcedureName, public tenantIdDh: number) {
    super(procedure);
  }

  override getData(
    selectedDevices: string[],
    selectedShifts: number[],
    timeSpan: FilterTimeSpanEnum | [string, string],
    timeAxisSpan?: FilterTimeAxisSpanEnum,
    params?: { [key: string]: any },
  ) {
    const generator = this.getGeneratorForMode(
      params?.mode ?? LaserOutputAndScrapTimelineChartMode.NonStackedLines,
    );
    return generator.getData(selectedDevices, selectedShifts, timeSpan, timeAxisSpan);
  }

  override updateOptions(
    data: any,
    parameters: GeneratorParams = {},
    prevOptions?: EChartsOption,
  ): EChartsOption {
    const generator = this.getGeneratorForMode(
      parameters.paramValues?.mode ?? LaserOutputAndScrapTimelineChartMode.NonStackedLines,
    );
    return generator.updateOptions(data, parameters, prevOptions);
  }

  override getMockData(): LaserOutputScrapHistoricalData[] | null {
    return mockLaserOutputScrapHistData();
  }

  private getGeneratorForMode(mode: LaserOutputAndScrapTimelineChartMode): ChartGenerator<any> {
    switch (mode) {
      case LaserOutputAndScrapTimelineChartMode.NonStackedLines:
      case LaserOutputAndScrapTimelineChartMode.StackedColumns:
        return new MultiLineChartGenerator(this.procedure, this.tenantIdDh);
      case LaserOutputAndScrapTimelineChartMode.RawMaterialUtilization:
        return new RawMaterialUtilizationTimelineGenerator(this.procedure, this.tenantIdDh);
      default:
        return new MultiLineChartGenerator(WidgetEnum.LaserOutputAndScrapTimeline, this.tenantIdDh);
    }
  }
}
