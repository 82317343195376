// --------------------------------------------------------------------------------
// <copyright file="dates.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import moment from 'moment';
import { FilterTimeAxisSpanEnum } from '@/models/enums/FilterTimeAxisSpanEnum';
import i18n from '@/i18n';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';

/** Returns if value is a Date instance with a valid value. */
export function isDate(value: any): value is Date {
  return value instanceof Date && !isNaN(value.getTime());
}

export function endOfYear(): Date {
  return moment().endOf('year').toDate();
}

export function yearToDate(): [Date, Date] {
  return [moment().startOf('year').toDate(), new Date()];
}

export function today(): Date {
  return moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
}

export function dateRangeToDateStrings(dateRange: [Date, Date]): [string, string] {
  const transform = (date: Date) =>
    moment(date).subtract(date.getTimezoneOffset(), 'minutes').format('YYYY-MM-DD');
  return [transform(dateRange[0]), transform(dateRange[1])];
}

/** Converts a Date into a ISO string with just the date part (YYYY-MM-DD). */
export function dateToShortISOString(date: Date): string {
  return moment(date).format('YYYY-MM-DD');
}

export function groupByDate(
  data: any[],
  dates: string[],
  groupingFunction: (groupObject: any, currentDataItem: any) => any,
  dateField = 'date',
) {
  return dates.map((date) => {
    const groupValues = data
      .filter((dataItem) => dataItem[dateField] === date)
      .reduce(groupingFunction, {});

    return {
      date,
      ...groupValues,
    };
  });
}

export function translateDateGrouping(dateGrouping: FilterTimeAxisSpanEnum): string {
  const dateGroupingKey = FilterTimeAxisSpanEnum[dateGrouping];
  return i18n.t(`timespans.${dateGroupingKey.toLowerCase()}`).toString();
}

export function lastDayOfNextMonth(date: Date): Date {
  date = moment(date).add(1, 'month').endOf('month').toDate();
  // Directly returning the date from m.toDate() would somehow make the
  // datepicker show the next day as the minimum day
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function formatDuration(durationSeconds: number): string {
  const duration = moment.duration(durationSeconds, 'seconds');
  if (duration.months() > 0) {
    return convertSecondsToMonthsDaysHours(duration);
  } else if (duration.days() > 0) {
    return convertSecondsToDaysHoursMinutes(duration);
  } else if (duration.hours() > 0) {
    return moment.unix(durationSeconds).utcOffset(0).format('H[h] m[m]');
  } else if (duration.minutes() > 0) {
    return moment.unix(durationSeconds).format('m[m] s[s]');
  } else {
    return moment.unix(durationSeconds).format('s[s]');
  }
}

export function convertSecondsToDaysHoursMinutes(duration: moment.Duration): string {
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  return `${days}d ${hours}h ${minutes}m`;
}

function convertSecondsToMonthsDaysHours(duration: moment.Duration): string {
  return `${duration.months()}M ${duration.days()}d ${duration.hours()}h`;
}

export function getMaxSecondsByTimeFilter(timeFilter: FilterTimeSpanEnum): number {
  const SECONDS_IN_A_DAY = 24 * 60 * 60;
  switch (timeFilter) {
    case FilterTimeSpanEnum.Day:
      return SECONDS_IN_A_DAY;
    case FilterTimeSpanEnum.Week:
      return SECONDS_IN_A_DAY * 7;
    case FilterTimeSpanEnum.Month:
      return moment().diff(moment().subtract(1, 'month'), 'seconds');
    case FilterTimeSpanEnum.Year:
      return moment().diff(moment().subtract(1, 'year'), 'seconds');
    default:
      return 0;
  }
}
