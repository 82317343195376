// --------------------------------------------------------------------------------
// <copyright file="layout-grid.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

export const TOTAL_ROWS = 8;
export const TOTAL_COLUMNS = 12;

export interface LayoutSlot {
  row: number;
  column: number;
  width: number;
  height: number;
}
