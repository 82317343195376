// --------------------------------------------------------------------------------
// <copyright file="matchers.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { usersService } from '@/services/users.service';
import { getFieldPathValue, isEmpty, valueOrDefault } from '@/utils/misc';
import { matchIgnoringCase } from '@/utils/table';
import { SubscribedUser } from './model';
import i18n from '@/i18n';

export type FilterMatcher = (row: any, filterValue: any) => boolean;

type TypeWithSubscribedUsers = {
  subscribedUsers: SubscribedUser[];
};

export function isCurrentUserSubscribed(
  row: TypeWithSubscribedUsers,
  filterValue: boolean,
): boolean {
  if (!filterValue) {
    return true;
  }
  return row.subscribedUsers.some((user) => user.id === usersService.store.current().id);
}

export function createTextListMatcher<T>(fieldPath: string): FilterMatcher {
  return (row: T, filterValue: string[]) =>
    textListMatches(
      getFieldPathValue(row, fieldPath) as string,
      filterValue,
    );
}

export function createTextListModelMatcher<T>(fieldPath: string): FilterMatcher {
  return (row: T, filterValue: string[]) => {
    const displayedModel = valueOrDefault(
      getFieldPathValue(row, fieldPath) as string,
      i18n.t('e_service_center.equipment_list.unknown_model').toString(),
    );
    return textListMatches(
      displayedModel,
      filterValue,
    );
  };
}

export function createTextListMatcherForEnum<T>(
  fieldPath: string,
  enumTranslator: (enumValue: any) => string,
): FilterMatcher {
  return (row: T, filterValue: string[]) =>
    textListMatches(
      enumTranslator(getFieldPathValue(row, fieldPath)),
      filterValue,
    );
}

export function containsSubscribers(row: TypeWithSubscribedUsers, filterValue: string[]): boolean {
  if (isEmpty(filterValue)) {
    return true;
  }

  const subscribedUserNames = row.subscribedUsers.map((subscribedUser) => subscribedUser.name);

  return filterValue.some((value) =>
    subscribedUserNames.some((name) => matchIgnoringCase(name, value)),
  );
}

function textListMatches(rowValue: string, filterValue: string[]): boolean {
  if (isEmpty(filterValue)) {
    return true;
  }

  return filterValue.some((value: string) => matchIgnoringCase(rowValue, value));
}

export function hasSubscriptions(row: TypeWithSubscribedUsers, filterValue: boolean): boolean {
  if (!filterValue) {
    return true;
  }
  return isEmpty(row.subscribedUsers);
}
