
import { Component, Vue } from 'vue-property-decorator';
import { Subsidiary } from '../../models/subsidiary';
import { subsidiariesService } from '@/services/subsidiaries.service';

@Component
export default class SubsidiaryCreationModal extends Vue {
  private subsidiary: Subsidiary = Subsidiary.GetEmpty();

  private isSubmitting = false;

  private handleChange(event: any): void {
    this.subsidiary = { ...this.subsidiary, [event.target.name]: event.target.value };
  }

  private get isEnabled() {
    if (this.subsidiary && this.$refs.form && this.subsidiary.code > 0) {
      const refForm = this.$refs.form as any;
      return refForm.checkValidity();
    }
    return false;
  }

  private async onSubmit() {
    this.isSubmitting = true;
    subsidiariesService
      .create(this.subsidiary)
      .then(() => {
        this.$emit('close');
      })
      .finally(() => (this.isSubmitting = false));
  }
}
