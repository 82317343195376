
import { Component, Prop, ProvideReactive, Vue } from 'vue-property-decorator';
import { FlexibleLayoutGridDefinition } from '@/views/dashboard/flexible-layout-grid';

/**
 * Defines a grid layout with rows and columns as specified in `layout`.
 *
 * The grid has 12 columns with a fixed column and row gap. It can have any
 * number of rows and has no height limit.
 *
 * `layout` is an array of row definitions, which are just and array of columns
 * widths. It only applies to desktop devices. On tablet and mobile devices it
 * will stack the elements in a single column spanning the full width.
 *
 * Slots:
 * - `widget-N`, where N = {0, 1, 2, ...}
 *
 * Example:
 * ```
 * const layout = [
 *   [4, 8],
 *   [12],
 * ];
 *
 * <grid-layout :layout="layout">
 *   <template #widget-0>W0</div>
 *   <template #widget-1>W1</div>
 *   <template #widget-2>W2</div>
 * </grid-layout>
 * ```
 *
 * on desktop, it would render like this:
 *
 * ```
 * |-------------|--------------------|
 * | W0 (4 cols) | W1 (8 cols)        |
 * |-------------|--------------------|
 * | W3 (12 cols)                     |
 * |----------------------------------|
 * ```
 *
 * It's implemented with [Bulma's Columns](http://versions.bulma.io/0.9.3/documentation/columns/).
 */
@Component
export default class FlexibleLayoutGrid extends Vue {
  @Prop({ required: true })
  private layout!: FlexibleLayoutGridDefinition;

  // Just to stop Vue from complaining about SingleWidgetContainer not having
  // these injected
  @ProvideReactive('technologyTenantIdDh')
  private technologyTenantIdDh = null;
  @ProvideReactive('supportTenantIdDh')
  private supportTenantIdDh = null;

  private getWidgetIndex(rowIndex: number, columnIndex: number): number {
    const processedWidgetsCount = this.layout.rows
      .slice(0, rowIndex)
      .reduce((totalWidgets, currentRow) => totalWidgets + currentRow.length, 0);
    return processedWidgetsCount + columnIndex;
  }
}
