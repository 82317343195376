
import { Component, Vue } from 'vue-property-decorator';
import { Subsidiary } from '@/models/subsidiary';
import { subsidiariesService } from '@/services/subsidiaries.service';
import { Routes } from '@/router/routes';
import SubsidiaryCreationModal from '../../components/subsidiaries/SubsidiaryCreationModal.vue';
import { UserType } from '@/models/userType';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import TableWithActions from '@/components/tableWithActions/TableWithActions.vue';
import { usersService } from '@/services/users.service';
import { hasCurrentUserPermission } from '@/utils/permissionUtils';
import { modalService } from '@/library-services/modal.service';
import { dialogService } from '@/library-services/dialog.service';

@Component({
  components: {
    TableActionButton,
    TableWithActions,
  },
})
export default class Subsidiaries extends Vue {
  private subsidiaries: Subsidiary[] = [];

  private isLoading = false;

  private confirmDelete(subsidiary: Subsidiary): void {
    dialogService.confirmWithPromise({
      title: this.$i18n.t('dialog.confirm_delete_title').toString(),
      message: `${this.$i18n
        .t('confirmation.message', {
          action: this.$i18n.t('action.delete').toString().toLowerCase(),
          entity: this.$i18n.t('subsidiary.name').toString().toLowerCase(),
          name: subsidiary.name,
        })
        .toString()}
				${this.$i18n.t('subsidiary.msg_delete').toString()}`,
      confirmText: this.$i18n.t('dialog.confirm_delete').toString(),
      cancelText: this.$i18n.t(`dialog.confirm_cancel`).toString(),
      type: 'is-danger',
      onConfirm: async () => await this.deleteSubsidiary(subsidiary.id),
    });
  }

  private async created() {
    await this.getSubsidiaries();
  }

  private async startEdit(subsidiary: Subsidiary) {
    this.$router.push({ name: Routes.SubsidiaryDetail, params: { id: subsidiary.id.toString() } });
  }

  private createSubsidiary() {
    modalService.open({
      parent: this,
      component: SubsidiaryCreationModal,
      events: {
        close: async () => await this.getSubsidiaries(),
      },
    });
  }

  private async getSubsidiaries() {
    this.isLoading = true;
    this.subsidiaries = await subsidiariesService.get().finally(() => (this.isLoading = false));
  }

  private async deleteSubsidiary(id: number) {
    await subsidiariesService.delete(id).then(() => {
      this.subsidiaries = this.subsidiaries.filter((x) => x.id !== id);
    });
  }

  private get canEditSubsidiaries(): boolean {
    return hasCurrentUserPermission(UserType.SubsidiaryAdmin);
  }

  private get canCreateOrDeleteSubsidiaries(): boolean {
    return usersService.store.current().isGlobalAdmin;
  }
}
