// --------------------------------------------------------------------------------
// <copyright file="state.ts" company="Bystronic Laser AG">
//  Copyright (C) Bystronic Laser AG 2021-2024
// </copyright>
// --------------------------------------------------------------------------------

import { User } from '@/models/user';
import PersistentState from './persistentState';

const state: PersistentState = {
  locale: '',
  stream: '',
  timezones: [],
  currencyCodes: [],
  measurements: [],
  deviceBaseModels: [],
  currentUser: {} as User,
  notifications: [],
  tenant: null,
  devices: [],
  shifts: [],
  targets: [],
  userTargets: [],
  kpiGroups: [],
  eventModules: [],
  eventCodes: [],
};

export default state;
