
import { Component, Prop, Vue } from 'vue-property-decorator';

type PositionProp = 'left' | 'right';

@Component
export default class IconSwitch extends Vue {
  @Prop({ required: false })
  private leftIconPack?: string;

  @Prop({ required: false })
  private rightIconPack?: string;

  @Prop({ required: false })
  private iconPack?: string;

  @Prop({ required: true, default: '' })
  private leftIcon!: string;

  @Prop({ required: true, default: '' })
  private rightIcon!: string;

  @Prop()
  private initialPosition?: PositionProp;

  private value: boolean = false;

  private created() {
    if (this.initialPosition) {
      this.value = this.initialPosition === 'right';
    }
  }

  private onClick($event: any): void {
    this.value = $event.target.checked;
    this.$emit('switch-image', this.value);
  }

  private getRightIconPack(): string {
    return (
      (!!this.rightIconPack && this.rightIconPack) || (!!this.iconPack && this.iconPack) || 'mdi'
    );
  }

  private getLeftIconPack(): string {
    return (
      (!!this.leftIconPack && this.leftIconPack) || (!!this.iconPack && this.iconPack) || 'mdi'
    );
  }
}
